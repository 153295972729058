import { createAsyncThunk } from '@reduxjs/toolkit';
import moment from 'moment';

import { APIs } from '../../constants/APIConstants';
import { DATE_RANGE_FORMAT_API } from '../../constants/Constants';
import { formatDateAPI } from '../../utils';
import { SUPPLIER } from '../actionPrefix';
import { axiosInstanceTechnician } from '../axios';

const {
  CREATE_SUPPLIER,
  EDIT_SUPPLIER,
  GET_SUPPLIER_DROPDOWN_LIST,
  GET_SUPPLIER_LIST,
  UPDATE_SUPPLIER,
} = SUPPLIER;

export const getSupplierList = createAsyncThunk(
  GET_SUPPLIER_LIST,
  async (
    {
      page,
      search,
      size,
      active,
      country,
      state,
      city,
      order,
      orderBy,
      fromDate,
      toDate,
      list_column_names,
    },
    { rejectWithValue }
  ) => {
    const params = new URLSearchParams();

    // Add params conditionally
    if (page) params.append('page', page);
    if (search) params.append('search', search);
    if (size) params.append('items_per_page', size);
    if (active) params.append('status', active);
    if (country) params.append('country', country);
    if (state) params.append('state', state);
    if (city) params.append('city', city);
    if (order) params.append('order', order);
    if (orderBy) params.append('order_by', orderBy);
    if (fromDate)
      params.append(
        'created_at__gte',
        formatDateAPI(moment(fromDate).format(DATE_RANGE_FORMAT_API))
      );
    if (toDate)
      params.append(
        'created_at__lte',
        formatDateAPI(moment(toDate).endOf('day').format(DATE_RANGE_FORMAT_API))
      );
    if (list_column_names)
      params.append('list_column_names', list_column_names);

    try {
      const response = await axiosInstanceTechnician.get(
        `${APIs.SUPPLIER.GET_SUPPLIERS}?${params.toString()}`
      );

      return { ...response.data, active };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateSupplier = createAsyncThunk(
  UPDATE_SUPPLIER,
  async ({ uuid, data }, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceTechnician.patch(
        APIs.SUPPLIER.UPDATE_SUPPLIER.replace('{uuid}', uuid),
        data
      );

      return response.data;
    } catch (error) {
      // Forward the custom error object to the rejected promise
      return rejectWithValue(error);
    }
  }
);

export const createSupplier = createAsyncThunk(
  CREATE_SUPPLIER,
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceTechnician.post(
        APIs.SUPPLIER.CREATE_SUPPLIER,
        data
      );

      return response.data;
    } catch (error) {
      // Forward the custom error object to the rejected promise
      return rejectWithValue(error);
    }
  }
);

export const editSupplier = createAsyncThunk(
  EDIT_SUPPLIER,
  async (uuid, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceTechnician.get(
        APIs.SUPPLIER.GET_SUPPLIER_BY_ID.replace('{uuid}', uuid)
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getSupplierDropdownList = createAsyncThunk(
  GET_SUPPLIER_DROPDOWN_LIST,
  async (req, { rejectWithValue }) => {
    const params = new URLSearchParams();

    params.append('limit', -1);
    try {
      const response = await axiosInstanceTechnician.get(
        `${APIs.SUPPLIER.GET_SUPPLIERS}?${params.toString()}`
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
