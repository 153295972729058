import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { Box, Card, Divider, Typography } from '@mui/material';

import { getDefectQuote } from '../../../store/workOrder/api';
import { formatStatus } from '../../../utils';
import CustomCircularLoader from '../../CommonComponents/CustomLoader';
import ImageView from '../../CommonComponents/ImageView';

const KeyValueComponent = ({ name, value }) => (
  <Box>
    <Typography variant="body1">{name}</Typography>
    <Typography variant="body2" sx={{ textTransform: 'capitalize' }}>
      {value}
    </Typography>
  </Box>
);

const DefectsQuotesView = ({ work_order_id, defect_id }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);

  const [defect, setDefect] = useState(null);

  useEffect(() => {
    if (work_order_id && defect_id) {
      setIsLoading(true);
      dispatch(
        getDefectQuote({ work_order_id: work_order_id, defect_id: defect_id })
      )
        .then((res) => setDefect(res?.payload?.data?.[0]))
        .finally(() => setIsLoading(false));
    }
  }, [work_order_id, defect_id]);

  return isLoading ? (
    <CustomCircularLoader />
  ) : (
    <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: '16px' }}>
      <Card sx={{ boxShadow: 'none !important' }}>
        <Box sx={{ padding: '12px' }}>
          <Typography variant="body1">{`${t('attributes.work_order.defect')} ${t('attributes.supplier.details')}`}</Typography>
        </Box>
        <Divider />
        <Box
          sx={{
            padding: '12px',
            display: 'flex',
            flexDirection: 'column',
            rowGap: '16px',
            width: '100%',
          }}
        >
          <KeyValueComponent
            name={t('attributes.work_order.title')}
            value={defect?.defect_title || '-'}
          />
          <KeyValueComponent
            name={t('attributes.work_order.severity')}
            value={formatStatus(defect?.severity) || '-'}
          />
          <KeyValueComponent
            name={t('attributes.productCatalogue.description')}
            value={defect?.defect_description || '-'}
          />
          <KeyValueComponent
            name={t('attributes.work_order.resolution')}
            value={defect?.short_resolution || '-'}
          />
        </Box>
      </Card>
      <Card sx={{ boxShadow: 'none !important' }}>
        <Box sx={{ padding: '12px' }}>
          <Typography variant="body1">
            {t('attributes.work_order.photos')}
          </Typography>
        </Box>
        <Divider />
        <Box
          sx={{
            display: 'flex',
            columnGap: '16px',
            padding: '16px',
            flexWrap: 'wrap',
          }}
        >
          {defect?.documents?.length ? (
            defect?.documents?.map((doc) => (
              <ImageView uuid={doc?.document_uuid} />
            ))
          ) : (
            <>No Document Uploaded</>
          )}
        </Box>
      </Card>
    </Box>
  );
};
export default DefectsQuotesView;
