import React from 'react';

import { Box, Typography } from '@mui/material';

const QuoteTypeLabel = ({ color, backgroundColor, label, icon }) => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'left',
      alignItems: 'center',
      borderRadius: '24px',
      borderWidth: '1px',
      borderColor: color,
      borderStyle: 'solid',
      backgroundColor: backgroundColor,
      width: '85px',
      pl: '8px',
    }}
  >
    {icon}
    <Typography
      variant="body2"
      sx={{
        color: color,
        paddingLeft: '8px',
      }}
    >
      {label}
    </Typography>
  </Box>
);

export default QuoteTypeLabel;
