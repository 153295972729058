import { createAsyncThunk } from '@reduxjs/toolkit';

import { APIs } from '../../constants/APIConstants';
import { FREQUENCY } from '../actionPrefix';
import { axiosPropertyUrl } from '../axios';

// Get Frequency List
export const getFrequencyList = createAsyncThunk(
  FREQUENCY.GET_FREQUENCY_LIST,
  async (
    { page, size, search, limit, active, order, orderBy },
    { rejectWithValue }
  ) => {
    const params = new URLSearchParams();

    // Add params conditionally
    if (page) params.append('page', page);
    if (size) params.append('size', size);
    if (search) params.append('search', search);
    if (limit) params.append('limit', limit);
    if (active) params.append('active', active);
    if (order) params.append('sort_order', order);
    if (orderBy) params.append('sort_by', orderBy);
    try {
      const response = await axiosPropertyUrl.get(
        `${APIs.FREQUENCY.GET_FREQUENCY_LIST}?${params.toString()}`
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
