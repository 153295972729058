import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { Box, Typography } from '@mui/material';

import { getDocument } from '../../store/CommonAPI';
import CustomCircularLoader from './CustomLoader';

const PdfView = ({ id }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [pdfData, setPdfData] = useState(null);

  useEffect(() => {
    if (id) {
      setLoading(true);
      dispatch(getDocument({ doc_uuid: id, isPreview: '&content_inline=true' }))
        .then((res) => setPdfData(res?.payload?.data?.[0]))
        .finally(() => setLoading(false));
    }
  }, [id]);

  if (!id) {
    return (
      <Box
        height={'100%'}
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <Typography variant="body1">
          {t('common.no')} {t('attributes.work_order.routineServiceReport')}{' '}
          {t('common.available')}
        </Typography>
      </Box>
    );
  }

  return loading ? (
    <CustomCircularLoader />
  ) : (
    <>
      <iframe
        src={pdfData?.presigned_url}
        title="PDF Viewer"
        width="100%"
        height="99%"
        style={{ border: 'none' }}
      />
    </>
  );
};

export default PdfView;
