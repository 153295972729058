import React, { useRef } from 'react';

import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Box, Tooltip, Typography } from '@mui/material';
import { GoogleMap, OverlayView, useLoadScript } from '@react-google-maps/api';

import { PRIMARY } from '../../constants/Colors';

const containerStyle = {
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const options = {
  fullscreenControl: true, // Hides the fullscreen control
  zoomControl: false, // Hides zoom in/out controls
  mapTypeControl: false, // Hides the map type control (e.g., satellite)
  streetViewControl: false, // Hides the Street View control
  keyboardShortcuts: false, // Disables keyboard shortcuts
  disableDefaultUI: true, // Disables all default UI (can use custom controls)
  scaleControl: false,
};

const CustomMarker = ({ color = PRIMARY, text = 10 }) => (
  <Box
    sx={{
      height: '35px',
      width: '35px',
      background: color,
      borderRadius: '50%',
      color: 'white',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <Typography variant="body1">{`${text}+`}</Typography>
  </Box>
);

// Group markers by lat, lng
const groupMarkersByLocation = (markers) => {
  const markerMap = {};

  markers.forEach((marker) => {
    const key = `${marker.lat}_${marker.lng}`;

    if (markerMap[key]) {
      markerMap[key].count += 1;
    } else {
      markerMap[key] = { ...marker, count: 1 };
    }
  });

  return Object.values(markerMap);
};

// Calculate the center point from all marker coordinates
const calculateCenter = (markers) => {
  const latSum = markers.reduce((sum, marker) => sum + marker.lat, 0);
  const lngSum = markers.reduce((sum, marker) => sum + marker.lng, 0);

  return {
    lat: latSum / markers.length,
    lng: lngSum / markers.length,
  };
};

const CustomGoogleMap = ({ isDashboard = true, markers = [] }) => {
  const mapRef = useRef(null);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_KEY,
  });

  if (!isLoaded) return <div>Loading...</div>;

  const groupedMarkers = groupMarkersByLocation(markers);
  const center = calculateCenter(markers); // Calculate the center dynamically

  return (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={12}
      options={options}
      onLoad={(map) => (mapRef.current = map)}
    >
      {groupedMarkers.map((marker) => {
        if (!marker.lat || !marker.lng) {
          return (
            <Box
              key={`${marker.lat}_${marker.lng}`}
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
              }}
            >
              <Typography variant="body2">No map found</Typography>
            </Box>
          );
        }

        return (
          <OverlayView
            key={`${marker.lat}_${marker.lng}`}
            position={{ lat: marker.lat, lng: marker.lng }}
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
          >
            <Tooltip title={marker.tooltipText || ''} placement="top" arrow>
              {isDashboard ? (
                <CustomMarker color={marker?.color} text={marker?.count} />
              ) : (
                <LocationOnIcon
                  sx={{
                    height: '30px',
                    width: '30px',
                    color: marker.color,
                  }}
                />
              )}
            </Tooltip>
          </OverlayView>
        );
      })}
    </GoogleMap>
  );
};

export default CustomGoogleMap;
