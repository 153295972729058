import { createSlice } from '@reduxjs/toolkit';

import {
  createInvoice,
  createInvoiceProduct,
  deleteInvoiceProduct,
  getInvoiceList,
  getInvoicePropertyDropdownList,
  getInvoiceStatusDropdownList,
  getInvoiceTypeDropdownList,
  updateInvoice,
  updateInvoiceProduct,
} from './api';

const invoicesTypesDropdownList = {
  isLoading: true,
  error: false,
  data: false,
};

const invoicesStatusDropdownList = {
  isLoading: true,
  error: false,
  data: false,
};

const invoicesPropertyDropdownList = {
  isLoading: true,
  error: false,
  data: false,
};

const initialData = {
  isLoading: false,
  error: false,
  data: false,
};

const initialGetData = {
  isLoading: true,
  error: null,
  invoiceList: [],
  page: 1,
  size: 10,
  total: 0,
  totalCount: 0,
};

const invoiceSlice = createSlice({
  name: 'invoices',
  initialState: {
    get: { ...initialGetData },
    create: { ...initialData },
    update: { ...initialData },
    createProduct: { ...initialData },
    updateProduct: { ...initialData },
    deleteProduct: { ...initialData },
    invoicesTypesDropdownList,
    invoicesStatusDropdownList,
    invoicesPropertyDropdownList,
  },
  reducers: {
    resetInvoiceList(state) {
      state.get = { ...initialGetData };
    },

    resetCreateInvoice(state) {
      state.create = { ...initialData };
    },

    resetUpdateInvoice(state) {
      state.update = { ...initialData };
    },

    resetCreateInvoiceProduct(state) {
      state.createProduct = { ...initialData };
    },

    resetUpdateInvoiceProduct(state) {
      state.updateProduct = { ...initialData };
    },

    resetDeleteInvoiceProduct(state) {
      state.deleteProduct = { ...initialData };
    },
  },
  extraReducers: (builder) => {
    builder
      // Invoice List
      .addCase(getInvoiceList.pending, (state) => {
        state.get.isLoading = true;
      })
      .addCase(getInvoiceList.fulfilled, (state, action) => {
        const { data, pagination } = action.payload;

        state.get.isLoading = false;
        state.get.invoiceList = data;
        state.get.total = pagination.total_items;
        state.get.totalCount = pagination.total_count;
      })
      .addCase(getInvoiceList.rejected, (state, action) => {
        state.get.isLoading = false;
        state.get.error = action.payload;
      })

      // Get Invoice type List
      .addCase(getInvoiceTypeDropdownList.pending, (state) => {
        state.invoicesTypesDropdownList.isLoading = true;
      })
      .addCase(getInvoiceTypeDropdownList.fulfilled, (state, action) => {
        state.invoicesTypesDropdownList.isLoading = false;
        state.invoicesTypesDropdownList.data = action.payload;
      })
      .addCase(getInvoiceTypeDropdownList.rejected, (state, action) => {
        state.invoicesTypesDropdownList.isLoading = false;
        state.invoicesTypesDropdownList.error = action.error.message;
      })

      // Get Invoice Property List
      .addCase(getInvoicePropertyDropdownList.pending, (state) => {
        state.invoicesPropertyDropdownList.isLoading = true;
      })
      .addCase(getInvoicePropertyDropdownList.fulfilled, (state, action) => {
        state.invoicesPropertyDropdownList.isLoading = false;
        state.invoicesPropertyDropdownList.data = action.payload;
      })
      .addCase(getInvoicePropertyDropdownList.rejected, (state, action) => {
        state.invoicesPropertyDropdownList.isLoading = false;
        state.invoicesPropertyDropdownList.error = action.error.message;
      })

      // Get Invoice Status List
      .addCase(getInvoiceStatusDropdownList.pending, (state) => {
        state.invoicesStatusDropdownList.isLoading = true;
      })
      .addCase(getInvoiceStatusDropdownList.fulfilled, (state, action) => {
        state.invoicesStatusDropdownList.isLoading = false;
        state.invoicesStatusDropdownList.data = action.payload;
      })
      .addCase(getInvoiceStatusDropdownList.rejected, (state, action) => {
        state.invoicesStatusDropdownList.isLoading = false;
        state.invoicesStatusDropdownList.error = action.error.message;
      })

      // Create Invoice
      .addCase(createInvoice.pending, (state) => {
        state.create.isLoading = true;
      })
      .addCase(createInvoice.fulfilled, (state, action) => {
        state.create.isLoading = false;
        state.create.error = false;
        state.create.data = action.payload;
      })
      .addCase(createInvoice.rejected, (state, action) => {
        state.create.isLoading = false;
        state.create.error = action.payload;
      })

      // Update Invoice
      .addCase(updateInvoice.pending, (state) => {
        state.update.isLoading = true;
      })
      .addCase(updateInvoice.fulfilled, (state, action) => {
        state.update.isLoading = false;
        state.update.error = false;
        state.update.data = action.payload;
      })
      .addCase(updateInvoice.rejected, (state, action) => {
        state.update.isLoading = false;
        state.update.error = action.payload;
      })

      .addCase(createInvoiceProduct.pending, (state) => {
        state.createProduct.isLoading = true;
      })
      .addCase(createInvoiceProduct.fulfilled, (state, action) => {
        state.createProduct.isLoading = false;
        state.createProduct.error = false;
        state.createProduct.data = action.payload;
      })
      .addCase(createInvoiceProduct.rejected, (state, action) => {
        state.createProduct.isLoading = false;
        state.createProduct.error = action.payload;
      })

      .addCase(updateInvoiceProduct.pending, (state) => {
        state.updateProduct.isLoading = true;
      })
      .addCase(updateInvoiceProduct.fulfilled, (state, action) => {
        state.updateProduct.isLoading = false;
        state.updateProduct.error = false;
        state.updateProduct.data = action.payload;
      })
      .addCase(updateInvoiceProduct.rejected, (state, action) => {
        state.updateProduct.isLoading = false;
        state.updateProduct.error = action.payload;
      })

      .addCase(deleteInvoiceProduct.pending, (state) => {
        state.deleteProduct.isLoading = true;
      })
      .addCase(deleteInvoiceProduct.fulfilled, (state, action) => {
        state.deleteProduct.isLoading = false;
        state.deleteProduct.error = false;
        state.deleteProduct.data = action.payload;
      })
      .addCase(deleteInvoiceProduct.rejected, (state, action) => {
        state.deleteProduct.isLoading = false;
        state.deleteProduct.error = action.payload;
      });
  },
});

export const {
  resetInvoiceList,
  resetCreateInvoice,
  resetUpdateInvoice,
  resetCreateInvoiceProduct,
  resetUpdateInvoiceProduct,
  resetDeleteInvoiceProduct,
} = invoiceSlice.actions;

export default invoiceSlice.reducer;
