import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { Box, Divider, styled, Typography } from '@mui/material';

import { ComingSoon } from '../../assets/images';
import { getAssetDetails } from '../../store/property/api';
import { formattedDate, loggedInUserRole } from '../../utils';
import CustomCircularLoader from '../CommonComponents/CustomLoader';
import StatusLabel from '../CommonComponents/StatusLabel';

const StyledHeaderBox = styled(Box)(({ value }) => ({
  padding: '7px 16px',
  backgroundColor: value ? 'rgba(255, 255, 255, 1)' : 'rgb(235, 235, 235, 0.2)',
  display: 'flex',
  justifyContent: 'space-between',
}));

const StyledContentBox = styled(Box)(() => ({
  display: 'flex',
  gap: '24px',
  padding: '16px 0 16px 16px',
  width: '50%',
}));

const StyledColumnBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
}));

const Centered = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  paddingTop: '100px',
  flexDirection: 'column',
  gap: '20px',
});

const KeyTypography = styled(Typography)(({ theme }) => ({}));
const ValueTypography = styled(Typography)(({ theme }) => ({}));

const AssetDetails = ({ setOpen, setAssetId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { assetDetails } = useSelector((state) => state.property);
  const {
    equipment_type,
    product,
    label,
    location,
    status,
    is_active,
    identification_number,
    serial_number,
    barcode,
    make,
    model,
    size,
    quantity,
    base_date,
    installation_date,
    internal_note,
    id,
  } = assetDetails.data;

  const { data: updatedAsset } = useSelector(
    (state) => state.property.updatedAsset
  );

  useEffect(() => {
    updatedAsset && dispatch(getAssetDetails({ assetId: id }));
  }, [updatedAsset]);

  const formatStatus = (status) => {
    const lowerCaseStatus =
      typeof status === 'string' ? status?.toLowerCase() : status;

    if (lowerCaseStatus === 'fail') {
      return <StatusLabel label="Fail" color="#C54036" />;
    }
    if (lowerCaseStatus === 'pass') {
      return <StatusLabel label="Pass" color="#95C020" />;
    }
    if (lowerCaseStatus === 'no check') {
      return <StatusLabel label="No Check" color="#59366B" />;
    }

    if (lowerCaseStatus === true) {
      return <StatusLabel label="Yes" color="#59C3C3" />;
    }
    if (lowerCaseStatus === false) {
      return <StatusLabel label="No" color="#454545" />;
    }
  };

  return assetDetails?.loading ? (
    <Box sx={{ paddingTop: '180px' }}>
      <CustomCircularLoader />
    </Box>
  ) : (
    <>
      <Box sx={{ overflowY: 'auto', height: 'calc(100vh - 170px)' }}>
        <StyledHeaderBox>
          <Typography variant="body1">
            {t('common.general_information')}
          </Typography>
          {loggedInUserRole() !== 'customer' && (
            <ModeEditOutlineOutlinedIcon
              onClick={() => {
                setOpen(true);
                setAssetId(id);
              }}
            />
          )}
        </StyledHeaderBox>
        <Divider />
        {/* Details */}
        <Box sx={{ display: 'flex', width: '100%' }}>
          {/* Left Side Content */}
          <StyledContentBox sx={{ width: '100%' }}>
            <StyledColumnBox sx={{ gap: 1, width: '100%' }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <KeyTypography minWidth="200px" variant="body1">
                  {t('attributes.property.equipment_type')}
                </KeyTypography>
                <ValueTypography variant="body2">
                  {equipment_type?.display_name ?? '-'}
                </ValueTypography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <KeyTypography minWidth="200px" variant="body1">
                  {t('attributes.property.equipment_class')}
                </KeyTypography>
                <ValueTypography variant="body2">
                  {product?.display_name ?? '-'}
                </ValueTypography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'start' }}>
                <KeyTypography minWidth="200px" variant="body1">
                  {t('attributes.property.asset_name')}
                </KeyTypography>
                <Box>
                  <ValueTypography variant="body2">
                    {label ?? '-'}
                  </ValueTypography>
                </Box>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <KeyTypography minWidth="200px" variant="body1">
                  {t('attributes.location')}
                </KeyTypography>
                <ValueTypography variant="body2">
                  {location ?? '-'}
                </ValueTypography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <KeyTypography minWidth="200px" variant="body1">
                  {t('attributes.property.status')}
                </KeyTypography>
                <ValueTypography variant="body2">
                  {formatStatus(status?.name) ?? '-'}
                </ValueTypography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <KeyTypography minWidth="200px" variant="body1">
                  {t('common.active')}
                </KeyTypography>
                <ValueTypography variant="body2">
                  {formatStatus(is_active) ?? '-'}
                </ValueTypography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <KeyTypography minWidth="200px" variant="body1">
                  {t('attributes.property.identification_number')}:
                </KeyTypography>
                <ValueTypography variant="body2">
                  {identification_number ?? '-'}
                </ValueTypography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <KeyTypography minWidth="200px" variant="body1">
                  {t('attributes.property.serial_number')}:
                </KeyTypography>
                <ValueTypography variant="body2">
                  {serial_number ?? '-'}
                </ValueTypography>
              </Box>
            </StyledColumnBox>
          </StyledContentBox>

          {/* Center Divider */}
          <Divider orientation="vertical" flexItem />

          {/* Right Side Content */}
          <StyledContentBox sx={{ width: '100%' }}>
            <StyledColumnBox sx={{ gap: 1, width: '100%' }}>
              <Box sx={{ display: 'flex' }}>
                <KeyTypography minWidth="148px" variant="body1">
                  {t('attributes.property.barcode')}:
                </KeyTypography>
                <ValueTypography variant="body2">
                  {barcode ?? '-'}
                </ValueTypography>
              </Box>
              <Box sx={{ display: 'flex' }}>
                <KeyTypography minWidth="148px" variant="body1">
                  {t('attributes.property.make')}:
                </KeyTypography>
                <ValueTypography variant="body2">{make ?? '-'}</ValueTypography>
              </Box>
              <Box sx={{ display: 'flex' }}>
                <KeyTypography minWidth="148px" variant="body1">
                  {t('attributes.property.model')}:
                </KeyTypography>
                <ValueTypography variant="body2">
                  {model ?? '-'}
                </ValueTypography>
              </Box>
              <Box sx={{ display: 'flex' }}>
                <KeyTypography minWidth="148px" variant="body1">
                  {t('attributes.property.size')}:
                </KeyTypography>
                <ValueTypography variant="body2">{size ?? '-'}</ValueTypography>
              </Box>
              <Box sx={{ display: 'flex' }}>
                <KeyTypography minWidth="148px" variant="body1">
                  {t('attributes.property.quantity')}:
                </KeyTypography>
                <ValueTypography variant="body2">
                  {quantity ?? '-'}
                </ValueTypography>
              </Box>
              <Box sx={{ display: 'flex' }}>
                <KeyTypography minWidth="148px" variant="body1">
                  {t('attributes.property.base_date')}:
                </KeyTypography>
                <ValueTypography variant="body2">
                  {formattedDate(base_date)}
                </ValueTypography>
              </Box>
              <Box sx={{ display: 'flex' }}>
                <KeyTypography minWidth="148px" variant="body1">
                  {t('attributes.property.installation_date')}:
                </KeyTypography>
                <ValueTypography variant="body2">
                  {formattedDate(installation_date)}
                </ValueTypography>
              </Box>
              <Box sx={{ display: 'flex' }}>
                <KeyTypography minWidth="148px" variant="body1">
                  {t('attributes.property.internal_note')}:
                </KeyTypography>
                <ValueTypography variant="body2">
                  {internal_note ?? '-'}
                </ValueTypography>
              </Box>
            </StyledColumnBox>
          </StyledContentBox>
        </Box>
        <Divider />

        <Centered>
          <img
            src={ComingSoon}
            alt="Coming Soon"
            style={{ width: '90px', height: '90px' }}
          />
          <Typography variant="body1">Floor Plan Coming Soon</Typography>
        </Centered>
      </Box>
    </>
  );
};

export default AssetDetails;
