import React from 'react';

import { Box, styled, Typography } from '@mui/material';

import FireLogo from '../../assets/images/fire_logo.svg';
const InvoiceContainer = styled(Box)(() => ({
  backgroundColor: 'white',
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
}));

const QuoteInvoice = () => {
  // Static data for the table
  const items = [
    {
      description: 'Front and rear brake cables',
      qty: 1,
      unitPrice: 100.0,
      amount: 100.0,
    },
    {
      description: 'New set of pedal arms',
      qty: 1,
      unitPrice: 15.0,
      amount: 30.0,
    },
    {
      description: 'Labor 3hrs',
      qty: 1,
      unitPrice: 5.0,
      amount: 15.0,
    },
  ];

  // Calculate Subtotal, Tax, and Total
  const subTotal = items.reduce((sum, item) => sum + item.amount, 0);
  const tax = subTotal * 0.0625;
  const total = subTotal + tax;

  return (
    <InvoiceContainer>
      {/* Header */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box>
          <Box>
            <Typography variant="body1">East Repair Inc.</Typography>
            <Typography variant="body2">
              1912 Harvest Lane
              <br />
              New York, NY, 12210
            </Typography>
          </Box>
          <Box sx={{ paddingTop: '16px' }}>
            <Typography variant="body1">Bill To</Typography>
            <Typography variant="body2">
              John Smith <br />2 Court Square <br /> New York, NY, 12210
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'end',
            alignContent: 'end',
          }}
        >
          <img src={FireLogo} alt="Fire Logo" height="40px" width="107px" />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              gap: '20px',
            }}
          >
            <Box sx={{ textAlign: 'right' }}>
              <Typography variant="body1">Invoice Number</Typography>
              <Typography variant="body1">Ref #</Typography>
              <Typography variant="body1">Invoice Date</Typography>
              <Typography variant="body1">Invoice Due</Typography>
            </Box>

            <Box sx={{ textAlign: 'right' }}>
              <Typography variant="body2">US - 001</Typography>
              <Typography variant="body2">123456</Typography>
              <Typography variant="body2">11/02/2023</Typography>
              <Typography variant="body2">11/03/2023</Typography>
            </Box>
          </Box>
        </Box>
      </Box>

      {/* Property Table */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%',
        }}
      >
        <Box sx={{ paddingTop: '40px' }}>
          <Box sx={{ display: 'flex', gap: '4px' }}>
            <Typography variant="body1">Property Name:</Typography>
            <Typography variant="body2">Chubb Fire & Security</Typography>
          </Box>
          <Box sx={{ display: 'flex', gap: '4px' }}>
            <Typography variant="body1">Scope:</Typography>
            <Typography variant="body2">
              Pumpsets (2005): Weekly (3) Sprinklers (2005): Weekly (3)
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', gap: '4px' }}>
            <Typography variant="body1">Note:</Typography>
          </Box>
          <Box sx={{ paddingTop: '16px' }}>
            <table style={{ width: '100%', borderCollapse: 'collapse' }}>
              <thead>
                <tr>
                  <th
                    style={{
                      padding: '8px',
                      border: '1px solid #EBEBEB',
                      textAlign: 'left',
                      backgroundColor: '#ebebeb20',
                    }}
                  >
                    <Typography variant="body1">DESCRIPTION</Typography>
                  </th>
                  <th
                    style={{
                      padding: '8px',
                      border: '1px solid #EBEBEB',
                      textAlign: 'center',
                      backgroundColor: '#ebebeb20',
                    }}
                  >
                    <Typography variant="body1">QTY</Typography>
                  </th>
                  <th
                    style={{
                      padding: '8px',
                      border: '1px solid #EBEBEB',
                      textAlign: 'right',
                      backgroundColor: '#ebebeb20',
                    }}
                  >
                    <Typography variant="body1">UNIT PRICE</Typography>
                  </th>
                  <th
                    style={{
                      padding: '8px',
                      border: '1px solid #EBEBEB',
                      textAlign: 'right',
                      backgroundColor: '#ebebeb20',
                    }}
                  >
                    <Typography variant="body1">AMOUNT</Typography>
                  </th>
                </tr>
              </thead>
              <tbody>
                {items.map((item, index) => (
                  <tr key={index}>
                    <td
                      style={{
                        padding: '8px',
                        border: '1px solid #EBEBEB',
                        textAlign: 'left',
                      }}
                    >
                      <Typography variant="body2">
                        {item.description}
                      </Typography>
                    </td>
                    <td
                      style={{
                        padding: '8px',
                        border: '1px solid #EBEBEB',
                        textAlign: 'center',
                      }}
                    >
                      {item.qty}
                    </td>
                    <td
                      style={{
                        padding: '8px',
                        border: '1px solid #EBEBEB',
                        textAlign: 'right',
                      }}
                    >
                      <Typography variant="body2">
                        ${item.unitPrice.toFixed(2)}
                      </Typography>
                    </td>
                    <td
                      style={{
                        padding: '8px',
                        border: '1px solid #EBEBEB',
                        textAlign: 'right',
                      }}
                    >
                      <Typography variant="body2">
                        ${item.amount.toFixed(2)}
                      </Typography>
                    </td>
                  </tr>
                ))}

                {/* Subtotal, Tax, Total rows */}
                <tr>
                  <td
                    colSpan={3}
                    style={{
                      padding: '8px',
                      textAlign: 'right',
                      border: 'none',
                    }}
                  >
                    <Typography variant="body1">Subtotal</Typography>
                  </td>
                  <td
                    style={{
                      padding: '8px',
                      textAlign: 'right',
                      border: '1px solid #EBEBEB',
                    }}
                  >
                    <Typography variant="body2">
                      ${subTotal.toFixed(2)}
                    </Typography>
                  </td>
                </tr>
                <tr>
                  <td
                    colSpan={3}
                    style={{
                      padding: '8px',
                      textAlign: 'right',
                      border: 'none',
                    }}
                  >
                    <Typography variant="body1">Tax (6.25%)</Typography>
                  </td>
                  <td
                    style={{
                      padding: '8px',
                      textAlign: 'right',
                      border: '1px solid #EBEBEB',
                    }}
                  >
                    <Typography variant="body2">${tax.toFixed(2)}</Typography>
                  </td>
                </tr>
                <tr>
                  <td
                    colSpan={3}
                    style={{
                      padding: '8px',
                      textAlign: 'right',
                      border: 'none',
                    }}
                  >
                    <Typography variant="body1">TOTAL</Typography>
                  </td>
                  <td
                    style={{
                      padding: '8px',
                      textAlign: 'right',
                      border: '1px solid #EBEBEB',
                    }}
                  >
                    <Typography variant="body1">${total.toFixed(2)}</Typography>
                  </td>
                </tr>
              </tbody>
            </table>
          </Box>
        </Box>
      </Box>
    </InvoiceContainer>
  );
};

export default QuoteInvoice;
