import { createSlice } from '@reduxjs/toolkit';

import { getStandardsDropdownList } from './api';

const standardsDropdownList = {
  isLoading: true,
  error: false,
  data: [],
};

const standardsSlice = createSlice({
  name: 'standards',
  initialState: {
    standardsDropdownList,
  },
  reducers: {
    resetStandardsDropdownList(state) {
      state.standardsDropdownList = { ...standardsDropdownList };
    },
  },
  extraReducers: (builder) => {
    builder
      // Get Standards Dropdown List
      .addCase(getStandardsDropdownList.pending, (state) => {
        state.standardsDropdownList.isLoading = true;
      })
      .addCase(getStandardsDropdownList.fulfilled, (state, action) => {
        state.standardsDropdownList.isLoading = false;
        state.standardsDropdownList.data = action.payload;
      })
      .addCase(getStandardsDropdownList.rejected, (state, action) => {
        state.standardsDropdownList.isLoading = false;
        state.standardsDropdownList.error = action.error.message;
      });
  },
});

export const { resetStandardsDropdownList } = standardsSlice.actions;

export default standardsSlice.reducer;
