import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';

import { useMsal } from '@azure/msal-react';
import { Backdrop } from '@mui/material';
import moment from 'moment/moment';

import { msalInstance } from '../App';
import {
  default as CustomCircularLoader,
  default as CustomLoader,
} from '../components/CommonComponents/CustomLoader';
import { InformativeModal } from '../components/CommonComponents/Modal';
import { getAuthority } from '../components/LandingPage';
import { ROUTENAME } from '../constants/RoutesConstants';
import { getMe } from '../store/CommonAPI';
import { isSsoSilentTokenAquired } from '../store/CommonReducer';

const AuthLayout = ({ children }) => {
  const { accounts } = useMsal();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isAuthSuccess, isAuthLoading, isUnAuthorised, silentTokenSuccess } =
    useSelector((state) => state.common);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const sessionExpiredOrInvalid =
    silentTokenSuccess?.sessionExpired || (isUnAuthorised && !isAuthLoading);

  useEffect(() => {
    if (accounts?.length) {
      setIsAuthenticated(true);
    }
    setIsLoading(false);
  }, [accounts]);

  useEffect(() => {
    const aquireSsoSilentToken = () => {
      const expirationTime = localStorage.getItem('expirationTime');

      if (!expirationTime) return;

      const expDate = moment(expirationTime * 1000); // Make sure expirationTime is correct format
      const currentTime = moment();
      const timeDifferenceInSeconds = expDate.diff(currentTime, 'seconds');

      if (timeDifferenceInSeconds <= 60) {
        if (
          !msalInstance.getActiveAccount() &&
          msalInstance.getAllAccounts().length > 0
        ) {
          // Account selection logic is app dependent. Adjust as needed for different use cases.
          msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
        }

        const account = msalInstance.getActiveAccount();

        const loginType = localStorage.getItem('loginType');
        const userInfo = JSON.parse(localStorage.getItem('user_info'));

        let signUpSignInFlowRequest = {
          authority: getAuthority(loginType),
          account: account,
        };

        if (
          !signUpSignInFlowRequest.account ||
          account?.username !== userInfo?.email
        ) {
          console.error('No matching account found for the user.');
          dispatch(
            isSsoSilentTokenAquired({
              silentTokenSuccess: false,
              sessionExpired: true,
            })
          );

          return;
        }

        msalInstance
          .acquireTokenSilent(signUpSignInFlowRequest)
          .then((res) => {
            localStorage.setItem('idToken', res?.idToken);
            localStorage.setItem('expirationTime', res?.idTokenClaims?.exp);
            dispatch(getMe());
          })
          .catch((error) => {
            console.error('Silent token refresh failed:', error);
            dispatch(
              isSsoSilentTokenAquired({
                silentTokenSuccess: false,
                sessionExpired: true,
              })
            );
          });
      } else {
        if (!silentTokenSuccess?.isSsoSuccess) {
          dispatch(
            isSsoSilentTokenAquired({
              silentTokenSuccess: true,
              sessionExpired: false,
            })
          );
        }
      }
    };

    aquireSsoSilentToken();
    const callbackId = setInterval(aquireSsoSilentToken, 10000); // Increase interval

    return () => {
      clearInterval(callbackId);
    };
  }, [accounts, localStorage.getItem('user_info')]);

  if (
    !silentTokenSuccess?.isSsoSuccess &&
    !silentTokenSuccess?.sessionExpired &&
    localStorage.getItem('user_info')
  ) {
    return (
      <Backdrop
        sx={(theme) => ({ zIndex: theme.zIndex.drawer + 1 })}
        open={!silentTokenSuccess?.isSsoSuccess}
      >
        <CustomCircularLoader />
      </Backdrop>
    );
  }

  if (!isAuthSuccess && isAuthLoading) {
    return (
      <Backdrop
        sx={(theme) => ({ zIndex: theme.zIndex.drawer + 1 })}
        open={isAuthLoading}
      >
        <CustomCircularLoader />
      </Backdrop>
    );
  }

  if (sessionExpiredOrInvalid) {
    return (
      <InformativeModal
        open={sessionExpiredOrInvalid}
        title={t('common.sessionExpiredTitle')}
        description={t('common.sessionExpiredDescription')}
        onConfirm={() => {
          localStorage.clear();
          navigate(ROUTENAME.DEFAULT_ROUTE);
        }}
      />
    );
  }

  return isLoading ? (
    <CustomLoader />
  ) : !isLoading && isAuthenticated ? (
    children
  ) : (
    <Navigate to={ROUTENAME.DEFAULT_ROUTE} />
  );
};

export default AuthLayout;
