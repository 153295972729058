import React from 'react';

import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';

/**
 * DynamicTable component
 * @param {Object} props Component props
 * @param {Array<Object>} props.headers Array of header objects with the following properties:
 *   - label: string - column label
 *   - width: string - column width
 *   - align: string - column alignment
 *   - type: string - column type (checkbox, textField, or undefined)
 *   - field: string - field name for the column
 *   - format: function - optional format function for the column
 * @param {Array<Object>} props.data Array of data objects with the following properties:
 *   - uuid: string - unique identifier for the item
 *   - [props.headers.field]: any - value for the corresponding field
 * @param {Array<Object>} props.selectedItems Array of selected items (for checkboxes)
 * @param {boolean} props.isAllSelected Boolean for the select all checkbox
 * @param {function} props.onSelectAll Function to handle select all checkbox
 * @param {function} props.onItemSelect Function to handle item checkbox
 * @param {function} props.onFieldChange Function to handle any field change (for TextField input)
 */
const CustomTableWithCheckbox = ({
  headers,
  data,
  selectedItems,
  isAllSelected,
  onSelectAll,
  onItemSelect,
  onFieldChange,
  hideCheckbox = true,
}) => (
  <TableContainer>
    <Table>
      <TableHead>
        <TableRow>
          {headers.map((header, index) => (
            <TableCell
              key={index}
              align={header.align || 'left'}
              sx={{
                borderBottom: '1px solid #EBEBEB',
                width: header.width,
              }}
            >
              {header.type === 'checkbox' && !hideCheckbox ? (
                <Checkbox
                  color="primary"
                  checked={isAllSelected}
                  onChange={onSelectAll}
                />
              ) : (
                <Typography variant="body1">{header.label}</Typography>
              )}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {data?.map((item, rowIndex) => (
          <TableRow key={rowIndex}>
            {headers.map((header, colIndex) => (
              <TableCell
                key={colIndex}
                align={header.align || 'left'}
                sx={{
                  borderBottom: '1px solid #EBEBEB',
                  width: header.width || 'auto',
                }}
              >
                {header.type === 'checkbox' ? (
                  <Checkbox
                    color="primary"
                    checked={selectedItems.some((p) => p.uuid === item.uuid)}
                    onChange={() => onItemSelect(item)}
                  />
                ) : header.type !== 'textField' ? (
                  <TextField
                    name={header.field}
                    type="number"
                    sx={{
                      '& .MuiInputBase-input': {
                        padding: '0 10px',
                        height: '30px',
                      },
                    }}
                    value={item[header.field] || ''}
                    onChange={(e) => onFieldChange(item, e.target.value)}
                  />
                ) : (
                  <Typography variant="body2">
                    {header.format
                      ? header.format(item[header.field])
                      : item[header.field]}
                  </Typography>
                )}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);

export default CustomTableWithCheckbox;
