/**
 * TODO: In future after Role Access, role will be passed from Role constant and will be handled accordingly
 *
 */
const useRoleHook = () => {
  const userRole = localStorage.getItem('role_name');

  return userRole;
};

export default useRoleHook;
