import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Box } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { uniqueId } from 'lodash';

import { DIVIDER_COLOR, STATUS_COLORS } from '../../constants/Colors';
import {
  CUSTOMER_QUOTATION_STATUS,
  DASHBOARD,
  MONTHS_DROPDOWN,
  SIX_MONTHS,
  TWELEVE_MONTHS,
} from '../../constants/Constants';
import { ROUTENAME } from '../../constants/RoutesConstants';
import {
  resetQuotationStatusCount,
  resetQuotationTotalAmount,
} from '../../store/quotes/reducer';
import CustomLineChart from '../Chart/LineChart/CustomLineChart';
import StackedBarChart from '../Chart/StackedBarChart/StackedBarChart';
import DashboardCard from '../CommonComponents/DashboardCard';
import ChartCard from '../Dashboard/ChartCard';

const defaultRangeData = {
  quoteStatusRange: MONTHS_DROPDOWN[0],
  quoteAmountRange: MONTHS_DROPDOWN[0],
};

const QuotesDashboard = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [ranges, setRanges] = useState(defaultRangeData);

  const { isLoading, data } = useSelector(
    (state) => state.quotes.quotationStatusCount
  );

  const { isLoading: totalAmountLoading, data: totalAmountData } = useSelector(
    (state) => state.quotes.quotationTotalAmount
  );

  const quoteCardData = [
    {
      title: t('common.status.pending'),
      value: 0,
      label: CUSTOMER_QUOTATION_STATUS.PENDING,
    },
    {
      title: t('common.status.approved'),
      value: 0,
      label: CUSTOMER_QUOTATION_STATUS.APPROVED,
    },
    {
      title: t('common.status.rejected'),
      value: 0,
      label: CUSTOMER_QUOTATION_STATUS.REJECTED,
    },
    {
      title: t('common.status.overdue'),
      value: 0,
      label: CUSTOMER_QUOTATION_STATUS.OVERDUE,
    },
    {
      title: t('attributes.customer.totalQuoteAmount'),
      value: 0,
      label: CUSTOMER_QUOTATION_STATUS.TOTAL_AMOUNT,
    },
  ];

  data &&
    data?.forEach((item) => {
      const card = quoteCardData.find((card) =>
        card.label.includes(item.status)
      );

      if (card) {
        card.value = item.count;
      }
    });
  totalAmountData &&
    (quoteCardData.at(-1).value = `${totalAmountData[0].total}`);

  useEffect(
    () => () => {
      dispatch(resetQuotationTotalAmount);
      dispatch(resetQuotationStatusCount);
    },
    []
  );

  const colors = [
    STATUS_COLORS.PENDING,
    STATUS_COLORS.APPROVED,
    STATUS_COLORS.IN_PROGRESS,
    STATUS_COLORS.REJECTED,
  ];

  //Static data for charts
  const sixMonthsData = {
    pending: [40, 100, 140, 200, 200, 250],
    approved: [20, 40, 50, 25, 60, 50],
    inProgress: [20, 40, 30, 25, 5, 15],
    rejected: [20, 23, 30, 40, 40, 30],
    quotesAmount: [2, 5.5, 2, 8.5, 1.5, 5],
    xAxisLabels: SIX_MONTHS,
  };
  const twelveMonthsData = {
    pending: [40, 100, 140, 200, 200, 250, 260, 300, 320, 400, 350, 280],
    approved: [20, 40, 50, 25, 60, 50, 55, 70, 80, 90, 60, 45],
    inProgress: [20, 40, 30, 25, 5, 15, 10, 35, 40, 20, 15, 25],
    rejected: [20, 23, 30, 40, 40, 30, 35, 45, 55, 60, 50, 40],
    quotesAmount: [2, 5.5, 2, 8.5, 1.5, 5, 7, 6.5, 8, 4, 5, 9],
    xAxisLabels: TWELEVE_MONTHS,
  };

  //Quote status data
  const currentQuoteStatusData =
    ranges.quoteStatusRange?.value === 6 ? sixMonthsData : twelveMonthsData;
  const quotesStatusXAxisLabels = currentQuoteStatusData.xAxisLabels;
  const quotesStatusData = [
    {
      data: currentQuoteStatusData.pending,
      label: t('common.status.pending'),
      id: 'pendingId',
      stack: 'total',
    },
    {
      data: currentQuoteStatusData.approved,
      label: t('common.status.approved'),
      id: 'approvedId',
      stack: 'total',
    },
    {
      data: currentQuoteStatusData.inProgress,
      label: t('common.status.inProgress'),
      id: 'inProgressId',
      stack: 'total',
    },
    {
      data: currentQuoteStatusData.rejected,
      label: t('common.status.rejected'),
      id: 'rejectedId',
      stack: 'total',
    },
  ];

  //Quote amount data
  const currentQuoteAmountData =
    ranges.quoteAmountRange?.value === 6 ? sixMonthsData : twelveMonthsData;
  const quotesAmountXAxisLabels = currentQuoteAmountData.xAxisLabels;
  const quotesAmountData = currentQuoteAmountData.quotesAmount;

  const handleCardClick = (status) => {
    if (status !== DASHBOARD) {
      const formattedStatus = status.toLowerCase().replace(' ', '-');

      navigate(
        `/${ROUTENAME.DASHBOARD}/${ROUTENAME.QUOTES}/${formattedStatus}`
      );
    } else {
      navigate(`/${ROUTENAME.DASHBOARD}/${ROUTENAME.QUOTES}`);
    }
  };

  return (
    <Box sx={{ bgcolor: DIVIDER_COLOR }}>
      <Box sx={{ width: '100%', padding: '16px 0 30px 0px' }}>
        <Grid container spacing={2}>
          {quoteCardData?.map((item) => (
            <Grid xs={12} sm={6} md={3} key={uniqueId('dashboardCard')}>
              <DashboardCard
                title={item.title}
                value={item.value}
                onClick={() => handleCardClick(item.title)}
                isLoading={isLoading || totalAmountLoading}
              />
            </Grid>
          ))}
        </Grid>
      </Box>

      <Box sx={{ display: 'flex', gap: '16px' }}>
        {/* Quote Status Chart */}
        <Box sx={{ flex: 1 }}>
          <ChartCard
            title={t('attributes.customer.quotesByStatus')}
            options={MONTHS_DROPDOWN}
            selectedType={ranges.quoteStatusRange}
            isEditableChart={false}
            setSelectedType={(id, val) =>
              setRanges((prev) => ({
                ...prev,
                quoteStatusRange: val,
              }))
            }
            height="257px"
            isSettingMode={true}
          >
            <StackedBarChart
              colors={colors}
              data={quotesStatusData}
              xLabels={quotesStatusXAxisLabels}
              legendPosition={{ vertical: 'top', horizontal: 'center' }}
            />
          </ChartCard>
        </Box>

        {/* Quote Amount Chart */}
        <Box sx={{ flex: 1 }}>
          <ChartCard
            title={t('attributes.customer.quoteAmount')}
            options={MONTHS_DROPDOWN}
            selectedType={ranges.quoteAmountRange}
            isEditableChart={false}
            setSelectedType={(id, val) =>
              setRanges((prev) => ({
                ...prev,
                quoteAmountRange: val,
              }))
            }
            height="257px"
            isSettingMode={true}
          >
            <CustomLineChart
              xAxisLabels={quotesAmountXAxisLabels}
              chartData={quotesAmountData}
              prefix={'$'}
              height={257}
            />
          </ChartCard>
        </Box>
      </Box>
    </Box>
  );
};

export default QuotesDashboard;
