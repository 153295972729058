import { createSlice } from '@reduxjs/toolkit';

import {
  getAccountManagerCommonDropdownList,
  getAccountManagerDropdownList,
} from './api';

const accountManagerDropdownList = {
  isLoading: true,
  error: false,
  data: false,
};

const accountManagerSlice = createSlice({
  name: 'accountManager',
  initialState: {
    accountManagerDropdownList,
  },
  extraReducers: (builder) => {
    builder
      // Get Account Manager List
      .addCase(getAccountManagerDropdownList.pending, (state) => {
        state.accountManagerDropdownList.isLoading = true;
      })
      .addCase(getAccountManagerDropdownList.fulfilled, (state, action) => {
        state.accountManagerDropdownList.isLoading = false;
        state.accountManagerDropdownList.data = action.payload;
      })
      .addCase(getAccountManagerDropdownList.rejected, (state, action) => {
        state.accountManagerDropdownList.isLoading = false;
        state.accountManagerDropdownList.error = action.error.message;
      })
      // Get Account Manager List
      .addCase(getAccountManagerCommonDropdownList.pending, (state) => {
        state.accountManagerDropdownList.isLoading = true;
      })
      .addCase(
        getAccountManagerCommonDropdownList.fulfilled,
        (state, action) => {
          state.accountManagerDropdownList.isLoading = false;
          state.accountManagerDropdownList.data = action.payload;
        }
      )
      .addCase(
        getAccountManagerCommonDropdownList.rejected,
        (state, action) => {
          state.accountManagerDropdownList.isLoading = false;
          state.accountManagerDropdownList.error = action.error.message;
        }
      );
  },
});

export default accountManagerSlice.reducer;
