import { createAsyncThunk } from '@reduxjs/toolkit';

import { APIs } from '../../constants/APIConstants';
import { TECHNICIAN } from '../actionPrefix';
import { axiosInstanceTechnician } from '../axios';

export const getTechnicianList = createAsyncThunk(
  TECHNICIAN.GET_TECHNICIANS,
  async (
    {
      page,
      search,
      size,
      limit,
      active,
      state,
      city,
      order,
      orderBy,
      company,
      category,
      skills,
      zones,
      list_column_names,
    },
    { rejectWithValue }
  ) => {
    const params = new URLSearchParams();

    // Add params conditionally
    if (page) params.append('page', page);
    if (search) params.append('search_by', search);
    if (size) params.append('size', size);
    if (limit) params.append('limit', limit);
    if (active) params.append('status', active);
    if (state) params.append('state', state);
    if (city) params.append('city', city);
    if (order) params.append('order', order);
    if (orderBy) params.append('order_by', orderBy);
    if (company) params.append('company_name', company);
    if (category) params.append('categories__contains', category);
    if (skills) params.append('skills__contains', skills);
    if (zones) params.append('zones__contains', zones);
    if (list_column_names)
      params.append('list_column_names', list_column_names);

    try {
      const response = await axiosInstanceTechnician.get(
        `${APIs.TECHNICIAN.GET_TECHNICIANS}?${params.toString()}`
      );

      return { ...response.data, active };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getTechnicianById = createAsyncThunk(
  TECHNICIAN.GET_TECHNICIAN_BY_ID,
  async (uuid, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceTechnician.get(
        APIs.TECHNICIAN.GET_UPDATE_TECHNICIANS.replace('{id}', uuid)
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateTechnician = createAsyncThunk(
  TECHNICIAN.UPDATE_TECHNICIAN,
  async ({ id: id, req }, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceTechnician.patch(
        APIs.TECHNICIAN.GET_UPDATE_TECHNICIANS.replace('{id}', id),
        req
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createTechnician = createAsyncThunk(
  TECHNICIAN.CREATE_TECHNICIAN,
  async (req, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceTechnician.post(
        APIs.TECHNICIAN.CREATE_TECHNICIANS,
        req
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getTechnicianNamesList = createAsyncThunk(
  TECHNICIAN.GET_TECHNICIAN_NAMES,
  async (req, { rejectWithValue }) => {
    const params = new URLSearchParams();

    if (req) params.append('zones', req);

    try {
      const response = await axiosInstanceTechnician.get(
        `${APIs.TECHNICIAN.GET_TECHNICIAN_NAMES}?${params.toString()}`
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
