import { createAsyncThunk } from '@reduxjs/toolkit';

import { APIs } from '../../constants/APIConstants';
import { ACCOUNT_MANAGER } from '../actionPrefix';
import { axiosInstanceCompany, axiosPropertyUrl } from '../axios';

// Get Account Manager List API
export const getAccountManagerDropdownList = createAsyncThunk(
  ACCOUNT_MANAGER.GET_ACCOUNT_MANAGER_DROPDOWN_LIST,
  async (req, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceCompany.get(
        APIs.ACCOUNT_MANAGER.GET_ACCOUNT_MANAGER_DROPDOWN_LIST
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// TODO: We've to remove the 'customer/account-managers' API call. here we've FE/BE both changes required.
// Get Account Manager Common List API
export const getAccountManagerCommonDropdownList = createAsyncThunk(
  ACCOUNT_MANAGER.GET_ACCOUNT_MANAGER_COMMON_DROPDOWN_LIST,
  async (req, { rejectWithValue }) => {
    try {
      const response = await axiosPropertyUrl.get(
        APIs.ACCOUNT_MANAGER.GET_ACCOUNT_MANAGER_COMMON_DROPDOWN_LIST
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
