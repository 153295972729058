import { createSlice } from '@reduxjs/toolkit';

import { getServiceAreasDropdownList } from './api';

const serviceAreasDropdownList = {
  isLoading: true,
  error: false,
  data: false,
};

const serviceAreasSlice = createSlice({
  name: 'serviceAreas',
  initialState: {
    serviceAreasDropdownList,
  },
  extraReducers: (builder) => {
    builder
      // Get ServiceAreas Dropdown List
      .addCase(getServiceAreasDropdownList.pending, (state) => {
        state.serviceAreasDropdownList.isLoading = true;
      })
      .addCase(getServiceAreasDropdownList.fulfilled, (state, action) => {
        state.serviceAreasDropdownList.isLoading = false;
        state.serviceAreasDropdownList.data = action.payload;
      })
      .addCase(getServiceAreasDropdownList.rejected, (state, action) => {
        state.serviceAreasDropdownList.isLoading = false;
        state.serviceAreasDropdownList.error = action.error.message;
      });
  },
});

export default serviceAreasSlice.reducer;
