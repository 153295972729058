import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Card, Divider, styled, Typography } from '@mui/material';

import { Validation } from '../../../constants/FieldValidationMsg';
import getDropdownDataHook from '../../../hooks/getDropdownDataHook';
import { getSupplierDropdownList } from '../../../store/supplier/api';
import { datePickerFormat } from '../../../utils';
import Autocomplete from '../../CommonComponents/AutoComplete';
import CustomDatePicker from '../../CommonComponents/CustomDatePicker';
import CustomCircularLoader from '../../CommonComponents/CustomLoader';
import CustomTextField from '../../CommonComponents/CustomTextField';

const FormWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '16px',
}));

const CustomCard = styled(Card)(() => ({
  boxShadow: 'none',
}));

const FormFieldWrapper = styled(Box)(() => ({
  padding: '16px',
  rowGap: '16px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));

const AddPurchaseOrder = ({ control, trigger, reset, nextStepTitle }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { purchaseOrdersList } = useSelector(
    (state) => state.purchaseOrders.get
  );

  const { getDropdown } = useSelector((state) => state.supplier);

  const [isLoading, setIsLoading] = useState(true);

  const supplierList = getDropdownDataHook({
    data: getDropdown?.data?.data,
    labelName: 'name',
    valueName: 'uuid',
  });

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    dispatch(getSupplierDropdownList());
  }, []);

  useEffect(() => {
    if (nextStepTitle) {
      const detail = purchaseOrdersList?.find(
        (item) => item.poid === nextStepTitle.step
      );

      reset({
        creation_date: detail?.created_at
          ? datePickerFormat(detail?.created_at)
          : null,
        supplier: {
          label: detail?.supplier?.name,
          value: detail?.supplier?.uuid,
        },
        description: detail?.description,
        delivery_instruction: detail?.delivery_instruction,
        uuid: detail?.uuid,
        status: detail?.status,
      });
    }
  }, [purchaseOrdersList]);

  return nextStepTitle && isLoading ? (
    <CustomCircularLoader />
  ) : (
    <FormWrapper>
      <CustomCard>
        <Box sx={{ padding: '16px' }}>
          <Typography variant="body1">
            {t('attributes.purchase_order.purchase_order_details')}
          </Typography>
        </Box>
        <Divider />
        <FormFieldWrapper component="form">
          <CustomDatePicker
            control={control}
            name="creation_date"
            label={t('attributes.purchase_order.po_creation_date')}
            pickerType="date"
            defaultValue={null}
            isRequired={true}
            trigger={trigger}
          />
          <Controller
            name="supplier"
            control={control}
            rules={{
              required: `${t('attributes.purchase_order.supplier')} ${Validation.general.required}`,
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Autocomplete
                label={t('attributes.purchase_order.supplier')}
                options={supplierList}
                value={value}
                onChange={(e, newValue) => {
                  onChange(newValue);
                }}
                helperText={error ? error.message : ''}
                error={error}
                isLoadingData={getDropdown?.isLoading}
              />
            )}
          />
          <Controller
            name="description"
            control={control}
            rules={{
              maxLength: {
                value: 300,
                message: Validation.general.description,
              },
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <CustomTextField
                label={t('attributes.description')}
                multiline
                isRequired={false}
                rows={3}
                value={value}
                onChange={(e) => {
                  onChange(e);
                  trigger('description');
                }}
                onClear={() => {
                  onChange('');
                }}
                helperText={error ? error.message : ''}
                error={error}
              />
            )}
          />
          <Controller
            name="delivery_instruction"
            control={control}
            rules={{
              maxLength: {
                value: 300,
                message: Validation.general.delivery_instruction,
              },
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <CustomTextField
                label={t('attributes.purchase_order.delivery_instruction')}
                multiline
                isRequired={false}
                rows={3}
                value={value}
                onChange={(e) => {
                  onChange(e);
                  trigger('delivery_instruction');
                }}
                onClear={() => {
                  onChange('');
                }}
                helperText={error ? error.message : ''}
                error={error}
              />
            )}
          />
        </FormFieldWrapper>
      </CustomCard>
    </FormWrapper>
  );
};

export default AddPurchaseOrder;
