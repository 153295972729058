import { createSlice } from '@reduxjs/toolkit';

import { getZonesDropdownList } from './api';

const zonesDropdownList = {
  isLoading: true,
  error: false,
  data: false,
};

const zonesSlice = createSlice({
  name: 'zones',
  initialState: {
    zonesDropdownList,
  },
  extraReducers: (builder) => {
    builder
      // Get Zones Dropdown List
      .addCase(getZonesDropdownList.pending, (state) => {
        state.zonesDropdownList.isLoading = true;
      })
      .addCase(getZonesDropdownList.fulfilled, (state, action) => {
        state.zonesDropdownList.isLoading = false;
        state.zonesDropdownList.data = action.payload;
      })
      .addCase(getZonesDropdownList.rejected, (state, action) => {
        state.zonesDropdownList.isLoading = false;
        state.zonesDropdownList.error = action.error.message;
      });
  },
});

export default zonesSlice.reducer;
