import FilterListIcon from '@mui/icons-material/FilterList';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import SyncOutlinedIcon from '@mui/icons-material/SyncOutlined';
import { styled } from '@mui/material';

// Internal
import { SECONDARY } from '../../constants/Colors';
import CustomButton from './CustomButton';

const FilterButtonStyled = styled(CustomButton)(({ isActive, theme }) => ({
  '&.MuiButton-root': {
    border: !isActive && `1px solid ${theme.palette.secondary.contrastText}`,
    color: !isActive && theme.palette.secondary.contrastText,
  },
}));

export const FilterButton = ({
  isActive = false,
  onClick = () => {},
  ...rest
}) => (
  <FilterButtonStyled
    startIcon={<FilterListIcon />}
    text="Filter"
    variant="outlined"
    isActive={isActive}
    sx={{
      bgcolor: SECONDARY,
      height: '26px',
    }}
    onClick={onClick}
    {...rest}
  />
);

export const ResetFilterButton = ({ disabled = true, onClick = () => {} }) => (
  <CustomButton
    sx={{
      bgcolor: SECONDARY,
      height: '26px',
    }}
    startIcon={<RotateLeftIcon />}
    text="Reset Filter"
    variant="outlined"
    disabled={disabled}
    onClick={onClick}
  />
);

export const RefreshDashboardButton = ({
  label = 'Refresh Dashboard',
  onClick,
  ...rest
}) => (
  <FilterButtonStyled
    sx={{
      bgcolor: SECONDARY,
      height: '26px',
    }}
    startIcon={<SyncOutlinedIcon />}
    text={label}
    variant="outlined"
    onClick={() => {
      if (!onClick) {
        window.location.reload();
      } else {
        onClick();
      }
    }}
    {...rest}
  />
);
