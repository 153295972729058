import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Card, Chip, styled, Typography } from '@mui/material';
import { Divider } from 'rsuite';

import {
  PRODUCT_TYPES,
  PRODUCT_TYPES_LABEL,
  UNIT_OF_MEASURES,
} from '../../constants/Constants';
import { resetproductDetails } from '../../store/productCatalogue/reducer';
import { findLabelByValue, formatPriceWithDecimalValue } from '../../utils';
import CustomCircularLoader from '../CommonComponents/CustomLoader';

const MainWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '16px',
}));

const CustomCard = styled(Card)(() => ({
  boxShadow: 'none',
}));

const ViewProductCatalogue = ({ equipmentTypeList }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { productDetails, isLoading } = useSelector(
    (state) => state.productCatalogue.edit
  );

  const [mappedEquipmentTypes, setMappedEquipmentTypes] = useState([]);

  const { equipment_type } = productDetails;

  useEffect(() => {
    const equipmentTypes =
      equipment_type?.map(
        (et) => findLabelByValue(equipmentTypeList, et)?.label
      ) || [];

    setMappedEquipmentTypes(equipmentTypes);
  }, [equipment_type, equipmentTypeList]);

  useEffect(
    () => () => {
      dispatch(resetproductDetails());
    },
    []
  );

  return isLoading ? (
    <CustomCircularLoader />
  ) : (
    <>
      <MainWrapper>
        <CustomCard>
          <Box sx={{ padding: '12px 16px 12px 16px' }}>
            <Typography variant="body1">
              {t('attributes.productCatalogue.product_details')}
            </Typography>
          </Box>
          <Divider style={{ margin: '0px' }} />

          <Box sx={{ padding: '16px' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '14px' }}>
              <Box sx={{ display: 'flex', gap: '120px' }}>
                <Box sx={{ minWidth: '74px' }}>
                  <Typography variant="body1">Type</Typography>
                  <Typography variant="body2">
                    {findLabelByValue(PRODUCT_TYPES, productDetails?.type)
                      ?.label ?? '-'}
                  </Typography>
                </Box>

                <Box>
                  <Typography variant="body1">
                    {t('attributes.name')}
                  </Typography>
                  <Typography variant="body2">
                    {productDetails?.product_name || '-'}
                  </Typography>
                </Box>
              </Box>

              {productDetails?.type !== PRODUCT_TYPES_LABEL.LABOUR && (
                <Box>
                  <Typography variant="body1">Equipment Type</Typography>
                  {mappedEquipmentTypes?.length > 0
                    ? mappedEquipmentTypes.map((item) => (
                        <Chip
                          label={item}
                          sx={{
                            height: '20px',
                            marginRight: '8px',
                          }}
                        />
                      ))
                    : '-'}
                </Box>
              )}

              <Box sx={{ display: 'flex', gap: '120px' }}>
                {productDetails?.type === PRODUCT_TYPES_LABEL.LABOUR && (
                  <Box sx={{ minWidth: '74px' }}>
                    <Typography variant="body1">
                      {t('attributes.productCatalogue.cost_price')}
                    </Typography>
                    <Typography variant="body2">
                      {formatPriceWithDecimalValue(productDetails?.cost) ?? '-'}
                    </Typography>
                  </Box>
                )}

                <Box>
                  <Typography variant="body1">
                    {t('attributes.productCatalogue.default_sell_price')}
                  </Typography>
                  <Typography variant="body2">
                    {formatPriceWithDecimalValue(productDetails?.sell_price) ??
                      '-'}
                  </Typography>
                </Box>
              </Box>

              <Box>
                <Typography variant="body1">
                  {t('attributes.description')}
                </Typography>
                <Typography variant="body2">
                  {productDetails?.description || '-'}
                </Typography>
              </Box>
            </Box>
          </Box>
        </CustomCard>

        {productDetails?.type !== PRODUCT_TYPES_LABEL.LABOUR && (
          <CustomCard>
            <Box sx={{ padding: '12px 16px 12px 16px' }}>
              <Typography variant="body1">
                {t('attributes.productCatalogue.supplier_details')}
              </Typography>
            </Box>
            <Divider style={{ margin: '0px' }} />

            <Box sx={{ padding: '16px' }}>
              <Box
                sx={{ display: 'flex', flexDirection: 'column', gap: '14px' }}
              >
                <Box sx={{ display: 'flex', gap: '120px' }}>
                  <Box>
                    <Typography variant="body1">
                      {t('attributes.productCatalogue.supplier')}
                    </Typography>
                    <Typography variant="body2">
                      {productDetails?.supplier ?? '-'}
                    </Typography>
                  </Box>

                  <Box>
                    <Typography variant="body1">
                      {t('attributes.productCatalogue.cost_price')}
                    </Typography>
                    <Typography variant="body2">
                      {formatPriceWithDecimalValue(productDetails?.cost) ?? '-'}
                    </Typography>
                  </Box>
                </Box>

                <Box>
                  <Typography variant="body1">
                    {t('attributes.productCatalogue.unit_of_measure')}
                  </Typography>
                  <Typography variant="body2">
                    {findLabelByValue(UNIT_OF_MEASURES, productDetails?.um)
                      ?.label ?? '-'}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </CustomCard>
        )}

        {productDetails?.type !== PRODUCT_TYPES_LABEL.LABOUR && (
          <CustomCard>
            <Box sx={{ padding: '12px 16px 12px 16px' }}>
              <Typography variant="body1">
                {t('attributes.productCatalogue.services_details')}
              </Typography>
            </Box>
            <Divider style={{ margin: '0px' }} />

            <Box sx={{ padding: '16px' }}>
              <Box
                sx={{ display: 'flex', flexDirection: 'column', gap: '14px' }}
              >
                <Box sx={{ display: 'flex', gap: '120px' }}>
                  <Box>
                    <Typography variant="body1">
                      {' '}
                      {t('attributes.productCatalogue.estimate_time')}
                    </Typography>
                    <Typography variant="body2">
                      {productDetails?.estimated_time_hours ?? '-'}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </CustomCard>
        )}
      </MainWrapper>
    </>
  );
};

export default ViewProductCatalogue;
