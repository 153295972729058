import React, { useCallback, useEffect, useMemo } from 'react';
import { Calendar, momentLocalizer, Views } from 'react-big-calendar';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Typography } from '@mui/material';
import { debounce } from 'lodash';
import moment from 'moment';

import { calculateTimeDuration } from '../../constants/common';
import {
  INVITATION_STATUS,
  SCHEDULE_BOARD_WO_UTC_LOCAL,
  TIME_SHEET_TYPE,
} from '../../constants/Constants';
import { getTimeSheetList } from '../../store/timesheet/api';
import { formattedDate } from '../../utils';
import CustomCircularLoader from '../CommonComponents/CustomLoader';

const localizer = momentLocalizer(moment);
const heightOfView = 'calc(100vh - 340px)';

const TimesheetCalendar = ({
  handleTimeSheetClick,
  date,
  setDate,
  selectedView,
  displayCalendarLoader,
  setDisplayCalendarLoader,
  selectedTechnician,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Get the technicians list from Redux store
  const { data: calendarTechList } = useSelector(
    (state) => state.timeSheet.getTechList
  );

  const { timeSheetList } = useSelector((state) => state.timeSheet.getList);

  const timeSheetDate = useMemo(() => {
    if (Views.WEEK === selectedView.value) {
      const startDate = new Date(moment(date).startOf('week'));
      const endDate = new Date(moment(date).endOf('week'));

      return { startDate, endDate };
    } else if (Views.DAY === selectedView.value) {
      return {
        startDate: new Date(moment(date).startOf('day')),
        endDate: new Date(moment(date).endOf('day')),
      };
    } else if (Views.MONTH === selectedView.value) {
      const startDate = new Date(moment(date).startOf('month'));
      const endDate = new Date(moment(date).endOf('month'));

      return { startDate, endDate };
    }
  }, [date, selectedView]);

  // Get Timesheet for Board Start
  const getTimeSheetListOrders = useCallback(() => {
    setDisplayCalendarLoader(true);
    const { startDate, endDate } = timeSheetDate;

    dispatch(
      getTimeSheetList({
        page: 1,
        size: 1,
        limit: -1,
        technicianUUID: selectedTechnician,
        calendarStartDate: startDate,
        calendarEndDate: endDate,
      })
    ).then(() => {
      setDisplayCalendarLoader(false);
    });
  }, [dispatch, calendarTechList, date, selectedView, selectedTechnician]);

  // Debounced function to avoid frequent API calls
  const debouncedFetchData = useCallback(
    debounce(getTimeSheetListOrders, 500),
    [getTimeSheetListOrders]
  );

  // Call debouncedFetchData whenever search, page, or filter changes
  useEffect(() => {
    debouncedFetchData();

    // Clean up function for debounce to avoid memory leaks
    return () => {
      debouncedFetchData.cancel();
    };
  }, [debouncedFetchData]);

  const refactoredTimesheets = useMemo(
    () =>
      timeSheetList.map((item) => ({
        id: item.uuid,
        title:
          `(${item?.work_order?.woid}) - ${TIME_SHEET_TYPE?.find((type) => type.value === item.type)?.label}` ||
          '',
        description: item.task_description,
        start: new Date(
          formattedDate(item.start_date_time, SCHEDULE_BOARD_WO_UTC_LOCAL)
        ),
        end: new Date(
          formattedDate(item.end_date_time, SCHEDULE_BOARD_WO_UTC_LOCAL)
        ),
        totalTime: calculateTimeDuration({
          startTime: item.start_date_time,
          endTime: item.end_date_time,
        }),
        status: item.status,
      })),
    [timeSheetList]
  );

  // Scheduler Start
  const TimesheetPropGetter = (event) => ({
    className: `timeSheet ${
      event?.status === 'draft'
        ? 'draft'
        : event?.status === 'submitted'
          ? 'submitted'
          : event?.status === 'approved'
            ? 'approved'
            : ''
    }`,
    style: { cursor: 'pointer' },
  });
  // Scheduler End

  const customTimesheetComponent = (event) => (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
        gap={2}
      >
        <Typography
          variant="body1"
          sx={{
            flexGrow: 1,
            textAlign: 'start',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            maxWidth: selectedView.value === Views.DAY ? '40%' : '100%',
          }}
        >
          {event.title}
        </Typography>
        {selectedView.value === Views.DAY && (
          <>
            <Typography
              variant="body2"
              sx={{
                flexGrow: 1,
                textAlign: 'center',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                maxWidth: '45%',
              }}
            >
              {event?.event?.description || t('common.noDescription')}
            </Typography>
            <Typography
              variant="h1"
              sx={{
                flexGrow: 1,
                textAlign: 'end',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                minWidth: '15%',
                paddingRight: '10px',
              }}
            >
              {event?.event?.totalTime}
            </Typography>
          </>
        )}
      </Box>
      {selectedView.value === Views.WEEK && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography
            variant="body2"
            sx={{
              flexGrow: 1,
              textAlign: 'start',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }}
          >
            {event?.event?.description || t('common.noDescription')}
          </Typography>
        </Box>
      )}
      {selectedView.value !== Views.DAY && (
        <Typography variant="body1">{event?.event?.totalTime}</Typography>
      )}
    </Box>
  );

  if (displayCalendarLoader) {
    return (
      <Box
        sx={{
          width: '100%',
          mt: 2,
          height: heightOfView,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <CustomCircularLoader />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        height: heightOfView,
        overflowY: 'auto',
      }}
    >
      <Calendar
        localizer={localizer}
        defaultView={Views.MONTH}
        events={refactoredTimesheets}
        eventPropGetter={TimesheetPropGetter}
        startAccessor="start"
        endAccessor="end"
        view={selectedView?.value}
        style={{
          height:
            selectedView.value === Views.MONTH
              ? 'calc(100vh - 300px)'
              : 'calc(100vh - 340px)',
        }}
        onSelectEvent={handleTimeSheetClick}
        toolbar={false}
        date={date}
        onNavigate={setDate}
        allDayMaxRows={2}
        showAllDay={false}
        dayLayoutAlgorithm="no-overlap"
        popup
        components={{
          event: customTimesheetComponent,
        }}
        scrollToTime={moment().subtract(3, 'hours').toDate()}
        tooltipAccessor={(event) =>
          `${event.title}\n${event?.description}\n${event?.totalTime}\n\n${INVITATION_STATUS[event?.status]}`
        }
        step={selectedView.value === Views.WEEK ? 30 : 60} // each slot will represent 30 minutes
        timeslots={1} // 2 slots per hour, meaning each slot will be 30 minutes
      />
    </Box>
  );
};

export default TimesheetCalendar;
