import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';

import { useMsal } from '@azure/msal-react';
// MUI
import { Box, Paper } from '@mui/material';

// Internal
import { DIVIDER_COLOR, SECONDARY } from '../../constants/Colors';
import { DRAWER_WIDTH, HEADER_HEIGHT } from '../../constants/Typography';
import { getLocation, getMe } from '../../store/CommonAPI';
import Header from './Header';
import Sidebar from './Sidebar';
const Layout = () => {
  const dispatch = useDispatch();
  const { accounts } = useMsal();

  const [isNavbarOpen, setIsNavbarOpen] = useState(true);

  const { silentTokenSuccess, stateCityList } = useSelector(
    (state) => state.common
  );

  useEffect(() => {
    if (accounts.length) {
      dispatch(getMe());
    }
  }, []);

  useEffect(() => {
    if (silentTokenSuccess?.isSsoSuccess && !stateCityList?.Australia) {
      // Store role into localstorage
      dispatch(getLocation());
    }
  }, [silentTokenSuccess?.isSsoSuccess]);

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <Header isNavbarOpen={isNavbarOpen} setIsNavbarOpen={setIsNavbarOpen} />
        <Sidebar isNavbarOpen={isNavbarOpen} />
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 2,
            width: { sm: `calc(100% - ${DRAWER_WIDTH}px)` },
            height: `calc(100vh - ${HEADER_HEIGHT}px)`,
            marginTop: `${HEADER_HEIGHT}px`,
            background: DIVIDER_COLOR,
          }}
        >
          <Paper
            sx={{
              height: '100%',
              bgcolor: SECONDARY,
              boxShadow: 0,
              position: 'relative',
            }}
          >
            <Outlet />
          </Paper>
        </Box>
      </Box>
    </>
  );
};

export default Layout;
