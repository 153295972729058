import { createSlice } from '@reduxjs/toolkit';

import { DATE_TIME_RANGE_FORMAT_DEFAULT } from '../../constants/Constants';
import { formattedDate } from '../../utils';
import {
  createTimeSheet,
  downloadTimeSheetList,
  getTimeSheetCalenderTechnicianList,
  getTimeSheetList,
} from './api';

const initialGetData = {
  isLoading: true,
  error: null,
  timeSheetList: [],
  page: 1,
  size: 10,
  total: 0,
  totalCount: 0,
};

const initialCreate = {
  isLoading: null,
  error: null,
  timesheet: null,
};

const initialTechList = {
  isLoading: true,
  error: false,
  data: false,
};

const timeSheetSlice = createSlice({
  name: 'timeSheet',
  initialState: {
    getList: { ...initialGetData },
    create: { ...initialCreate },
    getTechList: { ...initialTechList },
    downloadList: { ...initialGetData },
  },
  reducers: {
    resetTimeSheetList(state) {
      state.getList = { ...initialGetData };
    },
    resetTechList(state) {
      state.getTechList = { ...initialTechList };
    },
    resetTimesheetData(state) {
      state.create = { ...initialCreate };
    },
    resetDownloadTimeSheetList(state) {
      state.downloadList = { ...initialGetData };
    },
  },
  extraReducers: (builder) => {
    builder
      // TimeSheet List Start
      .addCase(getTimeSheetList.pending, (state) => {
        state.getList.isLoading = true;
      })
      .addCase(getTimeSheetList.fulfilled, (state, action) => {
        const { pagination, data } = action.payload;

        state.getList.isLoading = false;
        state.getList.timeSheetList = data;
        state.getList.total = pagination.total_items;
        state.getList.totalCount = pagination.total_count;
      })
      .addCase(getTimeSheetList.rejected, (state, action) => {
        state.getList.isLoading = false;
        state.getList.error = action.payload;
      })
      // TimeSheet List End

      // TimeEntry Start
      .addCase(createTimeSheet.pending, (state) => {
        state.create.isLoading = true;
      })
      .addCase(createTimeSheet.fulfilled, (state, action) => {
        state.create.error = false;
        state.create.isLoading = false;
        state.create.timesheet = action.payload;
      })
      .addCase(createTimeSheet.rejected, (state, action) => {
        state.create.isLoading = false;
        state.create.error = action.payload;
      })
      // TimeEntry End

      // Get Technician Names List
      .addCase(getTimeSheetCalenderTechnicianList.pending, (state) => {
        state.getTechList.isLoading = true;
      })
      .addCase(
        getTimeSheetCalenderTechnicianList.fulfilled,
        (state, action) => {
          state.getTechList.isLoading = false;
          state.getTechList.data = action.payload;
        }
      )
      .addCase(getTimeSheetCalenderTechnicianList.rejected, (state, action) => {
        state.getTechList.isLoading = false;
        state.getTechList.error = action.error.message;
      })

      // Download Timesheet list
      .addCase(downloadTimeSheetList.pending, (state) => {
        state.downloadList.isLoading = true;
      })
      .addCase(downloadTimeSheetList.fulfilled, (state, action) => {
        const { pagination, data } = action.payload;

        const formattedData = data.map((dp) => ({
          ...dp,
          start_date_time: formattedDate(
            dp.start_date_time,
            DATE_TIME_RANGE_FORMAT_DEFAULT
          ),
          end_date_time: formattedDate(
            dp.end_date_time,
            DATE_TIME_RANGE_FORMAT_DEFAULT
          ),
        }));

        state.downloadList.isLoading = false;
        state.downloadList.timeSheetList = formattedData;
        state.downloadList.total = pagination.total_items;
        state.downloadList.totalCount = pagination.total_count;
      })
      .addCase(downloadTimeSheetList.rejected, (state, action) => {
        state.downloadList.isLoading = false;
        state.downloadList.error = action.payload;
      });
  },
});

export const {
  resetTimeSheetList,
  resetTechList,
  resetTimesheetData,
  resetDownloadTimeSheetList,
} = timeSheetSlice.actions;

export default timeSheetSlice.reducer;
