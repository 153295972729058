import { createSlice } from '@reduxjs/toolkit';

import { TAB_STATUS } from '../../../constants/Constants';
import {
  createCustomer,
  getCustomerById,
  getCustomerCategoryDropdownList,
  getCustomerDefectCount,
  getCustomerList,
  updateCustomer,
} from './api';

const initialCreateData = {
  isLoading: false,
  error: false,
  createdCustomer: false,
};

const initialUpdateData = {
  isLoading: false,
  error: false,
  updatedCustomer: false,
};

const initialEditData = {
  isLoading: false,
  error: false,
  editCustomer: false,
};

const defectStatusCount = {
  isLoading: true,
  error: false,
  data: false,
};

const initialGetData = {
  isLoading: true,
  error: null,
  customerActiveList: [],
  customerInactiveList: [],
  page: 1,
  size: 10,
  total: 0,
  totalCount: 0,
};

const customerCategoryDropdownList = {
  isLoading: true,
  error: false,
  data: false,
};

const customerSlice = createSlice({
  name: 'customer',
  initialState: {
    get: { ...initialGetData },
    create: {
      ...initialCreateData,
    },
    update: {
      ...initialUpdateData,
    },
    edit: {
      ...initialEditData,
    },
    customerCategoryDropdownList,
    defectStatusCount,
  },
  reducers: {
    resetCreate(state) {
      state.create = { ...initialCreateData };
    },
    resetUpdate(state) {
      state.update = { ...initialUpdateData };
    },
    resetEdit(state) {
      state.edit = { ...initialEditData };
    },
    resetPagination(state) {
      state.get = { ...initialGetData };
    },
    resetDefectStatusCount(state) {
      state.defectStatusCount = { ...defectStatusCount };
    },
  },
  extraReducers: (builder) => {
    builder
      // List Customer
      .addCase(getCustomerList.pending, (state) => {
        state.get.isLoading = true;
      })
      .addCase(getCustomerList.fulfilled, (state, action) => {
        const { pagination, data, active } = action.payload;

        state.get.isLoading = false;
        if (active === TAB_STATUS.active) {
          state.get.customerActiveList = data;
        } else {
          state.get.customerInactiveList = data;
        }
        state.get.total = pagination.total_items;
        state.get.totalCount = pagination.total_count;
      })
      .addCase(getCustomerList.rejected, (state, action) => {
        state.get.isLoading = false;
        state.get.error = action.payload;
      })
      // Create Customer
      .addCase(createCustomer.pending, (state) => {
        state.create.isLoading = true;
      })
      .addCase(createCustomer.fulfilled, (state, action) => {
        state.create.error = false;
        state.create.isLoading = false;
        state.create.createdCustomer = action.payload;
      })
      .addCase(createCustomer.rejected, (state, action) => {
        state.create.isLoading = false;
        state.create.error = action.payload;
      })
      // Update Customer
      .addCase(updateCustomer.pending, (state) => {
        state.update.isLoading = true;
      })
      .addCase(updateCustomer.fulfilled, (state, action) => {
        state.update.error = false;
        state.update.isLoading = false;
        state.update.updatedCustomer = action.payload;
      })
      .addCase(updateCustomer.rejected, (state, action) => {
        state.update.isLoading = false;
        state.update.error = action.payload;
      })
      // Edit
      .addCase(getCustomerById.pending, (state) => {
        state.edit.isLoading = true;
      })
      .addCase(getCustomerById.fulfilled, (state, action) => {
        state.edit.isLoading = false;
        state.edit.editCustomer = action.payload.data[0];
      })
      .addCase(getCustomerById.rejected, (state, action) => {
        state.edit.isLoading = false;
        state.edit.error = action.payload;
      })
      // Get Customer Category Dropdown List
      .addCase(getCustomerCategoryDropdownList.pending, (state) => {
        state.customerCategoryDropdownList.isLoading = true;
      })
      .addCase(getCustomerCategoryDropdownList.fulfilled, (state, action) => {
        state.customerCategoryDropdownList.isLoading = false;
        state.customerCategoryDropdownList.data = action.payload;
      })
      .addCase(getCustomerCategoryDropdownList.rejected, (state, action) => {
        state.customerCategoryDropdownList.isLoading = false;
        state.customerCategoryDropdownList.error = action.error.message;
      })
      .addCase(getCustomerDefectCount.pending, (state) => {
        state.defectStatusCount.isLoading = true;
      })
      .addCase(getCustomerDefectCount.fulfilled, (state, action) => {
        state.defectStatusCount.isLoading = false;
        state.defectStatusCount.data = action.payload.data;
      })
      .addCase(getCustomerDefectCount.rejected, (state, action) => {
        state.defectStatusCount.isLoading = false;
        state.defectStatusCount.error = action.error.message;
      });
  },
});

export const {
  resetCreate,
  resetEdit,
  resetUpdate,
  resetPagination,
  resetDefectStatusCount,
} = customerSlice.actions;

export default customerSlice.reducer;
