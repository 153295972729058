import { createAsyncThunk } from '@reduxjs/toolkit';

import { APIs } from '../../constants/APIConstants';
import { ZONES } from '../actionPrefix';
import { axiosInstanceTechnician } from '../axios';

// Get Zones List API
export const getZonesDropdownList = createAsyncThunk(
  ZONES.GET_ZONES_DROPDOWN_LIST,
  async (req, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceTechnician.get(
        APIs.ZONES.GET_ZONES_DROPDOWN_LIST
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
