import { createAsyncThunk } from '@reduxjs/toolkit';

import { APIs } from '../../constants/APIConstants';
import { ADDRESS_REGION_CODE } from '../../constants/Constants';
import { GOOGLE } from '../actionPrefix';
import { axiosInstanceDocument } from '../axios';

export const getAutocompleteAddress = createAsyncThunk(
  GOOGLE.AUTOCOMPLETE,
  async ({ value }, { rejectWithValue }) => {
    try {
      const params = new URLSearchParams();
      if (value) params.set('input', value);
      params.set('type', 'autocomplete');
      params.set('country', ADDRESS_REGION_CODE.toLowerCase());

      const response = await axiosInstanceDocument.get(
        `${APIs.GOOGLE.AUTOCOMPLETE}?${params.toString()}`
      );

      return response.data;
    } catch (error) {
      // Forward the custom error object to the rejected promise
      return rejectWithValue(error);
    }
  }
);

export const getAddressDetail = createAsyncThunk(
  GOOGLE.DETAIL,
  async ({ place_id }, { rejectWithValue }) => {
    try {
      const params = new URLSearchParams();
      if (place_id) params.set('place_id', place_id);
      params.set('type', 'place');

      const response = await axiosInstanceDocument.get(
        `${APIs.GOOGLE.DETAIL}?${params.toString()}`
      );

      return response.data;
    } catch (error) {
      // Forward the custom error object to the rejected promise
      return rejectWithValue(error);
    }
  }
);
