import { createSlice } from '@reduxjs/toolkit';

import { TAB_STATUS } from '../../constants/Constants';
import {
  createTechnician,
  getTechnicianById,
  getTechnicianList,
  getTechnicianNamesList,
  updateTechnician,
} from './api';

const initialTechnicianNamesData = {
  isLoading: true,
  error: false,
  data: false,
};

const initialCreateData = {
  isLoading: false,
  error: false,
  createdTechnician: false,
};

const initialEditData = {
  isLoading: false,
  error: false,
  editTechnician: false,
};

const initialGetData = {
  isLoading: true,
  error: null,
  technicianActiveList: [],
  technicianInactiveList: [],
  page: 1,
  size: 10,
  total: 0,
  totalCount: 0,
};

const technicianSlice = createSlice({
  name: 'technician',
  initialState: {
    get: { ...initialGetData },
    create: {
      ...initialCreateData,
    },
    edit: {
      ...initialEditData,
    },
    technicianNamesDropdownList: { ...initialTechnicianNamesData },
  },
  reducers: {
    resetCreate(state) {
      state.create = { ...initialCreateData };
    },
    resetEdit(state) {
      state.edit = { ...initialEditData };
    },
    resetPagination(state) {
      state.get = { ...initialGetData };
    },
  },
  extraReducers: (builder) => {
    builder
      // Technician List
      .addCase(getTechnicianList.pending, (state) => {
        state.get.isLoading = true;
      })
      .addCase(getTechnicianList.fulfilled, (state, action) => {
        const { pagination, data, active } = action.payload;

        state.get.isLoading = false;

        if (active === TAB_STATUS.active) {
          state.get.technicianActiveList = data;
        } else {
          state.get.technicianInactiveList = data;
        }
        state.get.total = pagination.total_items;
        state.get.totalCount = pagination.total_count;
      })
      .addCase(getTechnicianList.rejected, (state, action) => {
        state.get.isLoading = false;
        state.get.error = action.payload;
      })
      // Create Technician
      .addCase(createTechnician.pending, (state) => {
        state.create.isLoading = true;
      })
      .addCase(createTechnician.fulfilled, (state, action) => {
        state.create.error = false;
        state.create.isLoading = false;
        state.create.createdTechnician = action.payload.data[0];
      })
      .addCase(createTechnician.rejected, (state, action) => {
        state.create.isLoading = false;
        state.create.error = action.payload;
      })
      // Update Technician
      .addCase(updateTechnician.pending, (state) => {
        state.create.isLoading = true;
      })
      .addCase(updateTechnician.fulfilled, (state, action) => {
        state.create.error = false;
        state.create.isLoading = false;
        state.create.createdTechnician = action.payload.data[0];
      })
      .addCase(updateTechnician.rejected, (state, action) => {
        state.create.isLoading = false;
        state.create.error = action.payload;
      })
      // Edit Technician
      .addCase(getTechnicianById.pending, (state) => {
        state.edit.isLoading = true;
      })
      .addCase(getTechnicianById.fulfilled, (state, action) => {
        state.edit.isLoading = false;
        state.edit.editTechnician = action.payload.data[0];
      })
      .addCase(getTechnicianById.rejected, (state, action) => {
        state.edit.isLoading = false;
        state.edit.error = action.payload;
      })

      // Get Technician Names List
      .addCase(getTechnicianNamesList.pending, (state) => {
        state.technicianNamesDropdownList.isLoading = true;
      })
      .addCase(getTechnicianNamesList.fulfilled, (state, action) => {
        state.technicianNamesDropdownList.isLoading = false;
        state.technicianNamesDropdownList.data = action.payload;
      })
      .addCase(getTechnicianNamesList.rejected, (state, action) => {
        state.technicianNamesDropdownList.isLoading = false;
        state.technicianNamesDropdownList.error = action.error.message;
      });
  },
});

export const { resetCreate, resetEdit, resetPagination } =
  technicianSlice.actions;

export default technicianSlice.reducer;
