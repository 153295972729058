import { createAsyncThunk } from '@reduxjs/toolkit';
import moment from 'moment';

import { APIs } from '../../constants/APIConstants';
import { DATE_RANGE_FORMAT_API } from '../../constants/Constants';
import { filterDateFormatted, formatDateAPI } from '../../utils';
import { TIME_SHEET } from '../actionPrefix';
import { axiosInstanceTechnician } from '../axios';

export const getTimeSheetList = createAsyncThunk(
  TIME_SHEET.GET_TIME_SHEETS,
  async (
    { timesheet_uuid, technicianUUID, calendarStartDate, calendarEndDate },
    { rejectWithValue }
  ) => {
    const params = new URLSearchParams();

    // Add params conditionally
    if (timesheet_uuid) params.append('timesheet_uuid', timesheet_uuid);
    if (technicianUUID) params.append('technician_uuid', technicianUUID);
    if (calendarStartDate)
      params.append('start_date', filterDateFormatted(calendarStartDate));
    if (calendarEndDate)
      params.append('end_date', filterDateFormatted(calendarEndDate));

    try {
      const response = await axiosInstanceTechnician.get(
        `${APIs.TIME_SHEET.GET_TIME_SHEETS}?${params.toString()}`
      );

      return { ...response.data };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const downloadTimeSheetList = createAsyncThunk(
  TIME_SHEET.DOWNLOAD_TIME_SHEETS,
  async (
    { timesheet_uuid, technicianUUID, calendarStartDate, calendarEndDate },
    { rejectWithValue }
  ) => {
    const params = new URLSearchParams();

    // Add params conditionally
    if (timesheet_uuid) params.append('timesheet_uuid', timesheet_uuid);
    if (technicianUUID) params.append('technician_uuid', technicianUUID);
    if (calendarStartDate)
      params.append(
        'start_date',
        formatDateAPI(moment(calendarStartDate).format(DATE_RANGE_FORMAT_API))
      );
    if (calendarEndDate)
      params.append(
        'end_date',
        formatDateAPI(
          moment(calendarEndDate).endOf('day').format(DATE_RANGE_FORMAT_API)
        )
      );

    params.append('limit', -1);

    try {
      const response = await axiosInstanceTechnician.get(
        `${APIs.TIME_SHEET.DOWNLOAD_TIME_SHEETS}?${params.toString()}`
      );

      return { ...response.data };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getSingleTimeEntry = createAsyncThunk(
  TIME_SHEET.GET_TIME_ENTRY,
  async ({ timesheet_uuid }, { rejectWithValue }) => {
    const params = new URLSearchParams();

    // Add params conditionally
    if (timesheet_uuid) params.append('timesheet_uuid', timesheet_uuid);

    try {
      const response = await axiosInstanceTechnician.get(
        `${APIs.TIME_SHEET.GET_TIME_ENTRY}?${params.toString()}`
      );
      return { ...response.data };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getTimeSheetCalenderTechnicianList = createAsyncThunk(
  TIME_SHEET.GET_CALENDAR_TECH_LIST,
  async (req, { rejectWithValue }) => {
    const params = new URLSearchParams();

    if (req) params.append('zones', req);

    try {
      const response = await axiosInstanceTechnician.get(
        `${APIs.TECHNICIAN.GET_TECHNICIAN_NAMES}?${params.toString()}`
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createTimeSheet = createAsyncThunk(
  TIME_SHEET.CREATE_TIME_SHEET,
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceTechnician.post(
        APIs.TIME_SHEET.GET_TIME_SHEETS,
        data
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateTimeSheet = createAsyncThunk(
  TIME_SHEET.CREATE_TIME_SHEET,
  async ({ timesheet_uuid, data }, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceTechnician.put(
        APIs.TIME_SHEET.UPDATE_TIME_SHEET.replace(
          ':timesheet_uuid',
          timesheet_uuid
        ),
        data
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const approveOrRejectTimeSheet = createAsyncThunk(
  TIME_SHEET.APPROVE_OR_REJECT,
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceTechnician.patch(
        APIs.TIME_SHEET.APPROVE_OR_REJECT,
        data
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteTimeSheet = createAsyncThunk(
  TIME_SHEET.DELETE_TIME_SHEET,
  async ({ timesheet_uuid }, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceTechnician.delete(
        APIs.TIME_SHEET.DELETE_TIME_SHEET.replace(
          ':timesheet_uuid',
          timesheet_uuid
        )
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
