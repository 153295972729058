export const PRIMARY = '#95C020';
export const SECONDARY = '#FFFFFF';
export const TEXT_COLOR = '#1E1E1E';
export const DISABLE = '#cccccc';
export const DISABLED_BACKGROUND = 'rgba(235, 235, 235, 1)';
export const DIVIDER_COLOR = '#EBEBEB';
export const WARNING = '#C54036';
export const WARNING_HOVER = '#aa3931';
export const NODATA_ICON_COLOR = '#0000008F';
export const INVITE_PENDING = '#FD7E14';
export const DRAFT = '#00000066';
export const YELLOW = '#F7B84B';
export const LAVENDER = '#A85BBB';
export const RED = '#F06548';
export const LIGHT_RED = '#d32f2f';
export const SKY = '#299CDB';
export const GOOGLE_MAP_MARKER_COLOR = ['95C020', '#59C3C3'];
export const ROW_HIGHLIGHTER_COLOR = '#95C02033';
export const DATA_AMANAGEMENT_ICON_COLOR = '#00000080';

export const STATUS_COLORS = {
  PENDING: YELLOW,
  APPROVED: PRIMARY,
  IN_PROGRESS: '#59C3C3',
  REJECTED: WARNING,
};
export const DARK_BLUE = '#405189';
export const TURQUOISE = '#59C3C3';
export const DARK_GREEN = '#395B50';
export const ORANGE_YELLOW = '#F0CC1C';
