import React from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Box, Divider, Typography } from '@mui/material';

import { SECONDARY } from '../../../../constants/Colors';
import getDropdownListHook from '../../../../hooks/getDropdownListHook';
import Autocomplete from '../../../CommonComponents/AutoComplete';

const AccountManager = ({ title, control, Section, trigger }) => {
  const { t } = useTranslation();

  const { accountManagerDropdownLoading, accountManagerDropdownData } =
    getDropdownListHook({
      reducerName: 'accountManager',
      dropdownListName: 'accountManagerDropdownList',
      labelName: 'name',
      valueName: 'uuid',
    });

  return (
    <Box
      sx={{
        background: SECONDARY,
        borderRadius: '4px',
      }}
    >
      <Box>
        <Box sx={{ padding: '16px' }}>
          <Typography variant="h2">{title}</Typography>
        </Box>
        <Divider />
        <Section>
          <Controller
            name="accountManager"
            control={control}
            rules={{}}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Autocomplete
                placeholder={t('attributes.account_manager.account_manager')}
                options={accountManagerDropdownData}
                value={value}
                onChange={(e, newValue) => {
                  onChange(newValue);
                  trigger('accountManager');
                }}
                isLoadingData={accountManagerDropdownLoading}
                helperText={error ? error.message : ''}
                error={error}
                isRequired={false}
              />
            )}
          />
        </Section>
      </Box>
    </Box>
  );
};

export default AccountManager;
