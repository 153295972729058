import BlockOutlinedIcon from '@mui/icons-material/BlockOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import { DIVIDER_COLOR } from '../../constants/Colors';
import { GLOBAL_ADMIN, SUPER_ADMIN } from '../../constants/Roles';
import { getUserRole } from '../../hoc/Permission';
import SuperAdminDashboard from './SuperAdminDashboard';
const CardBox = ({ color, icon, title, count }) => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'left',
      alignItems: 'center',
      bgcolor: 'white',
      borderRadius: '8px',
      padding: '16px',
      height: '100%',
    }}
  >
    <Box
      sx={{
        height: '64px',
        width: '64px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: color,
        borderRadius: '8px',
      }}
    >
      {icon}
    </Box>
    <Box sx={{ marginLeft: '16px' }}>
      <Typography variant="body2">{title}</Typography>
      <Typography variant="body3">{count}</Typography>
    </Box>
  </Box>
);

const Dashboard = () => {
  const userRole = getUserRole();

  return (
    <Box sx={{ bgcolor: DIVIDER_COLOR, height: '100%' }}>
      {userRole === GLOBAL_ADMIN && (
        <Box sx={{ width: '100%', padding: '16px 0px' }}>
          <Grid container spacing={2}>
            <Grid xs={12} sm={6} md={3}>
              <CardBox
                icon={
                  <CheckCircleOutlineIcon
                    sx={{ color: 'white', height: '50px', width: '50px' }}
                  />
                }
                color="#95C020"
                title="Active Company"
                count="30"
              />
            </Grid>
            <Grid xs={12} sm={6} md={3}>
              <CardBox
                icon={
                  <BlockOutlinedIcon
                    sx={{ color: 'white', height: '50px', width: '50px' }}
                  />
                }
                color="#C04B20"
                title="Inactive Company"
                count="30"
              />
            </Grid>
          </Grid>
        </Box>
      )}
      {userRole === SUPER_ADMIN && <SuperAdminDashboard />}
    </Box>
  );
};

export default Dashboard;
