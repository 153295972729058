import { createSlice } from '@reduxjs/toolkit';

import { createBillingCard, getBillingList } from './api';

const initialGetData = {
  isLoading: true,
  error: null,
  billingList: [],
  page: 1,
  size: 10,
  total: 0,
  totalCount: 0,
};

const initialCreate = {
  isLoading: null,
  error: null,
  bill: null,
};

const billingSlice = createSlice({
  name: 'billing',
  initialState: {
    getList: { ...initialGetData },
    create: { ...initialCreate },
  },
  reducers: {
    resetBillingData(state) {
      state.getList = { ...initialGetData };
    },
    resetBillingCreate(state) {
      state.create = { ...initialCreate };
    },
  },
  extraReducers: (builder) => {
    builder
      // Billing List Start
      .addCase(getBillingList.pending, (state) => {
        state.getList.isLoading = true;
      })
      .addCase(getBillingList.fulfilled, (state, action) => {
        const { total, total_records, data } = action.payload;

        state.getList.isLoading = false;
        state.getList.billingList = data;
        state.getList.total = total;
        state.getList.totalCount = total_records;
      })
      .addCase(getBillingList.rejected, (state, action) => {
        state.getList.isLoading = false;
        state.getList.error = action.payload;
      })
      // Billing List End

      // Billing Create Start
      .addCase(createBillingCard.pending, (state) => {
        state.create.isLoading = true;
      })
      .addCase(createBillingCard.fulfilled, (state, action) => {
        state.create.error = false;
        state.create.isLoading = false;
        state.create.bill = action.payload;
      })
      .addCase(createBillingCard.rejected, (state, action) => {
        state.create.isLoading = false;
        state.create.error = action.payload;
      });
    // Billing End
  },
});

export const { resetBillingData, resetBillingCreate } = billingSlice.actions;

export default billingSlice.reducer;
