import { useCallback, useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  Box,
  Card,
  Checkbox,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import { debounce } from 'lodash';

import { PRODUCT_TYPES } from '../../constants/Constants';
import { getproductCatalogueList } from '../../store/productCatalogue/api';
import Autocomplete from '../CommonComponents/AutoComplete';
import CustomGridTable from '../CommonComponents/CustomGridTable';
import CustomTextField from '../CommonComponents/CustomTextField';
import NoRecordFound from '../CommonComponents/NoDataPage/NoRecordFound';

const FormWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '16px',
}));

const CustomCard = styled(Card)(() => ({
  boxShadow: 'none',
}));

const FormFieldWrapper = styled(Box)(() => ({
  padding: '16px',
  rowGap: '16px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));

const AddProduct = ({
  control,
  watch,
  setValue,
  trigger,
  defectTitleList,
  selectedProductsError,
}) => {
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const clearHandler = (name) => setValue(name, '');
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const { productList, isLoading } = useSelector(
    (state) => state.productCatalogue.get
  );

  useEffect(() => {
    setProducts(productList);
  }, [productList]);

  const selectedtype = watch('type');
  const searchProduct = watch('search');

  const debouncedFetchProductList = useCallback(
    debounce((type, search) => {
      setLoading(true);
      dispatch(
        getproductCatalogueList({
          type,
          search: search,
          limit: '-1',
        })
      );
    }, 500),
    [dispatch]
  );

  // Trigger the debounced function when selected type or search changes
  useEffect(() => {
    debouncedFetchProductList(selectedtype?.value, searchProduct);
    setSelectedProducts([]);
    setIsAllSelected(false);
  }, [selectedtype?.value, searchProduct, debouncedFetchProductList]);

  const handleCheckboxChange = (product) => {
    setSelectedProducts((prevSelected) => {
      const existingProduct = prevSelected.find((p) => p.uuid === product.uuid);

      if (existingProduct) {
        // Remove if already selected
        return prevSelected.filter((p) => p.uuid !== product.uuid);
      } else {
        // Add if not selected
        return [...prevSelected, { ...product, qty: 1 }];
      }
    });
  };

  useEffect(() => {
    // Check if all individual checkboxes are selected
    if (
      selectedProducts.length === productList.length &&
      productList.length > 0
    ) {
      setIsAllSelected(true);
    } else {
      setIsAllSelected(false);
    }
  }, [selectedProducts, productList]);

  const handleQuantityChange = (product, qty) => {
    setSelectedProducts((prevSelected) => {
      const existingProduct = prevSelected.find((p) => p.uuid === product.uuid);

      if (!existingProduct) {
        return [...prevSelected, { ...product, qty: Number(qty) }];
      }

      return prevSelected.map((p) =>
        p.uuid === product.uuid ? { ...p, qty: Number(qty) } : p
      );
    });
  };

  const handleSelectAllChange = () => {
    setIsAllSelected((prevIsAllSelected) => {
      const newIsAllSelected = !prevIsAllSelected;

      if (newIsAllSelected) {
        setSelectedProducts((prevSelected) => {
          // Find products that are not already in the selectedProducts list
          const newProductsToAdd = productList.filter(
            (product) => !prevSelected.some((p) => p.uuid === product.uuid)
          );

          // Merge already selected products with new products
          return [
            ...prevSelected,
            ...newProductsToAdd.map((product) => ({ ...product, qty: 1 })),
          ];
        });
      } else {
        // Clear selectedProducts when "Select All" is unchecked
        setSelectedProducts([]);
      }

      return newIsAllSelected;
    });
  };

  useEffect(() => {
    setValue('selectedProducts', selectedProducts);
  }, [selectedProducts, setValue]);

  const columns = [
    {
      field: 'product_name',
      headerName: 'Product Name',
      flex: 4, // 40% width
      sortable: false,
      renderHeader: () => (
        <Box style={{ display: 'flex', alignItems: 'center' }}>
          <Checkbox
            color="primary"
            checked={isAllSelected}
            onChange={handleSelectAllChange}
          />
          <Typography variant="body1">Product Name</Typography>
        </Box>
      ),
      renderCell: (params) => (
        <Box style={{ display: 'flex', alignItems: 'center' }}>
          <Checkbox
            color="primary"
            checked={selectedProducts.some((p) => p.uuid === params.row.uuid)}
            onChange={() => handleCheckboxChange(params.row)}
          />
          <Typography variant="body2">{params.row.product_name}</Typography>
        </Box>
      ),
    },
    {
      field: 'quantity',
      headerName: 'Quantity',
      flex: 1, // 10% width
      sortable: false,
      renderCell: (params) => (
        <TextField
          name="qty"
          type="number"
          sx={{
            '& .MuiInputBase-input': {
              padding: '0 10px',
              height: '30px',
            },
          }}
          value={
            selectedProducts.find((p) => p.uuid === params.row.uuid)?.qty || 1
          }
          onChange={(e) => handleQuantityChange(params.row, e.target.value)}
        />
      ),
    },
    {
      field: 'cost',
      headerName: 'Cost',
      flex: 1, // 10% width
      sortable: false,
      renderCell: (params) => (
        <Typography variant="body2">{params.row.cost.toFixed(2)}</Typography>
      ),
    },
    {
      field: 'sell_price',
      headerName: 'Sell',
      flex: 1, // 10% width
      sortable: false,
      renderCell: (params) => (
        <Typography variant="body2">
          {params.row.sell_price.toFixed(2)}
        </Typography>
      ),
    },
  ];

  const rows = products?.map((item, index) => ({
    id: index,
    uuid: item.uuid,
    product_name: item.product_name,
    quantity: selectedProducts.find((p) => p.uuid === item.uuid)?.qty || 1,
    cost: item.cost,
    sell_price: item.sell_price,
  }));

  return (
    <FormWrapper>
      <CustomCard>
        <FormFieldWrapper component="form">
          <Controller
            name="defect_title"
            control={control}
            rules={{
              required: 'Defect title field is required',
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Autocomplete
                label={t('attributes.defectsQuotes.defectTitle')}
                options={defectTitleList}
                value={value}
                onChange={(e, newValue) => {
                  onChange(newValue);
                  trigger('defect_title');
                }}
                helperText={error ? error.message : ''}
                error={error}
                isLoadingData={false}
                isRequired={true}
              />
            )}
          />
          <Controller
            name="type"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Autocomplete
                label={t('attributes.productCatalogue.type')}
                options={PRODUCT_TYPES}
                value={value}
                onChange={(e, newValue) => {
                  onChange(newValue);
                  trigger('type');
                }}
                helperText={error ? error.message : ''}
                error={error}
                isLoadingData={false}
                isRequired={false}
              />
            )}
          />
          <Controller
            name="search"
            control={control}
            render={({
              field: { onChange, value, name, onBlur },
              fieldState: { error },
            }) => (
              <CustomTextField
                label={t('attributes.search')}
                fullWidth
                value={value}
                onChange={(e) => {
                  onChange(e);
                  trigger('search');
                }}
                onBlur={() => {
                  onChange(value.trim());
                  onBlur();
                }}
                helperText={error ? error.message : ''}
                error={error}
                onClear={() => clearHandler(name)}
                isRequired={false}
              />
            )}
          />
        </FormFieldWrapper>
      </CustomCard>
      <CustomCard>
        {selectedProductsError ? (
          <Typography color="error">{selectedProductsError}</Typography>
        ) : (
          <CustomGridTable
            columns={columns}
            rows={rows}
            total={rows.length}
            checkboxSelection={false}
            paginationRequired={false}
            filterHeight={340}
            disableColumnMenu={true}
            isLoading={isLoading}
            noData={<NoRecordFound />}
          />
        )}
      </CustomCard>
    </FormWrapper>
  );
};

export default AddProduct;
