import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Box } from '@mui/material';

import { BrokenImage } from '../../assets/images';
import { getDocument } from '../../store/CommonAPI';
import CustomCircularLoader from './CustomLoader';

const ImageView = ({ uuid }) => {
  const dispatch = useDispatch();
  const [imgSrc, setImgSrc] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (uuid) {
      setIsLoading(true);
      dispatch(getDocument({ doc_uuid: uuid }))
        .then((res) => setImgSrc(res?.payload?.data?.[0]?.presigned_url))
        .catch((err) => setError(true))
        .finally(() => setIsLoading(false));
    }
  }, [uuid]);
  return isLoading ? (
    <CustomCircularLoader />
  ) : (
    <Box sx={{ width: '100px', height: '100px', borderRadius: '8px' }}>
      {error ? (
        <img
          src={BrokenImage}
          style={{ width: '100%', height: '100%', objectFit: 'cover' }}
        />
      ) : (
        <img
          src={imgSrc}
          alt="User Document"
          onError={() => setError(true)}
          style={{ width: '100%', height: '100%', objectFit: 'cover' }}
        />
      )}
    </Box>
  );
};

export default ImageView;
