import React from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Box, Divider, Typography } from '@mui/material';

import { SECONDARY } from '../../../../constants/Colors';
import { Validation } from '../../../../constants/FieldValidationMsg';
import { EMAIL_REGEX } from '../../../../constants/Regex';
import { clearTextfields } from '../../../../utils';
import CommonCheckbox from '../../../CommonComponents/CustomCheckbox';
import CustomTextField from '../../../CommonComponents/CustomTextField';

const Billing = ({ title, control, trigger, setValue, Section }) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        background: SECONDARY,
        borderRadius: '4px',
      }}
    >
      <Box>
        <Box sx={{ padding: '16px' }}>
          <Typography variant="h2">{title}</Typography>
        </Box>
        <Divider />
        <Section>
          <Controller
            name="organization"
            control={control}
            rules={{
              maxLength: {
                value: 100,
                message: Validation.general.organization,
              },
            }}
            render={({
              field: { onChange, value, name, onBlur },
              fieldState: { error },
            }) => (
              <CustomTextField
                label={t(`attributes.customer.organisation`)}
                fullWidth
                value={value}
                onChange={(e) => {
                  onChange(e);
                  trigger('organization');
                }}
                helperText={error ? error.message : ''}
                error={error}
                onClear={() => clearTextfields(setValue, name)}
                isRequired={false}
                onBlur={() => {
                  onChange(value.trim());
                  onBlur();
                }}
              />
            )}
          />
          <Controller
            name="billing_contact_name"
            control={control}
            rules={{
              maxLength: {
                value: 80,
                message: Validation.general.billingContactName,
              },
            }}
            render={({
              field: { onChange, value, name, onBlur },
              fieldState: { error },
            }) => (
              <CustomTextField
                label="Billing Contact Name"
                fullWidth
                value={value}
                onChange={(e) => {
                  onChange(e);
                  trigger('billing_contact_name');
                }}
                helperText={error ? error.message : ''}
                error={error}
                onClear={() => clearTextfields(setValue, name)}
                isRequired={false}
                onBlur={() => {
                  onChange(value.trim());
                  onBlur();
                }}
              />
            )}
          />
          <Controller
            name="billingEmailTo"
            control={control}
            rules={{
              maxLength: {
                value: 254,
                message: Validation.general.email,
              },
              pattern: {
                value: EMAIL_REGEX,
                message: Validation.general.validEmail,
              },
            }}
            render={({
              field: { onChange, value, name },
              fieldState: { error },
            }) => (
              <CustomTextField
                label="Email To"
                fullWidth
                value={value}
                onChange={(e) => {
                  onChange(e.target.value);
                  trigger('billingEmailTo');
                }}
                helperText={error ? error.message : ''}
                error={error}
                onClear={() => clearTextfields(setValue, name)}
                isRequired={false}
              />
            )}
          />
          <CommonCheckbox
            control={control}
            name="fixedPriceContract"
            label="Fixed Price Contract"
            isRequired={false}
            showMessage={true}
            showDefaultMessage={true}
            messageOnChecked="Client is on a fixed price contract; the price should not be adjusted for CPI automatically."
            messageOnUnchecked="Client is on a fixed price contract; the price should not be adjusted for CPI automatically."
          />
          <CommonCheckbox
            control={control}
            name="manualBillingRequired"
            label="Manual Billing Required"
            isRequired={false}
            showMessage={true}
            showDefaultMessage={true}
            messageOnChecked="Invoice will not be automatically generated for this client; special attention is required."
            messageOnUnchecked="Invoice will not be automatically generated for this client; special attention is required."
          />
        </Section>
      </Box>
    </Box>
  );
};

export default Billing;
