import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { WarningAmberOutlined } from '@mui/icons-material';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import ForwardToInboxOutlinedIcon from '@mui/icons-material/ForwardToInboxOutlined';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { Box, Divider, Typography } from '@mui/material';
import { debounce } from 'lodash';
import moment from 'moment/moment';

import { RED, SECONDARY } from '../../../constants/Colors';
import {
  INVOICE_STATUS_LIST,
  PURCHASE_ORDER_STATUS,
  SCHEDULE_BOARD_WO_UTC_LOCAL,
  WORK_ORDER_STEPS,
} from '../../../constants/Constants';
import getDropdownListHook from '../../../hooks/getDropdownListHook';
import { snackbarToggle } from '../../../store/CommonReducer';
import { editCompany } from '../../../store/company/api';
import {
  createInvoice,
  getInvoiceList,
  updateInvoice,
} from '../../../store/invoices/api';
import {
  resetCreateInvoice,
  resetInvoiceList,
  resetUpdateInvoice,
} from '../../../store/invoices/reducer';
import {
  getPropertyCustomerList,
  getPropertyList,
} from '../../../store/property/api';
import {
  formatDateAPI,
  formatStatus,
  formattedDate,
  loggedInUserDetail,
} from '../../../utils';
import { Edit, View } from '../../CommonComponents/ActionComponent';
import Autocomplete from '../../CommonComponents/AutoComplete';
import CustomButton from '../../CommonComponents/CustomButton';
import CustomDateRangePicker from '../../CommonComponents/CustomDateRangePicker';
import CustomGridTable from '../../CommonComponents/CustomGridTable';
import CustomCircularLoader from '../../CommonComponents/CustomLoader';
import {
  FilterComponent,
  FilterSection,
} from '../../CommonComponents/FilterComponent';
import NewNoDataPage from '../../CommonComponents/NoDataPage/NewNoDataPage';
import NoRecordFound from '../../CommonComponents/NoDataPage/NoRecordFound';
import StyledMainWrapper from '../../CommonComponents/StyledMainWrapper';
import SwipeableDrawer from '../../CommonComponents/SwipeableDrawer';
import generatePDF from './DownloadPreviewInvoice';
import InvoiceDetail from './InvoiceDetail';
import PreviewInvoice from './PreviewInvoice';

const filterHeight = (isFilterOpen) => (isFilterOpen ? 360 : 390);

//default filters
const defaultFilters = {
  property: null,
  customer: null,
  status: null,
  invoice_date: {
    startDate: null,
    endDate: null,
  },
  due_date: {
    startDate: null,
    endDate: null,
  },
};

const Invoices = ({
  workOrderId = '',
  nextStepTitle,
  setNextStepTitle,
  isFromWorkOrder = true,
  isFromSideBar = false,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('created_at');
  const [currentPage, setCurrentPage] = useState(1);
  const [perPageData, setPerPageData] = useState(10);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({});
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [filters, setFilters] = useState(defaultFilters);
  const [searchText, setSearchText] = useState('');
  const [isFilterComponentVisible, setIsFilterComponentVisible] =
    useState(false);
  const [refresh, setRefresh] = useState(false);
  const [openPreview, setOpenPreview] = useState(false);
  const [invoiceId, setInvoiceId] = useState();
  const [invoiceDetails, setInvoiceDetails] = useState();
  const [stepTitle, setStepTitle] = useState();

  const user = loggedInUserDetail();

  const { invoiceList, total, totalCount, isLoading } = useSelector(
    (state) => state.invoices.get
  );

  const { data: createInvoiceData, isLoading: createInvoiceLoading } =
    useSelector((state) => state.invoices.create);

  const { data: updateInvoiceData, isLoading: updateInvoiceLoading } =
    useSelector((state) => state.invoices.update);

  const { data: createInvoiceProductData } = useSelector(
    (state) => state.invoices.createProduct
  );

  const { data: updateInvoiceProductData } = useSelector(
    (state) => state.invoices.updateProduct
  );

  const { data: deleteInvoiceProductData } = useSelector(
    (state) => state.invoices.deleteProduct
  );

  const { isLoading: isLoadingCompany, company } = useSelector(
    (state) => state.company.edit
  );

  const { propertyDropdownLoading, propertyDropdownData } = getDropdownListHook(
    {
      reducerName: 'property',
      dropdownListName: 'propertyList',
      labelName: 'property_name',
      valueName: 'uuid',
      loading: 'loading',
    }
  );

  const {
    propertyDropdownLoading: customerDropdownLoading,
    propertyDropdownData: customerDropdownData,
  } = getDropdownListHook({
    reducerName: 'property',
    dropdownListName: 'propertyCustomerList',
    labelName: 'customer_name',
    valueName: 'uuid',
  });

  useEffect(() => {
    const detail = invoiceList?.find((item) => item.id === invoiceId);

    detail && setInvoiceDetails(detail);
  }, [invoiceList, invoiceId]);

  useEffect(
    () => () => {
      dispatch(resetInvoiceList());
      dispatch(resetCreateInvoice());
      dispatch(resetUpdateInvoice());
    },
    []
  );

  useEffect(() => {
    if (!createInvoiceLoading) {
      if (createInvoiceData) {
        debouncedFetchData();
        dispatch(
          snackbarToggle({
            isOpen: true,
            isErrorMsg: false,
            msg: createInvoiceData.message,
          })
        );
      }
    }
  }, [createInvoiceLoading, createInvoiceData]);

  useEffect(() => {
    if (!updateInvoiceLoading) {
      if (updateInvoiceData) {
        setOpenPreview(false);
        debouncedFetchData();
        dispatch(
          snackbarToggle({
            isOpen: true,
            isErrorMsg: false,
            msg: updateInvoiceData.message,
          })
        );
      }
    }
  }, [updateInvoiceLoading, updateInvoiceData]);

  // For property dropdown
  useEffect(() => {
    const req = {
      limit: -1,
      customerUuid: filters.customer?.value,
    };

    dispatch(getPropertyList(req));
    dispatch(getPropertyCustomerList());
  }, [filters.customer?.value]);

  const columns = useMemo(() => {
    const baseColumns = [
      {
        headerName: `${t('common.id')}`,
        field: 'id',
        sortable: true,
        flex: 0.5,
        renderCell: ({ row }) => `INV-${row.id}`,
      },
      {
        headerName: `${t('attributes.invoice.invoice_date')}`,
        field: 'invoice_date',
        sortable: false,
        flex: 1,
      },
      {
        headerName: `${t('attributes.invoice.due_date')}`,
        field: 'due_date',
        sortable: false,
        flex: 1,
        renderCell: ({ row }) => {
          if (
            moment(
              formattedDate(row?.due_date, SCHEDULE_BOARD_WO_UTC_LOCAL)
            ).isBefore(moment())
          ) {
            return (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography color={RED}>
                  {formattedDate(row?.due_date)}
                </Typography>
                <WarningAmberOutlined sx={{ color: `${RED}!important` }} />
              </Box>
            );
          }

          return formattedDate(row.due_date);
        },
      },
      {
        headerName: `${t('common.total_amount')}`,
        field: 'totalAmount',
        sortable: false,
        flex: 1,
      },
      {
        headerName: `${t('attributes.invoice.status')}`,
        field: 'status',
        sortable: false,
        flex: 1,
        renderCell: ({ row }) => formatStatus(row.status),
      },
      {
        field: `${t('attributes.actions')}`,
        headerName: t('attributes.actions'),
        flex: 0.5,
        sortable: false,
        hideable: false,
        renderCell: ({ row }) => (
          <Box sx={{ display: 'flex', gap: '8px' }}>
            <View
              disabled={row?.status === PURCHASE_ORDER_STATUS.DRAFT}
              onClick={() => {
                setInvoiceId(row?.id);
                setOpenPreview(true);
                if (user.company_uuid) {
                  dispatch(editCompany(user?.company_uuid));
                }
              }}
            />
            <Edit
              onClick={() => {
                setInvoiceId(row?.id);
                setStepTitle({
                  title: WORK_ORDER_STEPS.invoice,
                  step: `INV-${row.id}`,
                });
                setNextStepTitle &&
                  setNextStepTitle({
                    title: WORK_ORDER_STEPS.invoice,
                    step: `INV-${row.id}`,
                  });
                dispatch(resetCreateInvoice());
              }}
            />
          </Box>
        ),
      },
    ];

    if (!isFromWorkOrder) {
      baseColumns.splice(
        1,
        0,
        {
          field: 'wo_id',
          headerName: `${t('attributes.invoice.wo_id')}`,
          sortable: true,
          flex: 1,
        },
        {
          field: 'billing_information',
          headerName: `${t('attributes.billingCard.billingInfoText')}`,
          sortable: false,
          flex: 1,
        }
      );
    }

    if (isFromSideBar) {
      baseColumns.splice(
        1,
        0,
        {
          field: 'customer',
          headerName: `${t('attributes.customer.customer')}`,
          sortable: true,
          flex: 1,
        },
        {
          field: 'property',
          headerName: `${t('attributes.property.property')}`,
          sortable: false,
          flex: 1,
          renderCell: ({ row }) =>
            row?.property ? (
              <Box>
                <Typography variant="body1">
                  {row?.property?.property_name}
                </Typography>
                <Typography variant="body2">
                  {`${row?.property?.address}, ${row?.property?.city}, ${row?.property?.state}, ${row?.property?.zip_code}`}
                </Typography>
              </Box>
            ) : (
              '-'
            ),
        }
      );
    }

    return baseColumns;
  }, []);

  const getAmountByQtyAndPrice = (data) =>
    data?.reduce((total, item) => {
      const amount = item.qty * Number(item.price);

      return total + amount;
    }, 0);

  const rows = invoiceList?.map((item) => ({
    id: item.id,
    invoice_date: formattedDate(item.created_at) || '-',
    due_date: item.due_date || '-',
    totalAmount: getAmountByQtyAndPrice(item.items).toFixed(2) || '-',
    status: item.status,
    wo_id: item.work_order_data?.id ? `W-${item.work_order_data?.id}` : '-',
    property:
      item.work_order_data?.property_equipment_routine_service_schedule
        ?.property,
    billing_information: item.billing_name,
    customer:
      item.work_order_data?.property_equipment_routine_service_schedule
        ?.property?.customer || '-',
  }));

  // Function to fetch data based on search, pagination, and filter
  const getAllInvoices = useCallback(() => {
    const visibleFieldsString = columns
      .filter((col) => columnVisibilityModel[col.field] !== false)
      .map((col) => col.field)
      .join(',');

    setIsDataLoading(true);

    dispatch(
      getInvoiceList({
        order: order,
        orderBy: orderBy,
        page: currentPage,
        size: perPageData,
        search: searchText,
        work_order_uuid: workOrderId,
        customer_uuid: filters.customer?.value,
        list_column_names: visibleFieldsString,
        invoiceFromDate: filters.invoice_date?.startDate,
        invoiceToDate: filters.invoice_date?.endDate,
        status: filters.status?.value,
      })
    ).finally(() => {
      setIsInitialLoading(false);
      setIsDataLoading(false);
    });
  }, [
    order,
    orderBy,
    columns,
    searchText,
    currentPage,
    perPageData,
    columnVisibilityModel,
    filters,
    refresh,
  ]);

  useEffect(() => {
    debouncedFetchData();
  }, [
    createInvoiceProductData,
    updateInvoiceProductData,
    deleteInvoiceProductData,
  ]);

  // Debounced function to avoid frequent API calls
  const debouncedFetchData = useCallback(debounce(getAllInvoices, 500), [
    getAllInvoices,
  ]);

  // Call debouncedFetchData whenever search, page, or filter changes
  useEffect(() => {
    debouncedFetchData();

    // Clean up function for debounce to avoid memory leaks
    return () => {
      debouncedFetchData.cancel();
    };
  }, [debouncedFetchData]);

  const onResetFilter = () => {
    setFilters(defaultFilters);
    setSearchText('');
  };

  const handleCreateInvoice = () => {
    dispatch(createInvoice({ work_order_uuid: workOrderId }));
  };

  const noData = (
    <>
      <NewNoDataPage
        icon={<PersonOutlineOutlinedIcon />}
        title={t('attributes.invoice.invoice')}
        createBtnText={t('common.create_new')}
        singularText="Invoice"
        filterHeight={380}
      />
    </>
  );

  const RenderedComponent = (
    <Box sx={{ width: '100%' }}>
      {isFromWorkOrder && (
        <>
          <Box sx={{ padding: '16px' }}>
            <CustomButton
              text={t('common.create_new')}
              color="secondary"
              sx={{ height: '52%' }}
              startIcon={<AddCircleOutlineOutlinedIcon />}
              onClick={() => {
                handleCreateInvoice(true);
              }}
              disabled={
                invoiceList.length >= 1 || createInvoiceLoading || isLoading
              }
            />
          </Box>
          <Divider />
        </>
      )}
      {!isDataLoading && totalCount === 0 && isFromWorkOrder && noData}
      {isInitialLoading ? (
        <Box
          sx={{
            width: '100%',
            mt: 2,
            minHeight: `calc(100vh - ${filterHeight(isFilterComponentVisible)}px)`,
            maxHeight: `calc(100vh - ${filterHeight(isFilterComponentVisible)}px)`,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <CustomCircularLoader />
        </Box>
      ) : (
        !isInitialLoading &&
        totalCount > 0 && (
          <Box>
            {!isFromWorkOrder && (
              <Box sx={{ p: '16px' }}>
                <FilterSection
                  onFilterBtnClick={() =>
                    setIsFilterComponentVisible(!isFilterComponentVisible)
                  }
                  searchText={searchText}
                  onSearchChange={(e) => {
                    setSearchText(e.target.value);
                  }}
                  isRefresh={true}
                  onRefreshFilter={() => setRefresh(!refresh)}
                  isActive={isFilterComponentVisible}
                  onResetFilter={onResetFilter}
                  isResetButtonVisible={
                    searchText ||
                    filters.status ||
                    filters.invoice_date.startDate ||
                    filters.invoice_date.endDate ||
                    filters.due_date.startDate ||
                    filters.due_date.endDate ||
                    filters.customer ||
                    filters.property
                  }
                />
                {isFilterComponentVisible && (
                  <FilterComponent>
                    {isFromSideBar && (
                      <>
                        <Autocomplete
                          placeholder={t('attributes.customer.customer')}
                          options={customerDropdownData}
                          onChange={(e, newVal) => {
                            if (newVal !== undefined) {
                              setFilters((prev) => ({
                                ...prev,
                                customer: newVal,
                              }));
                              setFilters((prev) => ({
                                ...prev,
                                property: null,
                              }));
                            }
                          }}
                          value={filters?.customer}
                          isLoadingData={customerDropdownLoading}
                          width="190px"
                        />
                        <Autocomplete
                          placeholder={t('attributes.property.properties')}
                          options={propertyDropdownData}
                          onChange={(e, newVal) => {
                            if (newVal !== undefined) {
                              setFilters((prev) => ({
                                ...prev,
                                property: newVal,
                              }));
                            }
                          }}
                          isLoadingData={propertyDropdownLoading}
                          value={filters?.property}
                          disabledDropdown={!filters?.customer?.value}
                          width="190px"
                        />
                      </>
                    )}
                    <Autocomplete
                      placeholder={t('attributes.property.status')}
                      options={INVOICE_STATUS_LIST}
                      value={filters?.status}
                      onChange={(e, newVal) =>
                        setFilters((prev) => ({
                          ...prev,
                          status: newVal,
                        }))
                      }
                      width="190px"
                    />
                    <CustomDateRangePicker
                      placeholder="Invoice Date"
                      onOkClick={(val) => {
                        setFilters((pre) => ({
                          ...pre,
                          invoice_date: { startDate: val[0], endDate: val[1] },
                        }));
                      }}
                      onClear={() => {
                        setFilters((pre) => ({
                          ...pre,
                          invoice_date: { startDate: null, endDate: null },
                        }));
                      }}
                      fromDate={filters.invoice_date?.startDate}
                      toDate={filters.invoice_date?.endDate}
                    />
                    <CustomDateRangePicker
                      placeholder="Due Date"
                      onOkClick={(val) => {
                        setFilters((pre) => ({
                          ...pre,
                          due_date: { startDate: val[0], endDate: val[1] },
                        }));
                      }}
                      onClear={() => {
                        setFilters((pre) => ({
                          ...pre,
                          due_date: { startDate: null, endDate: null },
                        }));
                      }}
                      fromDate={filters.due_date?.startDate}
                      toDate={filters.due_date?.endDate}
                    />
                  </FilterComponent>
                )}
              </Box>
            )}
            <CustomGridTable
              columns={columns}
              rows={rows || []}
              total={total}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              perPageData={perPageData}
              setPerPageData={setPerPageData}
              order={order}
              orderBy={orderBy}
              setOrder={setOrder}
              setOrderBy={setOrderBy}
              columnVisibilityModel={columnVisibilityModel}
              setColumnVisibilityModel={setColumnVisibilityModel}
              noData={<NoRecordFound />}
              isLoading={isDataLoading}
              checkboxSelection={false}
              filterHeight={filterHeight(isFilterComponentVisible)}
              sx={{ paddingLeft: '8px' }}
            />
          </Box>
        )
      )}
    </Box>
  );

  const MainWrapper = isFromSideBar ? (
    <StyledMainWrapper
      title={isFromSideBar ? t('attributes.invoice.invoices') : ' '}
      btn={
        isFromSideBar && (
          <CustomButton
            text={t('attributes.export')}
            color="secondary"
            sx={{ height: '52%' }}
            startIcon={<OpenInNewIcon />}
            onClick={() => {}}
          />
        )
      }
    >
      {RenderedComponent}
    </StyledMainWrapper>
  ) : (
    <>{RenderedComponent}</>
  );

  const handleUpdateInvoiceStatus = (status) => {
    const request = {
      type: invoiceDetails?.type,
      invoice_creation_date: formatDateAPI(invoiceDetails?.created_at),
      due_date: formatDateAPI(invoiceDetails?.due_date),
      payment_status: invoiceDetails?.payment_status,
    };

    dispatch(
      updateInvoice({
        invoice_uuid: invoiceDetails?.uuid,
        data: { ...request, status: status },
      })
    );
  };

  return (
    <>
      {!(nextStepTitle ? nextStepTitle : stepTitle) ? (
        MainWrapper
      ) : (
        <InvoiceDetail
          invoiceId={invoiceId}
          nextStepTitle={nextStepTitle ? nextStepTitle : stepTitle}
          setStepTitle={setStepTitle}
          isFromSideBar={isFromSideBar}
        />
      )}

      {openPreview && (
        <SwipeableDrawer
          bgColor={SECONDARY}
          open={openPreview}
          title={`INV-${invoiceDetails?.id}`}
          onClose={() => {
            setOpenPreview(false);
          }}
          footerButton={[
            <CustomButton
              text={t('attributes.download')}
              color="inherit"
              disabled={isLoadingCompany}
              startIcon={<DownloadOutlinedIcon />}
              onClick={() => {
                generatePDF(invoiceDetails, company);
              }}
            />,
            invoiceDetails?.status !== PURCHASE_ORDER_STATUS.SUBMITTED && (
              <CustomButton
                text={t('attributes.invoice.send_invoice')}
                disabled={updateInvoiceLoading}
                startIcon={<ForwardToInboxOutlinedIcon />}
                onClick={() =>
                  handleUpdateInvoiceStatus(PURCHASE_ORDER_STATUS.SUBMITTED)
                }
              />
            ),
          ]}
          width={653}
        >
          <PreviewInvoice
            companyData={company}
            isLoadingCompany={isLoadingCompany}
            invoiceDetails={invoiceDetails}
          />
        </SwipeableDrawer>
      )}
    </>
  );
};

export default Invoices;
