import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { debounce } from 'lodash';

import { getAutocompleteAddress as getAddress } from '../store/google/api';
import { resetAutocompleteAddress } from '../store/google/reducer';

const useAutocompleteAddress = (value) => {
  const dispatch = useDispatch();

  const getAutocompleteAddress = useCallback(() => {
    dispatch(
      getAddress({
        value,
      })
    ).finally(() => {});
  }, [dispatch, value]);

  const debouncedFetchData = useCallback(
    debounce(getAutocompleteAddress, 500),
    [getAutocompleteAddress]
  );

  useEffect(() => {
    debouncedFetchData();
  }, []);

  useEffect(() => {
    if (value?.length > 3) {
      debouncedFetchData();
    }

    return () => {
      dispatch(resetAutocompleteAddress());
      debouncedFetchData.cancel();
    };
  }, [debouncedFetchData, value]);

  return <></>;
};

export default useAutocompleteAddress;
