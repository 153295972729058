import React, { useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Card, Divider, styled, Typography } from '@mui/material';

import { Validation } from '../../constants/FieldValidationMsg';
import getDropdownDataHook from '../../hooks/getDropdownDataHook';
import { getAssetDetails, getProducts } from '../../store/property/api';
import {
  resetAssetDetails,
  resetcreateAsset,
  resetUpdateAsset,
} from '../../store/property/reducer';
import { clearTextfields, datePickerFormat } from '../../utils';
import Autocomplete from '../CommonComponents/AutoComplete';
import CommonCheckbox from '../CommonComponents/CustomCheckbox';
import CustomDatePicker from '../CommonComponents/CustomDatePicker';
import CustomCircularLoader from '../CommonComponents/CustomLoader';
import CustomTextField from '../CommonComponents/CustomTextField';

const FormWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '16px',
}));

const CustomCard = styled(Card)(() => ({
  boxShadow: 'none',
}));

const FormFieldWrapper = styled(Box)(() => ({
  padding: '16px',
  rowGap: '16px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));

const AssetForm = ({
  id,
  control,
  watch,
  setValue,
  trigger,
  equipmentTypes,
  reset,
  assetStatusList,
  nextStepTitle,
}) => {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const { products, assetDetails } = useSelector((state) => state.property);

  const productsList = getDropdownDataHook({
    data: products?.data?.data,
    labelName: 'display_name',
    valueName: 'code',
  });

  const selectedEquipmentType = watch('equipment_type');
  const selectedProduct = watch('product');
  const assetName =
    selectedEquipmentType?.label || selectedProduct?.label
      ? `${selectedEquipmentType?.label || ''}-${selectedProduct?.label || ''}`
      : '';

  useEffect(() => {
    dispatch(getProducts({ equipment_type: selectedEquipmentType?.value }));
  }, [selectedEquipmentType?.value]);

  useEffect(() => {
    if (id) {
      dispatch(getAssetDetails({ assetId: id }));
    }

    return () => {
      dispatch(resetUpdateAsset());
      dispatch(resetcreateAsset());
      !nextStepTitle && dispatch(resetAssetDetails());
    };
  }, []);

  useEffect(() => {
    if (!assetDetails?.loading && assetDetails && id) {
      const details = assetDetails?.data;

      reset({
        equipment_type: {
          label: details.equipment_type?.display_name,
          value: details.equipment_type?.code,
        },
        product: {
          label: details.product?.display_name,
          value: details.product?.code,
        },

        location: details?.location,
        identification_number: details?.identification_number,
        barcode: details?.barcode,

        make: details?.make,
        model: details?.model,

        size: details?.size,
        quantity: details?.quantity,

        base_date: details?.base_date
          ? datePickerFormat(details?.base_date)
          : null,
        installation_date: details?.installation_date
          ? datePickerFormat(details?.installation_date)
          : null,
        internal_note: details?.internal_note,
        active: details?.is_active,
        status_id: details?.status?.id
          ? {
              label: details?.status?.display_name,
              value: details?.status?.id,
            }
          : null,
      });
    } else {
      reset();
    }
  }, [assetDetails]);

  return assetDetails?.loading ? (
    <CustomCircularLoader />
  ) : (
    <FormWrapper>
      <CustomCard>
        <Box sx={{ padding: '16px' }}>
          <Typography variant="body1">General Information</Typography>
        </Box>
        <Divider />
        <FormFieldWrapper>
          <Controller
            name="equipment_type"
            control={control}
            rules={{
              required: 'Equipment Type field is required',
            }}
            render={({
              field: { onChange, value, name },
              fieldState: { error },
            }) => (
              <Autocomplete
                label="Equipment Type"
                options={equipmentTypes}
                value={value}
                onChange={(e, newValue) => {
                  onChange(newValue);
                  setValue('product', null);
                  trigger('equipment_type');
                }}
                helperText={error ? error.message : ''}
                error={error}
                isLoadingData={false}
                onClear={() => clearTextfields(setValue, name)}
                isRequired={true}
                disabledDropdown={id}
              />
            )}
          />
          <Controller
            name="product"
            control={control}
            rules={{
              required: `${t('attributes.property.equipment_class')} ${Validation.general.required}`,
            }}
            render={({
              field: { onChange, value, name },
              fieldState: { error },
            }) => (
              <Autocomplete
                label={t('attributes.property.equipment_class')}
                options={productsList}
                value={value}
                onChange={(e, newValue) => {
                  onChange(newValue);
                  trigger('product');
                }}
                helperText={error ? error.message : ''}
                error={error}
                isLoadingData={false}
                onClear={() => clearTextfields(setValue, name)}
                isRequired={true}
                disabledDropdown={!selectedEquipmentType}
              />
            )}
          />
          <Controller
            name="assetName"
            control={control}
            render={({ field: { onChange, name }, fieldState: { error } }) => (
              <CustomTextField
                label={assetName ? '' : 'Asset Name'}
                fullWidth
                value={assetName}
                disabled={true}
                onChange={(e) => {
                  onChange(e);
                }}
                helperText={error ? error.message : ''}
                error={error}
                onClear={() => clearTextfields(setValue, name)}
                isRequired={false}
              />
            )}
          />
          <Controller
            name="status_id"
            control={control}
            render={({
              field: { onChange, value, name },
              fieldState: { error },
            }) => (
              <Autocomplete
                label="Status"
                options={assetStatusList}
                value={value}
                onChange={(e, newValue) => {
                  onChange(newValue);
                  trigger('status_id');
                }}
                helperText={error ? error.message : ''}
                error={error}
                isLoadingData={false}
                onClear={() => clearTextfields(setValue, name)}
                isRequired={false}
              />
            )}
          />
          <Controller
            name="location"
            control={control}
            render={({
              field: { onChange, value, name },
              fieldState: { error },
            }) => (
              <Box
                sx={{ display: 'flex', flexDirection: 'column', rowGap: '4px' }}
              >
                <CustomTextField
                  label="Location"
                  fullWidth
                  value={value}
                  onChange={(e) => {
                    onChange(e);
                  }}
                  helperText={error ? error.message : ''}
                  error={error}
                  onClear={() => clearTextfields(setValue, name)}
                  isRequired={false}
                />
                <Typography variant="body2">
                  {t('attributes.property.locationInfoText')}
                </Typography>
              </Box>
            )}
          />

          <Controller
            name="identification_number"
            control={control}
            render={({
              field: { onChange, value, name },
              fieldState: { error },
            }) => (
              <Box
                sx={{ display: 'flex', flexDirection: 'column', rowGap: '4px' }}
              >
                <CustomTextField
                  label="Identification Number"
                  fullWidth
                  value={value}
                  onChange={(e) => {
                    onChange(e);
                  }}
                  helperText={error ? error.message : ''}
                  error={error}
                  onClear={() => clearTextfields(setValue, name)}
                  isRequired={false}
                />
                <Typography variant="body2">
                  {t('attributes.property.identificationNumberInfo')}
                </Typography>
              </Box>
            )}
          />
          {false && (
            <Controller
              name="serial"
              control={control}
              render={({
                field: { onChange, value, name },
                fieldState: { error },
              }) => (
                <CustomTextField
                  label="Serial"
                  fullWidth
                  value={value}
                  onChange={(e) => {
                    onChange(e);
                  }}
                  helperText={error ? error.message : ''}
                  error={error}
                  onClear={() => clearTextfields(setValue, name)}
                  isRequired={false}
                />
              )}
            />
          )}
          <Controller
            name="barcode"
            control={control}
            render={({
              field: { onChange, value, name },
              fieldState: { error },
            }) => (
              <CustomTextField
                label="Barcode"
                fullWidth
                value={value}
                onChange={(e) => {
                  onChange(e);
                }}
                helperText={error ? error.message : ''}
                error={error}
                onClear={() => clearTextfields(setValue, name)}
                isRequired={false}
              />
            )}
          />

          <Box sx={{ display: 'flex', columnGap: '8px', width: '100%' }}>
            <Controller
              name="make"
              control={control}
              render={({
                field: { onChange, value, name },
                fieldState: { error },
              }) => (
                <CustomTextField
                  label="Make"
                  fullWidth
                  value={value}
                  onChange={(e) => {
                    onChange(e);
                  }}
                  helperText={error ? error.message : ''}
                  error={error}
                  onClear={() => clearTextfields(setValue, name)}
                  isRequired={false}
                />
              )}
            />
            <Controller
              name="model"
              control={control}
              render={({
                field: { onChange, value, name },
                fieldState: { error },
              }) => (
                <CustomTextField
                  label="Model"
                  fullWidth
                  value={value}
                  onChange={(e) => {
                    onChange(e);
                  }}
                  helperText={error ? error.message : ''}
                  error={error}
                  onClear={() => clearTextfields(setValue, name)}
                  isRequired={false}
                />
              )}
            />
          </Box>

          <Box sx={{ display: 'flex', columnGap: '8px', width: '100%' }}>
            <Controller
              name="size"
              control={control}
              render={({
                field: { onChange, value, name },
                fieldState: { error },
              }) => (
                <CustomTextField
                  label="Size"
                  fullWidth
                  value={value}
                  onChange={(e) => {
                    onChange(e);
                  }}
                  helperText={error ? error.message : ''}
                  error={error}
                  onClear={() => clearTextfields(setValue, name)}
                  isRequired={false}
                />
              )}
            />
            <Controller
              name="quantity"
              control={control}
              render={({
                field: { onChange, value, name },
                fieldState: { error },
              }) => (
                <CustomTextField
                  label="Quantity"
                  fullWidth
                  value={value != null ? value : 1}
                  disabled={true}
                  onChange={(e) => {
                    onChange(e);
                  }}
                  helperText={error ? error.message : ''}
                  error={error}
                  onClear={() => clearTextfields(setValue, name)}
                  isRequired={false}
                />
              )}
            />
          </Box>
          <Box sx={{ display: 'flex', columnGap: '8px', width: '100%' }}>
            <CustomDatePicker
              control={control}
              name="base_date"
              label="Base Date"
              pickerType="date"
              defaultValue={null}
              trigger={trigger}
            />
            <CustomDatePicker
              control={control}
              name="installation_date"
              label="Installation Date"
              pickerType="date"
              defaultValue={null}
              trigger={trigger}
            />
          </Box>
          <Controller
            name="internal_note"
            control={control}
            rules={{
              maxLength: {
                value: 300,
                message: Validation.general.address,
              },
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <CustomTextField
                label="Internal note"
                multiline
                isRequired={false}
                rows={3}
                value={value}
                onChange={(e) => {
                  onChange(e);
                  trigger('internal_note');
                }}
                onClear={() => {
                  onChange('');
                }}
                helperText={error ? error.message : ''}
                error={error}
              />
            )}
          />
        </FormFieldWrapper>
      </CustomCard>
      {id && (
        <CustomCard>
          <Box sx={{ padding: '0 16px 0 16px' }}>
            <CommonCheckbox
              control={control}
              name="active"
              label={t('common.active')}
              isRequired={false}
              showMessage={true}
              messageOnChecked={t('label.superAdmin.assetActiveCheckbox')}
              messageOnUnchecked={t('label.superAdmin.assetInactiveCheckbox')}
            />
          </Box>
        </CustomCard>
      )}
    </FormWrapper>
  );
};

export default AssetForm;
