import React, { useEffect, useRef, useState } from 'react';

import { MarkerClusterer } from '@googlemaps/markerclusterer';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';

import { GOOGLE_MAP_MARKER_COLOR } from '../../constants/Colors';
import { FONT_SIZE } from '../../constants/Constants';

// Map container style
const containerStyle = {
  width: '100%',
  height: '400px',
};

// Google Map options (customize as needed)
const options = {
  disableDefaultUI: true, // Optional: disables default map UI
};

// Function to create an SVG icon with a specified color and scale based on zoom level
const createIcon = (color) => ({
  path: window.google.maps.SymbolPath.CIRCLE,
  fillColor: color,
  fillOpacity: 1,
  strokeColor: color,
  strokeWeight: 1,
  scale: 20,
});

const GoogleMapWithCluster = ({ groupedMarkers }) => {
  const mapRef = useRef(null); // Reference to store map instance
  const [map, setMap] = useState(null); // State to manage map instance

  // Load the Google Maps API script
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_KEY, // Replace with your Google Maps API key
  });

  // Function to calculate the average lat/lng
  const calculateCenter = (markers) => {
    const total = markers.length;
    const sum = markers.reduce(
      (acc, marker) => {
        acc.lat += marker.lat;
        acc.lng += marker.lng;

        return acc;
      },
      { lat: 0, lng: 0 }
    );

    return { lat: sum.lat / total, lng: sum.lng / total };
  };

  useEffect(() => {
    if (map && groupedMarkers?.length > 0) {
      const center = calculateCenter(groupedMarkers);

      map.setCenter(center);

      // Create Google Maps marker instances for each grouped marker
      const markers = groupedMarkers.map(
        (markerData) =>
          new window.google.maps.Marker({
            position: { lat: markerData.lat, lng: markerData.lng },
          })
      );

      const renderer = {
        render({ count, position }) {
          return new google.maps.Marker({
            label: {
              text: String(count),
              color: 'rgba(255, 255, 255, 1)',
              fontSize: FONT_SIZE,
            },
            position,
            icon: createIcon(
              count > 50
                ? GOOGLE_MAP_MARKER_COLOR[0]
                : GOOGLE_MAP_MARKER_COLOR[1]
            ),
            // adjust zIndex to be above other markers
            zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
          });
        },
      };

      const markerCluster = new MarkerClusterer({ markers, map, renderer });

      return () => markerCluster.clearMarkers();
    }
  }, [map, groupedMarkers]);

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      zoom={10}
      options={options}
      onLoad={(mapInstance) => {
        mapRef.current = mapInstance;
        setMap(mapInstance); // Store map instance
      }}
    ></GoogleMap>
  ) : (
    <div>Loading...</div> // Fallback for when the map is loading
  );
};

export default GoogleMapWithCluster;
