export const EXPORT_FILE_DATE_TIME_FORMAT = 'YYYY-MM-DD_HH:MM:SS';
export const COLUMNS_TO_EXPORT = {
  SUPER_ADMIN: [
    'ID',
    'NAME',
    'ADDRESS',
    'CELL_PHONE',
    'ZIP_CODE',
    'CITY',
    'EMAIL',
    'STATE',
    'CREATED_AT',
  ],
  GLOBAL_SUPER_ADMIN: [
    'ID',
    'NAME',
    'COMPANY_NAME',
    'ADDRESS',
    'CELL_PHONE',
    'ZIP_CODE',
    'CITY',
    'EMAIL',
    'STATE',
    'CREATED_AT',
  ],
  TECHNICIAN: [
    'ID',
    'TECHNICIAN_NAME',
    'TECHNICIAN_EMAIL',
    'CATEGORIES',
    'CREATED_AT',
  ],
  SUPPLIER: [
    'ID',
    'NAME',
    'BUSINESS_NUMBER',
    'CONTACT_NAME',
    'CONTACT_EMAIL',
    'CONTACT_PHONE',
    'ADDRESS',
    'STATE',
    'CITY',
    'POSTCODE',
    'CREATED_AT',
  ],
  PRODUCT_CATALOGUE: [
    'ID',
    'PRODUCT_NAME',
    'TYPE',
    'SUPPLIER',
    'COST',
    'COST_CURRENCY',
    'COST_PRICE_TYPE',
    'SELL_PRICE',
    'SELL_CURRENCY',
    'SELL_PRICE_TYPE',
    'EQUIPMENT_TYPE',
    'ESTIMATED_TIME_HOURS',
    'UM',
    'CREATED_AT',
  ],
  COMPANY: [
    'ID',
    'COMPANY_NAME',
    'ABN',
    'CELL_PHONE',
    'CONTACT_NAME',
    'EMAIL',
    'ADDRESS',
    'CITY',
    'STATE',
    'ZIP_CODE',
    'CREATED_AT',
  ],
  OFFICE_STAFF: [
    'ID',
    'NAME',
    'EMAIL',
    'CELL_PHONE',
    'ADDRESS',
    'CITY',
    'STATE',
    'POSTCODE',
    'CREATED_AT',
  ],
  ASSETS: [
    'ID',
    'ASSET_NAME',
    'EQUIPMENT_TYPE',
    'EQUIPMENT_CLASS',
    'BARCODE',
    'LOCATION',
    'STATUS',
    'ACTIVE',
    'IDENTIFICATION_NUMBER',
    'MAKE',
    'MODEL',
    'SIZE',
    'QUANTITY',
    'BASE_DATE',
    'INSTALLATION_DATE',
    'INTERNAL_NOTE',
  ],
  ROUTINE: [
    'ID',
    'STANDARD_NAME',
    'ROUTINE_NAME',
    'FREQUENCY',
    'EQUIPMENT_TYPE',
    'ANNUAL_COMPLIANCE_DATE',
    'CONTRACT_VALID_TILL',
  ],
  WORK_ORDER: [
    'ID',
    'WORK_ORDER_NAME',
    'WORK_ORDER_TYPE',
    'PROPERTY',
    'ASSET_COUNT',
    'TECHNICIAN',
    'ROUTINE_STANDARD_NAME',
    'ROUTINE_NAME',
    'FREQUENCY',
    'STATUS',
    'SCHEDULE_START_DATE',
    'SCHEDULE_END_DATE',
    'DUE_DATE',
    'PROPERTY_ZONE',
  ],
  TIMESHEET: [
    'ID',
    'TECHNICIAN_NAME',
    'WORK_ORDER_NAME',
    'STATUS',
    'TYPE',
    'START_DATE_TIME',
    'END_DATE_TIME',
    'TASK_DESCRIPTION',
    'CREATED_AT',
  ],
  QUOTE_LIST: [
    'ID',
    'PROPERTY_NAME',
    'DUE_DATE',
    'QUOTE_DATE',
    'TYPE',
    'STATUS',
    'AMOUNT',
    'CREATED_AT',
  ],
  PROPERTY: [
    'ID',
    'PROPERTY_NAME',
    'ADDRESS',
    'STATE',
    'CITY',
    'POST_CODE',
    'ZONES',
    'LAND/LOT_NUMBER',
    'STATUS',
    'CUSTOMER',
    'BILLING_CARD',
    'PARENT_PROPERTY',
    'OCSP_OR_DP/DN_NUMBER',
    'ORGANISATION',
    'AGM_DATE',
    'LOCAL_GOVERNMENT_AREA',
    'INTERNAL_NOTE',
    'TAGS',
    'REVIEW_DATE',
    'ANNUAL_COMPLIANCE_DATE',
    'CONTRACT_START_DATE',
    'CONTRACT_VALID_TILL',
    'DEFAULT_TECHNICIAN',
    'CONTACT_NAME',
    'CONTACT_EMAIL',
    'CONTACT_PHONE',
    'ACCESS_SCHEDULE',
    'ACCESS_PROCEDURE',
    'ACCESS_CODE',
    'ACCESS_NOTE',
    'SIGNATURE_REQUIRED',
    'INDUCTION_REQUIRED',
    'LOG_BOOK_ENTRY_REQUIRED',
    'POLICE_CHECK_REQUIRED',
    'IS_BOOKING_REQUIRED',
    'WORKING_WITH_CHILDREN_CHECK_REQUIRED',
    'SERVICE_REQUIREMENTS',
    'SAFTEY_REQUIREMENTS',
    'ANNUAL_DUE_CERTIFICATION_DATE',
    'BUILDING_ERA',
    'BUILDING_CLASS',
    'BUILDING_TYPE',
    'BUILDING_SIZE(SQFT)',
    'STORIES_ABOVE_GROUND',
    'STORIES_BELOW_GROUND',
    'CONSTRUCTION_CLASS',
    'BUILDING_PART',
    'BUILDING_TENANCIES',
    'BUILDING_STANDARDS',
    'OCCUPANCY_PERMIT_NUMBER',
    'OCCUPANCY_PERMIT_DATE',
    'OCCUPANCY_PERMIT_LOCATION',
    'CREATED_AT',
  ],
  CUSTOMER: [
    'ID',
    'CUSTOMER_NAME',
    'ABN_NUMBER',
    'CATEGORY',
    'AGREEMENT_SIGNED_DATE',
    'REGISTERED_ADDRESS',
    'STATE',
    'CITY',
    'POSTCODE',
    'NOTES',
    'CONTACT_NAME',
    'CONTACT_EMAIL',
    'CONTACT_PHONE',
    'CONTACT_ADDRESS',
    'CONTACT_STATE',
    'CONTACT_CITY',
    'CONTACT_POSTCODE',
    'ORGANISATION',
    'BILLING_CONTACT_NAME',
    'BILLING_EMAIL_TO',
    'FIXED_PRICE_CONTRACT',
    'MANUAL_BILLING_REQUIRED',
    'QUOTING_CONTACT_NAME',
    'QUOTING_EMAIL_TO',
    'ACCOUNT_MANAGER',
    'INVITATION_STATUS',
    'CREATED_AT',
  ],
  BILLING_CONTRACTS: [
    'ID',
    'NAME',
    'CUSTOMER',
    'PROPERTY',
    'CONTRACT_START_DATE',
    'CONTRACT_VALID_TILL',
    'CONTRACT_DATE',
    'STANDARD_ID',
    'ANNUAL_FIXED_PERCENTAGE(%)_INCREASE',
    'CREATED_AT',
  ],
};

export const EXPORT_FILE_NAMES = {
  SUPER_ADMIN: 'SUPER_ADMIN',
  GLOBAL_SUPER_ADMIN: 'GLOBAL_SUPER_ADMIN',
  TECHNICIAN: 'TECHNICIAN',
  SUPPLIER: 'SUPPLIER',
  PRODUCT_CATALOGUE: 'PRODUCT_CATALOGUE',
  COMPANY: 'COMPANY',
  OFFICE_STAFF: 'OFFICE_STAFF',
  ASSETS: 'ASSETS',
  ROUTINE: 'ROUTINE',
  WORK_ORDER: 'WORK_ORDER',
  TIMESHEET: 'TIMESHEET',
  QUOTE_LIST: 'QUOTE_LIST',
  PROPERTY: 'PROPERTY',
  CUSTOMER: 'CUSTOMER',
  BILLING_CONTRACTS: 'BILLING_CONTRACTS',
};

export const COLUMNS_TO_MAP = {
  ASSETS: {
    id: 'ID',
    label: 'ASSET_NAME',
    equipment_type: 'EQUIPMENT_TYPE',
    product: 'EQUIPMENT_CLASS',
    barcode: 'BARCODE',
    location: 'LOCATION',
    status: 'STATUS',
    is_active: 'ACTIVE',
    identification_number: 'IDENTIFICATION_NUMBER',
    make: 'MAKE',
    model: 'MODEL',
    size: 'SIZE',
    quantity: 'QUANTITY',
    base_date: 'BASE_DATE',
    installation_date: 'INSTALLATION_DATE',
    internal_note: 'INTERNAL_NOTE',
  },
  ROUTINE: {
    id: 'ID',
    'standards.name': 'STANDARD_NAME',
    'routine_types.name': 'ROUTINE_NAME',
    frequencies_list: 'FREQUENCY',
    'equipment_type.display_name': 'EQUIPMENT_TYPE',
    annual_maintenance_date: 'ANNUAL_COMPLIANCE_DATE',
    contract_expiry_date: 'contract_expiry_date',
  },
  WORK_ORDER: {
    id: 'ID',
    work_order_name: 'WORK_ORDER_NAME',
    work_order_type: 'WORK_ORDER_TYPE',
    property_name: 'PROPERTY',
    asset_count: 'ASSET_COUNT',
    technician_name: 'TECHNICIAN',
    'property_equipment_routine_service_schedule.standards.name':
      'ROUTINE_STANDARD_NAME',
    'property_equipment_routine_service_schedule.routine_types.name':
      'ROUTINE_NAME',
    'property_equipment_routine_service_schedule.frequencies.display_name':
      'FREQUENCY',
    latest_status: 'STATUS',
    scheduled_start_date_time: 'SCHEDULED_START_DATE_TIME',
    scheduled_end_date_time: 'SCHEDULED_END_DATE_TIME',
    due_date: 'DUE_DATE',
    property_zone: 'PROPERTY_ZONE',
  },
  TIMESHEET: {
    id: 'ID',
    'technician.technician_name': 'TECHNICIAN_NAME',
    'work_order.work_order_name': 'WORK_ORDER_NAME',
    status: 'STATUS',
    type: 'TYPE',
    start_date_time: 'START_DATE_TIME',
    end_date_time: 'END_DATE_TIME',
    task_description: 'TASK_DESCRIPTION',
    created_at: 'CREATED_AT',
  },
  QUOTE_LIST: {
    id: 'ID',
    property_name: 'PROPERTY_NAME',
    due_date: 'DUE_DATE',
    quote_date: 'QUOTE_DATE',
    quotation_type: 'TYPE',
    status: 'STATUS',
    amount: 'AMOUNT',
    created_at: 'CREATED_AT',
  },
  PROPERTY: {
    id: 'ID',
    property_name: 'PROPERTY_NAME',
    address: 'ADDRESS',
    state: 'STATE',
    city: 'CITY',
    zip_code: 'POST_CODE',
    zone: 'ZONES',
    lot_number: 'LAND/LOT_NUMBER',
    'property_status.display_name': 'STATUS',
    customer: 'CUSTOMER',
    'property_billing_card.display_name': 'BILLING_CARD',
    'property_parent.display_name': 'PARENT_PROPERTY',
    ocsp_number: 'OCSP_OR_DP/DN_NUMBER',
    organization: 'ORGANISATION',
    agm_date: 'AGM_DATE',
    'property_local_government_area.display_name': 'LOCAL_GOVERNMENT_AREA',
    internal_note: 'INTERNAL_NOTE',
    tags: 'TAGS',
    review_date: 'REVIEW_DATE',
    'contract_assignment_details.annual_compliance_date':
      'ANNUAL_COMPLIANCE_DATE',
    'contract_assignment_details.contract_start_date': 'CONTRACT_START_DATE',
    'contract_assignment_details.contract_expiry_date': 'CONTRACT_VALID_TILL',
    'contract_assignment_details.technician': 'DEFAULT_TECHNICIAN',
    'property_contact_info.name': 'CONTACT_NAME',
    'property_contact_info.email': 'CONTACT_EMAIL',
    'property_contact_info.phone': 'CONTACT_PHONE',
    access_schedule: 'ACCESS_SCHEDULE',
    access_procedure: 'ACCESS_PROCEDURE',
    access_code: 'ACCESS_CODE',
    access_note: 'ACCESS_NOTE',
    signature_required: 'SIGNATURE_REQUIRED',
    induction_required: 'INDUCTION_REQUIRED',
    log_book_entry_required: 'LOG_BOOK_ENTRY_REQUIRED',
    police_check_required: 'POLICE_CHECK_REQUIRED',
    is_booking_required: 'IS_BOOKING_REQUIRED',
    working_with_children_check_required:
      'WORKING_WITH_CHILDREN_CHECK_REQUIRED',
    service_requirements: 'SERVICE_REQUIREMENTS',
    technician_requirements: 'SAFTEY_REQUIREMENTS',
    annual_due_certification_date: 'ANNUAL_DUE_CERTIFICATION_DATE',
    property_building_era: 'BUILDING_ERA',
    property_building_class: 'BUILDING_CLASS',
    property_building_type: 'BUILDING_TYPE',
    building_size: 'BUILDING_SIZE(SQFT)',
    stories_above_ground: 'STORIES_ABOVE_GROUND',
    stories_below_ground: 'STORIES_BELOW_GROUND',
    property_construction_class: 'CONSTRUCTION_CLASS',
    building_part: 'BUILDING_PART',
    building_tenancies_no: 'BUILDING_TENANCIES',
    property_building_standard: 'BUILDING_STANDARDS',
    op_number: 'OCCUPANCY_PERMIT_NUMBER',
    op_date: 'OCCUPANCY_PERMIT_DATE',
    op_location: 'OCCUPANCY_PERMIT_LOCATION',
    created_at: 'CREATED_AT',
  },
  CUSTOMER: {
    id: 'ID',
    customer_name: 'CUSTOMER_NAME',
    customer_abn_number: 'ABN_NUMBER',
    categories: 'CATEGORY',
    agreement_signed_date: 'AGREEMENT_SIGNED_DATE',
    address: 'REGISTERED_ADDRESS',
    state: 'STATE',
    city: 'CITY',
    postcode: 'POSTCODE',
    notes: 'NOTES',
    'primary_contact.contact_name': 'CONTACT_NAME',
    'primary_contact.contact_email': 'CONTACT_EMAIL',
    'primary_contact.contact_phone': 'CONTACT_PHONE',
    'primary_contact.address': 'CONTACT_ADDRESS',
    'primary_contact.state': 'CONTACT_STATE',
    'primary_contact.city': 'CONTACT_CITY',
    'primary_contact.postcode': 'CONTACT_POSTCODE',
    organisation: 'ORGANISATION',
    billing_contact_name: 'BILLING_CONTACT_NAME',
    billing_email_to: 'BILLING_EMAIL_TO',
    fixed_price_contract: 'FIXED_PRICE_CONTRACT',
    manual_billing_required: 'MANUAL_BILLING_REQUIRED',
    quoting_contact_name: 'QUOTING_CONTACT_NAME',
    quoting_email_to: 'QUOTING_EMAIL_TO',
    account_manager_name: 'ACCOUNT_MANAGER',
    invitation_status: 'INVITATION_STATUS',
    created_at: 'CREATED_AT',
  },
  BILLING_CONTRACTS: {
    id: 'ID',
    name: 'NAME',
    customer: 'CUSTOMER',
    exportProperty: 'PROPERTY',
    contract_start_date: 'CONTRACT_START_DATE',
    contract_valid_till: 'CONTRACT_VALID_TILL',
    contract_date: 'CONTRACT_DATE',
    standard_id: 'STANDARD_ID',
    price_increase: 'ANNUAL_FIXED_PERCENTAGE(%)_INCREASE',
    created_at: 'CREATED_AT',
  },
};
