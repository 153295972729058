import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import BallotOutlinedIcon from '@mui/icons-material/BallotOutlined';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import { Box, Button, Stack, Typography } from '@mui/material';

import { DIVIDER_COLOR } from '../../../constants/Colors';
import { DOCUMENT_TEMPLATE_TYPES } from '../../../constants/Constants';
import { ROUTENAME } from '../../../constants/RoutesConstants';
import { snackbarToggle } from '../../../store/CommonReducer';
import { downloadSampleTemplate } from '../../../store/document/api';
import { resetTemplateData } from '../../../store/document/reducer';
import Autocomplete from '../AutoComplete';
import MainWrapper from '../MainWrapper';

export const Templates = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { control, handleSubmit } = useForm();
  const dispatch = useDispatch();
  const [selectedDocumentType, setSelectedDocumentType] = useState(null);

  useEffect(() => {
    // Perform the reset when the component mounts
    dispatch(resetTemplateData());
  }, [dispatch]);

  const handleDefaultStep = () => {
    navigate(`/${ROUTENAME.REPORTS}/${ROUTENAME.DATA_MANAGEMENT}`);
  };

  const onSubmit = async (data) => {
    try {
      // Dispatch the action to download the template
      const result = await dispatch(
        downloadSampleTemplate({ code: data.templateType.value })
      ).unwrap();

      // Create a URL for the downloaded blob
      const url = window.URL.createObjectURL(new Blob([result]));
      const link = document.createElement('a');

      link.href = url;
      link.setAttribute('download', `${data.templateType.value}.csv`); // Set the file name
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link); // Clean up the link
    } catch (error) {
      dispatch(
        snackbarToggle({
          isOpen: true,
          isErrorMsg: false,
          msg: t('attributes.reports.dataManagement.template.downloadError'),
        })
      );
    }
  };

  const TemplateDataWrapper = (
    <>
      <Box sx={{ backgroundColor: DIVIDER_COLOR, width: '100%' }}>
        <MainWrapper
          defaultPadding="0px 0px 16px 0px"
          title={t('attributes.reports.dataManagement.title')}
          variant="body1"
          isStep={true}
          step={t('attributes.reports.dataManagement.templates')}
          handleDefaultStep={handleDefaultStep}
        ></MainWrapper>
      </Box>
      <Stack
        component="form"
        sx={{ justifyContent: 'center', alignItems: 'center', height: '87vh' }}
        spacing={1}
        onSubmit={handleSubmit(onSubmit)}
      >
        <BallotOutlinedIcon sx={{ height: 40, width: 40, color: '#707070' }} />
        <Typography variant="body1">
          {t('attributes.reports.dataManagement.template.downloadTitle')}
        </Typography>
        <Typography variant="body2" align="center" sx={{ fontSize: '12px' }}>
          {t('attributes.reports.dataManagement.template.downloadDescription')}
        </Typography>
        <Controller
          name="templateType"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Autocomplete
              width="436px"
              label={t('attributes.reports.importData.selectDataType')}
              options={DOCUMENT_TEMPLATE_TYPES || []}
              value={value}
              onChange={(e, newValue) => {
                onChange(newValue);
                setSelectedDocumentType(newValue);
              }}
              helperText={error ? error.message : ''}
              error={error}
            />
          )}
        />
        {selectedDocumentType && (
          <Button
            type="submit"
            variant="contained"
            color="primary"
            startIcon={<DownloadOutlinedIcon />}
            sx={{ mt: 2 }}
            disabled={!selectedDocumentType}
          >
            {t('attributes.download')}
          </Button>
        )}
      </Stack>
    </>
  );

  return <>{TemplateDataWrapper}</>;
};
