import { createSlice } from '@reduxjs/toolkit';

import { getCategoryDropdownList } from './api';

const categoryDropdownList = {
  isLoading: true,
  error: false,
  data: false,
};

const categorySlice = createSlice({
  name: 'category',
  initialState: {
    categoryDropdownList,
  },
  extraReducers: (builder) => {
    builder
      // Get Category Dropdown List
      .addCase(getCategoryDropdownList.pending, (state) => {
        state.categoryDropdownList.isLoading = true;
      })
      .addCase(getCategoryDropdownList.fulfilled, (state, action) => {
        state.categoryDropdownList.isLoading = false;
        state.categoryDropdownList.data = action.payload;
      })
      .addCase(getCategoryDropdownList.rejected, (state, action) => {
        state.categoryDropdownList.isLoading = false;
        state.categoryDropdownList.error = action.error.message;
      });
  },
});

export default categorySlice.reducer;
