import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Card, Divider, Link, Paper, Typography } from '@mui/material';
import { debounce } from 'lodash';

import { FlexEnd, FlexStart, SpaceBetween } from '../../assets/commonStyled';
import {
  DARK_GREEN,
  DIVIDER_COLOR,
  TEXT_COLOR,
  TURQUOISE,
  WARNING,
} from '../../constants/Colors';
import getCityByStateHook from '../../hooks/getCityByStateHook';
import getDropdownListHook from '../../hooks/getDropdownListHook';
import getStatesHook from '../../hooks/getStatesHook';
import { getAccountManagerCommonDropdownList } from '../../store/accountManager/api';
import { getComplianceStatusDropdownList } from '../../store/complianceStatus/api';
import {
  getProperty,
  getPropertyList,
  getPropertyListForMap,
} from '../../store/property/api';
import { formattedDate } from '../../utils';
import StackedBarChart from '../Chart/StackedBarChart/StackedBarChart';
import { View } from '../CommonComponents/ActionComponent';
import Autocomplete from '../CommonComponents/AutoComplete';
import CustomDateRangePicker from '../CommonComponents/CustomDateRangePicker';
import CustomGridTable from '../CommonComponents/CustomGridTable';
import {
  FilterButton,
  RefreshDashboardButton,
  ResetFilterButton,
} from '../CommonComponents/FilterButton';
import GoogleMapWithCluster from '../CommonComponents/MarkerClusterer';
import NoRecordFound from '../CommonComponents/NoDataPage/NoRecordFound';
import CustomSearch from '../CommonComponents/Search';
import PropertyDetails from '../Properties/PropertyDetails';
import ChartCard from './ChartCard';

//default filters
const defaultFilters = {
  city: null,
  state: null,
  accoutnManager: null,
  complianceStatus: null,
  fromDate: null,
  toDate: null,
};

const initialBarChartData = {
  data: [
    {
      data: [80, 120, 50, 75, 45, 30, 60, 90, 110, 85, 95, 100],
      label: 'Current',
      id: 'current',
      stack: 'total',
    },
    {
      data: [30, 35, 25, 30, 40, 20, 50, 40, 55, 45, 65, 60],
      label: 'In Progress',
      id: 'inProgress',
      stack: 'total',
    },
    {
      data: [15, 12, 20, 25, 10, 20, 30, 25, 35, 30, 40, 45],
      label: 'Overdue',
      id: 'overdue',
      stack: 'total',
    },
  ],
  colors: [DARK_GREEN, TURQUOISE, WARNING],
  xLabel: [
    'Jan 2024',
    'Feb 2024',
    'Mar 2024',
    'Apr 2024',
    'May 2024',
    'Jun 2024',
    'Jul 2024',
    'Aug 2024',
    'Sep 2024',
    'Oct 2024',
    'Nov 2024',
    'Dec 2024',
  ],
};

const currentMonthIndex = 9;

const CustomerPropertyDashboard = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { profileDetails } = useSelector((state) => state.common);

  const [currentPage, setCurrentPage] = useState(1);
  const [perPageData, setPerPageData] = useState(5);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('property_name');

  const [searchText, setSearchText] = useState('');
  const [filters, setFilters] = useState(defaultFilters);

  const cityList = getCityByStateHook(filters.state?.value);
  const stateList = getStatesHook();

  const [columnVisibilityModel, setColumnVisibilityModel] = useState({});
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [propertyListForMap, setPropertyListForMap] = useState([]);
  const [markers, seMarkers] = useState([]);
  const [chartData, setChartData] = useState(initialBarChartData);

  const [isFilterComponentVisible, setIsFilterComponentVisible] =
    useState(false);
  const [propertyId, setPropertyId] = useState();
  const [propertyDataloading, setPropertyDataLoading] = useState(false);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [propertyNumId, setPropertyNumId] = useState();

  const filterHeight = (isFilterOpen) => (isFilterOpen ? 650 : 580);

  const { accountManagerDropdownLoading, accountManagerDropdownData } =
    getDropdownListHook({
      reducerName: 'accountManager',
      dropdownListName: 'accountManagerDropdownList',
      labelName: 'display_name',
      valueName: 'id',
    });

  const { complianceStatusDropdownLoading, complianceStatusDropdownData } =
    getDropdownListHook({
      reducerName: 'complianceStatus',
      dropdownListName: 'complianceStatusDropdownList',
      labelName: 'display_name',
      valueName: 'id',
    });

  const { propertyList } = useSelector((state) => state.property);

  const getMarkers = (data = []) =>
    data
      .filter((property) => property.latitude && property.longitude)
      .map((property) => {
        const color =
          property.property_compliance?.name === 'current'
            ? DARK_GREEN
            : property.property_compliance?.name === 'in_progress'
              ? TURQUOISE
              : WARNING;

        return {
          id: property.id,
          lat: parseFloat(property.latitude),
          lng: parseFloat(property.longitude),
          tooltipText: property.property_name,
          color: color,
        };
      });

  const [selectedDuration, setSelectedDuration] = useState({
    label: 'Six Months',
    value: 'sixMonths',
  });

  const dataDropdown = [
    { label: 'Monthly', value: 'monthly' },
    { label: 'Quarterly', value: 'quarterly' },
    { label: 'Six Months', value: 'sixMonths' },
    { label: 'Annually', value: 'annually' },
  ];
  const handlePropertyDetaiAPICall = (id) => {
    dispatch(getProperty(id))
      .then((response) => {
        setSelectedProperty(response.payload?.data?.[0]); // Set the complete property data
      })
      .catch((error) => {
        console.error('Error fetching property data:', error);
      })
      .finally(() => {
        setPropertyDataLoading(false); // Set loading to false whether success or error occurs
      });
  };

  const StatusBox = ({ color, text }) => (
    <Box sx={{ display: 'flex', columnGap: '5px', alignItems: 'center' }}>
      <Box
        sx={{
          height: '12px',
          width: '12px',
          background: color,
          borderRadius: '50%',
        }}
      />
      <Typography variant="body1" color={color}>
        {text}
      </Typography>
    </Box>
  );

  const columns = useMemo(() => {
    const baseColumns = [
      {
        field: 'property_name',
        headerName: t('attributes.name'),
        flex: 1,
        renderCell: ({ row }) => (
          <Link to="#">
            <Typography
              variant="body1"
              sx={{ cursor: 'pointer' }}
              color={TEXT_COLOR}
              onClick={() => {
                setPropertyDataLoading(true);
                setPropertyNumId(row?.id);
                setPropertyId(row?.uuid);
                handlePropertyDetaiAPICall(row?.uuid);
              }}
            >
              {row?.property_name}
            </Typography>
          </Link>
        ),
      },
      {
        field: 'address',
        headerName: t('attributes.address'),
        flex: 1.5,
        sortable: false,
      },
      {
        field: 'Compliance Status',
        headerName: t('attributes.customer.compliance_status'),
        flex: 1,
        sortable: false,
        renderCell: ({ row }) => (
          <StatusBox
            color={row?.complience_status?.color}
            text={row?.complience_status?.text}
          />
        ),
      },
      {
        field: 'invoices',
        headerName: t('attributes.customer.invoices'),
        flex: 1.3,
        sortable: false,
      },
      {
        field: 'quotes',
        headerName: t('attributes.customer.quotes'),
        flex: 1,
        sortable: false,
      },
      {
        field: 'defects',
        headerName: t('attributes.customer.defects'),
        flex: 1.5,
        sortable: false,
      },
      {
        field: 'created_at',
        headerName: t('attributes.customer.created_at'),
        flex: 1,
        valueFormatter: (params) => (params ? formattedDate(params) : ''),
      },
      {
        field: 'actions',
        headerName: t('attributes.actions'),
        flex: 0.5,
        sortable: false,
        hideable: false,
        renderCell: ({ row }) => (
          <View
            onClick={() => {
              setPropertyDataLoading(true);
              setPropertyNumId(row?.id);
              setPropertyId(row?.uuid);
              handlePropertyDetaiAPICall(row?.uuid);
            }}
          />
        ),
      },
    ];

    return baseColumns;
  }, []);

  const resetFilter = () => {
    setFilters(defaultFilters);
    setSearchText('');
  };

  const getAllProperties = useCallback(() => {
    const visibleFieldsString = columns
      .filter((col) => columnVisibilityModel[col.field] !== false)
      .map((col) => col.field)
      .join(',');

    setIsDataLoading(true);
    dispatch(
      getPropertyList({
        size: perPageData,
        page: currentPage,
        search: searchText,
        order: order,
        orderBy: orderBy,
        state: filters.state?.value,
        city: filters.city?.value,
        accountManager: filters?.accoutnManager?.value,
        complianceStatus: filters?.complianceStatus?.value,
        list_column_names: visibleFieldsString,
        fromDate: filters?.fromDate,
        toDate: filters?.toDate,
      })
    ).finally(() => {
      setIsDataLoading(false);
    });
  }, [
    dispatch,
    perPageData,
    currentPage,
    order,
    orderBy,
    filters,
    searchText,
    columns,
    columnVisibilityModel,
  ]);

  useEffect(() => {
    seMarkers(getMarkers(propertyListForMap));
  }, [propertyListForMap]);

  useEffect(() => {
    dispatch(getAccountManagerCommonDropdownList());
    dispatch(getComplianceStatusDropdownList());
    dispatch(
      getPropertyListForMap({
        size: 100,
        page: currentPage,
      })
    )
      .then((res) => {
        setPropertyListForMap(res.payload.data);
      })
      .finally(() => {});
  }, []);

  useEffect(() => {
    setCurrentPage(1);
  }, [perPageData, filters, searchText]);

  const debouncedFetchData = useCallback(debounce(getAllProperties, 500), [
    getAllProperties,
  ]);

  useEffect(() => {
    debouncedFetchData();

    return () => {
      debouncedFetchData.cancel();
    };
  }, [debouncedFetchData]);

  const rows = propertyList?.data?.data?.map((pl, index) => ({
    id: pl.id,
    uuid: pl?.uuid,
    property_name: pl?.property_name,
    address: pl?.address,
    complience_status:
      pl?.property_compliance?.id == 1
        ? { color: DARK_GREEN, text: 'Current' }
        : pl?.property_compliance?.id == 2
          ? { color: TURQUOISE, text: 'In Progress' }
          : { color: WARNING, text: 'Over Due' },
    invoices: 3,
    quotes: 6,
    defects: 2,
    created_at: pl?.created_at,
    actions: <></>,
  }));

  const updateChartData = (range) => {
    let updatedData = { ...initialBarChartData };

    switch (range) {
      case 'Monthly':
        // For Monthly, show only the last month (e.g., Dec 2023)
        updatedData.data = updatedData.data.map((item) => ({
          ...item,
          data: [item.data[currentMonthIndex]], // Show only the current month's data
        }));
        updatedData.xLabel = [initialBarChartData.xLabel[currentMonthIndex]]; // Label for the current month
        break;

      case 'Quarterly':
        // For Quarterly, show the last 3 months
        updatedData.data = updatedData.data.map((item) => ({
          ...item,
          data: item.data.slice(currentMonthIndex - 2, currentMonthIndex + 1),
        }));
        updatedData.xLabel = initialBarChartData.xLabel.slice(
          currentMonthIndex - 2,
          currentMonthIndex + 1
        );
        break;

      case 'Six Months':
        // Show the last 6 months
        updatedData.data = updatedData.data.map((item) => ({
          ...item,
          data: item.data.slice(currentMonthIndex - 5, currentMonthIndex + 1),
        }));
        updatedData.xLabel = initialBarChartData.xLabel.slice(
          currentMonthIndex - 5,
          currentMonthIndex + 1
        );
        break;

      case 'Annually':
        // For Annually, show all 12 months of the year
        updatedData = { ...initialBarChartData };
        break;

      default:
        break;
    }

    setChartData(updatedData);
  };

  useEffect(() => {
    updateChartData(selectedDuration.label);
  }, [selectedDuration]);

  const handleDefaultStep = () => {
    setPropertyId('');
    setIsFilterComponentVisible(false);
    resetFilter();
  };

  return propertyId ? (
    <PropertyDetails
      propertyId={propertyId}
      selectedProperty={selectedProperty}
      propertyDataloading={propertyDataloading}
      isEditable={false}
      propertyNumId={propertyNumId}
      handleDefaultStep={handleDefaultStep}
    />
  ) : (
    <Box sx={{ bgcolor: DIVIDER_COLOR, height: '100%' }}>
      <SpaceBetween>
        {t('common.welcome')}, {profileDetails?.customer_name || '-'}
        <RefreshDashboardButton />
      </SpaceBetween>
      <Box
        sx={{
          display: 'flex',
          columnGap: '16px',
          width: '100%',
          alignItems: 'stretch',
          marginTop: '16px',
        }}
      >
        <Box sx={{ flex: 1 }}>
          <Card sx={{ height: '100%' }}>
            <Typography variant="body1" sx={{ padding: '8px' }}>
              All Properties
            </Typography>
            <Divider />
            <Box sx={{ height: '340px', padding: '8px' }}>
              {markers ? (
                <GoogleMapWithCluster groupedMarkers={markers} />
              ) : (
                <CustomCircularLoader />
              )}
            </Box>
          </Card>
        </Box>
        <Box sx={{ flex: 1 }}>
          <ChartCard
            title="Compliance Summary by Properties"
            options={dataDropdown}
            selectedType={selectedDuration}
            isEditableChart={false}
            setSelectedType={(id, val) => setSelectedDuration(val)}
            height="340px"
            isSettingMode={true}
          >
            <StackedBarChart
              colors={chartData?.colors}
              data={chartData?.data}
              xLabels={chartData?.xLabel}
              isYAxis={false}
              markHeight={20}
              markWidth={20}
            />
          </ChartCard>
        </Box>
      </Box>
      <Paper sx={{ marginTop: '16px' }}>
        <SpaceBetween sx={{ padding: '8px' }}>
          <Typography variant="body1">All Properties Snapshot</Typography>
          <FlexEnd>
            <ResetFilterButton
              onClick={resetFilter}
              disabled={
                !(
                  searchText ||
                  filters.state ||
                  filters.city ||
                  filters.fromDate ||
                  filters.toDate ||
                  filters.accoutnManager ||
                  filters.complianceStatus
                )
              }
            />
            <FilterButton
              onClick={() =>
                setIsFilterComponentVisible(!isFilterComponentVisible)
              }
              isActive={isFilterComponentVisible}
            />
            <CustomSearch
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
            />
          </FlexEnd>
        </SpaceBetween>

        <Divider />
        {isFilterComponentVisible && (
          <FlexStart
            style={{
              background: DIVIDER_COLOR,
              padding: '8px',
              borderRadius: '4px',
              margin: '8px',
            }}
          >
            <Autocomplete
              placeholder={t('attributes.customer.compliance_status')}
              options={complianceStatusDropdownData}
              width="190px"
              value={filters?.complianceStatus}
              onChange={(e, newVal) =>
                setFilters((prev) => ({ ...prev, complianceStatus: newVal }))
              }
              isLoadingData={complianceStatusDropdownLoading}
            />
            <Autocomplete
              placeholder={t('common.state')}
              options={stateList}
              width="190px"
              value={filters?.state}
              onChange={(e, newVal) =>
                setFilters((prev) => ({ ...prev, state: newVal }))
              }
            />
            <Autocomplete
              placeholder={t('common.city')}
              options={cityList}
              width="190px"
              value={filters?.city}
              onChange={(e, newVal) =>
                setFilters((prev) => ({ ...prev, city: newVal }))
              }
              // disabledDropdown={!filters.state}
            />
            <Autocomplete
              placeholder={t('attributes.account_manager.account_manager')}
              options={accountManagerDropdownData}
              value={filters?.accoutnManager}
              onChange={(e, newVal) =>
                setFilters((prev) => ({ ...prev, accoutnManager: newVal }))
              }
              width="190px"
              isLoadingData={accountManagerDropdownLoading}
            />
            <CustomDateRangePicker
              onOkClick={(val) => {
                setFilters((pre) => ({
                  ...pre,
                  fromDate: val[0],
                  toDate: val[1],
                }));
              }}
              onClear={() => {
                setFilters((pre) => ({ ...pre, toDate: null, fromDate: null }));
              }}
              fromDate={filters?.fromDate}
              toDate={filters?.toDate}
              placement="topEnd"
            />
          </FlexStart>
        )}
        <CustomGridTable
          columns={columns}
          rows={rows}
          total={propertyList?.data?.total}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          rowsPerPageOptions={[5, 10, 20]}
          perPageData={perPageData}
          setPerPageData={setPerPageData}
          order={order}
          orderBy={orderBy}
          setOrder={setOrder}
          setOrderBy={setOrderBy}
          isLoading={isDataLoading}
          noData={<NoRecordFound />}
          checkboxSelection={false}
          columnVisibilityModel={columnVisibilityModel}
          setColumnVisibilityModel={setColumnVisibilityModel}
          filterHeight={filterHeight(isFilterComponentVisible)}
        />
      </Paper>
    </Box>
  );
};

export default CustomerPropertyDashboard;
