import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, styled, Typography } from '@mui/material';

import { DarkLogo } from '../../../assets/images';
import { formatPriceWithDecimalValue, formattedDate } from '../../../utils';
import CustomCircularLoader from '../../CommonComponents/CustomLoader';

const InvoiceContainer = styled(Box)(() => ({
  backgroundColor: 'white',
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
}));

const PreviewPurchaseOrder = ({
  companyData,
  isLoadingCompany,
  purchaseOrderDetails,
}) => {
  const { t } = useTranslation();

  const { poid, items, created_at, delivery_instruction, supplier } =
    purchaseOrderDetails;

  // Calculate Subtotal, Tax, and Total
  const subTotal = items?.reduce(
    (sum, item) => sum + Number(item?.price) * item?.qty,
    0
  );
  // const tax = subTotal * 0.0625;
  const total = subTotal;

  return isLoadingCompany ? (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
      }}
    >
      <CustomCircularLoader />
    </Box>
  ) : (
    <InvoiceContainer>
      {/* Header */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box>
          <Box>
            <Typography variant="body1">{companyData?.company_name}</Typography>
            <Typography variant="body2">
              {companyData?.address}
              <br />
              {`${companyData?.state}, ${companyData?.city}, ${companyData?.zip_code}`}
            </Typography>
          </Box>
          <Box sx={{ paddingTop: '16px' }}>
            <Typography variant="body1">
              {t('attributes.purchase_order.purchase_order_to')}
            </Typography>
            <Typography variant="body2">
              {supplier?.name} <br />
              {supplier?.address}
              <br />{' '}
              {`${supplier?.state}, ${supplier?.city}, ${supplier?.postcode}`}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'end',
            alignContent: 'end',
          }}
        >
          <img src={DarkLogo} alt="Fire Logo" height="40px" width="107px" />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              gap: '20px',
            }}
          >
            <Box sx={{ textAlign: 'right' }}>
              <Typography variant="body1">
                {t('attributes.work_order.purchase_order')}
              </Typography>
              <Typography variant="body1">
                {t('attributes.purchase_order.purchase_order_date')}
              </Typography>
            </Box>

            <Box sx={{ textAlign: 'right' }}>
              <Typography variant="body2">{poid}</Typography>
              <Typography variant="body2">
                {formattedDate(created_at) ?? '-'}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>

      {/* Property Table */}
      <Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%',
          }}
        >
          <Box sx={{ paddingTop: '16px' }}>
            <Box sx={{ gap: '4px' }}>
              <Typography variant="body1">
                {' '}
                {t('attributes.purchase_order.delivery_instruction')}
              </Typography>
              <Typography variant="body2">{delivery_instruction}</Typography>
            </Box>
            <Box sx={{ paddingTop: '30px' }}>
              <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                <thead>
                  <tr>
                    <th
                      style={{
                        padding: '8px',
                        border: '1px solid #EBEBEB',
                        textAlign: 'left',
                        backgroundColor: '#ebebeb20',
                      }}
                    >
                      <Typography variant="body1">
                        {t('common.desciption')}
                      </Typography>
                    </th>
                    <th
                      style={{
                        padding: '8px',
                        border: '1px solid #EBEBEB',
                        textAlign: 'center',
                        backgroundColor: '#ebebeb20',
                      }}
                    >
                      <Typography variant="body1">{t('common.qty')}</Typography>
                    </th>
                    <th
                      style={{
                        padding: '8px',
                        border: '1px solid #EBEBEB',
                        textAlign: 'right',
                        backgroundColor: '#ebebeb20',
                      }}
                    >
                      <Typography variant="body1">
                        {t('common.unit_price')}
                      </Typography>
                    </th>
                    <th
                      style={{
                        padding: '8px',
                        border: '1px solid #EBEBEB',
                        textAlign: 'right',
                        backgroundColor: '#ebebeb20',
                      }}
                    >
                      <Typography variant="body1">
                        {t('common.amount')}
                      </Typography>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {items?.map((item, index) => (
                    <tr key={index}>
                      <td
                        style={{
                          padding: '8px',
                          border: '1px solid #EBEBEB',
                          textAlign: 'left',
                        }}
                      >
                        <Typography variant="body1">
                          {item.product_catalogue?.product_name}
                        </Typography>
                      </td>
                      <td
                        style={{
                          padding: '8px',
                          border: '1px solid #EBEBEB',
                          textAlign: 'center',
                        }}
                      >
                        {item.qty}
                      </td>
                      <td
                        style={{
                          padding: '8px',
                          border: '1px solid #EBEBEB',
                          textAlign: 'right',
                        }}
                      >
                        <Typography variant="body2">
                          {Number(item.price)?.toFixed(2)}
                        </Typography>
                      </td>
                      <td
                        style={{
                          padding: '8px',
                          border: '1px solid #EBEBEB',
                          textAlign: 'right',
                        }}
                      >
                        <Typography variant="body2">
                          {formatPriceWithDecimalValue(
                            item.qty * Number(item.price)
                          )}
                        </Typography>
                      </td>
                    </tr>
                  ))}

                  {/* Subtotal, Tax, Total rows */}
                  {/* <tr>
                  <td
                    colSpan={3}
                    style={{
                      padding: '8px',
                      textAlign: 'right',
                      border: 'none',
                    }}
                  >
                    <Typography variant="body1">Subtotal</Typography>
                  </td>
                  <td
                    style={{
                      padding: '8px',
                      textAlign: 'right',
                      border: '1px solid #EBEBEB',
                    }}
                  >
                    <Typography variant="body2">
                      ${subTotal.toFixed(2)}
                    </Typography>
                  </td>
                </tr>
                <tr>
                  <td
                    colSpan={3}
                    style={{
                      padding: '8px',
                      textAlign: 'right',
                      border: 'none',
                    }}
                  >
                    <Typography variant="body1">Tax (6.25%)</Typography>
                  </td>
                  <td
                    style={{
                      padding: '8px',
                      textAlign: 'right',
                      border: '1px solid #EBEBEB',
                    }}
                  >
                    <Typography variant="body2">${tax.toFixed(2)}</Typography>
                  </td>
                </tr> */}
                  <tr>
                    <td
                      colSpan={3}
                      style={{
                        padding: '8px',
                        textAlign: 'right',
                        border: 'none',
                      }}
                    >
                      <Typography variant="body1">
                        {t('common.total')}
                      </Typography>
                    </td>
                    <td
                      style={{
                        padding: '8px',
                        textAlign: 'right',
                        border: '1px solid #EBEBEB',
                      }}
                    >
                      <Typography variant="body1">
                        {total?.toFixed(2)}
                      </Typography>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Box>
          </Box>
        </Box>
      </Box>
    </InvoiceContainer>
  );
};

export default PreviewPurchaseOrder;
