import { createAsyncThunk } from '@reduxjs/toolkit';
import moment from 'moment';

import { APIs } from '../../constants/APIConstants';
import { DATE_CONVERT_UTC_FORMAT_API } from '../../constants/Constants';
import { filterDateFormatted } from '../../utils';
import { SCHEDULE_BOARD } from '../actionPrefix';
import { axiosInstanceTechnician } from '../axios';

// Get Assigned Work Orders of Technicians
export const getAssignedWorkOrder = createAsyncThunk(
  SCHEDULE_BOARD.GET_SCHEDULE_BOARD_EVENT,
  async (
    {
      page,
      size,
      limit,
      scheduleStatus,
      zone,
      technicianUUID,
      latestStatus,
      scheduleStartDate,
      scheduleEndDate,
      unassignedWorkOrder,
    },
    { rejectWithValue }
  ) => {
    const params = new URLSearchParams();

    // Add params conditionally
    if (page) params.append('page', page);
    if (size) params.append('size', size);
    if (limit) params.append('limit', limit);
    if (scheduleStatus) params.append('schedule_status__in', scheduleStatus);
    if (latestStatus) params.append('latest_status', latestStatus);
    if (zone) params.append('zone', zone);
    if (technicianUUID) params.append('technician_uuid__in', technicianUUID);
    if (scheduleStartDate && scheduleEndDate) {
      const startDate = new Date(
        moment(scheduleStartDate).startOf('day').toDate()
      );
      const endDate = new Date(
        moment(scheduleEndDate).add(1, 'days').startOf('day').toDate()
      );

      params.append(
        'scheduled_start_date_time__gte',
        filterDateFormatted(startDate)
      );
      params.append(
        'scheduled_end_date_time__lte',
        filterDateFormatted(endDate)
      );
    }
    if (unassignedWorkOrder)
      params.append('unassigned_work_order', unassignedWorkOrder);

    try {
      const response = await axiosInstanceTechnician.get(
        `${APIs.WORK_ORDER.GET_WORK_ORDERS}?${params.toString()}`
      );

      return { ...response.data };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Assign Work Order To Technician
export const postAssignWOtoTech = createAsyncThunk(
  SCHEDULE_BOARD.ASSIGN_WORK_ORDER_TO_TECHNICIAN,
  async (req, { rejectWithValue }) => {
    const payload = {
      scheduled_start_date_time: filterDateFormatted(
        req.startDate,
        DATE_CONVERT_UTC_FORMAT_API
      ),
      scheduled_end_date_time: filterDateFormatted(
        req.endDate,
        DATE_CONVERT_UTC_FORMAT_API
      ),
      technician_uuid: req.resource,
    };

    try {
      const response = await axiosInstanceTechnician.post(
        APIs.SCHEDULE_BOARD.ASSIGN_WORK_ORDER_TO_TECHNICIAN.replace(
          '{uuid}',
          req.workOrderId
        ),
        payload
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Unassigned Work Order From Technician
export const unassignedWOtoTech = createAsyncThunk(
  SCHEDULE_BOARD.UNASSIGNED_WORK_ORDER_TO_TECHNICIAN,
  async ({ workOrderId }, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceTechnician.delete(
        APIs.SCHEDULE_BOARD.ASSIGN_WORK_ORDER_TO_TECHNICIAN.replace(
          '{uuid}',
          workOrderId
        )
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
