import { DRAFT, INVITE_PENDING, PRIMARY } from './Colors';

export const LOCATION = process.env.REACT_APP_LOCATION;
export const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_MAP_KEY;
export const GOOGLE_PLACE_KEY = process.env.REACT_APP_GOOGLE_PLACES_KEY;

export const ADDRESS_REGION_CODE = 'AU';
export const ADDRESS_COUNTRY = 'Australia';

export const COUNTRIES = {
  AUSTRALIA: 'AUS',
  UAE: 'UAE',
};

export const TAB_STATUS = {
  active: 'active',
  inactive: 'inactive',
};

export const INVITATION_STATUS = {
  draft: 'Draft',
  invited: 'Invited',
  invitation_pending: 'Invite Pending',
  submitted: 'Submitted',
  approved: 'Approved',
};

export const STATUS_COLOR_MAP = {
  draft: DRAFT,
  invited: PRIMARY,
  invitation_pending: INVITE_PENDING,
};

export const DASHBOARD = 'dashboard';
export const APPROVED = 'approved';
export const SUBMITTED = 'submitted';
export const DRAFT_STATUS = 'draft';
export const CREATED_STATUS = 'created';

export const SIX_MONTHS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'];
export const TWELEVE_MONTHS = [
  ...SIX_MONTHS,
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export const MONTHS_DROPDOWN = [
  { label: 'Six Months', value: 6 },
  { label: 'Twelve Months', value: 12 },
];
//  CHART_TYPE
export const PIE_CHART = 'pie_chart';
export const BAR_CHART = 'bar_chart';
export const LINE_CHART = 'line_chart';
export const SCATTER_CHART = 'scatter_chart';
export const SPARKLINE_CHART = 'sparkline_chart';
export const GAUGE_CHART = 'gauge_chart';

// Date format
export const DATE_FORMAT = 'DD/MM/YYYY';
export const DATE_RANGE_FORMAT = 'dd/MM/yyyy';
export const DATE_TIME_RANGE_FORMAT = 'dd/MM/yyyy hh:mm aa';
export const DATE_TIME_RANGE_FORMAT_DEFAULT = 'DD/MM/YYYY hh:mm A';
export const DATE_TIME_RANGE_FORMAT_PLACEHOLDER = 'DD/MM/YYYY HH:MM A';
export const DATE_FORMAT_API = 'YYYY-MM-DD';
export const DATE_FORMAT_API_WITH_TIME = 'YYYY-MM-DDTHH:mm';
export const DATE_CONVERT_UTC_FORMAT_API = 'YYYY-MM-DDTHH:mm:ss.SSS[Z]';
export const DATE_RANGE_FORMAT_API = 'YYYY-MM-DDTHH:mm:ss';
export const SCHEDULE_BOARD_DAY = 'MMMM DD, yyyy';
export const SCHEDULE_BOARD_WEEK_START = 'MMMM DD';
export const SCHEDULE_BOARD_WEEK_END = 'DD, YYYY';
export const SCHEDULE_BOARD_MONTH = 'MMMM, yyyy';
export const SCHEDULE_BOARD_WO_UTC_LOCAL = 'MM/DD/YYYY hh:mm A';

// Font size
export const FONT_SIZE = '14px';
// SSO
export const LOGIN_TYPE = {
  microsoft: 'microsoft',
  google: 'google',
};

export const TIME_SHEET_TYPE = [
  {
    label: 'Perform Routine Service',
    value: 'perform_routine_service',
  },

  {
    label: 'Travel To Property',
    value: 'travel_to_property',
  },
  {
    label: 'Other',
    value: 'other',
  },
];

export const CONFIRMATIVE_OPTIONS = [
  { label: 'Yes', value: 'true' },
  { label: 'No', value: 'false' },
];

export const CHART_CONST = {
  PROPERTIES: 'properties',
  QUOTES: 'quotes',
  DEFECTS: 'defects',
  PURCHASE_ORDER: 'purchase_order',
};

export const StaticChartData = [
  {
    id: 1,
    name: 'defects',
    order: 1,
    display_name: 'Defects',
    description: 'Critical, Non-Critical,Non-Conformance',
    is_active: true,
    isRadius: true,
    default_type: {
      id: 5,
      name: 'pie_chart',
      display_name: 'Pie Chart',
    },
    selected_type: {
      id: 5,
      name: 'pie_chart',
      display_name: 'Pie Chart',
    },
    supported_types: [
      {
        id: 5,
        name: 'pie_chart',
        display_name: 'Pie Chart',
      },
      {
        id: 4,
        name: 'bar_chart',
        display_name: 'Bar Chart',
      },
    ],
    widget_data: {
      pie_chart: {
        data: [
          { label: 'Group A', value: 40, color: '#F06548' },
          { label: 'Group B', value: 30, color: '#95C020' },
          { label: 'Group C', value: 30, color: '#299CDB' },
        ],
      },
      bar_chart: {
        data: [
          {
            data: [30, 35, 35, 40, 45, 45],
            label: 'Critical',
            id: 'critical',
            stack: 'total',
          },
          {
            data: [15, 12, 20, 25, 10, 20],
            label: 'Non-Critical',
            id: 'nonCritical',
            stack: 'total',
          },
          {
            data: [15, 12, 20, 25, 10, 20],
            label: 'Non-Conformance',
            id: 'nonConformance',
            stack: 'total',
          },
        ],
        colors: ['#F06548', '#95C020', '#299CDB'],
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
      },
    },
  },
  {
    id: 2,
    name: 'quotes',
    order: 2,
    display_name: 'Quotes',
    description:
      'Open, Pending Approval, Assigned,In Progress, Delayed, Overdue',
    is_active: true,
    isRadius: true,
    default_type: {
      id: 5,
      name: 'pie_chart',
      display_name: 'Pie Chart',
    },
    selected_type: {
      id: 5,
      name: 'pie_chart',
      display_name: 'Pie Chart',
    },
    supported_types: [
      {
        id: 5,
        name: 'pie_chart',
        display_name: 'Pie Chart',
      },
      {
        id: 4,
        name: 'bar_chart',
        display_name: 'Bar Chart',
      },
    ],
    widget_data: {
      pie_chart: {
        data: [
          { label: 'Open', value: 25, color: '#95C020' },
          { label: 'Pending Approval', value: 18, color: '#405189' },
          { label: 'Assigned', value: 17, color: '#299CDB' },
          { label: 'In Progress', value: 9, color: '#F7B84B' },
          { label: 'Delayed', value: 9, color: '#F06548' },
          { label: 'Overdue', value: 22, color: '#A85BBB' },
        ],
      },
      bar_chart: {
        data: [
          {
            data: [30, 35, 35, 40, 45, 45],
            label: 'Open',
            id: 'open',
            stack: 'total',
          },
          {
            data: [15, 12, 20, 25, 10, 20],
            label: 'Pending Approval',
            id: 'pendingApproval',
            stack: 'total',
          },
          {
            data: [15, 12, 20, 25, 10, 20],
            label: 'Assigned',
            id: 'assigned',
            stack: 'total',
          },
          {
            data: [15, 12, 20, 25, 10, 20],
            label: 'In Progress',
            id: 'inProgress',
            stack: 'total',
          },
          {
            data: [15, 12, 20, 25, 10, 20],
            label: 'Delayed',
            id: 'delayed',
            stack: 'total',
          },
          {
            data: [15, 12, 20, 25, 10, 20],
            label: 'Overdue',
            id: 'overdue',
            stack: 'total',
          },
        ],
        colors: [
          '#95C020',
          '#405189',
          '#299CDB',
          '#F7B84B',
          '#F06548',
          '#A85BBB',
        ],
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
      },
    },
  },
  {
    id: 3,
    name: 'invoices',
    order: 3,
    display_name: 'Invoices',
    description: 'Not Sent, In Progress, Pending Payments, Dispute, Overdue',
    is_active: true,
    default_type: {
      id: 5,
      name: 'pie_chart',
      display_name: 'Pie Chart',
    },
    selected_type: {
      id: 5,
      name: 'pie_chart',
      display_name: 'Pie Chart',
    },
    supported_types: [
      {
        id: 5,
        name: 'pie_chart',
        display_name: 'Pie Chart',
      },
      {
        id: 4,
        name: 'bar_chart',
        display_name: 'Bar Chart',
      },
    ],
    widget_data: {
      pie_chart: {
        data: [
          { label: 'Not Sent', value: 14, color: '#95C020' },
          { label: 'In Progress', value: 3, color: '#F7B84B' },
          { label: 'Pending Payments', value: 1, color: '#F06548' },
          { label: 'Dispute', value: 1, color: '#299CDB' },
          { label: 'Overdue', value: 1, color: '#A85BBB' },
        ],
      },
      bar_chart: {
        data: [
          {
            data: [30, 35, 35, 40, 45, 45],
            label: 'Not Sent',
            id: 'notSent',
            stack: 'total',
          },
          {
            data: [15, 12, 20, 25, 10, 20],
            label: 'In Progress',
            id: 'inProgress',
            stack: 'total',
          },
          {
            data: [15, 12, 20, 25, 10, 20],
            label: 'Pending Payments',
            id: 'pendingPayments',
            stack: 'total',
          },
          {
            data: [15, 12, 20, 25, 10, 20],
            label: 'Dispute',
            id: 'dispute',
            stack: 'total',
          },
          {
            data: [15, 12, 20, 25, 10, 20],
            label: 'Overdue',
            id: 'overdue',
            stack: 'total',
          },
        ],
        colors: ['#95C020', '#F7B84B', '#F06548', '#299CDB', '#A85BBB'],
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
      },
    },
  },
  {
    id: 4,
    name: 'routine_services',
    display_name: 'Routine Services',
    description: 'Unassigned, In Progress, Overdue, Blocked',
    is_active: true,
    order: 4,
    default_type: {
      id: 4,
      name: 'bar_chart',
      display_name: 'Bar Chart',
    },
    selected_type: {
      id: 4,
      name: 'bar_chart',
      display_name: 'Bar Chart',
    },
    supported_types: [
      {
        id: 9,
        name: 'line_chart',
        display_name: 'Line Chart',
      },
      {
        id: 4,
        name: 'bar_chart',
        display_name: 'Bar Chart',
      },
    ],
    widget_data: {
      bar_chart: {
        data: [
          {
            data: [30, 35, 35, 40, 45, 45],
            label: 'Unassigned',
            id: 'unAssigned',
            stack: 'total',
          },
          {
            data: [15, 12, 20, 25, 10, 20],
            label: 'In Progress',
            id: 'inProgressId',
            stack: 'total',
          },
          {
            data: [10, 17, 20, 25, 5, 15],
            label: 'Overdue',
            id: 'overDue',
            stack: 'total',
          },
          {
            data: [15, 23, 15, 60, 35, 5],
            label: 'Blocked',
            id: 'blocked',
            stack: 'total',
          },
        ],
        colors: ['#95C020', '#F7B84B', '#A85BBB', '#F06548'],
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
      },
      line_chart: {
        data: [
          {
            data: [2, 5.5, 2, 8.5, 1.5, 5],
            label: 'Unassigned',
            color: '#95C020',
          },
          {
            data: [3, 5, 2, 8, 1.5, 5],
            label: 'In Progress',
            color: '#F7B84B',
          },
          {
            data: [7, 5, 2, 5, 1, 5],
            label: 'Overdue',
            color: '#A85BBB',
          },
          {
            data: [2, 1, 2, 8, 1, 5],
            label: 'Blocked',
            color: '#F06548',
          },
        ],
        labels: [1, 2, 3, 4, 5, 6],
      },
    },
  },
  {
    id: 5,
    name: 'service_request',
    display_name: 'Service Request',
    description: 'Unassigned, In Progress, Overdue, Blocked',
    is_active: true,
    order: 5,
    default_type: {
      id: 4,
      name: 'bar_chart',
      display_name: 'Bar Chart',
    },
    selected_type: {
      id: 4,
      name: 'bar_chart',
      display_name: 'Bar Chart',
    },
    supported_types: [
      {
        id: 9,
        name: 'line_chart',
        display_name: 'Line Chart',
      },
      {
        id: 4,
        name: 'bar_chart',
        display_name: 'Bar Chart',
      },
    ],
    widget_data: {
      bar_chart: {
        data: [
          {
            data: [30, 35, 35, 40, 45, 45],
            label: 'Unassigned',
            id: 'unAssigned',
            stack: 'total',
          },
          {
            data: [15, 12, 20, 25, 10, 20],
            label: 'In Progress',
            id: 'inProgressId',
            stack: 'total',
          },
          {
            data: [10, 17, 20, 25, 5, 15],
            label: 'Overdue',
            id: 'overDue',
            stack: 'total',
          },
          {
            data: [15, 23, 15, 60, 35, 5],
            label: 'Blocked',
            id: 'blocked',
            stack: 'total',
          },
        ],
        colors: ['#95C020', '#F7B84B', '#A85BBB', '#F06548'],
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
      },
      line_chart: {
        data: [
          {
            data: [30, 35, 35, 40, 45, 45], // Unassigned
            label: 'Unassigned',
          },
          {
            data: [15, 12, 20, 25, 10, 20], // In Progress
            label: 'In Progress',
          },
          {
            data: [10, 17, 20, 25, 5, 15], // Overdue
            label: 'Overdue',
          },
          {
            data: [15, 23, 15, 60, 35, 5], // Blocked
            label: 'Blocked',
          },
        ],
        labels: [1, 2, 3, 4, 5, 6],
      },
    },
  },
  {
    id: 6,
    name: 'properties',
    order: 6,
    display_name: 'Properties',
    description:
      'Created, Deactivated, Reactivated,Contracts Expired, Contracts Expiring Soon',
    is_active: true,
    default_type: {
      id: 5,
      name: 'pie_chart',
      display_name: 'Pie Chart',
    },
    selected_type: {
      id: 5,
      name: 'pie_chart',
      display_name: 'Pie Chart',
    },
    supported_types: [
      {
        id: 5,
        name: 'pie_chart',
        display_name: 'Pie Chart',
      },
      {
        id: 4,
        name: 'bar_chart',
        display_name: 'Bar Chart',
      },
    ],
    widget_data: {
      pie_chart: {
        data: [
          { label: 'Created', value: 14, color: '#95C020' },
          { label: 'Deactivated', value: 3, color: '#405189' },
          { label: 'Reactivated', value: 1, color: '#299CDB' },
          { label: 'Contracts Expired', value: 1, color: '#F06548' },
          { label: 'Contracts Expiring Soon', value: 1, color: '#F7B84B' },
        ],
      },
      bar_chart: {
        data: [
          {
            data: [30, 35, 35, 40, 45, 45],
            label: 'Created',
            id: 'created',
            stack: 'total',
          },
          {
            data: [15, 12, 20, 25, 10, 20],
            label: 'Deactivated',
            id: 'deactivated',
            stack: 'total',
          },
          {
            data: [15, 12, 20, 25, 10, 20],
            label: 'Reactivated',
            id: 'reactivated',
            stack: 'total',
          },
          {
            data: [15, 12, 20, 25, 10, 20],
            label: 'Contracts Expired',
            id: 'contractsExpired',
            stack: 'total',
          },
          {
            data: [15, 12, 20, 25, 10, 20],
            label: 'Contracts Expiring Soon',
            id: 'contractsExpiringSoon',
            stack: 'total',
          },
        ],
        colors: ['#95C020', '#405189', '#299CDB', '#F06548', '#F7B84B'],
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
      },
    },
  },
];

export const PRODUCT_TYPES_LABEL = {
  EQUIPMENT: 'equipment',
  MATERIAL: 'material',
  EXPENSES: 'expenses',
  LABOUR: 'labour',
};

export const PRODUCT_TYPES = [
  { label: 'Material', value: 'material' },
  { label: 'Equipment', value: 'equipment' },
  { label: 'Expense', value: 'expense' },
  { label: 'Labour', value: 'labour' },
];

export const UNIT_OF_MEASURES = [
  { label: 'Each', value: 'each' },
  { label: 'Length', value: 'length' },
  { label: 'Weight', value: 'weight' },
];

export const PROPERTY_DETAIL_STEP = {
  asset_details: 'AssetDetails',
  billingContracts: 'Billing Contracts',
  invoice: 'Invoice',
};

export const WORK_ORDER_STEPS = {
  defect_quotes: 'Defects Quote',
  purchase_orders: 'Purchase Orders',
  invoice: 'Invoice',
};

// OTHER CHART

export const SCHEDULE_BOARD_CALENDAR_VIEW = {
  DAY: 'Day',
  WEEK: 'Week',
  MONTH: 'Month',
};

export const WO_STATUS = {
  created: 'created',
  pending: 'pending',
  awaitingForCustomerConfirmation: 'awaiting_for_customer_confirmation',
  confirmed: 'confirmed',
  inProgress: 'in_progress',
  submitted: 'submitted',
  completed: 'completed',
};

export const QUOTE_TYPE = {
  defect: 'defect',
};

export const WORK_ORDER_TYPE_LIST = [
  {
    label: 'Routine Service',
    value: 'routine_service',
  },
  {
    label: 'Maintenance',
    value: 'maintenance',
  },
];

export const CUSTOMER_DEFECT_STATUS = [
  {
    label: 'Critical',
    value: 'critical',
  },
  {
    label: 'Non-critical',
    value: 'non-critical',
  },
  {
    label: 'Non-Conformance',
    value: 'non-conformance',
  },
];

export const DEFECT_SEVERITY = [
  {
    label: 'Critical',
    value: 'critical',
  },
  {
    label: 'High',
    value: 'high',
  },
  {
    label: 'Medium',
    value: 'medium',
  },
  {
    label: 'Low',
    value: 'low',
  },
];

// Work Order Status Lable Start
export const CREATED = 'Created';
export const SCHEDULED = 'Scheduled';
export const APPOINTMENT_CONFIRMED = 'Appointment Confirmed';
export const IN_PROGRESS = 'In-Progress';
export const COMPLETED = 'Completed';
// Work Order Status Lable End

// Quote Type Label Start
export const DEFECT = 'Defect';

// Defect Status Lable Start
export const DEFECT_STATUS = {
  nonCritical: 'Non-Critical',
  nonConformance: 'Non-Conformance',
  critical: 'Critical',
};
// Defect Status Lable End

// Assets Status Lable Start
export const ASSETS_STATUS = {
  fail: 'Fail',
  pass: 'Pass',
  noCheck: 'No Check',
};
// Assets Status Lable End

// Work Order Status Filter ENUMS Start
export const STATUS_LIST = [
  {
    label: CREATED,
    value: WO_STATUS.created,
    key: 'latest_status',
    filter: {
      latest_status: WO_STATUS.created,
      schedule_status: WO_STATUS.pending,
    },
  },
  {
    label: SCHEDULED,
    value: WO_STATUS.awaitingForCustomerConfirmation,
    key: 'schedule_status',
    filter: {
      latest_status: WO_STATUS.created,
      schedule_status: WO_STATUS.awaitingForCustomerConfirmation,
    },
  },
  {
    label: APPOINTMENT_CONFIRMED,
    value: WO_STATUS.confirmed,
    key: 'schedule_status',
    filter: {
      latest_status: WO_STATUS.created,
      schedule_status: WO_STATUS.confirmed,
    },
  },
  {
    label: IN_PROGRESS,
    value: WO_STATUS.inProgress,
    key: 'latest_status',
    value2: WO_STATUS.awaitingForCustomerConfirmation,
    key2: 'schedule_status',
    filter: {
      latest_status: WO_STATUS.inProgress,
    },
  },
  {
    label: COMPLETED,
    value: WO_STATUS.submitted,
    key: 'latest_status',
    filter: {
      latest_status: WO_STATUS.submitted,
    },
  },
];
// Work Order Status Filter ENUMS End

// Quote Types ENUM
export const QUOTE_TYPE_LIST = [
  {
    label: DEFECT,
    value: QUOTE_TYPE.defect,
  },
];

// Function to get labels from STATUS_LIST based on status keys
export const getStatusLabels = (item) => {
  const latestStatusLabel = STATUS_LIST.find(
    (status) =>
      status.key === 'latest_status' && status.value === item.latest_status
  );

  let scheduleStatusLabel = null;
  let combineStatusLabel = null;

  if (
    latestStatusLabel?.value !== WO_STATUS.submitted &&
    latestStatusLabel?.value !== WO_STATUS.inProgress
  ) {
    scheduleStatusLabel = STATUS_LIST.find(
      (status) =>
        status.key === 'schedule_status' &&
        status.value === item.schedule_status
    )?.label;

    combineStatusLabel = STATUS_LIST.find(
      (status) =>
        status.key === 'latest_status' &&
        status.key2 === 'schedule_status' &&
        status.value === item.latest_status &&
        status.value2 === item.schedule_status
    )?.label;
  }

  return combineStatusLabel
    ? combineStatusLabel
    : scheduleStatusLabel
      ? scheduleStatusLabel
      : latestStatusLabel?.label;
};

// Common Error Message
export const COMMON_ERROR_MESSAGE = {
  default: 'Something went wrong on our end. Please try again later.',
};

export const FILE_UPLOAD_SIZES = {
  DOCUMENT: 50_000_000,
  IMAGE: 15_000_000,
};

export const PURCHASE_ORDER_STATUS = {
  DELETED: 'deleted',
  SUBMITTED: 'submitted',
  DRAFT: 'draft',
  CREATED: 'created',
  PAID: 'paid',
};

export const CUSTOMER_QUOTATION_STATUS = {
  APPROVED: 'approved',
  REJECTED: 'rejected',
  PENDING: 'submit_to_customer',
  OVERDUE: 'overdue',
  TOTAL_AMOUNT: 'total_amount',
};

export const CUSTOMER_DEFECTS = {
  CRITICAL: 'Critical',
  NON_CRITICAL: 'Non-Critical',
  NON_CONFORMANCE: 'Non-Conformance',
};

// Chart Colors Start
export const CHART_COLOR = {
  // Properties
  setup: '#95C020',
  hold: '#299CDB',
  active: '#F06548',
  inactive: '#F7B84B',
  // Defects
  critical: '#F06548',
  'non-critical': '#95C020',
  'non-conformance': '#7A4E8C',

  // Quotation
  approved: '#95C020',
  rejected: '#C54036',
  submitted: '#95C020',
  submit_to_customer: '#FD7E14',
  'in progress': '#007BFF',

  // Purchase Order
  draft: '#299CDB',
  created: '#F06548',
};
// Chart Colors End

export const DOCUMENT_IMPORT_STATUS = {
  UPLOADED: 'uploaded',
  PROCESSING: 'processing',
  INSERTED: 'inserted',
  FAILED: 'failed',
  INSERTED_WITH_ERRORS: 'inserted_with_errors',
};

export const DOCUMENT_IMPORT_STATUS_LIST = [
  { value: 'uploaded', label: 'Uploaded' },
  { value: 'processing', label: 'Processing' },
  { value: 'inserted', label: 'Inserted' },
  { value: 'failed', label: 'Failed' },
  { value: 'inserted_with_errors', label: 'Inserted with Errors' },
];

export const DOCUMENT_TEMPLATE_TYPES = [
  { value: 'customer', label: 'Customer' },
  { value: 'property', label: 'Property' },
  { value: 'technician', label: 'Technician' },
  { value: 'asset', label: 'Asset' },
  { value: 'supplier', label: 'Supplier' },
  { value: 'product_catalogue', label: 'Product catalogue' },
];

export const INVOICE_STATUS_LIST = [
  { value: 'draft', label: 'Draft' },
  { value: 'created', label: 'Created' },
  { value: 'submitted', label: 'Submitted' },
  { value: 'paid', label: 'Paid' },
];
