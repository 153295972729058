import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';

// Internal
import App from './App';
import './i18n/root';
import './index.css';
import reportWebVitals from './reportWebVitals';
import store from './store/store';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
reportWebVitals();
