import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Divider, Typography } from '@mui/material';

import { SECONDARY } from '../../../../constants/Colors';
import { Validation } from '../../../../constants/FieldValidationMsg';
import { EMAIL_REGEX } from '../../../../constants/Regex';
import getCityByStateHook from '../../../../hooks/getCityByStateHook';
import useAddressDetail from '../../../../hooks/useAddressDetail';
import useAutocompleteAddress from '../../../../hooks/useAutcompleteAddress';
import { clearTextfields } from '../../../../utils';
import Autocomplete from '../../../CommonComponents/AutoComplete';
import CustomTextField from '../../../CommonComponents/CustomTextField';

const PrimaryContacts = ({
  title,
  control,
  trigger,
  setValue,
  stateList,
  Section,
  watch,
  customerStatus,
  resetField,
}) => {
  const selectedState = watch('contactState');
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const cityList = getCityByStateHook(selectedState?.value);
  const {
    isLoading: isAutocompleteAddressLoading,
    data: autocompleteAddressData,
  } = useSelector((state) => state.googleAPIs.autocompleteAddress);
  const typedValue = watch('searchCustomerAddress');
  const [searchAddress, setSearchAddress] = useState('');
  useAutocompleteAddress(searchAddress);
  useAddressDetail({
    selectedAddress: typedValue,
    setValue: setValue,
    fieldName: {
      addressKey: 'address',
      stateKey: 'contactState',
      cityKey: 'contactCity',
      postCodeKey: 'contactPostCode',
    },
  });

  useEffect(() => () => resetField('searchCustomerAddress'), []);

  return (
    <Box
      sx={{
        background: SECONDARY,
        borderRadius: '4px',
      }}
    >
      <Box>
        <Box sx={{ padding: '16px' }}>
          <Typography variant="h2">{title}</Typography>
        </Box>
        <Divider />
        <Section>
          <Controller
            name="contact_name"
            control={control}
            rules={{
              required: `Contact Name ${Validation.general.required}`,
              maxLength: {
                value: 80,
                message: Validation.general.contactName,
              },
            }}
            render={({
              field: { onChange, value, name, onBlur },
              fieldState: { error },
            }) => (
              <CustomTextField
                label="Contact Name"
                fullWidth
                value={value}
                onChange={(e) => {
                  onChange(e);
                  trigger('contact_name');
                }}
                helperText={error ? error.message : ''}
                error={error}
                onClear={() => clearTextfields(setValue, name)}
                onBlur={() => {
                  onChange(value.trim());
                  onBlur();
                }}
              />
            )}
          />
          <Controller
            name="contact_email"
            control={control}
            rules={{
              required: `Contact Email ${Validation.general.required}`,
              maxLength: {
                value: 254,
                message: Validation.general.email,
              },
              pattern: {
                value: EMAIL_REGEX,
                message: Validation.general.validEmail,
              },
            }}
            render={({
              field: { onChange, value, name },
              fieldState: { error },
            }) => (
              <CustomTextField
                label="Contact Email"
                fullWidth
                value={value}
                disabled={customerStatus === 'invited'}
                onChange={(e) => {
                  onChange(e.target.value);
                  trigger('contact_email');
                }}
                helperText={error ? error.message : ''}
                error={error}
                onClear={() => clearTextfields(setValue, name)}
              />
            )}
          />
          <Controller
            name="contact_phone"
            control={control}
            rules={{
              required: `Contact phone ${Validation.general.required}`,
              pattern: {
                value: /^\d{10}$/,
                message: Validation.general.contactPhone,
              },
            }}
            render={({
              field: { onChange, value, name },
              fieldState: { error },
            }) => (
              <CustomTextField
                label="Contact Phone"
                fullWidth
                value={value}
                onChange={(e) => {
                  const numericValue = e.target.value.replace(/\D/g, '');

                  onChange(numericValue);
                  trigger('contact_phone');
                }}
                helperText={error ? error.message : ''}
                error={error}
                onClear={() => clearTextfields(setValue, name)}
              />
            )}
          />
          <Controller
            name="searchCustomerAddress"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Autocomplete
                label={t('common.searchAddress')}
                options={autocompleteAddressData || []}
                value={value}
                isRequired={false}
                isLoadingData={isAutocompleteAddressLoading}
                onChange={(e, newValue) => {
                  onChange(newValue);
                  setSearchAddress(e.target?.value);
                }}
                helperText={error ? error.message : ''}
                error={error}
              />
            )}
          />
          <Controller
            name="address"
            control={control}
            rules={{
              maxLength: {
                value: 300,
                message: Validation.general.address,
              },
            }}
            render={({
              field: { onChange, value, name, onBlur },
              fieldState: { error },
            }) => (
              <CustomTextField
                label="Contact Address"
                fullWidth
                value={value}
                onChange={(e) => {
                  onChange(e);
                  trigger('address');
                }}
                multiline
                rows={2}
                helperText={error ? error.message : ''}
                error={error}
                onClear={() => clearTextfields(setValue, name)}
                isRequired={false}
                onBlur={() => {
                  onChange(value.trim());
                  onBlur();
                }}
              />
            )}
          />
          <Box sx={{ display: 'flex', columnGap: '8px', width: '100%' }}>
            <Controller
              name="contactState"
              control={control}
              rules={{}}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <Autocomplete
                  label="State"
                  options={stateList}
                  value={value}
                  onChange={(e, newValue) => {
                    onChange(newValue);
                    setValue('contactCity', null);
                  }}
                  helperText={error ? error.message : ''}
                  error={error}
                  isRequired={false}
                />
              )}
            />
            <Controller
              name="contactCity"
              control={control}
              rules={{}}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <Autocomplete
                  label="City"
                  options={cityList}
                  value={value}
                  onChange={(e, newValue) => onChange(newValue)}
                  helperText={error ? error.message : ''}
                  error={error}
                  disabledDropdown={!selectedState}
                  isRequired={false}
                />
              )}
            />
          </Box>
          <Controller
            name="contactPostCode"
            control={control}
            rules={{
              pattern: {
                value: /^\d{4}$/,
                message: Validation.general.postCode,
              },
            }}
            render={({
              field: { onChange, value, name },
              fieldState: { error },
            }) => (
              <CustomTextField
                label="Post Code"
                fullWidth
                value={value}
                onChange={(e) => {
                  onChange(e);
                  trigger('contactPostCode');
                }}
                helperText={error ? error.message : ''}
                error={error}
                onClear={() => clearTextfields(setValue, name)}
                isRequired={false}
              />
            )}
          />
        </Section>
      </Box>
    </Box>
  );
};

export default PrimaryContacts;
