import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { ROUTENAME } from '../../constants/RoutesConstants';

const NotFound = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(ROUTENAME.DEFAULT_ROUTE);
  }, []);

  return null;
};

export default NotFound;
