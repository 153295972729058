import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Box } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { uniqueId } from 'lodash';
import moment from 'moment';

import { DIVIDER_COLOR } from '../../constants/Colors';
import { CUSTOMER_DEFECTS } from '../../constants/Constants';
import { getCustomerDefectCount } from '../../store/users/customer/api';
import DashboardCard from '../CommonComponents/DashboardCard';

const DefectDashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isLoading, data } = useSelector(
    (state) => state.customer.defectStatusCount
  );

  const defectCard = [
    {
      title: 'Critical',
      status: CUSTOMER_DEFECTS.CRITICAL,
      value: 0,
    },
    {
      title: 'Non-critical',
      status: CUSTOMER_DEFECTS.NON_CRITICAL,
      value: 0,
    },
    {
      title: 'Non-Conformance',
      status: CUSTOMER_DEFECTS.NON_CONFORMANCE,
      value: 0,
    },
  ];

  data &&
    data?.forEach((item) => {
      const card = defectCard.find((card) =>
        card.status.includes(item.severity)
      );

      if (card) {
        card.value = item.count;
      }
    });

  useEffect(() => {
    const req = {
      start_date: moment().subtract(6, 'months'),
      end_date: moment().add(1, 'days'),
    };

    dispatch(getCustomerDefectCount({ ...req }));
  }, []);

  const handleCardClick = (status) => {
    // TODO: Need to remove this for redirection to defect list, currently it is not working so commented it out
    // if (status !== DASHBOARD) {
    //   const formattedStatus = status.toLowerCase().replace(' ', '-');
    //   navigate(
    //     `/${ROUTENAME.DASHBOARD}/${ROUTENAME.DEFECTS}/${formattedStatus}`
    //   );
    // } else {
    //   navigate(`/${ROUTENAME.DASHBOARD}/${ROUTENAME.DEFECTS}`);
    // }
  };

  return (
    <Box sx={{ bgcolor: DIVIDER_COLOR }}>
      <Box sx={{ width: '100%', padding: '16px 0 30px 0px' }}>
        <Grid container spacing={2}>
          {defectCard?.map((item) => (
            <Grid xs={12} sm={6} md={4} key={uniqueId('dashboardCard')}>
              <DashboardCard
                title={item.title}
                value={item.value}
                onClick={() => handleCardClick(item.title)}
                isLoading={isLoading}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default DefectDashboard;
