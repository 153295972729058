import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate, useParams } from 'react-router-dom';

import { Box, styled } from '@mui/material';

import { DIVIDER_COLOR, SECONDARY } from '../../constants/Colors';
import { ROUTENAME } from '../../constants/RoutesConstants';
import { addSpaceAndCapitalizeString } from '../../utils';
import Autocomplete from '../CommonComponents/AutoComplete';
import CustomDateRangePicker from '../CommonComponents/CustomDateRangePicker';
import {
  FilterButton,
  RefreshDashboardButton,
  ResetFilterButton,
} from '../CommonComponents/FilterButton';
import MainWrapper from '../CommonComponents/MainWrapper';

//default filters
const defaultFilters = {
  dateRange: {
    fromData: null,
    toDate: null,
  },
  quoteType: null,
  properties: null,
};

const FiltersContainer = styled(Box)({
  borderRadius: '4px',
  backgroundColor: 'white',
  padding: '16px',
  display: 'flex',
  columnGap: '16px',
  rowGap: '16px',
  flexWrap: 'wrap',
});

const DefectDashboardLayout = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { status } = useParams();
  const [showFilterOptions, setShowFilterOptions] = useState(false);
  const [filters, setFilters] = useState(defaultFilters);

  const handleResetFilterBtnClick = () => {
    setFilters(defaultFilters);
  };
  const handleRefreshDashboardBtnClick = () => {
    window.location.reload();
  };

  return (
    <Box
      sx={{
        bgcolor: status ? SECONDARY : DIVIDER_COLOR,
        height: '100%',
      }}
    >
      <Box sx={{ bgcolor: DIVIDER_COLOR, paddingBottom: '16px' }}>
        <MainWrapper
          defaultPadding="0px"
          title={t('attributes.defects.defects')}
          variant="body1"
          isStep={status ? true : false}
          step={addSpaceAndCapitalizeString(status)}
          handleDefaultStep={() =>
            navigate(`/${ROUTENAME.DASHBOARD}/${ROUTENAME.DEFECTS}`)
          }
          btn={
            status
              ? []
              : [
                  <ResetFilterButton
                    onClick={handleResetFilterBtnClick}
                    disabled={
                      !(
                        filters.quoteType ||
                        filters.properties ||
                        filters.dateRange.fromData ||
                        filters.dateRange.toDate
                      )
                    }
                  />,
                  <FilterButton
                    onClick={() => setShowFilterOptions(!showFilterOptions)}
                    isActive={showFilterOptions}
                  />,
                  <RefreshDashboardButton
                    onClick={handleRefreshDashboardBtnClick}
                  />,
                ]
          }
        ></MainWrapper>
      </Box>
      {showFilterOptions && status && (
        <Box sx={{ paddingTop: '16px' }}>
          <FiltersContainer>
            <CustomDateRangePicker
              onOkClick={(val) => {
                setFilters((prev) => ({
                  ...prev,
                  dateRange: { fromData: val[0], toDate: val[1] },
                }));
              }}
              onClear={() => {
                setFilters((prev) => ({
                  ...prev,
                  dateRange: { fromData: null, toDate: null },
                }));
              }}
              fromDate={filters.dateRange?.fromData}
              toDate={filters.dateRange?.toDate}
            />
            <Autocomplete
              placeholder="Properties"
              options={[
                { label: 'Property 1', value: 'Property 1' },
                { label: 'Property 2', value: 'Property 2' },
              ]}
              value={filters?.properties}
              onChange={(e, newVal) =>
                setFilters((prev) => ({ ...prev, properties: newVal }))
              }
              width="190px"
            />
            <Autocomplete
              placeholder="Products"
              options={[
                { label: 'Product 1', value: 'Product 1' },
                { label: 'Product 2', value: 'Product 2' },
              ]}
              value={filters?.quoteType}
              onChange={(e, newVal) =>
                setFilters((prev) => ({ ...prev, quoteType: newVal }))
              }
              width="190px"
            />
          </FiltersContainer>
        </Box>
      )}

      <Outlet />
    </Box>
  );
};

export default DefectDashboardLayout;
