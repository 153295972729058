import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Card, Divider, styled, Typography } from '@mui/material';

import getDropdownDataHook from '../../hooks/getDropdownDataHook';
import { getProducts } from '../../store/property/api';
import CommonCheckbox from '../CommonComponents/CustomCheckbox';
import CustomCircularLoader from '../CommonComponents/CustomLoader';

const FormWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '16px',
}));

const CustomCard = styled(Card)(() => ({
  boxShadow: 'none',
}));

const FormFieldWrapper = styled(Box)(() => ({
  padding: '16px',
  rowGap: '16px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));

const FREQUENCY_COUNTS = [
  { label: 'Monthly', display: 'M', name: 'monthly' },
  { label: 'Three-Monthly', display: '3M', name: 'three_monthly' },
  { label: 'Six-monthly', display: 'H', name: 'six_monthly' },
  { label: 'Yearly', display: 'A', name: 'yearly' },
  { label: 'Five-yearly', display: '5Y', name: 'five_yearly' },
  { label: 'Ten-yearly', display: '10Y', name: 'ten_yearly' },
  { label: 'Twenty-five-yearly', display: '25Y', name: 'twenty_five_yearly' },
  { label: 'Thirty-yearly', display: '30Y', name: 'thirty_yearly' },
];

const RoutineForm = ({
  id,
  control,
  watch,
  setValue,
  trigger,
  equipmentTypes,
  reset,
}) => {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const { products, routineDetails } = useSelector((state) => state.property);

  const productsList = getDropdownDataHook({
    data: products?.data?.data,
    labelName: 'display_name',
    valueName: 'code',
  });

  const selectedEquipmentType = watch('equipment_type');
  const selectedProduct = watch('product');
  const routineName = `${selectedEquipmentType?.label || ''}-${selectedProduct?.label || ''}`;

  useEffect(() => {
    dispatch(getProducts({ equipment_type: selectedEquipmentType?.value }));
  }, [selectedEquipmentType?.value]);

  useEffect(() => {
    if (!routineDetails?.loading && routineDetails && id) {
      const details = routineDetails?.data;

      reset({
        monthly: details?.monthly,
        three_monthly: details?.three_monthly,
        six_monthly: details?.six_monthly,
        yearly: details?.yearly,
        five_yearly: details?.five_yearly,
        ten_yearly: details?.ten_yearly,
        twenty_five_yearly: details?.twenty_five_yearly,
        thirty_yearly: details?.thirty_yearly,
        active: details?.is_active,
      });
    }
  }, [routineDetails]);

  return routineDetails?.loading ? (
    <CustomCircularLoader />
  ) : (
    <FormWrapper>
      <CustomCard>
        <Box sx={{ padding: '16px' }}>
          <Typography variant="body1">Frequency</Typography>
        </Box>
        <Divider />
        <FormFieldWrapper>
          {FREQUENCY_COUNTS.map((freq) => (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <CommonCheckbox
                control={control}
                name={freq.name}
                label={freq.label}
                isRequired={false}
              />
              <Box
                sx={{
                  px: '16px',
                  py: '4px',
                  border: '1px solid black',
                  borderRadius: '24px',
                }}
              >
                {freq.display}
              </Box>
            </Box>
          ))}
        </FormFieldWrapper>
      </CustomCard>
      {id && (
        <CustomCard>
          <Box sx={{ padding: '0 16px 0 16px' }}>
            <CommonCheckbox
              control={control}
              name="active"
              label={t('common.active')}
              isRequired={false}
              showMessage={true}
              messageOnChecked="Routine will be moved to inactive"
              messageOnUnchecked="Routine will be moved to active"
            />
          </Box>
        </CustomCard>
      )}
    </FormWrapper>
  );
};

export default RoutineForm;
