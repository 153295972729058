import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';

import { Checkbox, FormControlLabel, Typography } from '@mui/material';

import { WARNING } from '../../constants/Colors';

const CommonCheckbox = ({
  control,
  name,
  label,
  isRequired = true,
  defaultValue = false,
  showMessage = false, // Prop to control message display
  showDefaultMessage = false,
  messageOnChecked = 'Checked',
  messageOnUnchecked = 'Unchecked',
  ...props
}) => {
  const [message, setMessage] = useState('');

  useEffect(() => {
    if (showDefaultMessage) {
      setMessage(defaultValue ? messageOnChecked : messageOnUnchecked);
    }
  }, [showDefaultMessage]);

  const handleCheckboxChange = (onChange, value) => {
    // Only update the message if showMessage is true
    if (showMessage) {
      setMessage(value ? messageOnChecked : messageOnUnchecked);
    }
    onChange(value); // Call the onChange handler to update the form state
  };

  return (
    <>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field: { onChange, value } }) => (
          <FormControlLabel
            control={
              <Checkbox
                checked={value}
                onChange={(e) =>
                  handleCheckboxChange(onChange, e.target.checked)
                }
                color="primary"
                heigth="50px"
                {...props}
              />
            }
            label={
              label ? (
                <>
                  <Typography variant="body2">
                    {label}
                    {isRequired && <span style={{ color: WARNING }}>*</span>}
                  </Typography>
                </>
              ) : (
                ''
              )
            }
          />
        )}
      />
      {message && (
        <Typography
          variant="body2"
          sx={{
            margin: showDefaultMessage
              ? '-28px 0 10px 22px'
              : '-10px 0 16px 22px',
            color: '#00000099',
          }}
        >
          {message}
        </Typography>
      )}
    </>
  );
};

export default CommonCheckbox;
