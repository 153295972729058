import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import AttachEmailOutlinedIcon from '@mui/icons-material/AttachEmailOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import DifferenceOutlinedIcon from '@mui/icons-material/DifferenceOutlined';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import PaymentIcon from '@mui/icons-material/Payment';
import { Box } from '@mui/material';

import { SECONDARY } from '../../constants/Colors';
import getDropdownDataHook from '../../hooks/getDropdownDataHook';
import { View } from '../CommonComponents/ActionComponent';
import Autocomplete from '../CommonComponents/AutoComplete';
import CustomButton from '../CommonComponents/CustomButton';
import CustomDateRangePicker from '../CommonComponents/CustomDateRangePicker';
import CustomGridTable from '../CommonComponents/CustomGridTable';
import {
  FilterComponent,
  FilterSection,
} from '../CommonComponents/FilterComponent';
import NoRecordFound from '../CommonComponents/NoDataPage/NoRecordFound';
import StatusLabel from '../CommonComponents/StatusLabel';
import StyledMainWrapper from '../CommonComponents/StyledMainWrapper';
import SwipeableDrawer from '../CommonComponents/SwipeableDrawer';
import InvoiceView from './InvoiceView';

const filterHeight = (isFilterOpen) => (isFilterOpen ? 357 : 301);

//default filters
const defaultFilters = {
  property: null,
  status: null,
  dateRange: {
    fromData: null,
    toDate: null,
  },
};

const InvoiceList = () => {
  // const dispatch = useDispatch();
  const { t } = useTranslation();

  const invoiceList = [
    {
      id: 1,
      inv_id: 'INV-98765',
      property_name: 'Wormald',
      invoice_date: '29/08/2024',
      due_date: '29/08/2024',
      payment_date: '30/08/2024',
      total: '$1,296.00',
      status: 'Paid',
    },
    {
      id: 2,
      inv_id: 'INV-98766',
      property_name: 'Chubb Fire & Security',
      invoice_date: '27/08/2024',
      due_date: '27/08/2024',
      payment_date: '27/08/2024',
      total: '$1,440.00',
      status: 'Paid',
    },
    {
      id: 3,
      inv_id: 'INV-98767',
      property_name: 'Tyco Fire & Security',
      invoice_date: '26/08/2024',
      due_date: '26/08/2024',
      payment_date: null,
      total: '$1,242.00',
      status: 'Pending',
    },
    {
      id: 4,
      inv_id: 'INV-98768',
      property_name: 'Blue Fire Systems',
      invoice_date: '25/08/2024',
      due_date: '25/08/2024',
      payment_date: '25/08/2024',
      total: '$1,350.00',
      status: 'Paid',
    },
    {
      id: 5,
      inv_id: 'INV-98769',
      property_name: 'Bens Fire Pty Ltd',
      invoice_date: '25/08/2024',
      due_date: '25/08/2024',
      payment_date: null,
      total: '$1,458.00',
      status: 'Pending',
    },
    {
      id: 6,
      inv_id: 'INV-98770',
      property_name: 'Jackson Davis',
      invoice_date: '24/08/2024',
      due_date: '24/08/2024',
      payment_date: '24/08/2024',
      total: '$1,188.00',
      status: 'Paid',
    },
    {
      id: 7,
      inv_id: 'INV-98771',
      property_name: 'Firesense',
      invoice_date: '23/08/2024',
      due_date: '23/08/2024',
      payment_date: '23/08/2024',
      total: '$1,512.00',
      status: 'Paid',
    },
    {
      id: 8,
      inv_id: 'INV-98772',
      property_name: 'Protector Fire Services',
      invoice_date: '22/08/2024',
      due_date: '22/08/2024',
      payment_date: null,
      total: '$1,350.00',
      status: 'Overdue',
    },
    {
      id: 9,
      inv_id: 'INV-98772',
      property_name: 'Protector Fire Services',
      invoice_date: '22/08/2024',
      due_date: '22/08/2024',
      payment_date: null,
      total: '$1,350.00',
      status: 'Pending',
    },
    {
      id: 10,
      inv_id: 'INV-98772',
      property_name: 'Protector Fire Services',
      invoice_date: '22/08/2024',
      due_date: '22/08/2024',
      payment_date: null,
      total: '$1,350.00',
      status: 'Overdue',
    },
  ];
  const total = 10;

  // const { invoiceList, total } = useSelector((state) => state.invoices.get);
  const invoiceStatusList = [
    {
      id: 1,
      name: 'pending',
      display_name: 'Pending',
      created_at: '2024-09-30T11:47:33.745537',
      updated_at: '2024-09-30T11:47:33.745574',
    },
    {
      id: 2,
      name: 'paid',
      display_name: 'Paid',
      created_at: '2024-09-30T11:47:33.925141',
      updated_at: '2024-09-30T11:47:33.925220',
    },
    {
      id: 3,
      name: 'overdue',
      display_name: 'Overdue',
      created_at: '2024-09-30T11:47:34.091066',
      updated_at: '2024-09-30T11:47:34.091116',
    },
  ];

  const invoicesStatusListDropdown = invoiceStatusList.map((status) => ({
    label: status.display_name,
    value: status.name,
  }));

  const invoiceStatusListLoading = false;
  // const { data: invoiceStatusList, isLoading: invoiceStatusListLoading } =
  //   useSelector((state) => state.invoices.invoicesStatusDropdownList);
  const invoicePropertyList = [
    {
      value: 'blue_fire_systems',
      label: 'Blue Fire Systems',
    },
    {
      value: 'bens_fire_pty_ltd',
      label: 'Bens Fire Pty Ltd',
    },
    {
      value: 'firesense',
      label: 'Firesense',
    },
    {
      value: 'wormald',
      label: 'Wormald',
    },
  ];
  const invoicePropertyListLoading = false;
  // const { data: invoicePropertyList, isLoading: invoicePropertyListLoading } =
  //   useSelector((state) => state.invoices.invoicesPropertyDropdownList);

  const [isFilterComponentVisible, setIsFilterComponentVisible] =
    useState(false);

  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('id');
  const [currentPage, setCurrentPage] = useState(1);
  const [perPageData, setPerPageData] = useState(10);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({});
  const [filters, setFilters] = useState(defaultFilters);
  const [isDataLoading, setIsDataLoading] = useState(false);

  const [open, setOpen] = useState(false);
  const [searchText, setSearchText] = useState('');

  const invoicesStatusList = getDropdownDataHook({
    data: invoiceStatusList?.data,
    labelName: 'display_name',
    valueName: 'name',
  });
  const invoicesPropertyList = getDropdownDataHook({
    data: invoicePropertyList?.data,
    labelName: 'display_name',
    valueName: 'name',
  });

  const columns = useMemo(() => {
    const baseColumns = [
      {
        field: 'inv_id',
        headerName: 'Invoice ID',
        flex: 1,
        sortable: true,
      },
      {
        field: 'property_name',
        headerName: 'Property Name',
        flex: 1,
        sortable: false,
      },

      {
        field: 'invoice_date',
        headerName: 'Invoice Date',
        flex: 1,
        sortable: false,
      },
      {
        field: 'due_date',
        headerName: 'Due Date',
        flex: 1,
      },
      {
        field: 'payment_date',
        headerName: 'Payment Date',
        flex: 1,
      },
      {
        field: 'total',
        headerName: 'Total',
        flex: 1,
      },
      {
        field: 'status',
        headerName: 'Status',
        flex: 1,
        sortable: false,
        renderCell: ({ row }) => formatStatus(row.status),
      },
      {
        field: 'edit',
        headerName: 'Actions',
        flex: 0.5,
        sortable: false,
        renderCell: () => (
          <View
            onClick={() => {
              setOpen(true);
            }}
          />
        ),
      },
    ];

    return baseColumns;
  }, []);

  // useEffect(() => {
  //   dispatch(getInvoiceTypeDropdownList());
  //   dispatch(getInvoicePropertyDropdownList());
  //   dispatch(getInvoiceStatusDropdownList());

  //   return () => {
  //     dispatch(resetInvoiceList());
  //   };
  // }, []);

  // Function to fetch data based on search, pagination, and filter
  // const getAllInvoiceList = useCallback(() => {
  //   setIsDataLoading(true);

  //   dispatch(
  //     getInvoiceList({
  //       order: order,
  //       orderBy: orderBy === 'id' ? 'id' : orderBy,
  //       page: currentPage,
  //       size: perPageData,
  //       search: searchText,
  //       fromDate: filters.dateRange?.fromData,
  //       toDate: filters.dateRange?.toDate,
  //       status: filters.status?.label || status,
  //       type: filters.quoteType?.label,
  //       property_name: filters.property?.label,
  //     })
  //   ).finally(() => {
  //     setIsDataLoading(false);
  //   });
  // }, [
  //   dispatch,
  //   perPageData,
  //   currentPage,
  //   order,
  //   orderBy,
  //   filters,
  //   searchText,
  //   columns,
  //   columnVisibilityModel,
  // ]);

  // Reset pagination on page size, filters, or searchText change
  useEffect(() => {
    setCurrentPage(1);
  }, [perPageData, filters, searchText]);

  // Debounced function to avoid frequent API calls
  // const debouncedFetchData = useCallback(debounce(getAllInvoiceList, 500), [
  //   getAllInvoiceList,
  // ]);

  // Call debouncedFetchData whenever search, page, or filter changes
  // useEffect(() => {
  //   debouncedFetchData();

  //   // Clean up function for debounce to avoid memory leaks
  //   return () => {
  //     debouncedFetchData.cancel();
  //   };
  // }, [debouncedFetchData]);

  const rows = invoiceList?.map((item) => {
    const updatedItem = Object.keys(item).reduce((acc, key) => {
      acc[key] = item[key] === ('' || null) ? '-' : item[key];

      return acc;
    }, {});

    updatedItem.invoice_date = item.invoice_date;
    updatedItem.due_date = item.due_date;
    updatedItem.amount = item.amount;

    return updatedItem;
  });

  const formatStatus = (status) => {
    const lowerCaseStatus = status?.toLowerCase();

    if (lowerCaseStatus === 'overdue') {
      return <StatusLabel label="Overdue" color="#C54036" />;
    }
    if (lowerCaseStatus === 'paid') {
      return <StatusLabel label="Paid" color="#95C020" />;
    }
    if (lowerCaseStatus === 'pending') {
      return <StatusLabel label="Pending" color="#FD7E14" />;
    }
    if (lowerCaseStatus === 'in progress') {
      return <StatusLabel label="In Progress" color="#007BFF" />;
    }
  };

  const resetFilter = () => {
    setFilters(defaultFilters);
    setSearchText('');
  };

  const renderedComponent = (
    <>
      <FilterSection
        onFilterBtnClick={() =>
          setIsFilterComponentVisible(!isFilterComponentVisible)
        }
        isRefresh={true}
        searchText={searchText}
        isActive={isFilterComponentVisible}
        onResetFilter={resetFilter}
        onSearchChange={(e) => setSearchText(e.target.value)}
        isResetButtonVisible={
          searchText ||
          filters.property ||
          filters.status ||
          filters.dateRange.fromData ||
          filters.dateRange.toDate
        }
      />
      {isFilterComponentVisible && (
        <FilterComponent>
          <Autocomplete
            placeholder="Property Name"
            options={invoicePropertyList}
            value={filters?.property}
            onChange={(e, newValue) =>
              setFilters((prev) => ({ ...prev, property: newValue }))
            }
            width="190px"
            isLoadingData={invoicePropertyListLoading}
          />
          <Autocomplete
            placeholder="Status"
            options={invoicesStatusListDropdown}
            value={filters?.status}
            onChange={(e, newValue) =>
              setFilters((prev) => ({ ...prev, status: newValue }))
            }
            width="190px"
            isLoadingData={invoiceStatusListLoading}
          />
          <CustomDateRangePicker
            placeholder="Invoice date"
            onOkClick={(val) => {
              setFilters((prev) => ({
                ...prev,
                dateRange: { fromData: val[0], toDate: val[1] },
              }));
            }}
            onClear={() => {
              setFilters((prev) => ({
                ...prev,
                dateRange: { fromData: null, toDate: null },
              }));
            }}
            fromDate={filters.dateRange?.fromData}
            toDate={filters.dateRange?.toDate}
            placement="bottomEnd"
          />
          <CustomDateRangePicker
            placeholder="Due date"
            onOkClick={(val) => {
              setFilters((prev) => ({
                ...prev,
                dateRange: { fromData: val[0], toDate: val[1] },
              }));
            }}
            onClear={() => {
              setFilters((prev) => ({
                ...prev,
                dateRange: { fromData: null, toDate: null },
              }));
            }}
            fromDate={filters.dateRange?.fromData}
            toDate={filters.dateRange?.toDate}
            placement="bottomEnd"
          />
        </FilterComponent>
      )}
      <CustomGridTable
        columns={columns}
        rows={rows}
        total={total}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        perPageData={perPageData}
        setPerPageData={setPerPageData}
        order={order}
        orderBy={orderBy}
        setOrder={setOrder}
        setOrderBy={setOrderBy}
        columnVisibilityModel={columnVisibilityModel}
        setColumnVisibilityModel={setColumnVisibilityModel}
        noData={<NoRecordFound />}
        isLoading={isDataLoading}
        filterHeight={filterHeight(isFilterComponentVisible)}
      />
    </>
  );

  return (
    <>
      <StyledMainWrapper
        btn={
          <>
            <CustomButton
              text={t('attributes.export')}
              color="secondary"
              sx={{ height: '52%' }}
              startIcon={<OpenInNewIcon />}
              onClick={() => {}}
            />
            <CustomButton
              text="Email a Link"
              color="secondary"
              sx={{ height: '52%' }}
              startIcon={<AttachEmailOutlinedIcon />}
              onClick={() => {}}
            />
          </>
        }
      >
        <Box sx={{ width: '100%', p: '16px 16px 0 16px' }}>
          {renderedComponent}
        </Box>
      </StyledMainWrapper>
      {open && (
        <SwipeableDrawer
          bgColor={SECONDARY}
          open={open}
          title="TAX INVOICE"
          onClose={() => {
            setOpen(false);
          }}
          footerButton={[
            <CustomButton
              text="Differed"
              color="inherit"
              disabled={false}
              startIcon={<DifferenceOutlinedIcon />}
              onClick={() => {}}
            />,
            <CustomButton
              text="Cancel"
              color="inherit"
              disabled={false}
              startIcon={<CancelOutlinedIcon />}
              onClick={() => {
                setOpen(false);
              }}
            />,
            <CustomButton
              text="Pay"
              disabled={false}
              startIcon={<PaymentIcon />}
              onClick={() => {
                setOpen(false);
              }}
            />,
          ]}
          width={653}
        >
          <InvoiceView />
        </SwipeableDrawer>
      )}
    </>
  );
};

export default InvoiceList;
