export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID, // Your Client ID
    knownAuthorities: [process.env.REACT_APP_KNOWN_AUTHORITY], // Your tenant-specific domain
    redirectUri: process.env.REACT_APP_REDIRECT_URL, // Replace with your actual redirect URI
    postLogoutRedirectUri: process.env.REACT_APP_POSTLOGOUT_URL, // Optional: where to redirect users after logout
  },
  cache: {
    cacheLocation: process.env.REACT_APP_CACHE_LOCATION, // You can use 'localStorage' for longer sessions
    storeAuthStateInCookie: false, // Recommended to set this to true for IE11/Edge
  },
  system: {
    iframeHashTimeout: 10000,
    loadFrameTimeout: 10000,
    windowHashTimeout: 10000,
  },
};
export const microsoft_auth = process.env.REACT_APP_MICROSOFT_AUTH_URL;
export const google_auth = process.env.REACT_APP_GOOGLE_AUTH_URL;

export const loginRequest = {
  scopes: process.env.REACT_APP_SCOPE.split(' '),
};
