import React from 'react';
import { useSelector } from 'react-redux';

import { Box, Tab, Tabs, Typography } from '@mui/material';

import { DIVIDER_COLOR, TEXT_COLOR } from '../../constants/Colors';

const TechnicianList = ({
  selectedTechnician,
  setSelectedTechnician,
  selectedTab,
  setSelectedTab,
}) => {
  const { data: calendarTechList } = useSelector(
    (state) => state.timeSheet.getTechList
  );

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleTechnicianClick = (event, technician) => {
    setSelectedTechnician(technician);
  };

  if (!calendarTechList || calendarTechList?.data?.length === 0) {
    return null;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        borderTop: `1px solid ${DIVIDER_COLOR}`,
        borderBottom: `1px solid ${DIVIDER_COLOR}`,
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', padding: '10px' }}>
        <Typography variant="body1">Technicians</Typography>
      </Box>
      <Tabs
        value={selectedTab}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons
        allowScrollButtonsMobile
        sx={{
          display: 'flex',
          alignItems: 'center',
          paddingRight: '5px',
          '& .MuiTabs-scrollButtons': {
            backgroundColor: 'var(--primary-color)',
            borderRadius: '50%',
            height: '32px',
            width: '32px',
            display: 'flex',
            alignItems: 'center',
            color: 'var(--white-color)',
            '&.Mui-disabled': {
              opacity: 0.3,
              color: 'var(--white-color) !important',
            },
          },
          '& .MuiTab-root': {
            color: TEXT_COLOR,
            padding: '0 10px',
          },
        }}
      >
        {calendarTechList.data?.map(({ uuid, display_name }) => (
          <Tab
            key={uuid}
            label={
              <Typography
                variant="body1"
                color={
                  selectedTechnician === uuid
                    ? 'var(--primary-color)'
                    : TEXT_COLOR
                }
              >
                {display_name}
              </Typography>
            }
            onClick={() => handleTechnicianClick(null, uuid)}
          />
        ))}
      </Tabs>
    </Box>
  );
};

export default TechnicianList;
