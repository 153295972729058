import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import HandshakeOutlinedIcon from '@mui/icons-material/HandshakeOutlined';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Box, Typography } from '@mui/material';
import { debounce } from 'lodash';

import { exportToCSV } from '../../../constants/common';
import { PROPERTY_DETAIL_STEP } from '../../../constants/Constants';
import {
  COLUMNS_TO_EXPORT,
  COLUMNS_TO_MAP,
  EXPORT_FILE_NAMES,
} from '../../../constants/ExportConstant';
import getDropdownListHook from '../../../hooks/getDropdownListHook';
import { snackbarToggle } from '../../../store/CommonReducer';
import {
  getPropertyCustomerList,
  getPropertyList,
} from '../../../store/property/api';
import { resetPropertyList } from '../../../store/property/reducer';
import { getBillingContractList } from '../../../store/users/billingContract/api';
import { resetBillingContractList } from '../../../store/users/billingContract/reducer';
import { formatDate, formattedDate } from '../../../utils';
import { Edit } from '../../CommonComponents/ActionComponent';
import Autocomplete from '../../CommonComponents/AutoComplete';
import CustomButton from '../../CommonComponents/CustomButton';
import CustomDateRangePicker from '../../CommonComponents/CustomDateRangePicker';
import CustomGridTable from '../../CommonComponents/CustomGridTable';
import CustomCircularLoader from '../../CommonComponents/CustomLoader';
import {
  FilterComponent,
  FilterSection,
} from '../../CommonComponents/FilterComponent';
import NewNoDataPage from '../../CommonComponents/NoDataPage/NewNoDataPage';
import NoRecordFound from '../../CommonComponents/NoDataPage/NoRecordFound';
import StyledMainWrapper from '../../CommonComponents/StyledMainWrapper';
import TabView from '../../CommonComponents/TabView';
import BillingContractForm from './BillingContractForm';

const listColumnNames = {
  id: 'id',
  name: 'name',
  contract_start_date: 'contract_start_date',
  contract_valid_till: 'contract_valid_till',
  price_increase: 'price_increase',
  created_at: 'created_at',
  edit: 'edit',
  customer: 'customer',
  property: 'property',
};

//default filters
const defaultFilters = {
  customer: null,
  property: null,
  contractStartDateFrom: null,
  contractStartDateTo: null,
  contractValidTillFrom: null,
  contractValidTillTo: null,
  createdAtFrom: null,
  createdAtTo: null,
};
const filterHeight = (isFilterOpen) => (isFilterOpen ? 357 : 301);

const BillingContract = ({
  isFromProperty = false,
  setNextStepTitle,
  nextStepTitle,
  selectedProperty,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const {
    billingContractActiveList,
    billingContractInactiveList,
    total,
    totalCount,
  } = useSelector((state) => state.billingContract.getList);

  const [currentPage, setCurrentPage] = useState(1);
  const [perPageData, setPerPageData] = useState(10);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('created_at');
  const [searchText, setSearchText] = useState('');
  const [activeTab, setActiveTab] = useState(0);
  const [filters, setFilters] = useState(defaultFilters);
  const [refresh, setRefresh] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({});
  const [isFilterComponentVisible, setIsFilterComponentVisible] =
    useState(false);
  const [selectedRows, setSelectedRows] = useState(null);

  // Dropdown list start
  const {
    propertyDropdownLoading: customerDropdownLoading,
    propertyDropdownData: customerDropdownData,
  } = getDropdownListHook({
    reducerName: 'property',
    dropdownListName: 'propertyCustomerList',
    labelName: 'customer_name',
    valueName: 'uuid',
  });

  const { propertyDropdownLoading, propertyDropdownData } = getDropdownListHook(
    {
      reducerName: 'property',
      dropdownListName: 'propertyList',
      labelName: 'property_name',
      valueName: 'uuid',
      loading: 'loading',
    }
  );
  // Dropdown list end

  const columns = useMemo(() => {
    const baseColumns = [
      {
        field: 'id',
        headerName: t('common.id'),
        flex: 1,
      },
      {
        field: 'name',
        headerName: t('attributes.name'),
        flex: 1.5,
        sortable: false,
      },
      {
        field: 'contract_start_date',
        headerName: t('attributes.billingContract.contractStartDate'),
        flex: 1,
        sortable: false,
      },
      {
        field: 'contract_valid_till',
        headerName: t('attributes.billingContract.contractValidTill'),
        flex: 1,
        sortable: false,
      },
      {
        field: 'price_increase',
        headerName: t('attributes.billingContract.priceIncreasePercentage'),
        flex: 1,
        sortable: false,
      },
      {
        field: 'created_at',
        headerName: t('common.createdAt'),
        flex: 1,
      },
      {
        field: 'edit',
        headerName: t('attributes.actions'),
        flex: 0.5,
        sortable: false,
        hideable: false,
        renderCell: ({ row }) => (
          <Edit
            onClick={() => {
              if (!isFromProperty) {
                navigate(row.uuid);
              } else {
                setNextStepTitle({
                  title: PROPERTY_DETAIL_STEP.billingContracts,
                  step: row.id,
                  editId: row.uuid,
                });
              }
            }}
          />
        ),
      },
    ];

    // Add conditional columns
    if (!isFromProperty) {
      baseColumns.splice(
        2,
        0,
        {
          field: 'customer',
          headerName: t('attributes.billingContract.customer'),
          flex: 1,
          sortable: false,
        },
        {
          field: 'property',
          headerName: t('attributes.billingContract.property'),
          flex: 1.3,
          sortable: false,
          renderCell: ({ row }) => (
            <Box>
              <Typography variant="body1">{row.propertyName}</Typography>
              <Typography variant="body2">{`${row.propertyAddress}`}</Typography>
            </Box>
          ),
        }
      );
    }

    return baseColumns;
  }, []);

  // Function to fetch data based on search, pagination, and filter
  const getBillingContracts = useCallback(() => {
    setSelectedRows(null);
    if (isFromProperty && !selectedProperty?.uuid) {
      return;
    }
    const visibleFieldsString = columns
      .filter((col) => columnVisibilityModel[col.field] !== false)
      .map((col) => col.field)
      .join(',');

    setIsDataLoading(true);
    const req = {
      page_size: perPageData,
      page: currentPage,
      search: searchText,
      order,
      orderBy,
      customerUUID:
        filters?.customer?.value || selectedProperty?.customer_uuid || null,
      propertyUUID: filters?.property?.value || selectedProperty?.uuid || null,
      list_column_names: visibleFieldsString,
      contractStartDateFrom: filters?.contractStartDateFrom,
      contractStartDateTo: filters?.contractStartDateTo,
      contractValidTillDateFrom: filters?.contractValidTillFrom,
      contractValidTillDateTo: filters?.contractValidTillTo,
      createdFrom: filters?.createdAtFrom,
      createdTo: filters?.createdAtTo,
      ...(!isFromProperty && { active: activeTab ? activeTab === 0 : true }),
    };

    dispatch(getBillingContractList(req)).finally(() => {
      setIsDataLoading(false);
      setIsInitialLoading(false);
    });
  }, [
    dispatch,
    activeTab,
    perPageData,
    currentPage,
    order,
    orderBy,
    filters,
    searchText,
    columns,
    columnVisibilityModel,
    refresh,
    selectedProperty,
  ]);

  useEffect(() => {
    const defaultColumnVisibilityModel = listColumnNames;

    columns.forEach((column) => {
      defaultColumnVisibilityModel[column.field] = true;
    });
    setColumnVisibilityModel(defaultColumnVisibilityModel);
  }, [activeTab]);

  useEffect(() => {
    dispatch(getPropertyCustomerList());

    return () => {
      dispatch(resetBillingContractList());
      if (!isFromProperty) {
        dispatch(resetPropertyList());
      }
    };
  }, []);

  useEffect(() => {
    const req = {
      limit: -1,
      customerUuid: filters.customer?.value,
    };

    if (filters.customer?.value) {
      dispatch(resetPropertyList());
      dispatch(getPropertyList(req));
    }
  }, [dispatch, filters.customer?.value]);

  // Reset pagination on page size, filters, or searchText change
  useEffect(() => {
    setCurrentPage(1);
  }, [perPageData, filters, searchText, activeTab]);

  // Debounced function to avoid frequent API calls
  const debouncedFetchData = useCallback(debounce(getBillingContracts, 500), [
    getBillingContracts,
  ]);

  // Call debouncedFetchData whenever search, page, or filter changes
  useEffect(() => {
    debouncedFetchData();

    // Clean up function for debounce to avoid memory leaks
    return () => {
      debouncedFetchData.cancel();
    };
  }, [debouncedFetchData]);

  const formatBillingContractRow = (item) => ({
    ...item,
    uuid: item.uuid,
    id: `BC-${item.id}`,
    name: item.name,
    customer: item?.customer?.customer_name,
    propertyName: item?.property?.property_name,
    propertyAddress:
      item?.property?.address &&
      item?.property?.city &&
      item?.property?.state &&
      item?.property?.zip_code
        ? `${item.property.address}, ${item.property.city}, ${item.property.state}, ${item.property.zip_code}`
        : '-',
    exportProperty: `${item?.property?.property_name}\n\n${
      item?.property?.address &&
      item?.property?.city &&
      item?.property?.state &&
      item?.property?.zip_code
        ? `${item.property.address}, ${item.property.city}, ${item.property.state}, ${item.property.zip_code}`
        : '-'
    }`,
    // Note: For Billing Contract, Contract Date, Start and Valid Till date will use the simple contract date without time so no need to convert to UTC and Local.
    contract_start_date: formatDate(item?.contract_start_date),
    contract_valid_till: formatDate(item?.contract_end_date),
    contract_date: formatDate(item?.contract_date),
    price_increase: `${item?.cpi_percentage_anually}%`,
    created_at: formattedDate(item?.created_at),
  });

  const rows = (
    activeTab === 0 ? billingContractActiveList : billingContractInactiveList
  )?.map(formatBillingContractRow);

  const resetFilter = () => {
    setFilters(defaultFilters);
    setSearchText('');
  };

  const onTabChange = () => {
    setIsDataLoading(true);
    setFilters(defaultFilters);
    setSearchText('');
    setIsFilterComponentVisible(false);
  };

  const handleSelectionChange = (newSelection) => {
    setSelectedRows(rows?.filter((row) => newSelection.includes(row.id)));
  };

  const noData = (
    <NewNoDataPage
      icon={<HandshakeOutlinedIcon />}
      title={t('attributes.billingContract.billingContracts')}
      customText={
        !isFromProperty ? t('label.billingContract.noBillingContracts') : false
      }
      singularText={t('attributes.billingContract.billingContracts')}
      createBtnText={`${t('attributes.add')} ${t('attributes.new')}`}
      filterHeight={filterHeight(isFilterComponentVisible)}
      onlyTitle={!isFromProperty}
    />
  );

  if (
    isFromProperty &&
    nextStepTitle?.title === PROPERTY_DETAIL_STEP.billingContracts
  ) {
    return (
      <BillingContractForm
        isFromProperty={true}
        nextStepTitle={nextStepTitle}
        selectedProperty={selectedProperty}
        setNextStepTitle={setNextStepTitle}
      />
    );
  }

  const renderedComponent = (
    <>
      {!isFromProperty && (
        <>
          <FilterSection
            onFilterBtnClick={() =>
              setIsFilterComponentVisible(!isFilterComponentVisible)
            }
            searchText={searchText}
            onSearchChange={(e) => {
              setSearchText(e.target.value);
            }}
            isResetButtonVisible={
              searchText ||
              filters.customer?.value ||
              filters.property?.value ||
              filters?.contractStartDateFrom ||
              filters?.contractStartDateTo ||
              filters?.contractValidTillFrom ||
              filters?.contractValidTillTo ||
              filters?.createdAtFrom ||
              filters?.createdAtTo
            }
            onResetFilter={resetFilter}
            isActive={isFilterComponentVisible}
            onRefreshFilter={() => setRefresh(!refresh)}
            isRefresh={true}
          />
          {isFilterComponentVisible && (
            <FilterComponent>
              <Autocomplete
                placeholder={t('attributes.customer.customer')}
                options={customerDropdownData}
                onChange={(e, newVal) => {
                  if (newVal !== undefined) {
                    setFilters((prev) => ({ ...prev, customer: newVal }));
                    setFilters((prev) => ({ ...prev, property: null }));
                  }
                }}
                value={filters?.customer}
                isLoadingData={customerDropdownLoading}
                width="190px"
              />
              <Autocomplete
                placeholder={t('attributes.property.properties')}
                options={propertyDropdownData}
                onChange={(e, newVal) => {
                  if (newVal !== undefined) {
                    setFilters((prev) => ({ ...prev, property: newVal }));
                  }
                }}
                isLoadingData={propertyDropdownLoading}
                value={filters?.property}
                disabledDropdown={!filters?.customer?.value}
                width="190px"
              />
              <CustomDateRangePicker
                placeholder={t('attributes.billingContract.contractStartDate')}
                onOkClick={(val) => {
                  setFilters((prev) => ({
                    ...prev,
                    contractStartDateFrom: val[0],
                    contractStartDateTo: val[1],
                  }));
                }}
                onClear={() => {
                  setFilters((prev) => ({
                    ...prev,
                    contractStartDateFrom: null,
                    contractStartDateTo: null,
                  }));
                }}
                fromDate={filters?.contractStartDateFrom}
                toDate={filters?.contractStartDateTo}
              />
              <CustomDateRangePicker
                placeholder={t('attributes.billingContract.contractValidTill')}
                onOkClick={(val) => {
                  setFilters((prev) => ({
                    ...prev,
                    contractValidTillFrom: val[0],
                    contractValidTillTo: val[1],
                  }));
                }}
                onClear={() => {
                  setFilters((prev) => ({
                    ...prev,
                    contractValidTillFrom: null,
                    contractValidTillTo: null,
                  }));
                }}
                fromDate={filters?.contractValidTillFrom}
                toDate={filters?.contractValidTillTo}
              />
              <CustomDateRangePicker
                placeholder={t('common.createdAt')}
                onOkClick={(val) => {
                  setFilters((prev) => ({
                    ...prev,
                    createdAtFrom: val[0],
                    createdAtTo: val[1],
                  }));
                }}
                onClear={() => {
                  setFilters((prev) => ({
                    ...prev,
                    createdAtFrom: null,
                    createdAtTo: null,
                  }));
                }}
                fromDate={filters?.createdAtFrom}
                toDate={filters?.createdAtTo}
              />
            </FilterComponent>
          )}
        </>
      )}
      <CustomGridTable
        columns={columns}
        rows={rows}
        total={total}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        perPageData={perPageData}
        setPerPageData={setPerPageData}
        order={order}
        orderBy={orderBy}
        setOrder={setOrder}
        setOrderBy={setOrderBy}
        isLoading={isDataLoading}
        noData={<NoRecordFound />}
        columnVisibilityModel={columnVisibilityModel}
        setColumnVisibilityModel={setColumnVisibilityModel}
        filterHeight={filterHeight(isFilterComponentVisible)}
        onRowSelectionModelChange={handleSelectionChange}
        paginationRequired={!isFromProperty}
      />
    </>
  );

  const mainWrapper = (
    <StyledMainWrapper
      title={
        isFromProperty
          ? false
          : t('attributes.billingContract.billingContracts')
      }
      btn={
        !isInitialLoading &&
        (total === 0 && isFromProperty ? (
          <CustomButton
            text={`${t('attributes.add')} ${t('attributes.new')}`}
            color="secondary"
            sx={{ height: '52%' }}
            startIcon={<AddCircleOutlineOutlinedIcon />}
            onClick={() => {
              setNextStepTitle({
                title: PROPERTY_DETAIL_STEP.billingContracts,
                step: `${t('attributes.add')} ${t('attributes.new')}`,
              });
            }}
          />
        ) : (
          !isFromProperty && (
            <CustomButton
              text={t('attributes.export')}
              color="secondary"
              sx={{ height: '52%' }}
              startIcon={<OpenInNewIcon />}
              onClick={() => {
                if (!selectedRows || selectedRows?.length === 0) {
                  dispatch(
                    snackbarToggle({
                      isOpen: true,
                      isErrorMsg: true,
                      msg: t('message.common.noRecordExportMessage'),
                    })
                  );
                  setSelectedRows(null);
                } else {
                  exportToCSV(
                    selectedRows,
                    COLUMNS_TO_EXPORT.BILLING_CONTRACTS,
                    EXPORT_FILE_NAMES.BILLING_CONTRACTS,
                    COLUMNS_TO_MAP.BILLING_CONTRACTS
                  );
                }
              }}
            />
          )
        ))
      }
      isFromProperty={isFromProperty}
    >
      {!isDataLoading && totalCount === 0 && noData}
      {!isDataLoading && total === 0 && isFromProperty && noData}
      {isInitialLoading ? (
        <Box
          sx={{
            width: '100%',
            mt: 2,
            minHeight: `calc(100vh - ${isFilterComponentVisible ? 357 : 301}px)`,
            maxHeight: `calc(100vh - ${isFilterComponentVisible ? 357 : 301}px)`,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <CustomCircularLoader />
        </Box>
      ) : (
        !isInitialLoading &&
        (isFromProperty ? total > 0 : totalCount > 0) &&
        (isFromProperty ? (
          renderedComponent
        ) : (
          <TabView
            tabs={[
              {
                label: t('common.active'),
                component: renderedComponent,
              },
              {
                label: t('common.inactive'),
                component: renderedComponent,
              },
            ]}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            onTabChange={onTabChange}
          />
        ))
      )}
    </StyledMainWrapper>
  );

  return <>{mainWrapper}</>;
};

export default BillingContract;
