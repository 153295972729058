import { useEffect, useMemo, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Card, styled, Typography } from '@mui/material';
import moment from 'moment';

import { TIME_SHEET_TYPE } from '../../constants/Constants';
import { Validation } from '../../constants/FieldValidationMsg';
// eslint-disable-next-line
import { calculateTimeDuration } from '../../constants/common';
import { getAssignedWorkOrder } from '../../store/scheduleBoard/api';
import { getTechnicianNamesList } from '../../store/technician/api';
import { getSingleTimeEntry } from '../../store/timesheet/api';
import { resetTimesheetData } from '../../store/timesheet/reducer';
import { clearTextfields, datePickerFormat } from '../../utils';
import Autocomplete from '../CommonComponents/AutoComplete';
import CustomDatePicker from '../CommonComponents/CustomDatePicker';
import CustomCircularLoader from '../CommonComponents/CustomLoader';
import CustomTextField from '../CommonComponents/CustomTextField';
import CustomTimePicker from '../CommonComponents/CustomTimePicker';
import { defaultValues } from './Timesheet';

const FormWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '16px',
}));

const CustomCard = styled(Card)(() => ({
  boxShadow: 'none',
}));

const FormFieldWrapper = styled(Box)(() => ({
  padding: '16px',
  rowGap: '16px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));

const TimeEntry = ({
  setError,
  control,
  id,
  getValues,
  reset,
  setValue,
  trigger,
  watch,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const startTime = watch('start_time');
  const endTime = watch('end_time');

  const [selectedTechnician, setSelectedTechnician] = useState(null);
  const [isEditLoading, setIsEditLoading] = useState(false);

  const [technicianOption, setTechnicianOption] = useState([]);
  const [isTechnicianLoading, setIsTechnicianLoading] = useState(false);

  const [workOrderOption, setWorkOrderOption] = useState([]);
  const [isWorkOrderLoading, setIsWorkOrderLoading] = useState(false);

  const [firstSelect, setFirstSelect] = useState(false);
  const [secondSelect, setSecondSelect] = useState(false);

  // Get the technicians list from Redux store
  const { data: technicianList } = useSelector(
    (state) => state.technician.technicianNamesDropdownList
  );

  useEffect(() => {
    if (id) {
      setIsEditLoading(true);
      dispatch(getSingleTimeEntry({ timesheet_uuid: id }))
        .then((res) => {
          const data = res?.payload?.data?.[0];
          const startTime = new Date(data?.start_date_time);
          const endTime = new Date(data?.end_date_time);
          reset({
            technician: data?.technician?.uuid
              ? {
                  label: data?.technician?.technician_name,
                  value: data?.technician?.uuid,
                }
              : null,
            work_order: data?.work_order_uuid
              ? {
                  label: data?.work_order?.work_order_name,
                  value: data?.work_order_uuid,
                }
              : null,
            type: TIME_SHEET_TYPE?.find((t) => t.value === data?.type) || null,
            date: data?.start_date_time
              ? datePickerFormat(data?.start_date_time)
              : null,
            start_time: startTime,
            end_time: endTime,
            description: data?.task_description,
          });
          setSelectedTechnician(
            data?.technician?.uuid
              ? {
                  label: data?.technician?.technician_name,
                  value: data?.technician?.uuid,
                }
              : null
          );
        })
        .finally(() => setIsEditLoading(false));
    }
  }, [id]);

  useEffect(() => {
    setIsTechnicianLoading(true);
    // Get technician option
    dispatch(getTechnicianNamesList()).finally(() =>
      setIsTechnicianLoading(false)
    );
  }, []);

  useEffect(() => {
    if (technicianList) {
      setTechnicianOption(() =>
        technicianList?.data?.map((technician) => ({
          label: technician.display_name,
          value: technician.uuid,
        }))
      );
    }
  }, [technicianList]);

  useEffect(() => {
    if (selectedTechnician?.value) {
      setIsWorkOrderLoading(true);
      dispatch(
        getAssignedWorkOrder({
          limit: -1,
          technicianUUID: [selectedTechnician?.value],
        })
      )
        .then((res) =>
          setWorkOrderOption(() =>
            res?.payload?.data?.map((r) => ({
              label: `(WO-${r.id}) ${r.work_order_name}`,
              value: r.uuid,
            }))
          )
        )
        .finally(() => setIsWorkOrderLoading(false));
    }
  }, [selectedTechnician]);

  useEffect(
    () => () => {
      reset(defaultValues);
      dispatch(resetTimesheetData());
    },
    []
  );

  const calculateDuration = useMemo(
    () => calculateTimeDuration({ startTime, endTime }),
    [startTime, endTime]
  );

  return isEditLoading ? (
    <CustomCircularLoader />
  ) : (
    <FormWrapper>
      <CustomCard>
        <FormFieldWrapper>
          <Controller
            name="technician"
            control={control}
            rules={{
              required: `${t('attributes.technician.technician')} ${Validation.general.required}`,
            }}
            render={({
              field: { onChange, value, name },
              fieldState: { error },
            }) => (
              <Autocomplete
                label={t('attributes.technician.technician')}
                options={technicianOption}
                value={value}
                onChange={(e, newValue) => {
                  onChange(newValue);
                  setSelectedTechnician(newValue);
                  setWorkOrderOption([]);
                  setValue('work_order', null);
                }}
                helperText={error ? error.message : ''}
                error={error}
                disabledDropdown={id}
                isLoadingData={isTechnicianLoading}
                onClear={() => clearTextfields(setValue, name)}
              />
            )}
          />
          <Controller
            name="work_order"
            control={control}
            rules={{
              required: `${t('attributes.workOrder.workOrder')} ${Validation.general.required}`,
            }}
            render={({
              field: { onChange, value, name },
              fieldState: { error },
            }) => (
              <Autocomplete
                label={t('attributes.workOrder.workOrder')}
                options={workOrderOption}
                value={value}
                onChange={(e, newValue) => onChange(newValue)}
                helperText={error ? error.message : ''}
                error={error}
                isLoadingData={isWorkOrderLoading}
                onClear={() => clearTextfields(setValue, name)}
                disabled={!selectedTechnician?.value}
              />
            )}
          />
          <Controller
            name="type"
            control={control}
            rules={{
              required: `${t('attributes.productCatalogue.type')} ${Validation.general.required}`,
            }}
            render={({
              field: { onChange, value, name },
              fieldState: { error },
            }) => (
              <Autocomplete
                label={t('attributes.productCatalogue.type')}
                options={TIME_SHEET_TYPE}
                value={value}
                onChange={(e, newValue) => onChange(newValue)}
                helperText={error ? error.message : ''}
                error={error}
                isLoadingData={false}
                onClear={() => clearTextfields(setValue, name)}
              />
            )}
          />
          <CustomDatePicker
            control={control}
            name="date"
            label={t('attributes.date')}
            pickerType="date"
            disabled={id}
            isRequired={true}
          />
          <Box sx={{ display: 'flex', columnGap: '16px' }}>
            <Controller
              name="start_time"
              control={control}
              rules={{
                required: `${t('common.start')} ${t('attributes.timesheet.time')} ${Validation.general.required}`,
                validate: {
                  timeRange: (value) => {
                    const endTime = getValues('end_time');
                    if (
                      value &&
                      endTime &&
                      moment(value).isAfter(moment(endTime)) &&
                      !firstSelect
                    ) {
                      return t('attributes.timesheet.startTimeValidationMsg');
                    }
                    if (
                      value &&
                      endTime &&
                      moment(value).isSame(moment(endTime), 'minute') &&
                      !firstSelect
                    ) {
                      return t(
                        'attributes.timesheet.startTimeSameValidationMsg'
                      );
                    }
                    return true;
                  },
                },
              }}
              render={({
                field: { onChange, value, name },
                fieldState: { error },
              }) => (
                <CustomTimePicker
                  name={name}
                  label={`${t('common.start')} ${t('attributes.timesheet.time')}`}
                  value={value}
                  onChange={(time) => {
                    !secondSelect && setFirstSelect(true);
                    if (moment(time).isAfter(moment(getValues('end_time')))) {
                      setError('start_time', {
                        message: t(
                          'attributes.timesheet.startTimeValidationMsg'
                        ),
                      });
                    } else if (
                      moment(time).isSame(
                        moment(getValues('end_time')),
                        'minute'
                      )
                    ) {
                      setError('start_time', {
                        message: t(
                          'attributes.timesheet.startTimeSameValidationMsg'
                        ),
                      });
                    } else {
                      setError('start_time', {});
                    }
                    setError('end_time', {});
                    onChange(time);
                  }}
                  onClear={() => {
                    setError('start_time', {});
                    setFirstSelect(false);
                  }}
                  error={error ? error?.message : ''}
                />
              )}
            />

            <Controller
              name="end_time"
              control={control}
              rules={{
                required: `${t('common.end')} ${t('attributes.timesheet.time')} ${Validation.general.required}`,
                validate: {
                  timeRange: (value) => {
                    const startTime = getValues('start_time');
                    if (
                      value &&
                      startTime &&
                      moment(value).isBefore(moment(startTime)) &&
                      !secondSelect
                    ) {
                      return t('attributes.timesheet.endTimeValidationMsg');
                    }
                    if (
                      value &&
                      startTime &&
                      moment(value).isSame(moment(startTime), 'minute') &&
                      !secondSelect
                    ) {
                      return t('attributes.timesheet.endTimeSameValidationMsg');
                    }
                    return true;
                  },
                },
              }}
              render={({
                field: { onChange, value, name },
                fieldState: { error },
              }) => (
                <CustomTimePicker
                  name={name}
                  label={`${t('common.end')} ${t('attributes.timesheet.time')}`}
                  value={value}
                  onChange={(time) => {
                    !firstSelect && setSecondSelect(true);
                    if (
                      moment(time).isBefore(moment(getValues('start_time')))
                    ) {
                      setError('end_time', {
                        message: t('attributes.timesheet.endTimeValidationMsg'),
                      });
                    } else if (
                      moment(time).isSame(getValues('start_time'), 'minute')
                    ) {
                      setError('end_time', {
                        message: t(
                          'attributes.timesheet.endTimeSameValidationMsg'
                        ),
                      });
                    } else {
                      setError('end_time', {});
                    }
                    setError('start_time', {});
                    onChange(time);
                  }}
                  onClear={() => {
                    setError('end_time', {});
                    setSecondSelect(false);
                  }}
                  error={error ? error?.message : ''}
                />
              )}
            />
          </Box>
          <Typography variant="body2">
            <b>{t('attributes.work_order.duration')}</b> : {calculateDuration}
          </Typography>
          <Controller
            name="description"
            control={control}
            render={({
              field: { onChange, value, name, onBlur },
              fieldState: { error },
            }) => (
              <CustomTextField
                label={t('attributes.description')}
                fullWidth
                value={value}
                onChange={(e) => {
                  onChange(e);
                  trigger('description');
                }}
                multiline
                rows={3}
                helperText={error ? error.message : ''}
                error={error}
                onClear={() => clearTextfields(setValue, name)}
                onBlur={() => {
                  onChange(value.trim());
                  onBlur();
                }}
                isRequired={false}
              />
            )}
          />
        </FormFieldWrapper>
      </CustomCard>
    </FormWrapper>
  );
};

export default TimeEntry;
