import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { COMMON_ERROR_MESSAGE } from '../constants/Constants';
import { snackbarToggle } from '../store/CommonReducer';

/**
 * Custom hook to handle server-side errors in forms managed by react-hook-form.
 */
const useServerSideErrors = (serverErrors, setError, clearErrors) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleServerErrors = useCallback(() => {
    if (serverErrors) {
      // Clear existing errors
      clearErrors();

      if (typeof serverErrors !== 'object') {
        dispatch(
          snackbarToggle({
            isOpen: true,
            isErrorMsg: true,
            msg: COMMON_ERROR_MESSAGE.default,
          })
        );
      }

      // Loop through server errors and set them in the form
      if (Array.isArray(serverErrors)) {
        serverErrors?.forEach((fieldError) => {
          setError(fieldError.field || 'EMPTY', {
            type: 'server',
            message: fieldError.message,
          });
        });
      }
    }
  }, [serverErrors, setError, clearErrors]);

  return { handleServerErrors };
};

export default useServerSideErrors;
