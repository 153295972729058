import { Navigate } from 'react-router-dom';

import { CUSTOMER } from '../constants/Roles';
import { ROUTENAME } from '../constants/RoutesConstants';
import { permissionRoute } from '../constants/SidebarMenu';
import { getUserRole } from './Permission';

const AuthRoute = ({ children, type }) => {
  let hasPermission = false;

  const loggedInUserRole = getUserRole();

  if (Array.isArray(type)) {
    hasPermission = type.some(
      (routeType) =>
        permissionRoute?.[loggedInUserRole]?.[routeType]?.isAccessible
    );
  } else {
    hasPermission = permissionRoute?.[loggedInUserRole]?.[type]?.isAccessible;
  }

  return hasPermission ? (
    children
  ) : (
    <Navigate
      to={
        loggedInUserRole === CUSTOMER
          ? `${ROUTENAME.DASHBOARD}/${ROUTENAME.PROPERTIES}`
          : ROUTENAME.DEFAULT_ROUTE
      }
    />
  );
};

export default AuthRoute;
