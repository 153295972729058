import { useMemo } from 'react';
import { useSelector } from 'react-redux';

const getDropdownListHook = ({
  reducerName = '',
  dropdownListName = '',
  labelName = '',
  valueName = '',
  loading = 'isLoading',
  customeNameForDropdown = null,
  fetchDataFrom = 'data',
  ...rest
}) => {
  // Get the category dropdown list from Redux store
  const stateDropdownList = useSelector((state) => {
    const dropdownList = state?.[reducerName]?.[dropdownListName];

    return (
      dropdownList?.data?.[fetchDataFrom] ||
      dropdownList?.[fetchDataFrom] ||
      dropdownList ||
      []
    );
  });

  // Get the loading state from Redux store
  const isLoading = useSelector(
    (state) => state?.[reducerName]?.[dropdownListName]?.[loading]
  );

  // Memoize the transformation of category data
  const data = useMemo(() => {
    if (!Array.isArray(stateDropdownList) || stateDropdownList?.length === 0) {
      return [];
    } else {
      return stateDropdownList?.map((item) => ({
        label: item[labelName],
        value: item[valueName],
        ...(rest
          ? Object.entries(rest).reduce((acc, [key, value]) => {
              acc[key] = item[value];

              return acc;
            }, {})
          : {}),
      }));
    }
  }, [stateDropdownList]);

  const customeName = customeNameForDropdown || reducerName;

  // Return both the loading status and the transformed data
  return {
    [`${customeName}DropdownLoading`]: isLoading,
    [`${customeName}DropdownData`]: data,
  };
};

export default getDropdownListHook;
