import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import { Box } from '@mui/material';
import debounce from 'lodash.debounce';

import { exportToCSV, validateAddress } from '../../constants/common';
import { TAB_STATUS } from '../../constants/Constants';
import {
  COLUMNS_TO_EXPORT,
  EXPORT_FILE_NAMES,
} from '../../constants/ExportConstant';
import getCityByStateHook from '../../hooks/getCityByStateHook';
import getStatesHook from '../../hooks/getStatesHook';
import useServerSideErrors from '../../hooks/useServerSideErrors';
import { snackbarToggle } from '../../store/CommonReducer';
import {
  createSupplier,
  getSupplierList,
  updateSupplier,
} from '../../store/supplier/api';
import {
  resetCreate,
  resetEdit,
  resetPagination,
} from '../../store/supplier/reducer';
import { formattedDate } from '../../utils';
import { Edit } from '../CommonComponents/ActionComponent';
import Autocomplete from '../CommonComponents/AutoComplete';
import CustomButton from '../CommonComponents/CustomButton';
import CustomDateRangePicker from '../CommonComponents/CustomDateRangePicker';
import CustomGridTable from '../CommonComponents/CustomGridTable';
import CustomCircularLoader from '../CommonComponents/CustomLoader';
import {
  FilterComponent,
  FilterSection,
} from '../CommonComponents/FilterComponent';
import { ConfirmationModal } from '../CommonComponents/Modal';
import NewNoDataPage from '../CommonComponents/NoDataPage/NewNoDataPage';
import NoRecordFound from '../CommonComponents/NoDataPage/NoRecordFound';
import StyledMainWrapper from '../CommonComponents/StyledMainWrapper';
import SwipeableDrawer from '../CommonComponents/SwipeableDrawer';
import TabView from '../CommonComponents/TabView';
import SupplierForm from './SupplierForm';

const defaultValues = {
  name: '',
  business_number: '',
  contact_name: '',
  email: '',
  phone: '',
  address: '',
  city: null,
  state: null,
  active: null,
  post_code: '',
};

const filterHeight = (isFilterOpen) => (isFilterOpen ? 359 : 290);

const Suppliers = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const columns = useMemo(() => {
    const baseColumns = [
      {
        headerName: `${t('attributes.supplier.supplier')} ${t('attributes.name')}`,
        field: 'name',
        sortable: false,
        flex: 1,
      },
      {
        headerName: t('attributes.supplier.business_number'),
        field: 'business_number',
        flex: 1,
        sortable: false,
      },
      {
        headerName: t('attributes.supplier.contact_name'),
        field: 'contact_name',
        flex: 1,
        sortable: false,
      },
      {
        headerName: t('attributes.email'),
        field: 'contact_email',
        flex: 1,
        sortable: false,
      },
      {
        headerName: t('attributes.supplier.contact_phone'),
        field: 'contact_phone',
        flex: 1,
        sortable: false,
      },
      {
        headerName: t('attributes.customer.created_at'),
        field: 'created_at',
        sortable: true,
        flex: 1,
        valueFormatter: (params) => (params ? formattedDate(params) : ''),
      },
      {
        field: 'edit',
        headerName: t('attributes.edit'),
        flex: 0.5,
        sortable: false,
        hideable: false,
        renderCell: ({ row }) => (
          <Edit
            onClick={() => {
              setOpen(true);
              setSupplierId(row.uuid);
            }}
          />
        ),
      },
    ];

    return baseColumns;
  }, []);

  // Redux state
  const {
    supplierActiveList,
    supplierInactiveList,
    size,
    page,
    total,
    totalCount,
  } = useSelector((state) => state.supplier.get);
  const {
    isLoading: createLoading,
    createdSupplier,
    error,
  } = useSelector((state) => state.supplier.create);

  // Form state and methods from react-hook-form
  const {
    handleSubmit,
    control,
    reset,
    setValue,
    setError,
    clearErrors,
    watch,
    trigger,
  } = useForm({
    defaultValues: defaultValues,
  });

  // Local state
  const [currentPage, setCurrentPage] = useState(page);
  const [perPageData, setPerPageData] = useState(size);
  const [searchText, setSearchText] = useState('');
  const [filters, setFilters] = useState({ city: null, state: null });
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('created_at');
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const [supplierId, setSupplierId] = useState('');
  const [serverErrors, setServerErrors] = useState([]);
  const [isFilterComponentVisible, setIsFilterComponentVisible] =
    useState(false);
  const [open, setOpen] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({});
  const [hasFormValues, sethasFormValues] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [selectedRows, setSelectedRows] = useState(null);
  // Custom hook
  const cityList = getCityByStateHook(filters.state?.value);
  const stateList = getStatesHook();
  const { handleServerErrors } = useServerSideErrors(
    serverErrors,
    setError,
    clearErrors
  );

  const watchedFields = watch();

  useEffect(() => {
    sethasFormValues(Object.values(watchedFields).some((value) => value));
  }, [watchedFields]);

  // Function to fetch data based on search, pagination, and filter
  const getSupplier = useCallback(() => {
    setSelectedRows(null);
    // Set loading state based on active tab
    const visibleFieldsString = columns
      .filter((col) => columnVisibilityModel[col.field] !== false)
      .map((col) => col.field)
      .join(',');

    setIsDataLoading(true);
    dispatch(
      getSupplierList({
        page: currentPage,
        size: perPageData,
        search: searchText,
        active: activeTab === 0 ? TAB_STATUS.active : TAB_STATUS.inactive,
        country: filters.country?.value,
        state: filters.state?.value,
        city: filters.city?.value,
        orderBy: orderBy,
        order: order,
        fromDate: fromDate,
        toDate: toDate,
        list_column_names: visibleFieldsString,
      })
    ).finally(() => {
      setIsDataLoading(false);
      setIsInitialLoading(false);
    });
  }, [
    dispatch,
    currentPage,
    perPageData,
    searchText,
    activeTab,
    filters,
    order,
    orderBy,
    fromDate,
    toDate,
    columnVisibilityModel,
    refresh,
  ]);

  useEffect(
    () => () => {
      dispatch(resetCreate());
      dispatch(resetEdit());
      dispatch(resetPagination());
    },
    []
  );

  // Reset pagination on page size, filters, or searchText change
  useEffect(() => {
    setCurrentPage(1);
  }, [perPageData, filters, searchText, fromDate, toDate]);

  // Debounced function to avoid frequent API calls
  const debouncedFetchData = useCallback(debounce(getSupplier, 500), [
    getSupplier,
  ]);

  // Call debouncedFetchData whenever search, page, or filter changes
  useEffect(() => {
    debouncedFetchData();

    // Clean up function for debounce to avoid memory leaks
    return () => {
      debouncedFetchData.cancel();
    };
  }, [debouncedFetchData]);

  useEffect(() => {
    if (createLoading !== null && !createLoading) {
      if (error) {
        if (error.errorDetails) {
          setServerErrors(error.errorDetails);
        }
      } else {
        if (createdSupplier) {
          setOpen(false);
          if (!supplierId) setActiveTab(0);
          debouncedFetchData();
          dispatch(
            snackbarToggle({
              isOpen: true,
              isErrorMsg: false,
              msg: !supplierId
                ? t('message.supplier.createdSuccessfully')
                : t('message.supplier.updatedSuccessfully'),
            })
          );
        }
      }
    }
  }, [error, createLoading]);

  const rows =
    activeTab === 0
      ? supplierActiveList?.map((cl) => ({
          ...cl,
          id: cl.id,
          uuid: cl.uuid,
          name: cl.name,
          business_number: cl.business_number,
          contact_name: cl.contact_name,
          contact_email: cl.contact_email,
          contact_phone: cl.contact_phone,
          created_at: cl.created_at,
        }))
      : supplierInactiveList?.map((cl) => ({
          ...cl,
          id: cl.uuid,
          uuid: cl.uuid,
          name: cl.name,
          business_number: cl.business_number,
          contact_name: cl.contact_name,
          contact_email: cl.contact_email,
          contact_phone: cl.contact_phone,
          created_at: cl.created_at,
        }));

  useEffect(() => {
    if (serverErrors?.length > 0) {
      handleServerErrors(); // Call the function to set the server-side errors in the form
    }
  }, [serverErrors, handleServerErrors]);

  const onSubmit = async (data) => {
    const hasError = await validateAddress({
      clearErrors,
      address: data.address,
      city: data.city,
      state: data.state,
      postcode: data.post_code,
      fieldName: {
        addressKey: 'address',
        addressValue: t('attributes.address'),
        stateKey: 'state',
        stateValue: t('common.state'),
        cityKey: 'city',
        cityValue: t('common.city'),
        postCodeKey: 'post_code',
        postCodeValue: t('attributes.postCode'),
      },
      setServerErrors: setServerErrors,
    });

    if (hasError) return;

    const createdSupplierData = {
      name: data.name,
      business_number: data.business_number,
      contact_name: data.contact_name,
      contact_email: data.contact_email,
      contact_phone: data.phone,
      address: data.address,
      city: data.city.value,
      state: data.state.value,
      postcode: data.post_code,
      status: supplierId
        ? data.active
          ? TAB_STATUS.active
          : TAB_STATUS.inactive
        : TAB_STATUS.active,
    };

    if (!supplierId) {
      dispatch(createSupplier(createdSupplierData));
    } else {
      dispatch(updateSupplier({ uuid: data?.uuid, data: createdSupplierData }));
    }
  };

  const handleSelectionChange = (newSelection) => {
    setSelectedRows(rows?.filter((row) => newSelection.includes(row.id)));
  };

  const resetFilter = () => {
    setFilters({ state: null, city: null });
    setSearchText('');
    setFromDate(null);
    setToDate(null);
  };

  const onTabChange = () => {
    setIsDataLoading(true);
    setFilters({ state: null, city: null });
    setSearchText('');
    setIsFilterComponentVisible(false);
  };

  const noData = (
    <NewNoDataPage
      icon={<LocalShippingOutlinedIcon />}
      title={t('attributes.supplier.suppliers')}
      singularText={t('attributes.supplier.supplier')}
      filterHeight={filterHeight(isFilterComponentVisible)}
      createBtnText={`${t('attributes.add')} ${t('attributes.supplier.supplier')}`}
    />
  );
  const renderedComponent = (
    <>
      <FilterSection
        onFilterBtnClick={() =>
          setIsFilterComponentVisible(!isFilterComponentVisible)
        }
        onRefreshFilter={() => setRefresh(!refresh)}
        isRefresh={true}
        searchText={searchText}
        isActive={isFilterComponentVisible}
        onResetFilter={resetFilter}
        onSearchChange={(e) => setSearchText(e.target.value)}
        isResetButtonVisible={
          searchText || filters.state || filters.city || fromDate || toDate
        }
      />
      {isFilterComponentVisible && (
        <FilterComponent>
          <Autocomplete
            placeholder={t('common.state')}
            options={stateList}
            width="190px"
            value={filters?.state}
            onChange={(e, newVal) => setFilters({ city: null, state: newVal })}
          />
          <Autocomplete
            placeholder={t('common.city')}
            options={cityList}
            width="190px"
            value={filters?.city}
            onChange={(e, newVal) =>
              setFilters((prev) => ({ ...prev, city: newVal }))
            }
            // disabledDropdown={!filters.state}
          />
          <CustomDateRangePicker
            onOkClick={(val) => {
              setFromDate(val[0]);
              setToDate(val[1]);
            }}
            onClear={() => {
              setFromDate(null);
              setToDate(null);
            }}
            fromDate={fromDate}
            toDate={toDate}
          />
        </FilterComponent>
      )}
      <CustomGridTable
        columns={columns}
        rows={rows}
        total={total}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        perPageData={perPageData}
        setPerPageData={setPerPageData}
        order={order}
        orderBy={orderBy}
        setOrder={setOrder}
        setOrderBy={setOrderBy}
        columnVisibilityModel={columnVisibilityModel}
        onRowSelectionModelChange={handleSelectionChange}
        setColumnVisibilityModel={setColumnVisibilityModel}
        noData={<NoRecordFound />}
        isLoading={isDataLoading}
        filterHeight={filterHeight(isFilterComponentVisible)}
      />
    </>
  );

  return (
    <>
      {openConfirmationModal && (
        <ConfirmationModal
          title={t('confirmationModal.title')}
          description={t('confirmationModal.description')}
          open={openConfirmationModal}
          setOpen={setOpenConfirmationModal}
          onConfirm={() => {
            setOpen(false);
            setOpenConfirmationModal(false);
          }}
        />
      )}
      <StyledMainWrapper
        title={t('attributes.supplier.suppliers')}
        btn={
          <>
            <CustomButton
              text={`${t('attributes.add')} ${t('attributes.supplier.supplier')}`}
              color="secondary"
              sx={{ height: '52%' }}
              startIcon={<AddCircleOutlineOutlinedIcon />}
              onClick={() => {
                setOpen(true);
                setSupplierId('');
                reset(defaultValues);
              }}
            />
            <CustomButton
              text={t('attributes.export')}
              color="secondary"
              sx={{ height: '52%' }}
              startIcon={<OpenInNewIcon />}
              onClick={() => {
                if (!selectedRows || selectedRows?.length === 0) {
                  dispatch(
                    snackbarToggle({
                      isOpen: true,
                      isErrorMsg: true,
                      msg: t('message.common.noRecordExportMessage'),
                    })
                  );
                  setSelectedRows(null);
                } else {
                  exportToCSV(
                    selectedRows,
                    COLUMNS_TO_EXPORT.SUPPLIER,
                    EXPORT_FILE_NAMES.SUPPLIER
                  );
                }
              }}
            />
          </>
        }
      >
        {!isDataLoading && totalCount === 0 && noData}
        {isInitialLoading ? (
          <Box
            sx={{
              width: '100%',
              mt: 2,
              minHeight: `calc(100vh - ${filterHeight(isFilterComponentVisible)}px)`,
              maxHeight: `calc(100vh - ${filterHeight(isFilterComponentVisible)}px)`,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <CustomCircularLoader />
          </Box>
        ) : (
          !isInitialLoading &&
          totalCount > 0 && (
            <TabView
              tabs={[
                {
                  label: t('common.active'),
                  component: renderedComponent,
                },
                {
                  label: t('common.inactive'),
                  component: renderedComponent,
                },
              ]}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              onTabChange={onTabChange}
            />
          )
        )}
      </StyledMainWrapper>

      {open && (
        <SwipeableDrawer
          open={open}
          title={
            !supplierId
              ? `${t('attributes.add')} ${t('attributes.supplier.supplier')}`
              : `${t('attributes.edit')} ${t('attributes.supplier.supplier')}`
          }
          onClose={() => {
            hasFormValues ? setOpenConfirmationModal(true) : setOpen(false);
          }}
          footerButton={
            <CustomButton
              text={createLoading ? t('common.loading') : t('attributes.save')}
              startIcon={<SaveOutlinedIcon />}
              onClick={handleSubmit(onSubmit)}
              disabled={createLoading}
            />
          }
        >
          <SupplierForm
            id={supplierId}
            control={control}
            reset={reset}
            setValue={setValue}
            watch={watch}
            trigger={trigger}
            clearErrors={clearErrors}
            setServerErrors={setServerErrors}
          />
        </SwipeableDrawer>
      )}
    </>
  );
};

export default Suppliers;
