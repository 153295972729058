import { createSlice } from '@reduxjs/toolkit';

import { getLocation, getMe, uploadDocument } from './CommonAPI';

const commonSlice = createSlice({
  name: 'common',
  initialState: {
    snackbarOpen: false,
    snackbarMessage: '',
    isErrorMsg: false,
    stateCityList: {},
    isUnAuthorised: false,
    isAuthLoading: false,
    isDocumentLoading: false,
    uploadedSuccessfully: false,
    uploadError: null,
    profileDetails: null,
    silentTokenSuccess: {
      sessionExpired: false,
      isSsoSuccess: false,
    },
  },
  reducers: {
    snackbarToggle: (state, action) => {
      state.snackbarOpen = action.payload.isOpen;
      state.snackbarMessage = action.payload.msg;
      state.isErrorMsg = action.payload.isErrorMsg;
    },
    isSsoSilentTokenAquired: (state, action) => {
      state.silentTokenSuccess = {
        sessionExpired: action.payload.sessionExpired,
        isSsoSuccess: action.payload.silentTokenSuccess,
      };
    },
    resetDocumentData(state) {
      state.documentData = null;
      state.isDocumentLoading = false;
      state.uploadedSuccessfully = false;
      state.uploadError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLocation.fulfilled, (state, action) => {
        const stateCityList = action.payload.data;

        state.stateCityList = stateCityList[0];
      })
      .addCase(getMe.pending, (state) => {
        state.isAuthLoading = true;
        state.isUnAuthorised = false;
      })
      .addCase(getMe.fulfilled, (state, action) => {
        const data = action?.payload;

        if (data?.portals?.some((item) => item.field_name === 'web')) {
          state.profileDetails = data;
          state.isAuthLoading = false;
          state.isAuthSuccess = true;
          state.silentTokenSuccess = {
            sessionExpired: false,
            isSsoSuccess: true,
          };
        } else {
          state.isAuthSuccess = false;
          state.isAuthLoading = false;
          state.snackbarOpen = true;
          state.snackbarMessage =
            'Sorry, you are not allowed to login to web portal, Please login to mobile app.';
          state.isErrorMsg = true;
          state.isUnAuthorised = true;
          state.silentTokenSuccess = {
            sessionExpired: false,
            isSsoSuccess: true,
          };
        }
      })
      .addCase(getMe.rejected, (state, action) => {
        state.isAuthSuccess = false;
        state.isAuthLoading = false;
        state.snackbarOpen = true;
        state.snackbarMessage = action?.error?.message;
        state.isErrorMsg = true;
        state.isUnAuthorised = true;
        state.silentTokenSuccess = {
          sessionExpired: true,
          isSsoSuccess: false,
        };
      })
      .addCase(uploadDocument.pending, (state) => {
        state.isDocumentLoading = true;
      })
      .addCase(uploadDocument.fulfilled, (state, action) => {
        state.isDocumentLoading = false;
        state.uploadedSuccessfully = true;
        state.documentData = action.payload?.data[0];
      })
      .addCase(uploadDocument.rejected, (state, action) => {
        state.uploadError = action.payload;
      });
  },
});

export default commonSlice.reducer;
export const { snackbarToggle, resetDocumentData, isSsoSilentTokenAquired } =
  commonSlice.actions; // Export the action
