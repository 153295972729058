import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { Box, Chip, Divider, Stack, styled, Typography } from '@mui/material';
import { toNumber } from 'lodash';

import { DIVIDER_COLOR } from '../../constants/Colors';
import { SQFT_TO_SQM } from '../../constants/common';
import { PROPERTY_DETAIL_STEP } from '../../constants/Constants';
import { resetUpdateInvoice } from '../../store/invoices/reducer';
import { formattedDate } from '../../utils';
import CustomCircularLoader from '../CommonComponents/CustomLoader';
import CustomGoogleMap from '../CommonComponents/GoogleMap';
import MainWrapper from '../CommonComponents/MainWrapper';
import StatusLabel from '../CommonComponents/StatusLabel';
import TabView from '../CommonComponents/TabView';
import BillingContract from '../Users/BillingContracts/BillingContracts';
import Invoices from '../WorkOrder/Invoices/Invoices';
import AssetsList from './AssetsList';
import RoutineList from './RoutineList';

const StyledHeaderBox = styled(Box)(({ value }) => ({
  padding: '7px 16px',
  backgroundColor: value ? 'rgba(255, 255, 255, 1)' : 'rgb(235, 235, 235, 0.2)',
  display: 'flex',
  justifyContent: 'space-between',
}));

const StyledContentBox = styled(Box)(() => ({
  display: 'flex',
  gap: '24px',
  padding: '16px 0 16px 16px',
  width: '50%',
}));

const StyledColumnBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
}));

const KeyTypography = styled(Typography)(({ theme }) => ({}));
const ValueTypography = styled(Typography)(({ theme }) => ({}));

const tabHashMap = {
  0: '', // Default tab
  1: '#assets',
  2: '#routines',
  3: '#billing-contracts',
  4: '#invoices',
  5: '#coming-soon',
};

const PropertyDetails = ({
  propertyId,
  selectedProperty,
  propertyDataloading,
  propertyNumId,
  handleEditPropertyForm,
  isEditable = true,
  handleDefaultStep,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { hash } = useLocation();
  const navigate = useNavigate();

  const initialTabIndex =
    Object.keys(tabHashMap).find((key) => hash === tabHashMap[key]) || 0;

  const [activeTab, setActiveTab] = useState(initialTabIndex);
  const [nextStepTitle, setNextStepTitle] = useState();

  const formatStatus = (status) => {
    if (status === 'active') {
      return <StatusLabel label="Active" color="#95C020" />;
    }
    if (status === 'inactive') {
      return <StatusLabel label="In Active" color="#C54036" />;
    }
    if (status === 'hold') {
      return <StatusLabel label="Hold" color="#FD7E14" />;
    }
    if (status === 'setup') {
      return <StatusLabel label="Setup" color="#007BFF" />;
    }
  };

  const markers = [
    {
      id: 1,
      lat: toNumber(selectedProperty?.latitude) ?? '',
      lng: toNumber(selectedProperty?.longitude) ?? '',
      tooltipText: selectedProperty?.address,
      color: '#395B50',
    },
  ];

  const center = {
    lat: toNumber(selectedProperty?.latitude) ?? '',
    lng: toNumber(selectedProperty?.longitude) ?? '',
  };

  const renderPropertyDetails = propertyDataloading ? (
    <Box sx={{ paddingTop: '180px' }}>
      <CustomCircularLoader />
    </Box>
  ) : (
    <Box sx={{ overflowY: 'auto', height: 'calc(100vh - 170px)' }}>
      <StyledHeaderBox>
        <Typography variant="body1">
          {t('attributes.property.propertyDetails')}
        </Typography>
        {isEditable && (
          <ModeEditOutlineOutlinedIcon
            onClick={() => handleEditPropertyForm(0)}
          />
        )}
      </StyledHeaderBox>
      <Divider />
      {/* Details */}
      <Box sx={{ display: 'flex', width: '100%' }}>
        {/* Left Side Content */}
        <StyledContentBox sx={{ width: '100%' }}>
          <StyledColumnBox sx={{ gap: 1, width: '100%' }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <KeyTypography
                minWidth="140px"
                variant="body1"
              >{`${t('attributes.property.propertyId')}:`}</KeyTypography>
              <ValueTypography variant="body2">
                {selectedProperty?.pid}
              </ValueTypography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <KeyTypography minWidth="140px" variant="body1">
                {t('attributes.property.propertyName')}
              </KeyTypography>
              <ValueTypography variant="body2">
                {selectedProperty?.property_name}
              </ValueTypography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'start' }}>
              <KeyTypography minWidth="140px" variant="body1">
                {t('attributes.address')}
              </KeyTypography>
              <Box>
                <ValueTypography variant="body2">
                  {`${selectedProperty?.address}, ${selectedProperty?.state}, ${selectedProperty?.city}, ${selectedProperty?.zip_code}`}
                </ValueTypography>
                <Box sx={{ height: '100px', width: '200px' }}>
                  <CustomGoogleMap
                    isDashboard={false}
                    markers={markers}
                    center={center}
                  />
                </Box>
              </Box>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <KeyTypography minWidth="140px" variant="body1">
                {t('attributes.property.landLotNumber')}
              </KeyTypography>
              <ValueTypography variant="body2">
                {selectedProperty?.lot_number ?? '-'}
              </ValueTypography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <KeyTypography minWidth="140px" variant="body1">
                {t('attributes.property.status')}
              </KeyTypography>
              <ValueTypography variant="body2">
                {formatStatus(selectedProperty?.property_status?.name)}
              </ValueTypography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <KeyTypography
                minWidth="140px"
                variant="body1"
              >{`${t('attributes.property.tags')}:`}</KeyTypography>
              <ValueTypography variant="body2">
                <Stack direction="row" spacing={1} sx={{ height: '20px' }}>
                  {selectedProperty?.property_tags?.map((item) => (
                    <Chip
                      key={item.id}
                      label={item.tags?.display_name}
                      sx={{ height: '20px' }}
                    />
                  )) ?? '-'}
                </Stack>
              </ValueTypography>
            </Box>
          </StyledColumnBox>
        </StyledContentBox>

        {/* Center Divider */}
        <Divider orientation="vertical" flexItem />

        {/* Right Side Content */}
        <StyledContentBox sx={{ width: '100%' }}>
          <StyledColumnBox sx={{ gap: 1, width: '100%' }}>
            <Box sx={{ display: 'flex' }}>
              <KeyTypography
                minWidth="200px"
                variant="body1"
              >{`${t('attributes.property.customer')}:`}</KeyTypography>
              <ValueTypography variant="body2">
                {selectedProperty?.customer ?? '-'}
              </ValueTypography>
            </Box>
            <Box sx={{ display: 'flex' }}>
              <KeyTypography
                minWidth="200px"
                variant="body1"
              >{`${t('attributes.property.billingCard')}:`}</KeyTypography>
              <ValueTypography variant="body2">
                {selectedProperty?.billing_card_details?.billing_name ?? '-'}
              </ValueTypography>
            </Box>
            <Box sx={{ display: 'flex' }}>
              <KeyTypography
                minWidth="200px"
                variant="body1"
              >{`${t('attributes.property.parentProperty')}:`}</KeyTypography>
              <ValueTypography variant="body2">
                {selectedProperty?.property_parent?.display_name ?? '-'}
              </ValueTypography>
            </Box>
            <Box sx={{ display: 'flex' }}>
              <KeyTypography
                minWidth="200px"
                variant="body1"
              >{`${t('attributes.property.ocspNumber')}:`}</KeyTypography>
              <ValueTypography variant="body2">
                {selectedProperty?.ocsp_number ?? '-'}
              </ValueTypography>
            </Box>
            <Box sx={{ display: 'flex' }}>
              <KeyTypography
                minWidth="200px"
                variant="body1"
              >{`${t('attributes.property.organization')}:`}</KeyTypography>
              <ValueTypography variant="body2">
                {selectedProperty?.organization ?? '-'}
              </ValueTypography>
            </Box>
            <Box sx={{ display: 'flex' }}>
              <KeyTypography
                minWidth="200px"
                variant="body1"
              >{`${t('attributes.property.agmNewData')}:`}</KeyTypography>
              <ValueTypography variant="body2">
                {formattedDate(selectedProperty?.agm_date) ?? '-'}
              </ValueTypography>
            </Box>
            <Box sx={{ display: 'flex' }}>
              <KeyTypography
                minWidth="200px"
                variant="body1"
              >{`${t('attributes.property.reviewNewDate')}:`}</KeyTypography>
              <ValueTypography variant="body2">
                {formattedDate(selectedProperty?.review_date) ?? '-'}
              </ValueTypography>
            </Box>
            <Box sx={{ display: 'flex' }}>
              <KeyTypography
                minWidth="200px"
                variant="body1"
              >{`${t('attributes.property.localGovArea')}:`}</KeyTypography>
              <ValueTypography variant="body2">
                {selectedProperty?.property_local_government_area
                  ?.display_name ?? '-'}
              </ValueTypography>
            </Box>
            <Box sx={{ display: 'flex' }}>
              <KeyTypography
                minWidth="200px"
                variant="body1"
              >{`${t('attributes.property.internalNote')}:`}</KeyTypography>
              <ValueTypography variant="body2">
                {selectedProperty?.internal_note ?? '-'}
              </ValueTypography>
            </Box>
          </StyledColumnBox>
        </StyledContentBox>
      </Box>
      <Divider />
      {/* Contact section */}
      <StyledHeaderBox value={true}>
        <Typography variant="body1">
          {t('attributes.property.contractAndAssignmentDetails')}
        </Typography>
      </StyledHeaderBox>
      <Divider />
      <Box sx={{ display: 'flex', width: '100%' }}>
        <StyledContentBox>
          <StyledColumnBox>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <KeyTypography
                minWidth="200px"
                variant="body1"
              >{`${t('attributes.property.annualComplianceDate')}:`}</KeyTypography>
              <ValueTypography variant="body2">
                {formattedDate(
                  selectedProperty?.contract_assignment_details
                    ?.annual_compliance_date
                ) ?? '-'}
              </ValueTypography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <KeyTypography
                minWidth="200px"
                variant="body1"
              >{`${t('attributes.property.contractStartDate')}:`}</KeyTypography>
              <ValueTypography variant="body2">
                {formattedDate(
                  selectedProperty?.contract_assignment_details
                    ?.contract_start_date
                ) ?? '-'}
              </ValueTypography>
            </Box>
          </StyledColumnBox>
        </StyledContentBox>

        <StyledContentBox>
          <StyledColumnBox>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <KeyTypography
                minWidth="160px"
                variant="body1"
              >{`${t('attributes.property.contractEndDate')}:`}</KeyTypography>
              <ValueTypography variant="body2">
                {formattedDate(
                  selectedProperty?.contract_assignment_details
                    ?.contract_expiry_date
                ) ?? '-'}
              </ValueTypography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <KeyTypography
                minWidth="160px"
                variant="body1"
              >{`${t('attributes.property.defaultTechnician')}:`}</KeyTypography>
              <ValueTypography variant="body2">
                {selectedProperty?.contract_assignment_details?.technician ??
                  '-'}
              </ValueTypography>
            </Box>
          </StyledColumnBox>
        </StyledContentBox>
      </Box>

      <Divider />

      <StyledHeaderBox>
        <Typography variant="body1">
          {t('attributes.property.propertyManager')}
        </Typography>
        {isEditable && (
          <ModeEditOutlineOutlinedIcon
            onClick={() => handleEditPropertyForm(0)}
          />
        )}
      </StyledHeaderBox>
      <Divider />
      <Box sx={{ display: 'flex', width: '100%' }}>
        <StyledContentBox>
          <StyledColumnBox>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <KeyTypography
                minWidth="151px"
                variant="body1"
              >{`${t('attributes.property.contact')} ${t('attributes.name')}:`}</KeyTypography>
              <ValueTypography variant="body2">
                {selectedProperty?.property_contact_info?.name ?? '-'}
              </ValueTypography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <KeyTypography
                minWidth="151px"
                variant="body1"
              >{`${t('attributes.property.contact')} ${t('attributes.phone')}:`}</KeyTypography>
              <ValueTypography variant="body2">
                {selectedProperty?.property_contact_info?.phone ?? '-'}
              </ValueTypography>
            </Box>
          </StyledColumnBox>
        </StyledContentBox>
        <StyledContentBox>
          <StyledColumnBox>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <KeyTypography
                minWidth="151px"
                variant="body1"
              >{`${t('attributes.property.contact')} ${t('attributes.email')}:`}</KeyTypography>
              <ValueTypography variant="body2">
                {selectedProperty?.property_contact_info?.email ?? '-'}
              </ValueTypography>
            </Box>
          </StyledColumnBox>
        </StyledContentBox>
      </Box>
      <Divider />
      {/* Access Details */}
      <StyledHeaderBox>
        <Typography variant="body1">
          {t('attributes.property.accessDetails')}
        </Typography>
        {isEditable && (
          <ModeEditOutlineOutlinedIcon
            onClick={() => handleEditPropertyForm(1)}
          />
        )}
      </StyledHeaderBox>

      <Divider />

      <Box sx={{ display: 'flex', width: '100%' }}>
        <StyledContentBox>
          <StyledColumnBox>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <KeyTypography minWidth="160px" variant="body1">
                {t('attributes.property.accessSchedule')}:
              </KeyTypography>
              <ValueTypography variant="body2">
                {selectedProperty?.access_details[0]?.access_detail_schedule?.map(
                  (item) => (
                    <Chip
                      key={item.id}
                      label={item.schedules?.display_name}
                      sx={{ height: '20px' }}
                    />
                  )
                ) ?? '-'}
              </ValueTypography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <KeyTypography minWidth="160px" variant="body1">
                {t('attributes.property.accessProcedure')}:
              </KeyTypography>
              <ValueTypography variant="body2">
                {selectedProperty?.access_details[0]?.access_detail_procedure?.map(
                  (item) => (
                    <Chip
                      key={item.id}
                      label={item.procedures?.display_name}
                      sx={{ height: '20px' }}
                    />
                  )
                ) ?? '-'}
              </ValueTypography>
            </Box>
          </StyledColumnBox>
        </StyledContentBox>

        <StyledContentBox>
          <StyledColumnBox>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <KeyTypography minWidth="117px" variant="body1">
                {t('attributes.property.accessCode')}:
              </KeyTypography>
              <ValueTypography variant="body2">
                {selectedProperty?.access_details[0]?.access_code ?? '-'}
              </ValueTypography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <KeyTypography minWidth="117px" variant="body1">
                {t('attributes.property.accessNote')}:
              </KeyTypography>
              <ValueTypography variant="body2">
                {selectedProperty?.access_details[0]?.access_note ?? '-'}
              </ValueTypography>
            </Box>
          </StyledColumnBox>
        </StyledContentBox>
      </Box>

      <Divider />

      {/* Site Requirements */}
      <StyledHeaderBox>
        <Typography variant="body1">
          {t('attributes.property.siteRequirement')}
        </Typography>
        {isEditable && (
          <ModeEditOutlineOutlinedIcon
            onClick={() => handleEditPropertyForm(2)}
          />
        )}
      </StyledHeaderBox>

      <Divider />

      <Box sx={{ display: 'flex', width: '100%' }}>
        <StyledContentBox>
          <StyledColumnBox>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <KeyTypography minWidth="320px" variant="body1">
                {t('attributes.property.signatureRequired')}
              </KeyTypography>
              <ValueTypography variant="body2">
                {selectedProperty?.site_requirements[0]?.signature_required ? (
                  <Chip
                    label="True"
                    sx={{
                      height: '20px',
                      backgroundColor: '#95C020',
                      color: 'white',
                    }}
                  />
                ) : (
                  <Chip
                    label="False"
                    sx={{
                      height: '20px',
                      backgroundColor: '#C54036',
                      color: 'white',
                    }}
                  />
                )}
              </ValueTypography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <KeyTypography minWidth="320px" variant="body1">
                {t('attributes.property.inductionRequired')}
              </KeyTypography>
              <ValueTypography variant="body2">
                {selectedProperty?.site_requirements[0]?.induction_required ? (
                  <Chip
                    label="True"
                    sx={{
                      height: '20px',
                      backgroundColor: '#95C020',
                      color: 'white',
                    }}
                  />
                ) : (
                  <Chip
                    label="False"
                    sx={{
                      height: '20px',
                      backgroundColor: '#C54036',
                      color: 'white',
                    }}
                  />
                )}
              </ValueTypography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <KeyTypography minWidth="320px" variant="body1">
                {t('attributes.property.logBookEntryRequired')}
              </KeyTypography>
              <ValueTypography variant="body2">
                {selectedProperty?.site_requirements[0]
                  ?.log_book_entry_required ? (
                  <Chip
                    label="True"
                    sx={{
                      height: '20px',
                      backgroundColor: '#95C020',
                      color: 'white',
                    }}
                  />
                ) : (
                  <Chip
                    label="False"
                    sx={{
                      height: '20px',
                      backgroundColor: '#C54036',
                      color: 'white',
                    }}
                  />
                )}
              </ValueTypography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <KeyTypography minWidth="320px" variant="body1">
                {t('attributes.property.policeCheckRequired')}
              </KeyTypography>
              <ValueTypography variant="body2">
                {selectedProperty?.site_requirements[0]
                  ?.police_check_required ? (
                  <Chip
                    label="True"
                    sx={{
                      height: '20px',
                      backgroundColor: '#95C020',
                      color: 'white',
                    }}
                  />
                ) : (
                  <Chip
                    label="False"
                    sx={{
                      height: '20px',
                      backgroundColor: '#C54036',
                      color: 'white',
                    }}
                  />
                )}
              </ValueTypography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <KeyTypography minWidth="320px" variant="body1">
                {`${t('attributes.property.isBooking')} ${t('attributes.required')}`}
              </KeyTypography>
              <ValueTypography variant="body2">
                {selectedProperty?.is_booking_required ? (
                  <Chip
                    label="True"
                    sx={{
                      height: '20px',
                      backgroundColor: '#95C020',
                      color: 'white',
                    }}
                  />
                ) : (
                  <Chip
                    label="False"
                    sx={{
                      height: '20px',
                      backgroundColor: '#C54036',
                      color: 'white',
                    }}
                  />
                )}
              </ValueTypography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <KeyTypography minWidth="320px" variant="body1">
                {t('attributes.property.workingWithChildrenCheckRequired')}
              </KeyTypography>
              <ValueTypography variant="body2">
                {selectedProperty?.site_requirements[0]
                  ?.working_with_children_check_required ? (
                  <Chip
                    label="True"
                    sx={{
                      height: '20px',
                      backgroundColor: '#95C020',
                      color: 'white',
                    }}
                  />
                ) : (
                  <Chip
                    label="False"
                    sx={{
                      height: '20px',
                      backgroundColor: '#C54036',
                      color: 'white',
                    }}
                  />
                )}
              </ValueTypography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <KeyTypography minWidth="320px" variant="body1">
                {t('attributes.property.serviceRequirements')}:
              </KeyTypography>
              <ValueTypography variant="body2">
                {selectedProperty?.site_requirements[0]?.service_requirements ??
                  '-'}
              </ValueTypography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <KeyTypography minWidth="320px" variant="body1">
                {t('attributes.property.safetyRequirements')}:
              </KeyTypography>
              <ValueTypography variant="body2">
                {selectedProperty?.site_requirements[0]
                  ?.technician_requirements ?? '-'}
              </ValueTypography>
            </Box>
          </StyledColumnBox>
        </StyledContentBox>
      </Box>

      <Divider />

      {/* Building Attributes */}
      <StyledHeaderBox>
        <Typography variant="body1">
          {t('attributes.property.buildingAttributes')}
        </Typography>
        {isEditable && (
          <ModeEditOutlineOutlinedIcon
            onClick={() => handleEditPropertyForm(3)}
          />
        )}
      </StyledHeaderBox>

      <Divider />

      <Box sx={{ display: 'flex', width: '100%' }}>
        <StyledContentBox>
          <StyledColumnBox>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <KeyTypography minWidth="250px" variant="body1">
                {t('attributes.property.annualDueCertificateDate')}
              </KeyTypography>
              <ValueTypography variant="body2">
                {formattedDate(
                  selectedProperty?.building_attributes[0]
                    ?.annual_due_certification_date
                ) ?? '-'}
              </ValueTypography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <KeyTypography minWidth="250px" variant="body1">
                {t('attributes.property.buildingEra')}
              </KeyTypography>
              <ValueTypography variant="body2">
                {selectedProperty?.building_attributes[0]?.property_building_era
                  ?.display_name ?? '-'}
              </ValueTypography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <KeyTypography minWidth="250px" variant="body1">
                {t('attributes.property.buildingClass')}
              </KeyTypography>
              <ValueTypography variant="body2">
                {selectedProperty?.building_attributes[0]
                  ?.property_building_class?.display_name ?? '-'}
              </ValueTypography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <KeyTypography minWidth="250px" variant="body1">
                {t('attributes.property.buildingType')}
              </KeyTypography>
              <ValueTypography variant="body2">
                {selectedProperty?.building_attributes[0]
                  ?.property_building_type?.display_name ?? '-'}
              </ValueTypography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <KeyTypography minWidth="250px" variant="body1">
                {t('attributes.property.buildingSize')}
              </KeyTypography>
              <ValueTypography variant="body2">
                {selectedProperty?.building_attributes[0]?.building_size ?? '-'}
              </ValueTypography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <KeyTypography minWidth="250px" variant="body1">
                {t('attributes.property.buildingSizeSqm')}
              </KeyTypography>
              <ValueTypography variant="body2">
                {SQFT_TO_SQM(
                  selectedProperty?.building_attributes[0]?.building_size
                ) ?? '-'}
              </ValueTypography>
            </Box>
          </StyledColumnBox>
        </StyledContentBox>

        <StyledContentBox>
          <StyledColumnBox>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <KeyTypography minWidth="187px" variant="body1">
                {t('attributes.property.storyAboveGround')}
              </KeyTypography>
              <ValueTypography variant="body2">
                {selectedProperty?.building_attributes[0]
                  ?.stories_above_ground ?? '-'}
              </ValueTypography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <KeyTypography minWidth="187px" variant="body1">
                {t('attributes.property.storyBelowGround')}
              </KeyTypography>
              <ValueTypography variant="body2">
                {selectedProperty?.building_attributes[0]
                  ?.stories_below_ground ?? '-'}
              </ValueTypography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <KeyTypography minWidth="187px" variant="body1">
                {t('attributes.property.constructionClass')}
              </KeyTypography>
              <ValueTypography variant="body2">
                {selectedProperty?.building_attributes[0]
                  ?.property_construction_class?.display_name ?? '-'}
              </ValueTypography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <KeyTypography minWidth="187px" variant="body1">
                {t('attributes.property.buildingPart')}
              </KeyTypography>
              <ValueTypography variant="body2">
                {selectedProperty?.building_attributes[0]?.building_part ?? '-'}
              </ValueTypography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <KeyTypography minWidth="187px" variant="body1">
                {t('attributes.property.buildingTenancies')}
              </KeyTypography>
              <ValueTypography variant="body2">
                {selectedProperty?.building_attributes[0]
                  ?.building_tenancies_no ?? '-'}
              </ValueTypography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <KeyTypography minWidth="187px" variant="body1">
                {t('attributes.property.buildingStandards')}
              </KeyTypography>
              <ValueTypography variant="body2">
                {selectedProperty?.building_attributes[0]
                  ?.property_building_standard?.standard_display_name ?? '-'}
              </ValueTypography>
            </Box>
          </StyledColumnBox>
        </StyledContentBox>
      </Box>

      <Divider />

      {/* Occupancy Permit */}
      <StyledHeaderBox>
        <Typography variant="body1">
          {t('attributes.property.occupancyPermit')}
        </Typography>
        {isEditable && (
          <ModeEditOutlineOutlinedIcon
            onClick={() => handleEditPropertyForm(4)}
          />
        )}
      </StyledHeaderBox>

      <Divider />

      <Box sx={{ display: 'flex', width: '100%' }}>
        <StyledContentBox>
          <StyledColumnBox>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <KeyTypography minWidth="230px" variant="body1">
                {t('attributes.property.occupancyPermitNumber')}:
              </KeyTypography>
              <ValueTypography variant="body2">
                {selectedProperty?.occupancy_permit[0]?.op_number ?? '-'}
              </ValueTypography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <KeyTypography minWidth="230px" variant="body1">
                {t('attributes.property.occupancyPermitDate')}:
              </KeyTypography>
              <ValueTypography variant="body2">
                {formattedDate(
                  selectedProperty?.occupancy_permit[0]?.op_date
                ) ?? '-'}
              </ValueTypography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <KeyTypography minWidth="230px" variant="body1">
                {t('attributes.property.occupancyPermitLocation')}:
              </KeyTypography>
              <ValueTypography variant="body2">
                {selectedProperty?.occupancy_permit[0]?.op_location ?? '-'}
              </ValueTypography>
            </Box>
          </StyledColumnBox>
        </StyledContentBox>
      </Box>
    </Box>
  );

  const handleNextStep = () => {
    setNextStepTitle();
    dispatch(resetUpdateInvoice());
  };

  const handleEditAsset = () => {};

  useEffect(() => {
    if (nextStepTitle?.title !== PROPERTY_DETAIL_STEP.asset_details) {
      // Update active tab based on hash changes
      const hashIndex = Object.keys(tabHashMap).find(
        (key) => hash === tabHashMap[key]
      );

      setActiveTab(hashIndex ? parseInt(hashIndex) : 0); // Default to 0 if no match
    }
  }, [hash, nextStepTitle]);

  const tabs = [
    {
      label: t('attributes.property.propertyDetails'),
      component: renderPropertyDetails,
    },
    {
      label:
        nextStepTitle?.title === PROPERTY_DETAIL_STEP.asset_details
          ? t('attributes.property.asset_details')
          : t('attributes.property.assets'),
      component: (
        <AssetsList
          propertyId={propertyId}
          isEditable={isEditable}
          nextStepTitle={nextStepTitle}
          setNextStepTitle={setNextStepTitle}
          setActiveTab={setActiveTab}
          handleEditAsset={handleEditAsset}
        />
      ),
    },
    {
      label: 'Routines',
      component: <RoutineList propertyId={propertyNumId} />,
    },
    {
      label: t('attributes.billingContract.billingContracts'),
      component: (
        <BillingContract
          propertyId={propertyNumId}
          isFromProperty={true}
          setNextStepTitle={setNextStepTitle}
          setActiveTab={setActiveTab}
          selectedProperty={selectedProperty}
        />
      ),
    },
    {
      label: t('attributes.invoice.invoice'),
      component: (
        <Invoices
          nextStepTitle={nextStepTitle}
          setNextStepTitle={setNextStepTitle}
          isFromWorkOrder={false}
        />
      ),
    },
    {
      label: 'Comming Soon...',
      component: 'Comming soon',
    },
  ];

  const filteredTabsForAssetDetai =
    nextStepTitle?.title === PROPERTY_DETAIL_STEP.asset_details
      ? tabs.filter(
          (tab) =>
            tab.label !== t('attributes.property.propertyDetails') &&
            tab.label !== 'Routines' &&
            tab.label !== t('attributes.billingContract.billingContracts') &&
            tab.label !== 'Comming Soon...'
        )
      : tabs;

  const updatedTabs =
    nextStepTitle?.title === PROPERTY_DETAIL_STEP.asset_details
      ? [
          ...filteredTabsForAssetDetai,
          {
            label: t('attributes.property.defect_history'),
            component: 'Comming soon',
          },
        ]
      : filteredTabsForAssetDetai;

  return (
    <>
      <Box sx={{ bgcolor: DIVIDER_COLOR, paddingBottom: '16px' }}>
        <MainWrapper
          defaultPadding="0px"
          title="Properties"
          variant="body1"
          isStep={true}
          nextStepTitle={nextStepTitle}
          step={propertyDataloading ? '' : selectedProperty?.pid}
          handleDefaultStep={handleDefaultStep}
          handleNextStep={handleNextStep}
        ></MainWrapper>
      </Box>

      {nextStepTitle?.title !== PROPERTY_DETAIL_STEP.billingContracts &&
        nextStepTitle?.title !== PROPERTY_DETAIL_STEP.invoice && (
          <Box
            sx={{
              width: '100%',
              backgroundColor: DIVIDER_COLOR,
            }}
          >
            <Box
              sx={{
                width: '100%',
                backgroundColor: 'white',
              }}
            >
              <TabView
                tabs={updatedTabs}
                activeTab={activeTab}
                setActiveTab={(index) => {
                  setActiveTab(index);
                  // Update the hash based on the active tab
                  if (
                    nextStepTitle?.title !== PROPERTY_DETAIL_STEP.asset_details
                  ) {
                    const newHash = tabHashMap[index] || '';

                    navigate(`${location.pathname}${newHash}`, {
                      replace: true,
                    });
                  }
                }}
                onTabChange={() => {}}
                isSubDetails={true}
              />
            </Box>
          </Box>
        )}

      {nextStepTitle?.title === PROPERTY_DETAIL_STEP.billingContracts && (
        <Box
          sx={{
            maxHeight: '(calc(100vh - 200px), 100%)',
          }}
        >
          <BillingContract
            propertyId={propertyNumId}
            isFromProperty={true}
            nextStepTitle={nextStepTitle}
            setNextStepTitle={setNextStepTitle}
            setActiveTab={setActiveTab}
            selectedProperty={selectedProperty}
          />
        </Box>
      )}

      {nextStepTitle?.title === PROPERTY_DETAIL_STEP.invoice && (
        <Box
          sx={{
            maxHeight: '(calc(100vh - 200px), 100%)',
          }}
        >
          <Invoices
            nextStepTitle={nextStepTitle}
            setNextStepTitle={setNextStepTitle}
            isFromWorkOrder={false}
          />
        </Box>
      )}
    </>
  );
};

export default PropertyDetails;
