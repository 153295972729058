import { Button, styled, Typography } from '@mui/material';

import { FONT_SIZE } from '../../constants/Constants';

const CustomisedButton = styled(Button)(() => ({
  boxShadow: 'none',
  '&:hover': {
    boxShadow: 'none',
  },
  '&.Mui-disabled': {
    boxShadow: 'none',
    cursor: 'not-allowed',
    pointerEvents: 'auto',
  },
}));

const CustomButton = ({
  text = '',
  variant = 'contained',
  startIcon,
  children,
  endIcon,
  color = 'primary',
  disabled,
  style = {},
  fontSize = FONT_SIZE,
  onClick = () => {},
  ...rest
}) => (
  <CustomisedButton
    variant={variant}
    startIcon={startIcon}
    endIcon={endIcon}
    color={color}
    disabled={disabled}
    onClick={onClick}
    fontSize={fontSize}
    style={style}
    {...rest}
  >
    {text ? (
      <Typography variant="body1" fontSize={fontSize} sx={{ pt: '1px' }}>
        {text}
      </Typography>
    ) : (
      children
    )}
  </CustomisedButton>
);

export default CustomButton;
