import moment from 'moment';

import BasicBarChart from './components/Chart/BarChart/CustomBarChart';
import LineChart from './components/Chart/LineChart/LineChart';
import Piechart from './components/Chart/PieChart/PieChart';
// import StackedBarChart from './components/Chart/StackedBarChart/StackedBarChart';
import StatusLabel from './components/CommonComponents/StatusLabel';
import {
  APPOINTMENT_CONFIRMED,
  ASSETS_STATUS,
  BAR_CHART,
  CHART_COLOR,
  CHART_CONST,
  COMPLETED,
  CREATED,
  DATE_CONVERT_UTC_FORMAT_API,
  DATE_FORMAT,
  DATE_FORMAT_API,
  DATE_FORMAT_API_WITH_TIME,
  DEFECT_STATUS,
  IN_PROGRESS,
  LINE_CHART,
  PIE_CHART,
  PURCHASE_ORDER_STATUS,
  SCHEDULED,
} from './constants/Constants';

export const loggedInUserRole = () => {
  const user_info = localStorage.getItem('user_info');
  const user = user_info ? JSON.parse(user_info) : null;

  return user?.role_name;
};

export const loggedInUserDetail = () => {
  const user_info = localStorage.getItem('user_info');
  const user = user_info ? JSON.parse(user_info) : null;

  return user;
};

export const formatDate = (date, defaultFormat = DATE_FORMAT) => {
  if (!date) return null;

  return moment(date).format(defaultFormat); // Convert UTC to local and format it
};

export const formatDateForAPI = (date, defaultFormat = DATE_FORMAT_API) => {
  if (!date) return null;

  return moment(date).format(defaultFormat); // Convert UTC to local and format it
};

/**
 * Format date to be displayed in the UI
 * @param {string | Date} date - date in string or Date format
 * @param {string} [defaultFormat=DATE_FORMAT] - desired date format
 * @returns {string} - formatted date in the desired format
 */
export const formattedDate = (date, defaultFormat = DATE_FORMAT) => {
  if (!date) return null;

  return moment.utc(date).local().format(defaultFormat); // Convert UTC to local and format it
};

/**
 * Format date to be sent to API
 * @param {string | Date} receivedDate - date in string or Date format
 * @param {string} [defaultFormat=DATE_FORMAT_API_WITH_TIME] - desired date format
 * @returns {string} - formatted date in the desired format
 */
export const filterDateFormatted = (
  receivedDate,
  defaultFormat = DATE_FORMAT_API_WITH_TIME
) => {
  const date = new Date(receivedDate);
  const newDate = moment(date).utc().format(defaultFormat);

  return newDate;
};

export const datePickerFormat = (receivedDate) =>
  moment.utc(receivedDate).local().toDate();

/**
 * Format date to be sent to API
 * @param {string | Date} date - date in string or Date format
 * @param {string} [defaultFormat=DATE_FORMAT_API_WITH_TIME] - desired date format
 * @returns {string} - formatted date in the desired format
 */
export const formatDateAPI = (
  date,
  defaultFormat = DATE_FORMAT_API_WITH_TIME,
  defaultUTCFormat = DATE_CONVERT_UTC_FORMAT_API
) => {
  if (!date) return null;

  return moment(date, defaultUTCFormat).utc().format(defaultFormat);
};

export const debounce = (func, wait) => {
  let timeout;

  return function (...args) {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, args), wait);
  };
};

export function getUniqueFrequencies(frequencyList) {
  const uniqueFrequencies = {};

  frequencyList.forEach((freq) => {
    uniqueFrequencies[freq.code] = freq;
  });

  return Object.values(uniqueFrequencies);
}

export const clearTextfields = (setFun, name) => setFun(name, '');

export const getWidgetChartData = (chartName, chartType, data) => {
  switch (chartType) {
    case PIE_CHART: {
      if (chartName === CHART_CONST.PROPERTIES) {
        return {
          data: data?.map((d) => ({
            label: d?.display_name,
            value: d?.count,
            color: CHART_COLOR[d?.name],
          })),
        };
      }
      if (chartName === CHART_CONST.DEFECTS) {
        return {
          data: data?.map((d) => ({
            label: d?.severity,
            value: d?.count,
            color: CHART_COLOR[d?.severity?.toLowerCase()],
          })),
        };
      }

      if (chartName === CHART_CONST.QUOTES) {
        return {
          data: data?.map((d) => ({
            label: d?.status
              ?.split('_')
              ?.map((s) => s.charAt(0).toUpperCase() + s.slice(1))
              .join(' '),
            value: d?.count,
            color: CHART_COLOR[d?.status],
          })),
        };
      }

      if (chartName === CHART_CONST.PURCHASE_ORDER) {
        return {
          data: data?.map((d) => ({
            label: d?.status[0] + d?.status?.substr(1)?.toLowerCase(),
            value: d?.count,
            color: CHART_COLOR[d?.status?.toLowerCase()],
          })),
        };
      }

      break;
    }

    case BAR_CHART: {
      if (chartName === CHART_CONST.PROPERTIES) {
        let colors = [];
        let labels = [];
        let count = [];

        data?.forEach((d) => {
          colors?.push(CHART_COLOR[d?.name]);
          labels?.push(d?.display_name);
          count.push(d?.count);
        });

        return {
          colors: colors,
          data: count,
          labels: labels,
        };
      }
      if (chartName === CHART_CONST.DEFECTS) {
        let colors = [];
        let labels = [];
        let count = [];

        data?.forEach((d) => {
          colors?.push(CHART_COLOR[d?.severity?.toLowerCase()]);
          labels?.push(d?.severity);
          count.push(d?.count);
        });

        return {
          colors: colors,
          data: count,
          labels: labels,
        };
      }
      if (chartName === CHART_CONST.QUOTES) {
        let colors = [];
        let labels = [];
        let count = [];

        data?.forEach((d) => {
          colors?.push(CHART_COLOR[d?.status]);
          labels?.push(
            d?.status
              ?.split('_')
              ?.map((s) => s.charAt(0).toUpperCase() + s.slice(1))
              .join(' ')
          );
          count.push(d?.count);
        });

        return {
          colors: colors,
          data: count,
          labels: labels,
        };
      }

      if (chartName === CHART_CONST.PURCHASE_ORDER) {
        let colors = [];
        let labels = [];
        let count = [];

        data?.forEach((d) => {
          colors?.push(CHART_COLOR[d?.status.toLowerCase()]);
          labels?.push(d?.status[0] + d?.status?.substr(1)?.toLowerCase());
          count.push(d?.count);
        });

        return {
          colors: colors,
          data: count,
          labels: labels,
        };
      }

      break;
    }

    case LINE_CHART: {
      if (chartName === CHART_CONST.PROPERTIES) {
        let labels = [];
        let count = [];

        data?.forEach((d) => {
          labels?.push(d?.display_name);
          count.push(d?.count);
        });

        return {
          data: data?.map((d) => ({
            label: d?.display_name,
            color: CHART_COLOR[d?.name],
            data: count,
          })),
          labels: count,
        };
      }
      if (chartName === CHART_CONST.DEFECTS) {
        let labels = [];
        let count = [];

        data?.forEach((d) => {
          labels?.push(d?.severity);
          count.push(d?.count);
        });

        return {
          data: data?.map((d) => ({
            label: d?.severity,
            color: CHART_COLOR[d?.severity?.toLowerCase()],
            data: count,
          })),
          labels: count,
        };
      }
      if (chartName === CHART_CONST.QUOTES) {
        let labels = [];
        let count = [];

        data?.forEach((d) => {
          labels?.push(
            d?.status
              ?.split('_')
              ?.map((s) => s.charAt(0).toUpperCase() + s.slice(1))
              .join(' ')
          );
          count.push(d?.count);
        });

        return {
          data: data?.map((d) => ({
            label: d?.status
              ?.split('_')
              ?.map((s) => s.charAt(0).toUpperCase() + s.slice(1))
              .join(' '),
            color: CHART_COLOR[d?.status],
            data: count,
          })),
          labels: count,
        };
      }
      if (chartName === CHART_CONST.PURCHASE_ORDER) {
        let labels = [];
        let count = [];

        data?.forEach((d) => {
          labels?.push(d?.status[0] + d?.status?.substr(1)?.toLowerCase());
          count.push(d?.count);
        });

        return {
          data: data?.map((d) => ({
            label: d?.status[0] + d?.status?.substr(1)?.toLowerCase(),
            color: CHART_COLOR[d?.status.toLowerCase()],
            data: count,
          })),
          labels: count,
        };
      }
      break;
    }

    default:
      break;
  }
};

export const displayChart = (item) => {
  const data = item.widget_data[item?.type];

  switch (item?.type) {
    case PIE_CHART:
      return <Piechart data={data?.data} isRadius={item?.is_radius} />;
    case BAR_CHART:
      return (
        <BasicBarChart
          data={data?.data}
          xLabels={data?.labels}
          colors={data?.colors}
        />
      );
    case LINE_CHART:
      return <LineChart data={data?.data} xLabels={data?.labels} />;

    // case GAUGE_CHART:
    //   break;
    // case SPARKLINE_CHART:
    //   break;
    // case SCATTER_CHART:
    //   break;
    default:
  }
};

export const isCreatedWithinLastFiveMinutes = (createdAt) => {
  if (!createdAt) return false;
  const nowUTC = moment.utc();
  const createdAtDate = moment.utc(createdAt);

  const diffInMinutes = nowUTC.diff(createdAtDate, 'minutes');

  return diffInMinutes <= 5;
};

export const findLabelByValue = (list, value) => {
  const item = list.find((option) => option.value === value);

  return item ? { label: item.label, value: item.value } : null;
};

export const addSpaceAndCapitalizeString = (value) =>
  value
    ?.split('-')
    ?.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    ?.join(' ');

export const capitalizeFirstLetter = (value) =>
  value
    .split('-')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');

export const formatStatus = (status, height = '12px', width = '12px') => {
  const lowerCaseStatus =
    typeof status === 'string' ? status?.toLowerCase() : status;

  if (
    status === ASSETS_STATUS.fail ||
    lowerCaseStatus === ASSETS_STATUS.fail?.toLowerCase()
  ) {
    return <StatusLabel label={ASSETS_STATUS.fail} color="#C54036" />;
  }
  if (
    status === ASSETS_STATUS.pass ||
    lowerCaseStatus === ASSETS_STATUS.pass?.toLowerCase()
  ) {
    return <StatusLabel label={ASSETS_STATUS.pass} color="#95C020" />;
  }
  if (
    status === ASSETS_STATUS.noCheck ||
    lowerCaseStatus === ASSETS_STATUS.noCheck?.toLowerCase()
  ) {
    return <StatusLabel label={ASSETS_STATUS.noCheck} color="#59366B" />;
  }

  if (lowerCaseStatus === true) {
    return <StatusLabel label="Yes" color="#59C3C3" />;
  }
  if (lowerCaseStatus === false) {
    return <StatusLabel label="No" color="#454545" />;
  }

  if (
    status === DEFECT_STATUS.critical ||
    lowerCaseStatus === DEFECT_STATUS.critical?.toLowerCase()
  ) {
    return (
      <StatusLabel
        label="Critical"
        color="#C04B20"
        height={height}
        width={width}
      />
    );
  }
  if (
    status === DEFECT_STATUS.nonConformance ||
    lowerCaseStatus === DEFECT_STATUS.nonConformance?.toLowerCase()
  ) {
    return (
      <StatusLabel
        label="Non-Conformance"
        color="#7A4E8C"
        height={height}
        width={width}
      />
    );
  }
  if (
    status === DEFECT_STATUS.nonCritical ||
    lowerCaseStatus === DEFECT_STATUS.nonCritical?.toLowerCase()
  ) {
    return (
      <StatusLabel
        label="Non-Critical"
        color="#95C020"
        height={height}
        width={width}
      />
    );
  }
  if (lowerCaseStatus === 'open') {
    return <StatusLabel label="Open" color="#FF0000" />;
  }
  if (lowerCaseStatus === 'close') {
    return <StatusLabel label="Close" color="#95C020" />;
  }
  if (lowerCaseStatus === 'in_progress') {
    return <StatusLabel label="In Progress" color="#2196F3" />;
  }

  // Work Order list status start
  if (status === CREATED) {
    return (
      <StatusLabel
        label={CREATED}
        color="#454545"
        height={height}
        width={width}
      />
    );
  }
  if (status === SCHEDULED) {
    return (
      <StatusLabel
        label={SCHEDULED}
        color="#59C3C3"
        height={height}
        width={width}
      />
    );
  }
  if (status === APPOINTMENT_CONFIRMED) {
    return (
      <StatusLabel
        label={APPOINTMENT_CONFIRMED}
        color="#2CCDA1"
        height={height}
        width={width}
      />
    );
  }
  if (status === IN_PROGRESS) {
    return (
      <StatusLabel
        label={IN_PROGRESS}
        color="#F7A142"
        height={height}
        width={width}
      />
    );
  }
  if (status === COMPLETED) {
    return (
      <StatusLabel
        label={COMPLETED}
        color="#95C020"
        height={height}
        width={width}
      />
    );
  }
  if (lowerCaseStatus === 'pending') {
    return (
      <StatusLabel
        label="Pending"
        color="#FD7E14"
        height={height}
        width={width}
      />
    );
  }
  if (lowerCaseStatus === PURCHASE_ORDER_STATUS.DELETED) {
    return <StatusLabel label="Deleted" color="#C54036" />;
  }
  if (lowerCaseStatus === PURCHASE_ORDER_STATUS.SUBMITTED) {
    return <StatusLabel label="Submitted" color="#95C020" />;
  }
  if (lowerCaseStatus === PURCHASE_ORDER_STATUS.DRAFT) {
    return <StatusLabel label="Draft" color="#999999" />;
  }
  if (lowerCaseStatus === PURCHASE_ORDER_STATUS.CREATED) {
    return <StatusLabel label="Created" color="#59C3C3" />;
  }
  if (lowerCaseStatus === PURCHASE_ORDER_STATUS.PAID) {
    return <StatusLabel label="Paid" color="#395B50" />;
  }
  // Work Order list status end
};

export const formatPriceWithDecimalValue = (price) => {
  if (price == null || isNaN(price)) return '';
  const numPrice = parseFloat(price);

  return numPrice.toFixed(2);
};
