import { createSlice } from '@reduxjs/toolkit';

import { getComplianceStatusDropdownList } from './api';

const complianceStatusDropdownList = {
  isLoading: true,
  error: false,
  data: false,
};

const accountManagerSlice = createSlice({
  name: 'complianceStatus',
  initialState: {
    complianceStatusDropdownList,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getComplianceStatusDropdownList.pending, (state) => {
        state.complianceStatusDropdownList.isLoading = true;
      })
      .addCase(getComplianceStatusDropdownList.fulfilled, (state, action) => {
        state.complianceStatusDropdownList.isLoading = false;
        state.complianceStatusDropdownList.data = action.payload;
      })
      .addCase(getComplianceStatusDropdownList.rejected, (state, action) => {
        state.complianceStatusDropdownList.isLoading = false;
        state.complianceStatusDropdownList.error = action.error.message;
      });
  },
});

export default accountManagerSlice.reducer;
