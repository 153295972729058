import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  SwipeableDrawer as MUISwipeableDrawer,
  Typography,
  styled,
} from '@mui/material';

// Internal
import {
  HEADER_HEIGHT,
  SWIPEABLE_DRAWER_WIDTH,
} from '../../constants/Typography';

const MUISwipeableDrawerStyled = styled(MUISwipeableDrawer)(
  ({ theme, width, bgColor }) => ({
    '& .MuiDrawer-paper': {
      borderRadius: 0,
      width: width || SWIPEABLE_DRAWER_WIDTH,
      background: bgColor ? bgColor : theme.palette.divider,
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
  })
);

const StyledHeader = styled(Box)(({ theme }) => ({
  background: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  height: `${HEADER_HEIGHT}px`,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '8px',
}));

const StyledFooter = styled(Box)(({ theme }) => ({
  background: theme.palette.secondary.main,
  display: 'flex',
  height: `${HEADER_HEIGHT}px`,
  justifyContent: 'flex-end',
  alignItems: 'center',
  padding: '0 8px',
  borderTop: `1px solid ${theme.palette.divider}`,
  position: 'relative', // Footer sticks at the bottom within the flex layout
  gap: '16px',
}));

const ScrollableSection = styled(Box)(() => ({
  flex: 1, // Allows the section to grow and fill the available space
  overflowY: 'auto', // Enables scrolling when content overflows
  padding: '16px',
}));

const SwipeableDrawer = ({
  anchor = 'right',
  open,
  title = '',
  onOpen = () => {},
  onClose = () => {},
  children,
  footerButton,
  width,
  bgColor = '',
}) => (
  <MUISwipeableDrawerStyled
    anchor={anchor}
    open={open}
    onClose={onClose}
    onOpen={onOpen}
    width={width}
    bgColor={bgColor}
  >
    <StyledHeader>
      <Typography variant="h2">{title}</Typography>
      <CloseIcon onClick={onClose} />
    </StyledHeader>
    <ScrollableSection>{children}</ScrollableSection>
    {footerButton && (
      <StyledFooter>
        {Array.isArray(footerButton)
          ? footerButton.map((buttonElement, index) => (
              <Box key={index}>{buttonElement}</Box>
            ))
          : footerButton}
      </StyledFooter>
    )}
  </MUISwipeableDrawerStyled>
);

export default SwipeableDrawer;
