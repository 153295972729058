/**
 * NOTE: Please maintain alphabetical order to facilitate easier searching and navigation within the file.
 * Add the values according to the following labels:
 *
 * 1. attributes - This section includes site-wide attribute values, e.g., Email, Name, Password, etc.
 * 2. auth - This section includes all labels related to authentication, with nested objects for each type of authentication (e.g., login, signUp, passwordReset, etc.).
 * 3. common - This section includes common translations used throughout the app.
 * 4. label - This section includes labels for buttons, e.g., Login, Sign Up, etc.
 * 5. message - This section includes common messages to be displayed in the app, e.g., Email verified, etc.
 * 6. modal - This section includes all labels, descriptions, and subheadings for modals, e.g., SignUp modal, Launch setup modal, etc.
 **/

const translation = {
  attributes: {
    email: 'Email',
    name: 'Name',
    address: 'Address',
    description: 'Description',
    create: 'Create',
    new: 'New',
    add: 'Add',
    save: 'Save',
    export: 'Export',
    import: 'Import',
    contact: 'Contact',
    information: 'Information',
    postCode: 'Post Code',
    delete: 'Delete',
    code: 'Code',
    note: 'Note',
    number: 'Number',
    required: 'Required',
    requirements: 'Requirements',
    date: 'Date',
    location: 'Location',
    previous: 'Previous',
    edit: 'Edit',
    search: 'Search',
    actions: 'Actions',
    action: 'Action',
    total: 'Total',
    amount: 'Amount',
    phone: 'Phone',
    model: 'Model',
    quantity: 'Quantity',
    unit_price: 'Unit Price',
    installation: 'Installation',
    serial: 'Serial',
    make: 'Make',
    size: 'Size',
    documents: 'Documents',
    view: 'View',
    download: 'Download',
    recommendation: 'Recommendation',
    cost: 'Cost',
    preview: 'Preview',
    set_as_draft: 'Set as Draft',
    finish: 'Finish',
    reupload: 'Reupload',
    customer: {
      agreement_signed_date: 'Agreement Date',
      categories: 'Category',
      contact_email: 'Contact Email',
      contact_name: 'Contact Name',
      created_at: 'Created at',
      customer_name: 'Customer Name',
      invitation_status: 'Status',
      registered_address: 'Registered Address',
      add_customer: 'Add Customer',
      customers: 'Customers',
      customer: 'Customer',
      compliance_status: 'Compliance Status',
      invoices: 'Invoices',
      quotes: 'Quotes',
      quote_type: 'Quote Type',
      defects: 'Defects',
      totalQuoteAmount: 'Total Quote Amounts',
      quotesByStatus: 'Quotes by Status',
      quoteAmount: 'Quote Amount',
      organisation: 'Organisation',
      customerDetails: {
        registeredAddress: 'Registered Address',
      },
    },
    company: {
      company: 'Company',
      cell_phone: 'Cell Phone',
      companies: 'Fire Companies',
      fire: 'Fire',
      details: 'Details',
      abnNumber: 'ABN number',
      logo: 'logo',
      logoCapital: 'Logo',
      upload: 'Upload',
    },
    property: {
      property: 'Property',
      barcode: 'Barcode',
      identification: 'Identification',
      parent: 'Parent',
      asset: 'Asset',
      base: 'Base',
      perform: 'Perform',
      properties: 'Properties',
      status: 'Status',
      billingCard: 'Billing Card',
      tags: 'Tags',
      constructionClass: 'Construction Class',
      access: 'Access',
      schedule: 'Schedule',
      siteRequirement: 'Site Requirements',
      buildingAttributes: 'Building Attributes',
      occupancyPermit: 'Occupancy Permit',
      occupancyPermitNumber: 'Occupancy Permit Number',
      occupancyPermitDate: 'Occupancy Permit Date',
      occupancyPermitLocation: 'Occupancy Permit Location',
      propertyId: 'Property ID',
      propertyName: 'Property Name',
      landLotNumber: 'Land/Lot Number',
      ocspNumber: 'OCSP or DP/DN Number',
      accountManager: 'Account Manager',
      parentProperty: 'Parent Property',
      customer: 'Customer',
      agmNewData: 'AGM Date',
      reviewNewDate: 'Review Date',
      contractAndAssignmentDetails: 'Contract and Assignment Details',
      annualComplianceDate: 'Annual Compliance Date',
      contractStartDate: 'Contract Start Date',
      contractEndDate: 'Contract Valid Till',
      defaultTechnician: 'Default Technician',
      accessDetails: 'Access Details',
      accessSchedule: 'Access Schedule',
      accessProcedure: 'Access Procedure',
      accessCode: 'Access Code',
      accessNote: 'Access Note',
      signatureRequired: 'Signature Required',
      inductionRequired: 'Induction Required',
      logBookEntryRequired: 'Log Book Entry Required',
      policeCheckRequired: 'Police Check Required',
      workingWithChildrenCheckRequired: 'Working with Children Check Required',
      serviceRequirements: 'Service Requirements',
      safetyRequirements: 'Safety Requirements',
      organization: 'Organisation',
      agmDate: 'AGM date',
      localGovArea: 'Local Government Area',
      internalNote: 'Internal Note',
      reviewDate: 'Review date',
      procedure: 'Procedure',
      signature: 'Signature',
      induction: 'Induction',
      logBookEntry: 'Log Book Entry',
      policeCheck: 'Police Check',
      workingWithChildren: 'Working With Children Check',
      service: 'Service',
      safety: 'Safety',
      annualDueCertificateDate: 'Annual Due Certification Date',
      buildingEra: 'Building Era',
      buildingClass: 'Building Class',
      buildingType: 'Building Type',
      buildingSizeText: 'Building Size',
      buildingSize: 'Building Size (sqft)',
      buildingSizeSqm: 'Building Size (sqm)',
      storyAboveGround: 'Stories Above Ground',
      storyBelowGround: 'Stories Below Ground',
      buildingPart: 'Building Part',
      buildingTenancies: 'Building Tenancies',
      buildingStandards: 'Building Standards',
      propertyDetails: 'Property Details',
      zone: 'Zone',
      assets: 'Assets',
      locationInfoText:
        'Provide location information formatted as Building 5A, Floor 8, Unit 1.',
      identificationNumberInfo:
        'Provide Identification number such as Door No.',
      propertyManager: 'Property Manager',
      contact: 'Contact',
      isBooking: 'Is Booking',
      equipment_type: 'Equipment Type',
      equipment_class: 'Equipment Class',
      asset_name: 'Asset Name',
      identification_number: 'Identification Number',
      serial_number: 'Serial Number',
      make: 'Make',
      model: 'Model',
      size: 'Size',
      quantity: 'Quantity',
      base_date: 'Base Date',
      installation_date: 'Installation Date',
      internal_note: 'Internal Note',
      defect_history: 'Defect History',
      asset_details: 'Asset Details',
    },
    billingCard: {
      accountpartner: 'Linked to Accounting Partner',
      addBill: 'Add Billing Information',
      editBill: 'Edit Billing Information',
      accountDetails: 'Account Details',
      billName: 'Billing Name',
      abn: 'ABN',
      contactDetails: 'Contact Details',
      billingInfoText: 'Billing Information',
      accountingIntegration: 'Accounting Integration',
      partnerId: 'Partner UID',
      postalAddress: 'Postal Address',
      sync: 'Sync',
      deleteTitle: 'Delete Billing Info',
      deleteDescription:
        'Are you sure, you want to delete this billing information?',
      contact_email: 'Contact Email',
    },
    superAdmin: {
      superAdmins: 'Super Admins',
      superAdmin: 'Super Admin',
      agreement_document: 'Agreement document',
    },
    technician: {
      technician_name: 'Technician Name',
      company_name: 'Company Name',
      contact_phone: 'Contact Phone',
      category: 'Category',
      skills: 'Skills',
      created_at: 'Created at',
      technician: 'Technician',
      technicians: 'Technicians',
      add_technician: 'Add Technician',
      contact_address: 'Contact Address',
      zones: 'Zones',
    },
    account_manager: {
      account_manager: 'Account Manager',
    },
    office_staff: {
      office_staff: 'Office Staff',
      office_staffs: 'Office Staffs',
      add_office_staff: 'Add Office Staff ',
      contact_information: 'Contact Information',
      state: 'State',
      city: 'City',
      created_at: 'Created at',
      cell_phone: 'Cell Phone',
      create_new_office_staff: 'Create New Office Staff',
      edit_office_staff: 'Edit Office Staff',
    },
    work_order: {
      duration: 'Duration',
      id: 'ID',
      title: 'Title',
      service_report: 'Service Report',
      defects_quote: 'Defects Quote',
      assign_to: 'Assigned To',
      purchase_order: 'Purchase Order',
      contract_details: 'Contract Details',
      work_order_details: 'Work Order Details',
      work_order_name: 'Work Order Name',
      tolerance_date_duration: 'Tolerance Date Duration',
      estimated_duration: 'Estimated Duration',
      work_order_type: 'Work Order Type',
      asset_count: 'Asset Count',
      frequency: 'Frequency',
      property_contact: 'Property Contact',
      status: 'Status',
      appointment: 'Appointment',
      date_nd_time: 'Date & Time',
      due_date: 'Due Date',
      actions: 'Actions',
      work_orders: 'Work Orders',
      add_work_order: 'Add Work Order',
      quote_id: 'Quote ID',
      quote_date: 'Quote Date',
      quote: 'Quote',
      resolution: 'Resolution',
      defect: 'Defect',
      severity: 'Severity',
      identified: 'Identified',
      photos: 'Photos',
      image: 'Image',
      created_on: 'Created On',
      routineServiceReport: 'Routine Service Report',
      routineServiceName: 'Routine Service Name',
      preview: 'Preview',
      bill_to: 'Bill To',
      quote_due: 'Quote Due',
      ref: 'Ref',
      quotationSuccessMessage: 'Quotation has been sent successfully',
      quoteAddProductSuccessMsg: 'Defect quote product added successfully',
      quoteEditProductSuccessMsg: 'Defect quote product updated successfully',
      approved: 'Quote approved successfully',
      rejected: 'Quote rejected successfully',
    },
    purchase_order: {
      purchase_order_id: 'Purchase Order ID',
      status: 'Status',
      supplier: 'Supplier',
      create_new_purchase_order: 'Create New Purchase Order',
      edit_purchase_order: 'Edit Purchase Order',
      purchase_order_created_successfully:
        'Purchase order created successfully',
      purchase_order_details: 'Purchase Order Details',
      po_creation_date: 'PO Creation Date',
      delivery_instruction: 'Delivery Instruction',
      add_product: 'Add Product',
      created_on: 'Created On',
      product: 'Product',
      purchaseOrderAddProductSuccessMsg:
        'Purchase order product added successfully',
      purchase_order_to: 'Purchase Order To',
      purchase_order_date: 'Purchase Order Date',
    },
    invoice: {
      invoices: 'Invoices',
      invoice: 'Invoice',
      paid: 'Paid',
      due: 'Due',
      overdue: 'Overdue',
      byStatus: 'by Status',
      invoice_date: 'Invoice Date',
      due_date: 'Due Date',
      status: 'Status',
      wo_id: 'WO ID',
      invoice_to: 'Invoice To',
      invoice_number: 'Invoice Number',
      issued_date: 'Issued Date',
      invoice_due: 'Invoice Due',
      scope: 'Scope',
      send_invoice: 'Send Invoice',
    },
    supplier: {
      suppliers: 'Suppliers',
      supplier: 'Supplier',
      business_number: 'Business Number',
      contact_name: 'Contact Name',
      contact_phone: 'Contact Phone',
      details: 'Details',
      phone: 'Phone',
    },
    productCatalogue: {
      product: 'Product',
      products: 'Products',
      product_catalogue: 'Product Catalogue',
      product_name: 'Product Name',
      product_type: 'Product Type',
      supplier: 'Supplier',
      cost_price: 'Cost Price',
      default_sell_price: 'Default Sell Price',
      sell_price: 'Sell Price',
      equipment_type: 'Equipment Type',
      status: 'Status',
      product_details: 'Product Details',
      type: 'Type',
      supplier_details: 'Supplier Details',
      unit_of_measure: 'Unit of Measure',
      services_details: 'Services Details',
      estimate_time: 'Estimate Time',
      view_product: 'View Product',
      edit_product: 'Edit Product',
      add_product: 'Add Product',
      description: 'Description',
      labour_details: 'Labour Details',
      materials: 'Materials',
      equipment: 'Equipment',
      expenses: 'Expenses',
      labour: 'Labour',
      materials_detail_text:
        'should be used for costs that remain (or are consumed) on site after a task is completed.',
      equipment_details_text:
        'should be used for costs that are brought to a site temporarily.',
      expenses_details_text:
        'can be used for costs that are neither brought to site nor left on site, e.g. parking costs or material delivery costs.',
      labour_details_text:
        'costs and charges for routine service inspections, repair or replacement.',
      product_detail_description_note:
        'Leave blank to use the product name. The description is typically shown on reports, invoices and purchase orders.',
      labour_details_cost_price_note:
        "This is your blended average cost for this type of labour. This value is used during quoting, and updated with the individual technician's labour rate as technicians log time to a task.",
      esitmated_time_note:
        'Estimated labour time (in minute) needs for this material product, e.g. 15, 30, 120.',
    },
    workOrder: {
      workOrder: 'Work Order',
      noDataNote: 'There are no Work Order listed currently.',
    },
    scheduleBoard: {
      workOrderName: 'Work Order Name',
      scheduleBoard: 'Schedule Board',
      selectFutureDateAndTime:
        'Please select a date and time that is in the future',
      noTechForSelectedZone:
        'There are no technician available for selected zone.',
      workOrder: 'Work Order',
      cannotAssignToPastDateTime:
        'Work orders cannot be assigned to a past date and time. Please select a current or future date.',
      cannotAssignForDay:
        'Work orders cannot be assigned for more than one day. Please select a single time slot.',
      dateAndTime: 'Date & Time',
      minimumDurationForWO:
        'Minimum Work Order duration should be at least 1 hour.',
    },
    defectsQuotes: {
      defectTitle: 'Defect Title',
    },
    timesheet: {
      time: 'Time',
      timesheet: 'Timesheet',
      timeEntry: 'Time Entry',
      noDataNote: 'There are no Timesheet listed currently.',
      startTimeValidationMsg: 'Start time cannot be greater than the end time.',
      startTimeSameValidationMsg: 'Start time cannot be same as the end time.',
      endTimeValidationMsg: 'End time cannot be less than the start time.',
      endTimeSameValidationMsg: 'End time cannot be same as the start time.',
      deleteTitle: 'Delete Time Entry',
      deleteMsg: 'Are you sure you want to delete this time entry?',
    },
    reports: {
      reports: 'Reports',
      dataManagement: {
        title: 'Data Management',
        noDataNote: 'There are no Data Management listed currently.',
        templates: 'Templates',
        template: {
          downloadError: 'Error downloading the template',
          downloadTitle: 'Download Templates',
          downloadDescription:
            'Choose the specific data type you want to download.',
        },
        importData: 'Import Data',
        exportData: 'Export Data',
        importDataNote:
          'To import bulk data, select the data type and upload your \n file for validation and processing.',
        exportDataNote:
          'Export data from the system and save it locally on your \n device',
        templatesNote:
          'Download the sample template file, fill it with your records,\n  and get ready to import',
      },
      importData: {
        batchId: 'Batch ID',
        fileType: 'Type',
        fileName: 'File Name',
        result: 'Result',
        currentStatus: 'Status',
        createdAt: 'Upload Date',
        selectDataType: 'Make a Selection',
        selectData: 'Select Your Data',
        selectDataDescription:
          'From the dropdown menu, choose the specific type of \n data you wish to import',
        document: 'Document',
        btnText: 'Click to Import or No File Selected',
        docLoadingTitle: 'Your data is being uploaded in the background',
        docLoadingDescription:
          'Your data is being uploaded in the background. You can navigate away from this page and return later to view the summary.',
        docSuccessTitle: 'Your data has been uploaded successfully.',
        docSuccessBody:
          "Your data has been successfully uploaded. To import new data, go to the home screen, or click the 'View' button to see the imported data.",
        docErrorTitle: 'Your data upload was unsuccessful.',
        docErrorDescription:
          "Your data upload was unsuccessful. You can try again by clicking the 'Reupload' button.",
      },
    },
    billingContract: {
      billingContract: 'Billing Contract',
      billingContracts: 'Billing Contracts',
      noDataNote: 'There are no Billing Contract listed currently.',
      customer: 'Customer',
      property: 'Property',
      contractStartDate: 'Contract Start Date',
      contractValidTill: 'Contract Valid Till',
      contractDate: 'Contract Date',
      standard: 'Standard',
      annualFixedIncreasePercentage: 'Annual Fixed Percentage(%) Increase',
      priceIncreasePercentage: 'Price Increase(%)',
    },
    defects: {
      defects: 'Defects',
    },
  },

  auth: {
    login: {
      continueLoginWith: 'Continue Login with',
      login: 'Login',
      unAuthorized: `Sorry, you are not allowed to view this resource.<br />If you need access, please contact an administrator or sign in with the correct account.`,
      notAllowed:
        'Sorry, you are not allowed to login to web portal, Please login to mobile app.',
    },
  },

  common: {
    general_information: 'General Information',
    category: 'Category',
    city: 'City',
    next: 'Next',
    state: 'State',
    start: 'Start',
    end: 'End',
    active: 'Active',
    inactive: 'Inactive',
    loading: 'Loading...',
    welcome: 'Welcome',
    send: 'Send',
    searchAddress: 'Search Address',
    no: 'No',
    available: 'Available',
    refresh: 'Refresh',
    approve: 'Approve',
    submit: 'Submit',
    sessionExpiredTitle: 'Session Expired',
    sessionExpiredDescription:
      'Your session has expired due to inactivity. For your security, please sign in again to continue.',
    noDescription: 'No description',
    status: {
      pending: 'Pending',
      approved: 'Approved',
      inProgress: 'In-progress',
      quotesRejected: 'Quotes Rejected',
      rejected: 'Rejected',
      overdue: 'Overdue',
      draft: 'Draft',
      submitted: 'Submitted',
      inReview: 'In Review',
    },
    desciption: 'DESCRIPTION',
    qty: 'QTY',
    unit_price: 'UNIT PRICE',
    amount: 'AMOUNT',
    total: 'TOTAL',
    total_amount: 'Total Amount',
    create_new: 'Create New',
    termsAndCondition: 'Terms & Condition',
    paymentDecription1: 'Payment will due within 15 days once invoice raised.',
    paymentDecription2: 'Please make checks payable to:',
    select_at_least_one_product: 'Please select at least one product!',
    createdAt: 'Created at',
    id: 'ID',
    details: 'Details',
    cancel: 'Cancel',
  },

  label: {
    login: 'Login',
    globalAdmin: {
      fireCompanyActiveCheckbox: 'Fire company will be moved to active',
      fireCompanyInactiveCheckbox: 'Fire company will be moved to inactive',
      superAdminActiveCheckbox: 'Super Admin will be moved to active',
      superAdminInactiveCheckbox: 'Super Admin will be moved to inactive',
    },
    superAdmin: {
      superAdminActiveCheckbox: 'Super Admin will be moved to active',
      superAdminInactiveCheckbox: 'Super Admin will be moved to inactive',
      technicianActiveCheckbox: 'Technician will be moved to active',
      technicianInactiveCheckbox: 'Technician will be moved to inactive',
      assetActiveCheckbox: 'Asset will be moved to active',
      assetInactiveCheckbox: 'Asset will be moved to inactive',
      supplierActiveCheckbox: 'Supplier will be moved to active',
      supplierInactiveCheckbox: 'Supplier will be moved to inactive',
      productActiveCheckbox: 'Product will be moved to active',
      productInactiveCheckbox: 'Product will be moved to inactive',
      officeStaffActiveCheckbox: 'Office Staff will be moved to active',
      officeStaffInactiveCheckbox: 'Office Staff will be moved to inactive',
    },
    billingContract: {
      billingContractActiveCheckbox: 'Billing Contract will be moved to active',
      billingContractInactiveCheckbox:
        'Billing Contract will be moved to inactive',
      oneRoutineServiceMustBeSelected:
        'Kindly select at least one routine service',
      noBillingContracts: 'There are no billing contracts listed currently.',
    },
  },

  message: {
    common: {
      noRecordExportMessage: 'Please select at least 1 record for export.',
      uploadFileIncorrect:
        "Please check the upload file it's not in correct format.",
      noFileCSV: 'No file selected. Please upload a CSV.',
      fileEmpty: 'File cannot be empty',
    },
    errorPage: {
      status: '404',
      error: 'Page not found',
      errorMessage: 'The page you were looking for does not exist.',
    },
    forbiddenPage: {
      status: '403',
      forbidden: 'Forbidden',
      forbiddenMessage: 'You do not have permission to view this page.',
    },
    customer: {
      customerCreatedSuccessfully: 'Customer created successfully',
      customerUpdatedSuccessfully: 'Customer updated successfully',
      uploadDocumentMaxSizeError: 'File size exceeds the limit of 50 MB',
    },
    company: {
      companyCreatedSuccessfully: 'Fire company created successfully',
      companyUpdatedSuccessfully: 'Fire company updated successfully',
      uploadLogoMaxSizeError: 'File size exceeds the limit of 15 MB',
    },
    superAdmin: {
      superAdminCreatedSuccessfully: 'Super admin created successfully',
      superAdminUpdatedSuccessfully: 'Super admin updated successfully',
    },
    supplier: {
      createdSuccessfully: 'Supplier created successfully',
      updatedSuccessfully: 'Supplier updated successfully',
    },
    office_staff: {
      createdSuccessfully: 'Office Staff created successfully',
      updatedSuccessfully: 'Office Staff updated successfully',
    },
    productCatalogue: {
      createdSuccessfully: 'Product created successfully',
      updatedSuccessfully: 'Product updated successfully',
    },
    work_order: {
      unableToDownloadPDF: 'Unable to download PDF. Please try again later.',
    },
    timesheet: {
      noTimerangeSelectMessage:
        'Please select a timerange to download for timesheet entries.',
      noTechnicianSelectMessage: 'Please select at least 1 technician',
      timerangeExcedMessage:
        'You are allowed to select timesheet entries for download within a 30-day period. Please ensure your selection is within this timeframe',
      technicianlistExcedMessage:
        'You are allowed to select a maximum of 5 technicians.',
    },
  },

  confirmationModal: {
    title: 'Warning: Unsaved Changes',
    description: 'Closing the panel will result in losing any unsaved changes.',

    scheduleBoard: {
      title: 'Unassigned Work Order',
      description: 'Are you sure you want to unassign this work order?',
    },
  },

  deleteConfirmationModal: {
    title: 'Warning: Are you sure you want to delete',
    description: 'Once this item is deleted, it cannot be restored.',
  },

  modal: {
    delete: {
      message: 'Are you sure you want to delete?',
    },
  },
};

export default { translation };
