import { createAsyncThunk } from '@reduxjs/toolkit';

import { APIs } from '../../constants/APIConstants';
import { SKILLS } from '../actionPrefix';
import { axiosInstanceTechnician } from '../axios';

// Get Skills List API
export const getSkillsDropdownList = createAsyncThunk(
  SKILLS.GET_SKILLS_DROPDOWN_LIST,
  async (req, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceTechnician.get(
        APIs.SKILLS.GET_SKILLS_DROPDOWN_LIST
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
