import React from 'react';

import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';

// Internal
import { PRIMARY } from '../../constants/Colors';

const FooterContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  bottom: 0,
  left: 0,
  width: '100%',
  height: '100px',
  borderTop: `1px solid ${theme.palette.divider}`,
  backgroundColor: theme.palette.secondary.main,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const LandingPageFooter = () => (
  <FooterContainer>
    <Typography sx={{ fontWeight: '400', fontSize: '16px' }}>
      Copyright © 2024 Fire <span style={{ color: PRIMARY }}>ORA</span>. All
      rights reserved.
    </Typography>
  </FooterContainer>
);

export default LandingPageFooter;
