// DroppableArea.js
import React from 'react';
import { useDrop } from 'react-dnd';

import { Box } from '@mui/material';

const DroppableArea = ({ id, onDrop, children }) => {
  const [{ isOver }, drop] = useDrop(() => ({
    accept: 'CHART',
    drop: (item) => onDrop(item.id, id),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  }));

  return <Box ref={drop}>{children}</Box>;
};

export default DroppableArea;
