import { createAsyncThunk } from '@reduxjs/toolkit';

import { APIs } from '../../constants/APIConstants';
import { ROUTINE } from '../actionPrefix';
import { axiosPropertyUrl } from '../axios';

// Get Routine List API
export const getRoutineStandardFrequencyList = createAsyncThunk(
  ROUTINE.GET_ROUTINE_ROUTINE_STANDARD_FREQUENCY_LIST,
  async ({ page, size, limit, standardUUID }, { rejectWithValue }) => {
    const params = new URLSearchParams();

    if (page) params.append('page', page);
    if (size) params.append('size', size);
    if (limit) params.append('limit', limit);
    if (standardUUID) params.append('standard_id', standardUUID);

    try {
      const response = await axiosPropertyUrl.get(
        `${APIs.ROUTINE.GET_ROUTINE_ROUTINE_STANDARD_FREQUENCY_LIST}?${params?.toString()}`
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
