import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { Box, Card, Divider, Typography } from '@mui/material';

import { getAssetDetails } from '../../../store/workOrder/api';
import { formatStatus, formattedDate } from '../../../utils';
import CustomCircularLoader from '../../CommonComponents/CustomLoader';

const KeyValueComponent = ({ name, value }) => (
  <Box>
    <Typography variant="body1">{name}</Typography>
    <Typography variant="body2" sx={{ textTransform: 'capitalize' }}>
      {value}
    </Typography>
  </Box>
);

const AssetView = ({ asset_id, work_order_id }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);

  const [asset, setAsset] = useState(null);

  useEffect(() => {
    if (asset_id && work_order_id) {
      setIsLoading(true);
      dispatch(
        getAssetDetails({ work_order_id: work_order_id, asset_uuid: asset_id })
      )
        .then((res) => setAsset(res?.payload?.data?.[0]))
        .finally(() => setIsLoading(false));
    }
  }, [work_order_id, asset_id]);

  return isLoading ? (
    <CustomCircularLoader />
  ) : (
    <Card sx={{ boxShadow: 'none !important' }}>
      <Box sx={{ padding: '12px' }}>
        <Typography variant="body1">{`${t('attributes.property.asset')} ${t('attributes.supplier.details')}`}</Typography>
      </Box>
      <Divider />
      <Box
        sx={{
          padding: '12px',
          display: 'flex',
          width: '100%',
          columnGap: '16px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: '16px',
            minWidth: '50%',
          }}
        >
          <KeyValueComponent
            name={t('attributes.productCatalogue.equipment_type')}
            value={asset?.asset?.equipment_type?.display_name || '-'}
          />
          <KeyValueComponent
            name={`${t('attributes.property.asset')} ${t('attributes.name')}`}
            value={asset?.asset?.label || '-'}
          />
          <KeyValueComponent
            name={t('attributes.property.status')}
            value={formatStatus(asset?.asset_status) || '-'}
          />
          <KeyValueComponent
            name={`${t('attributes.property.identification')} ${t('attributes.number')}`}
            value={asset?.asset?.identification_number || '-'}
          />
          <KeyValueComponent
            name={t('attributes.property.barcode')}
            value={asset?.asset?.barcode || '-'}
          />
          <KeyValueComponent
            name={t('attributes.model')}
            value={asset?.asset?.model || '-'}
          />
          <KeyValueComponent
            name={t('attributes.quantity')}
            value={asset?.asset?.quantity || '-'}
          />
          <KeyValueComponent
            name={`${t('attributes.installation')} ${t('attributes.date')}`}
            value={formattedDate(asset?.asset?.installation_date) || '-'}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: '16px',
            minWidth: '30%',
          }}
        >
          <KeyValueComponent
            name={t('attributes.property.equipment_class')}
            value={asset?.asset?.product?.display_name || '-'}
          />
          <KeyValueComponent
            name={t('attributes.location')}
            value={asset?.asset?.location || '-'}
          />
          <KeyValueComponent
            name={t('common.active')}
            value={formatStatus(asset?.asset?.is_active) || '-'}
          />
          <KeyValueComponent
            name={`${t('attributes.serial')} ${t('attributes.number')}`}
            value={asset?.asset?.serial_number || '-'}
          />
          <KeyValueComponent
            name={t('attributes.make')}
            value={asset?.asset?.make || '-'}
          />
          <KeyValueComponent
            name={t('attributes.size')}
            value={asset?.asset?.size || '-'}
          />
          <KeyValueComponent
            name={`${t('attributes.property.base')} ${t('attributes.date')}`}
            value={formattedDate(asset?.asset?.base_date) || '-'}
          />
          <KeyValueComponent
            name={t('attributes.property.internalNote')}
            value={asset?.asset?.internal_note || '-'}
          />
        </Box>
      </Box>
    </Card>
  );
};

export default AssetView;
