import { Box, Typography } from '@mui/material';
import { TimePicker } from 'rsuite';

import 'rsuite/dist/rsuite.min.css';
import { WARNING } from '../../constants/Colors';
import CustomTextField from './CustomTextField';

const CustomTimePicker = ({
  error,
  label,
  value,
  isRequired = true,
  onChange,
  onClear,
  ...rest
}) => {
  const placeholderText = `${label}${isRequired ? ' *' : ''}`;
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <TimePicker
        format="hh:mm aa"
        showMeridiem
        placeholder={placeholderText}
        size="md"
        renderInput={(inputProps) => <CustomTextField {...inputProps} />}
        onChange={onChange}
        value={value}
        onClean={onClear}
        {...rest}
      />
      {error && (
        <Typography variant="body2" sx={{ color: WARNING }}>
          {error}
        </Typography>
      )}
    </Box>
  );
};
export default CustomTimePicker;
