import { createSlice } from '@reduxjs/toolkit';

import {
  getQuotationStatus,
  getQuotationTotalAmount,
  getQuotesList,
  getQuotesPropertyDropdownList,
  getQuotesStatusDropdownList,
  getQuotesTypeDropdownList,
} from './api';

const quotesTypesDropdownList = {
  isLoading: true,
  error: false,
  data: false,
};

const quotesStatusDropdownList = {
  isLoading: true,
  error: false,
  data: false,
};

const quotationStatusCount = {
  isLoading: true,
  error: false,
  data: false,
};

const quotationTotalAmount = {
  isLoading: true,
  error: false,
  data: false,
};

const quotesPropertyDropdownList = {
  isLoading: true,
  error: false,
  data: false,
};

const initialGetData = {
  isLoading: true,
  error: null,
  quoteList: [],
  page: 1,
  size: 10,
  total: 0,
  totalCount: 0,
};

const quoteSlice = createSlice({
  name: 'quotes',
  initialState: {
    get: { ...initialGetData },
    quotesTypesDropdownList,
    quotesStatusDropdownList,
    quotesPropertyDropdownList,
    quotationStatusCount,
    quotationTotalAmount,
  },
  reducers: {
    resetQuoteList(state) {
      state.get = { ...initialGetData };
    },
    resetQuotationTotalAmount(state) {
      state.quotationTotalAmount = { ...quotationTotalAmount };
    },
    resetQuotationStatusCount(state) {
      state.quotationStatusCount = { ...quotationStatusCount };
    },
  },
  extraReducers: (builder) => {
    builder
      // Quote List
      .addCase(getQuotesList.pending, (state) => {
        state.get.isLoading = true;
      })
      .addCase(getQuotesList.fulfilled, (state, action) => {
        const { data, pagination } = action.payload;

        state.get.isLoading = false;
        state.get.quoteList = data;
        state.get.total = pagination.total_items;
        state.get.totalCount = pagination.total_count;
      })
      .addCase(getQuotesList.rejected, (state, action) => {
        state.get.isLoading = false;
        state.get.error = action.payload;
      })

      // Get Quote type List
      .addCase(getQuotesTypeDropdownList.pending, (state) => {
        state.quotesTypesDropdownList.isLoading = true;
      })
      .addCase(getQuotesTypeDropdownList.fulfilled, (state, action) => {
        state.quotesTypesDropdownList.isLoading = false;
        state.quotesTypesDropdownList.data = action.payload;
      })
      .addCase(getQuotesTypeDropdownList.rejected, (state, action) => {
        state.quotesTypesDropdownList.isLoading = false;
        state.quotesTypesDropdownList.error = action.error.message;
      })

      // Get Quote Property List
      .addCase(getQuotesPropertyDropdownList.pending, (state) => {
        state.quotesPropertyDropdownList.isLoading = true;
      })
      .addCase(getQuotesPropertyDropdownList.fulfilled, (state, action) => {
        state.quotesPropertyDropdownList.isLoading = false;
        state.quotesPropertyDropdownList.data = action.payload;
      })
      .addCase(getQuotesPropertyDropdownList.rejected, (state, action) => {
        state.quotesPropertyDropdownList.isLoading = false;
        state.quotesPropertyDropdownList.error = action.error.message;
      })

      // Get Quote Status List
      .addCase(getQuotesStatusDropdownList.pending, (state) => {
        state.quotesStatusDropdownList.isLoading = true;
      })
      .addCase(getQuotesStatusDropdownList.fulfilled, (state, action) => {
        state.quotesStatusDropdownList.isLoading = false;
        state.quotesStatusDropdownList.data = action.payload;
      })
      .addCase(getQuotesStatusDropdownList.rejected, (state, action) => {
        state.quotesStatusDropdownList.isLoading = false;
        state.quotesStatusDropdownList.error = action.error.message;
      })
      // Get Quotation Status Count
      .addCase(getQuotationStatus.pending, (state) => {
        state.quotationStatusCount.isLoading = true;
      })
      .addCase(getQuotationStatus.fulfilled, (state, action) => {
        state.quotationStatusCount.isLoading = false;
        state.quotationStatusCount.data = action.payload.data;
      })
      .addCase(getQuotationStatus.rejected, (state, action) => {
        state.quotationStatusCount.isLoading = false;
        state.quotationStatusCount.error = action.error.message;
      })

      // Get Quotation Total Amount
      .addCase(getQuotationTotalAmount.pending, (state) => {
        state.quotationTotalAmount.isLoading = true;
      })
      .addCase(getQuotationTotalAmount.fulfilled, (state, action) => {
        state.quotationTotalAmount.isLoading = false;
        state.quotationTotalAmount.data = action.payload.data;
      })
      .addCase(getQuotationTotalAmount.rejected, (state, action) => {
        state.quotationTotalAmount.isLoading = false;
        state.quotationTotalAmount.error = action.error.message;
      });
  },
});

export const {
  resetQuoteList,
  resetQuotationStatusCount,
  resetQuotationTotalAmount,
} = quoteSlice.actions;

export default quoteSlice.reducer;
