import React, { useMemo } from 'react';
import { useDrag } from 'react-dnd';

import { Box } from '@mui/material';

const DraggableChart = ({ id, children }) => {
  // Memoize the item object to avoid unnecessary recalculations
  const dragItem = useMemo(() => ({ id }), [id]);

  // Setup useDrag hook
  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: 'CHART',
      item: dragItem, // Use the memoized dragItem
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [dragItem]
  );

  return (
    <Box
      ref={drag}
      elevation={3}
      sx={{
        opacity: isDragging ? 0.5 : 1,
        textAlign: 'center',
        cursor: 'move',
      }}
    >
      {children}
    </Box>
  );
};

export default DraggableChart;
