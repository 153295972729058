import { useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Box, Card, styled } from '@mui/material';

import { Validation } from '../../../constants/FieldValidationMsg';
import CustomTextField from '../../CommonComponents/CustomTextField';

const FormWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '16px',
}));

const CustomCard = styled(Card)(() => ({
  boxShadow: 'none',
}));

const FormFieldWrapper = styled(Box)(() => ({
  padding: '16px',
  rowGap: '16px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));

const EditPurchaseOrderProduct = ({
  control,
  reset,
  trigger,
  setValue,
  data,
}) => {
  const { t } = useTranslation();
  const clearHandler = (name) => setValue(name, '');

  useEffect(() => {
    if (data) {
      reset({
        product_name: data.product,
        qty: data.qty,
        sell_price: parseFloat(data.unit_price || 0)?.toFixed(2),
        product_catalogue_uuid: data.product_catalogue_uuid,
        uuid: data.uuid,
        product_catalogue_type: data.product_catalogue_type,
        product_uuid: data.product_uuid,
      });
    }
  }, [data]);

  return (
    <FormWrapper>
      <CustomCard>
        <FormFieldWrapper component="form">
          <Controller
            name="product_name"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <CustomTextField
                label={t('attributes.productCatalogue.product_name')}
                fullWidth
                value={value}
                onChange={(e) => {
                  onChange(e);
                  trigger('product_name');
                }}
                disabled={true}
                helperText={error ? error.message : ''}
                error={error}
              />
            )}
          />
          <Controller
            name="qty"
            control={control}
            rules={{
              required: `${t('attributes.quantity')} ${Validation.general.required}`,
            }}
            render={({
              field: { onChange, value, name, onBlur },
              fieldState: { error },
            }) => (
              <CustomTextField
                label={t('attributes.quantity')}
                fullWidth
                value={value}
                onChange={(e) => {
                  const numericValue = e.target.value.replace(/\D/g, '');

                  onChange(numericValue);
                  trigger('qty');
                }}
                onBlur={() => {
                  onChange(value?.trim());
                  onBlur();
                }}
                helperText={error ? error.message : ''}
                error={error}
                onClear={() => clearHandler(name)}
              />
            )}
          />
          <Controller
            name="sell_price"
            control={control}
            rules={{
              required: `${t('attributes.productCatalogue.sell_price')} ${Validation.general.required}`,
            }}
            render={({
              field: { onChange, value, name, onBlur },
              fieldState: { error },
            }) => (
              <CustomTextField
                type="number"
                label={t('attributes.productCatalogue.sell_price')}
                fullWidth
                value={value}
                onChange={(e) => {
                  if (e?.target?.value > 0 || e?.target?.value === '') {
                    onChange(e);
                    trigger('sell_price');
                  }
                }}
                onBlur={() => {
                  onChange(value.trim());
                  onBlur();
                }}
                helperText={error ? error.message : ''}
                error={error}
                onClear={() => clearHandler(name)}
              />
            )}
          />
        </FormFieldWrapper>
      </CustomCard>
    </FormWrapper>
  );
};

export default EditPurchaseOrderProduct;
