import React from 'react';

import { Box, Typography } from '@mui/material';

export const ComingSoon = () => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
    }}
  >
    <Typography variant="body1">Coming Soon</Typography>
  </Box>
);

export default ComingSoon;
