import React, { useCallback, useEffect, useMemo } from 'react';
import { Calendar, momentLocalizer, Views } from 'react-big-calendar';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Typography } from '@mui/material';
import { debounce } from 'lodash';
import moment from 'moment';

import {
  COMPLETED,
  getStatusLabels,
  IN_PROGRESS,
  SCHEDULE_BOARD_WO_UTC_LOCAL,
  SCHEDULED,
  WO_STATUS,
} from '../../constants/Constants';
import { snackbarToggle } from '../../store/CommonReducer';
import {
  getAssignedWorkOrder,
  postAssignWOtoTech,
} from '../../store/scheduleBoard/api';
import { formatStatus, formattedDate } from '../../utils';
import CustomButtonLoader from '../CommonComponents/CustomButtonLoader';
import CustomCircularLoader from '../CommonComponents/CustomLoader';

const localizer = momentLocalizer(moment);
const DragAndDropCalendar = withDragAndDrop(Calendar);

const ScheduleBoardCalendar = ({
  workOrders,
  setWorkOrders,
  handleWorkOrderClick,
  draggedWorkOrder,
  setDraggedWorkOrder,
  date,
  setDate,
  selectedView,
  isWorkOrderUpdated,
  setIsWorkOrderUpdated,
  displayCalendarLoader,
  setDisplayCalendarLoader,
  setServerErrors,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Get the technicians list from Redux store
  const { data: technicianList } = useSelector(
    (state) => state.technician.technicianNamesDropdownList
  );

  const scheduleBoardDate = useMemo(() => {
    if (Views.WEEK === selectedView.value) {
      const startDate = new Date(moment(date).startOf('week'));
      const endDate = new Date(moment(date).endOf('week'));

      return { startDate, endDate };
    } else if (Views.DAY === selectedView.value) {
      return {
        startDate: new Date(date),
        endDate: new Date(date),
      };
    }
  }, [date, selectedView]);

  // Get Work Order for Board Start
  const getAssignedWorkOrders = useCallback(() => {
    const uuids = technicianList.data?.map((item) => item.uuid)?.join(',');
    const { startDate, endDate } = scheduleBoardDate;

    dispatch(
      getAssignedWorkOrder({
        page: 1,
        size: 1,
        limit: -1,
        technicianUUID: uuids,
        latestStatus: WO_STATUS.confirmed,
        scheduleStartDate: startDate,
        scheduleEndDate: endDate,
        unassignedWorkOrder: false,
      })
    ).then((response) => {
      if (response?.payload?.data?.length > 0) {
        const workOrders = response.payload.data?.map((item) => {
          const WOStatus = getStatusLabels(item);

          return {
            id: item.uuid,
            fullDayTitle: `W-${item.id}`,
            title: `W-${item.id} - ${
              item.property_equipment_routine_service_schedule?.equipment_type
                ?.display_name
            } - ${item?.work_order_type?.toUpperCase()}`,
            start: formattedDate(
              item.scheduled_start_date_time,
              SCHEDULE_BOARD_WO_UTC_LOCAL
            ),
            end: formattedDate(
              item.scheduled_end_date_time,
              SCHEDULE_BOARD_WO_UTC_LOCAL
            ),
            resourceId: item.technician_uuid,
            workOrderStatus: WOStatus,
            isBookingRequired: [SCHEDULED].includes(WOStatus),
            isEditable:
              [SCHEDULED].includes(WOStatus) ||
              ![IN_PROGRESS, COMPLETED].includes(WOStatus),
          };
        });

        setWorkOrders(workOrders);
      }
      setDisplayCalendarLoader(false);
    });
  }, [dispatch, technicianList, isWorkOrderUpdated, date, selectedView]);

  // Debounced function to avoid frequent API calls
  const debouncedFetchData = useCallback(debounce(getAssignedWorkOrders, 500), [
    getAssignedWorkOrders,
  ]);

  // Call debouncedFetchData whenever search, page, or filter changes
  useEffect(() => {
    debouncedFetchData();

    // Clean up function for debounce to avoid memory leaks
    return () => {
      debouncedFetchData.cancel();
    };
  }, [debouncedFetchData]);

  const technicianRefactoredList =
    technicianList.data?.map(({ uuid, display_name }, index) => ({
      resourceIndex: index,
      resourceId: uuid,
      resourceTitle: display_name,
    })) || [];

  const refactoredWorkOrders = useMemo(
    () =>
      workOrders.map((event) => ({
        ...event,
        start: new Date(event.start),
        end: new Date(event.end),
      })),
    [workOrders]
  );

  // Scheduler Start
  const customOnDragOverFromOutside = useCallback(
    (dragEvent) => {
      if (draggedWorkOrder !== 'undroppable') {
        dragEvent.preventDefault();
      }
    },
    [draggedWorkOrder]
  );

  const dragFromOutsideItem = useCallback(
    () => (draggedWorkOrder === 'undroppable' ? null : draggedWorkOrder),
    [draggedWorkOrder]
  );

  const onDropFromOutside = useCallback(
    ({ start, end, resource, allDay: isAllDay }) => {
      // DragAndDropCalendar = withDragAndDrop(Calendar);

      if (
        (!draggedWorkOrder?.workOrderId && start && end && resource) ||
        !resource
      ) {
        dispatch(
          snackbarToggle({
            isOpen: true,
            isErrorMsg: true,
            msg: t('attributes.scheduleBoard.cannotAssignForDay'),
          })
        );

        return;
      } else if (
        moment(start) < moment() ||
        draggedWorkOrder === 'undroppable'
      ) {
        setDraggedWorkOrder(null);

        dispatch(
          snackbarToggle({
            isOpen: true,
            isErrorMsg: true,
            msg: t('attributes.scheduleBoard.cannotAssignToPastDateTime'),
          })
        );

        return;
      } else if (draggedWorkOrder?.workOrderId) {
        // Set the start and end times for all-day refactoredWorkOrders
        const startDate = isAllDay
          ? new Date(start.setHours(0, 0, 0, 0))
          : start;
        const endDate = isAllDay
          ? new Date(end.setHours(23, 59, 59, 999))
          : new Date(start.getTime() + 60 * 60 * 1000);

        const { workOrderId, title, fullDayTitle } = draggedWorkOrder;

        const newWorkOrder = {
          title: isAllDay ? fullDayTitle : title,
          start: startDate,
          end: endDate,
          allDay: isAllDay || false,
          id: workOrderId,
          resourceId: resource,
          isWOLoading: true,
        };

        setDraggedWorkOrder(null);
        setWorkOrders((prev) => [...prev, { ...newWorkOrder }]);
        dispatch(
          postAssignWOtoTech({
            workOrderId,
            startDate,
            endDate,
            resource,
          })
        ).then((res) => {
          if (res?.payload?.status !== 200 && res?.payload?.status !== 201) {
            setServerErrors(res?.payload?.errorDetails);
          }
          setIsWorkOrderUpdated((prev) => !prev);
        });
      }
    },
    [draggedWorkOrder, setDraggedWorkOrder]
  );

  const moveWorkOrder = useCallback(
    ({ event, start, end, resourceId, isAllDay }) => {
      // Set the start and end times for all-day refactoredWorkOrders
      const startDate = isAllDay
        ? new Date(moment(start).startOf('day').toDate())
        : start;
      const endDate = isAllDay
        ? new Date(moment(start).add(1, 'days').startOf('day').toDate())
        : end;

      if (moment(startDate) < moment()) {
        dispatch(
          snackbarToggle({
            isOpen: true,
            isErrorMsg: true,
            msg: t('attributes.scheduleBoard.cannotAssignToPastDateTime'),
          })
        );

        return;
      }

      const updateWO = {
        ...event,
        start: startDate,
        end: endDate,
        resourceId,
        allDay: isAllDay,
      };
      const selectedWOIndex = workOrders.find(
        (existingWorkOrder) => existingWorkOrder.id === event.id
      );

      setWorkOrders((prev) =>
        prev.map((event) =>
          event.id === selectedWOIndex.id
            ? {
                ...updateWO,
                isWOLoading: true,
              }
            : event
        )
      );

      dispatch(
        postAssignWOtoTech({
          workOrderId: event.id,
          startDate: startDate,
          endDate: endDate,
          resource: resourceId,
        })
      ).then((res) => {
        if (res?.payload?.status !== 200 && res?.payload?.status !== 201) {
          setServerErrors(res?.payload?.errorDetails);
        }
        setIsWorkOrderUpdated((prev) => !prev);
      });
    },
    [workOrders]
  );

  const resizeWorkOrder = useCallback(
    ({ event, start, end }) => {
      const updateWO = { ...event, start, end };

      if (moment(start) < moment()) {
        dispatch(
          snackbarToggle({
            isOpen: true,
            isErrorMsg: true,
            msg: t('attributes.scheduleBoard.cannotAssignToPastDateTime'),
          })
        );

        return;
      }

      if (moment(end).diff(moment(start), 'hours') < 1) {
        dispatch(
          snackbarToggle({
            isOpen: true,
            isErrorMsg: true,
            msg: t('attributes.scheduleBoard.minimumDurationForWO'),
          })
        );

        return;
      }

      setWorkOrders((prev) =>
        prev.map((existingWorkOrder) =>
          existingWorkOrder.id === event.id
            ? {
                ...updateWO,
                isWOLoading: true,
              }
            : existingWorkOrder
        )
      );

      dispatch(
        postAssignWOtoTech({
          workOrderId: event.id,
          startDate: start,
          endDate: end,
          resource: event.resourceId,
        })
      ).then((res) => {
        if (res?.payload?.status !== 200 && res?.payload?.status !== 201) {
          setServerErrors(res?.payload?.errorDetails);
        }
        setIsWorkOrderUpdated((prev) => !prev);
      });
    },
    [setWorkOrders]
  );

  const workOrderPropGetter = (event) => {
    const isDraggable =
      event.isDraggable !== undefined ? event.isDraggable : true;

    return {
      className: `${isDraggable ? 'isDraggable' : 'nonDraggable'} ${event.isEditable ? 'isEditable' : 'nonEditable'} ${event.isBookingRequired ? 'isBookingRequired' : 'noBookingRequired'}`,
      style: { cursor: isDraggable ? 'pointer' : 'default' },
    };
  };
  // Scheduler End

  const customWorkOrderComponent = (event) => (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
        gap={2}
      >
        <Typography
          sx={{
            flexGrow: 1,
            textAlign: 'start',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          }}
        >
          {moment(event?.event?.end).diff(
            moment(event?.event?.start),
            'hours'
          ) >= 24
            ? event?.event.fullDayTitle
            : event.title}
        </Typography>
        {event?.event?.isWOLoading && (
          <Box sx={{ textAlign: 'end', paddingLeft: '20px' }}>
            <CustomButtonLoader
              sx={{ top: '0 !important', position: 'relative !important' }}
            />
          </Box>
        )}
      </Box>
      <Box
        sx={{
          flexGrow: 1,
          textAlign: 'start',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
        }}
      >
        {formatStatus(event?.event?.workOrderStatus)}
      </Box>
    </Box>
  );

  if (displayCalendarLoader) {
    return (
      <Box
        sx={{
          width: '100%',
          mt: 2,
          minHeight: `420px`,
          maxHeight: `420px`,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <CustomCircularLoader />
      </Box>
    );
  }

  const resourceAccessor = (resourceIndex) => {
    if (resourceIndex % 2 === 0) return 'even-resource';

    return 'odd-resource';
  };

  return (
    <DragAndDropCalendar
      localizer={localizer}
      defaultView={Views.DAY}
      events={refactoredWorkOrders}
      onEventDrop={moveWorkOrder}
      onEventResize={resizeWorkOrder}
      onDropFromOutside={onDropFromOutside}
      onDragOverFromOutside={customOnDragOverFromOutside}
      // dragFromOutsideItem={dragFromOutsideItem}
      eventPropGetter={workOrderPropGetter}
      resizable={true}
      startAccessor="start"
      endAccessor="end"
      resources={technicianRefactoredList}
      resourceIdAccessor="resourceId"
      resourceTitleAccessor={({ resourceIndex, resourceTitle }) => (
        <span className={resourceAccessor(resourceIndex)}>{resourceTitle}</span>
      )}
      view={selectedView?.value}
      style={{ height: 428 }}
      onSelectEvent={handleWorkOrderClick}
      toolbar={false}
      date={date}
      onNavigate={setDate}
      draggableAccessor={(event) =>
        moment(event.start).isAfter(moment()) &&
        !event.isWOLoading &&
        event.isEditable
      }
      resizableAccessor={(event) =>
        moment(event.end).isAfter(moment()) &&
        !event.isWOLoading &&
        event.isEditable
      }
      allDayMaxRows={2}
      popup
      components={{
        event: customWorkOrderComponent,
      }}
      tooltipAccessor={(event) => `${event.title}\n${event?.workOrderStatus}`}
      scrollToTime={moment().subtract(3, 'hours').toDate()}
      dayLayoutAlgorithm={'no-overlap'}
    />
  );
};

export default ScheduleBoardCalendar;
