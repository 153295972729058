import { createTheme } from '@mui/material/styles';

import {
  DISABLE,
  DIVIDER_COLOR,
  PRIMARY,
  SECONDARY,
  TEXT_COLOR,
} from '../constants/Colors';
import { FONT_SIZE } from '../constants/Constants';

let defaultTheme = createTheme({
  palette: {
    primary: {
      main: PRIMARY,
      contrastText: SECONDARY,
    },
    secondary: {
      main: SECONDARY,
      contrastText: TEXT_COLOR,
    },
  },
});

export const theme = createTheme(defaultTheme, {
  palette: {
    primary: {
      main: defaultTheme.palette.primary.main,
    },
    secondary: {
      main: defaultTheme.palette.secondary.main,
    },
    action: {
      disabled: DISABLE,
    },
    divider: DIVIDER_COLOR,
  },
  typography: {
    fontFamily: '"Inter", "Helvetica", "Arial", sans-serif',
    fontWeightBold: 700,
    fontWeightMedium: 600,
    fontWeightLight: 500,
    fontWeightRegular: 400,
    htmlFontSize: 16,
    body1: {
      fontWeight: 600,
      fontSize: FONT_SIZE, // 14px
    },
    common400: {
      fontWeight: 400,
    },
    common600: {
      fontWeight: 600,
    },
    body2: {
      fontWeight: 400,
      fontSize: FONT_SIZE, // 14px
    },
    body3: {
      fontSize: '1.625rem', // 24px
      fontWeight: 700,
    },
    h1: {
      fontSize: '1rem', // 16px
      fontWeight: 600,
    },
    h2: {
      fontSize: FONT_SIZE, // 14px
      fontWeight: 700,
    },
    h3: {
      fontSize: '1.125rem', // 18px
      fontWeight: 600,
      lineHeight: '1.625rem',
    },
    h4: {
      fontSize: '1.5rem', // 24px
      fontWeight: 700,
    },
    h5: {
      fontSize: '2.25rem', // 36px
      fontWeight: 700,
    },
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: '"Inter", "Helvetica", "Arial", sans-serif', // Force Inter on Typography
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          height: '50px',
          '&:hover': {
            backgroundColor: 'none',
          },
          '&.Mui-disabled': {
            color: '#00000061 !important',
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          height: '36px', //Have to remvoe height in future
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: '6px',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: `1px solid ${DIVIDER_COLOR}`,
          padding: '8px',
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: defaultTheme.palette.primary.main,
            color: defaultTheme.palette.secondary.main,
            svg: {
              path: {
                fill: defaultTheme.palette.secondary.main,
              },
            },
            '&:hover': {
              backgroundColor: defaultTheme.palette.primary.main,
              color: defaultTheme.palette.secondary.main,
            },
          },
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 0,
          '& .MuiSvgIcon-root': {
            height: '24px',
            width: '24px',
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          fontSize: FONT_SIZE,
        },
      },
    },

    MuiSvgIcon: {
      styleOverrides: {
        root: {
          cursor: 'pointer',
          fontSize: '1rem !important',
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            paddingTop: '2.5px !important',
            paddingBottom: '2.5px !important',
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontFamily: '"Inter", "Helvetica", "Arial", sans-serif',
        },
      },
    },
    // Add global scrollbar styles
    MuiCssBaseline: {
      styleOverrides: `
        /* For WebKit browsers (Chrome, Safari, Edge) */
        ::-webkit-scrollbar {
          width: 4px;
          height: ${defaultTheme.spacing(1)};
        }
        ::-webkit-scrollbar-track {
          background-color: ${defaultTheme.palette.background.default};
        }
        ::-webkit-scrollbar-thumb {
          background-color: ${defaultTheme.palette.primary.main};
          border-radius: ${defaultTheme.shape.borderRadius};
        }
        ::-webkit-scrollbar-thumb:hover {
          background-color: ${defaultTheme.palette.primary.dark};
        }
        /* For Firefox */
        scrollbar-width: thin;
        scrollbar-color: ${defaultTheme.palette.primary.main} ${defaultTheme.palette.background.default};
      `,
    },
  },
});
