import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import AssignmentReturnedIcon from '@mui/icons-material/AssignmentReturned';
import CloseIcon from '@mui/icons-material/Close';
import {
  Backdrop,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Tooltip,
  Typography,
  styled,
} from '@mui/material';
import moment from 'moment';

import { WARNING } from '../../constants/Colors';
import {
  DATE_TIME_RANGE_FORMAT,
  DATE_TIME_RANGE_FORMAT_PLACEHOLDER,
} from '../../constants/Constants';
import { Validation } from '../../constants/FieldValidationMsg';
import CustomButton from '../CommonComponents/CustomButton';
import CustomDateRangePicker from '../CommonComponents/CustomDateRangePicker';
import CustomTextField from '../CommonComponents/CustomTextField';
import { ConfirmationModal } from '../CommonComponents/Modal';

// Styled Components
const CustomDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    width: '800px',
    maxWidth: '800px',
    overflow: 'hidden',
    height: '384px',
    maxHeight: '384px',
    padding: '8px',
    backgroundColor: theme.palette.secondary.main,
  },
}));

const CustomDialogContent = styled(DialogContent)(({ theme }) => ({
  display: 'flex',
  background: theme.palette.secondary.main,
  flexDirection: 'column',
  padding: '16px',
  borderRadius: '4px',
  gap: '16px',
}));

const CloseButton = styled(IconButton)(({ theme, isUnAssign }) => ({
  position: 'absolute',
  right: isUnAssign || 0,
  top: 0,
  margin: '16px',
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.secondary.main,
  width: '36px',
  height: '36px',
  '&:hover': {
    backgroundColor: theme.palette.secondary.main,
    svg: {
      path: {
        fill: theme.palette.primary.main,
      },
    },
  },
}));

export const WorkOrderPopUp = ({
  open,
  setOpen,
  selectWorkOrder,
  setSelectedWorkOrder,
  WOError,
  handleUnassignWOFromTech = () => {},
  handleSave = () => {},
  setWOError = () => {},
}) => {
  const { t } = useTranslation();
  const { isLoading } = useSelector(
    (state) => state.scheduleBoard.assignWOtoTech
  );
  const { isLoading: isRemoving } = useSelector(
    (state) => state.scheduleBoard.unassignedWOFromTech
  );

  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

  const isPastWorkOrder = !selectWorkOrder.isEditable;

  const title = `${isPastWorkOrder ? t('attributes.view') : t('attributes.edit')} ${t('attributes.scheduleBoard.workOrder')}`;

  return (
    <>
      <CustomDialog
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        open={open}
        onClose={null}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
      >
        <DialogTitle>{title}</DialogTitle>
        {!isPastWorkOrder && (
          <Tooltip title={t('confirmationModal.scheduleBoard.title')} arrow>
            <CloseButton
              isUnAssign={40}
              onClick={() => setOpenConfirmationModal(true)}
              disabled={isLoading || isRemoving}
            >
              <AssignmentReturnedIcon />
            </CloseButton>
          </Tooltip>
        )}
        <CloseButton onClick={() => setOpen(false)}>
          <CloseIcon />
        </CloseButton>
        <CustomDialogContent>
          <Typography variant="body2">
            <CustomTextField
              label={t('attributes.scheduleBoard.workOrderName')}
              fullWidth
              value={selectWorkOrder.title}
              isRequired={false}
              disabled={!isPastWorkOrder}
              displayEndAdornment={!isPastWorkOrder}
            />
            <Box sx={{ mt: '16px' }}>
              <CustomDateRangePicker
                width="100%"
                placeholder={`${DATE_TIME_RANGE_FORMAT_PLACEHOLDER} - ${DATE_TIME_RANGE_FORMAT_PLACEHOLDER}`}
                format={DATE_TIME_RANGE_FORMAT}
                onOkClick={(val) => {
                  setSelectedWorkOrder((prev) => ({
                    ...prev,
                    startDate: val[0],
                    endDate: val[1],
                    isEditable: true,
                  }));
                  if (moment(val[0]).isBefore(moment())) {
                    setWOError({
                      date: t(
                        'attributes.scheduleBoard.selectFutureDateAndTime'
                      ),
                    });
                  } else if (
                    moment(val[1]).isBefore(moment(val[0])) ||
                    moment(val[1]).isSame(moment(val[0])) ||
                    moment(val[1]).isBefore(moment()) ||
                    moment(val[1]).diff(moment(val[0]), 'hours') < 1
                  ) {
                    setWOError({
                      date: t('attributes.scheduleBoard.minimumDurationForWO'),
                    });
                  } else {
                    setWOError(null);
                  }
                }}
                onClear={() => {
                  setSelectedWorkOrder((prev) => ({
                    ...prev,
                    startDate: null,
                    endDate: null,
                  }));
                  setWOError({
                    date: `${t('attributes.scheduleBoard.dateAndTime')} ${Validation.general.required}`,
                  });
                }}
                fromDate={selectWorkOrder.startDate}
                toDate={selectWorkOrder.endDate}
                showMeridian
                disabledDate={(current) => current < moment().startOf('day')}
                readOnly={isPastWorkOrder}
              />
              {WOError?.date && (
                <Typography variant="body2" color={WARNING}>
                  {WOError?.date}
                </Typography>
              )}
            </Box>
          </Typography>
        </CustomDialogContent>
        {!isPastWorkOrder && (
          <Box>
            <Divider />
            <DialogActions>
              <CustomButton
                text="Cancel"
                color="inherit"
                onClick={() => setOpen(false)}
              />
              <CustomButton
                onClick={handleSave}
                disabled={WOError?.date || isLoading || isRemoving}
                text={isLoading ? 'Loading...' : 'Save'}
              />
            </DialogActions>
          </Box>
        )}
      </CustomDialog>
      <ConfirmationModal
        title={t('confirmationModal.scheduleBoard.title')}
        description={t('confirmationModal.scheduleBoard.description')}
        open={openConfirmationModal}
        setOpen={setOpenConfirmationModal}
        onConfirm={() => {
          setOpenConfirmationModal();
          handleUnassignWOFromTech();
        }}
      />
    </>
  );
};
