import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import { Box, Divider, Typography } from '@mui/material';
import { debounce } from 'lodash';

import { SECONDARY } from '../../../constants/Colors';
import {
  DATE_FORMAT_API,
  PURCHASE_ORDER_STATUS,
  WORK_ORDER_STEPS,
} from '../../../constants/Constants';
import { SWIPEABLE_DRAWER_DEFECT_QUOTE_ADD_PRODUCT_WIDTH } from '../../../constants/Typography';
import useServerSideErrors from '../../../hooks/useServerSideErrors';
import { snackbarToggle } from '../../../store/CommonReducer';
import { editCompany } from '../../../store/company/api';
import {
  createPurchaseOrder,
  getPurchaseOrders,
  updatePurchaseOrder,
} from '../../../store/purchaseOrders/api';
import {
  resetCreatePurchaseOrder,
  resetUpdatePurchaseOrder,
} from '../../../store/purchaseOrders/reducer';
import {
  formatDateAPI,
  formatDateForAPI,
  formatStatus,
  formattedDate,
  loggedInUserDetail,
} from '../../../utils';
import { Edit, View } from '../../CommonComponents/ActionComponent';
import CustomButton from '../../CommonComponents/CustomButton';
import CustomGridTable from '../../CommonComponents/CustomGridTable';
import CustomCircularLoader from '../../CommonComponents/CustomLoader';
import { ConfirmationModal } from '../../CommonComponents/Modal';
import NewNoDataPage from '../../CommonComponents/NoDataPage/NewNoDataPage';
import NoRecordFound from '../../CommonComponents/NoDataPage/NoRecordFound';
import SwipeableDrawer from '../../CommonComponents/SwipeableDrawer';
import AddPurchaseOrder from './AddPurchaseOrder';
import generatePDF from './DonwloadPurchaseOrderPDF';
import PreviewPurchaseOrder from './PreviewPurchaseOrder';
import PurchaseOrderDetail from './PurchaseOrderDetail';

const defaultValues = {
  start_date: '',
  supplier_uuid: '',
  work_order_uuid: '',
  description: '',
  delivery_instruction: '',
};

const PurchaseOrders = ({ workOrderId, nextStepTitle, setNextStepTitle }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('created_at');
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({});
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [hasFormValues, sethasFormValues] = useState(false);
  const [serverErrors, setServerErrors] = useState([]);
  const [open, setOpen] = useState(false);
  const [openPreview, setOpenPreview] = useState(false);
  const [poid, setPoid] = useState();
  const [purchaseOrderDetails, setPurchaseOrderDetails] = useState({});
  const [loadingDetails, setLoadingDetails] = useState(false);

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    trigger,
    setError,
    clearErrors,
    reset,
  } = useForm({ defaultValues });

  const { handleServerErrors } = useServerSideErrors(
    serverErrors,
    setError,
    clearErrors
  );

  const watchedFields = watch();

  const { purchaseOrdersList, total } = useSelector(
    (state) => state.purchaseOrders.get
  );

  const {
    isLoading: purchaseOrderDetailLoading,
    error: purchaseOrderDetailError,
    purchaseOrderDetail,
  } = useSelector((state) => state.purchaseOrders.updatePurchaseOrder);

  const {
    createdPurchaseOrder,
    isLoading: createdPurchaseOrderLoading,
    error,
  } = useSelector((state) => state.purchaseOrders.create);

  const { isLoading: isLoadingCompany, company } = useSelector(
    (state) => state.company.edit
  );

  const user = loggedInUserDetail();

  useEffect(() => {
    const detail = purchaseOrdersList?.find((item) => item.poid === poid);

    if (detail) {
      setPurchaseOrderDetails(detail);
      setLoadingDetails(false);
    }
  }, [purchaseOrdersList, poid]);

  useEffect(() => {
    if (serverErrors?.length > 0) {
      handleServerErrors(); // Call the function to set the server-side errors in the form
    }
  }, [serverErrors, handleServerErrors]);

  useEffect(() => {
    sethasFormValues(Object.values(watchedFields).some((value) => value));
  }, [watchedFields]);

  const columns = useMemo(() => {
    const baseColumns = [
      {
        headerName: `${t('attributes.purchase_order.purchase_order_id')}`,
        field: 'poid',
        sortable: true,
        flex: 1,
      },
      {
        headerName: `${t('attributes.purchase_order.supplier')}`,
        field: 'supplier',
        sortable: false,
        flex: 1,
      },
      {
        headerName: `${t('attributes.description')}`,
        field: 'description',
        sortable: false,
        flex: 1,
      },
      {
        headerName: `${t('attributes.purchase_order.status')}`,
        field: 'status',
        sortable: false,
        flex: 1,
        renderCell: ({ row }) => formatStatus(row.status),
      },
      {
        headerName: `${t('common.total_amount')}`,
        field: 'totalAmount',
        sortable: false,
        flex: 1,
      },
      {
        headerName: `${t('attributes.work_order.created_on')}`,
        field: 'createdOn',
        sortable: false,
        flex: 1,
      },
      {
        field: `${t('attributes.actions')}`,
        headerName: t('attributes.actions'),
        flex: 0.5,
        sortable: false,
        hideable: false,
        renderCell: ({ row }) => (
          <Box sx={{ display: 'flex', gap: '8px' }}>
            <View
              disabled={row?.status === PURCHASE_ORDER_STATUS.DRAFT}
              onClick={() => {
                setLoadingDetails(true);
                setPoid(row?.poid);
                setOpenPreview(true);
                if (user.company_uuid) {
                  dispatch(editCompany(user?.company_uuid));
                }
              }}
            />
            <Edit
              onClick={() => {
                setNextStepTitle({
                  title: WORK_ORDER_STEPS.purchase_orders,
                  step: row?.poid,
                });

                dispatch(resetUpdatePurchaseOrder());
              }}
            />
          </Box>
        ),
      },
    ];

    return baseColumns;
  }, []);

  const getAmountByQtyAndPrice = (data) =>
    data
      ?.reduce((sum, item) => sum + item?.qty * parseFloat(item?.price || 0), 0)
      .toFixed(2);

  const rows = purchaseOrdersList?.map((item) => ({
    id: item.uuid,
    poid: item.poid,
    supplier: item.supplier?.name,
    description: item.description || '-',
    status: item.status,
    totalAmount: getAmountByQtyAndPrice(item.items) || '-',
    createdOn: formattedDate(item.creation_date) || '-',
  }));

  useEffect(
    () => () => {
      dispatch(resetCreatePurchaseOrder());
      dispatch(resetUpdatePurchaseOrder());
    },
    []
  );

  // Function to fetch data based on search, pagination, and filter
  const getAllPurchaseOrder = useCallback(() => {
    const visibleFieldsString = columns
      .filter((col) => columnVisibilityModel[col.field] !== false)
      .map((col) => col.field)
      .join(',');

    setIsDataLoading(true);

    dispatch(
      getPurchaseOrders({
        work_order_uuid: workOrderId,
        list_column_names: visibleFieldsString,
        order: order,
        orderBy: orderBy === 'poid' ? 'id' : orderBy,
        limit: -1,
      })
    ).finally(() => {
      setIsInitialLoading(false);
      setIsDataLoading(false);
    });
  }, [dispatch, order, orderBy, columns, columnVisibilityModel]);

  // Debounced function to avoid frequent API calls
  const debouncedFetchData = useCallback(debounce(getAllPurchaseOrder, 500), [
    getAllPurchaseOrder,
  ]);

  // Call debouncedFetchData whenever search, page, or filter changes
  useEffect(() => {
    debouncedFetchData();

    // Clean up function for debounce to avoid memory leaks
    return () => {
      debouncedFetchData.cancel();
    };
  }, [debouncedFetchData]);

  useEffect(() => {
    if (!createdPurchaseOrderLoading) {
      if (error) {
        if (error.errorDetails) {
          setServerErrors(error.errorDetails);
        }
      } else {
        if (createdPurchaseOrder) {
          setOpen(false);
          debouncedFetchData();
          dispatch(
            snackbarToggle({
              isOpen: true,
              isErrorMsg: false,
              msg: t(
                'attributes.purchase_order.purchase_order_created_successfully'
              ),
            })
          );
          setNextStepTitle({
            title: WORK_ORDER_STEPS.purchase_orders,
            step: createdPurchaseOrder?.poid,
          });
        }
      }
    }
  }, [error, createdPurchaseOrderLoading]);

  useEffect(() => {
    if (!purchaseOrderDetailLoading) {
      if (purchaseOrderDetailError) {
        if (purchaseOrderDetailError.errorDetails) {
          setServerErrors(purchaseOrderDetailError.errorDetails);
        }
      } else {
        if (purchaseOrderDetail) {
          setOpen(false);
          setOpenPreview(false);
          debouncedFetchData();
          dispatch(
            snackbarToggle({
              isOpen: true,
              isErrorMsg: false,
              msg: purchaseOrderDetail.message,
            })
          );
        }
      }
    }
  }, [purchaseOrderDetailLoading, purchaseOrderDetailError]);

  const onSubmit = (formData) => {
    const request = {
      creation_date: formatDateForAPI(formData?.creation_date),
      supplier_uuid: formData.supplier?.value,
      work_order_uuid: workOrderId,
      description: formData?.description,
      delivery_instruction: formData?.delivery_instruction,
    };

    if (nextStepTitle) {
      dispatch(
        updatePurchaseOrder({
          purchase_order_id: formData?.uuid,
          data: { ...request, status: formData?.status },
        })
      );
    } else {
      dispatch(createPurchaseOrder(request));
    }
  };

  const handleUpdatePurchaseOrderStatus = (status) => {
    const request = {
      creation_date: formatDateAPI(
        purchaseOrderDetails?.creation_date,
        DATE_FORMAT_API
      ),
      supplier_uuid: purchaseOrderDetails.supplier?.uuid,
      work_order_uuid: workOrderId,
      description: purchaseOrderDetails?.description,
      delivery_instruction: purchaseOrderDetails?.delivery_instruction,
    };

    dispatch(
      updatePurchaseOrder({
        purchase_order_id: purchaseOrderDetails?.uuid,
        data: { ...request, status: status },
      })
    );
  };

  const noData = (
    <>
      <NewNoDataPage
        icon={<PersonOutlineOutlinedIcon />}
        title={t('attributes.work_order.purchase_order')}
        createBtnText={t('common.create_new')}
        singularText={t('attributes.work_order.purchase_order')}
        filterHeight={380}
      />
    </>
  );

  const MainWrapper = (
    <>
      <Box sx={{ padding: '16px' }}>
        <Box
          sx={{
            display: 'flex',
            gap: '8px',
            alignItems: 'center',
            cursor: 'pointer',
            width: 'max-content',
          }}
          onClick={() => {
            setOpen(true);
            reset(defaultValues);
          }}
        >
          <AddCircleOutlineOutlinedIcon />
          <Typography variant="body1">{t('common.create_new')}</Typography>
        </Box>
      </Box>
      <Divider />
      {!isDataLoading && total === 0 && noData}
      {isInitialLoading ? (
        <Box
          sx={{
            width: '100%',
            mt: 2,
            display: 'flex',
            paddingTop: '200px',
            alignItems: 'center',
          }}
        >
          <CustomCircularLoader />
        </Box>
      ) : (
        !isInitialLoading &&
        total > 0 && (
          <Box>
            <CustomGridTable
              columns={columns}
              rows={rows || []}
              order={order}
              orderBy={orderBy}
              setOrder={setOrder}
              setOrderBy={setOrderBy}
              columnVisibilityModel={columnVisibilityModel}
              setColumnVisibilityModel={setColumnVisibilityModel}
              noData={<NoRecordFound />}
              isLoading={isDataLoading}
              checkboxSelection={false}
              paginationRequired={false}
              sx={{ paddingLeft: '18px' }}
            />
          </Box>
        )
      )}
    </>
  );

  return (
    <>
      {openConfirmationModal && (
        <ConfirmationModal
          title={t('confirmationModal.title')}
          description={t('confirmationModal.description')}
          open={openConfirmationModal}
          setOpen={setOpenConfirmationModal}
          onConfirm={() => {
            setOpen(false);
            setOpenConfirmationModal(false);
          }}
        />
      )}
      {!nextStepTitle ? (
        MainWrapper
      ) : (
        <PurchaseOrderDetail
          workOrderId={workOrderId}
          nextStepTitle={nextStepTitle}
          setOpenEditPurchaseDetail={setOpen}
        />
      )}

      {open && (
        <SwipeableDrawer
          open={open}
          title={
            nextStepTitle
              ? t('attributes.purchase_order.edit_purchase_order')
              : t('attributes.purchase_order.create_new_purchase_order')
          }
          onClose={() => {
            hasFormValues ? setOpenConfirmationModal(true) : setOpen(false);
          }}
          footerButton={
            <CustomButton
              startIcon={nextStepTitle ? <SaveOutlinedIcon /> : null}
              endIcon={nextStepTitle ? null : <ChevronRightIcon />}
              text={nextStepTitle ? t('attributes.save') : t('common.next')}
              onClick={handleSubmit(onSubmit)}
              disabled={false}
            />
          }
          width={SWIPEABLE_DRAWER_DEFECT_QUOTE_ADD_PRODUCT_WIDTH}
        >
          <AddPurchaseOrder
            control={control}
            setValue={setValue}
            trigger={trigger}
            reset={reset}
            nextStepTitle={nextStepTitle}
          />
        </SwipeableDrawer>
      )}

      {openPreview && (
        <SwipeableDrawer
          bgColor={SECONDARY}
          open={openPreview}
          title={purchaseOrderDetails?.poid}
          onClose={() => {
            setOpenPreview(false);
          }}
          footerButton={[
            <CustomButton
              text={t('attributes.download')}
              color="inherit"
              disabled={isLoadingCompany}
              startIcon={<DownloadOutlinedIcon />}
              onClick={() => {
                generatePDF(purchaseOrderDetails, company);
              }}
            />,
            purchaseOrderDetails?.status !==
              PURCHASE_ORDER_STATUS.SUBMITTED && (
              <CustomButton
                text={t('common.submit')}
                disabled={purchaseOrderDetailLoading || isLoadingCompany}
                startIcon={<SendOutlinedIcon />}
                onClick={() =>
                  handleUpdatePurchaseOrderStatus(
                    PURCHASE_ORDER_STATUS.SUBMITTED
                  )
                }
              />
            ),
          ]}
          width={653}
        >
          <PreviewPurchaseOrder
            companyData={company}
            purchaseOrderDetails={purchaseOrderDetails}
            isLoadingCompany={isLoadingCompany}
          />
        </SwipeableDrawer>
      )}
    </>
  );
};

export default PurchaseOrders;
