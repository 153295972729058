import { createAsyncThunk } from '@reduxjs/toolkit';
import moment from 'moment';

import { APIs } from '../../constants/APIConstants';
import { DATE_RANGE_FORMAT_API } from '../../constants/Constants';
import { OFFICE_STAFF } from '../actionPrefix';
import { axiosInstanceAuthAndFilter } from '../axios';

export const getOfficeStaffList = createAsyncThunk(
  OFFICE_STAFF.GET_OFFICE_STAFFS,
  async (
    {
      page,
      search,
      limit,
      active,
      state,
      city,
      order,
      orderBy,
      list_column_names,
      fromDate,
      toDate,
    },
    { rejectWithValue }
  ) => {
    const params = new URLSearchParams();

    // Add params conditionally
    if (page) params.append('page', page);
    if (search) params.append('search', search);
    if (limit) params.append('items_per_page', limit);
    if (state) params.append('state', state);
    if (city) params.append('city', city);
    if (order) params.append('sort_order', order);
    if (orderBy) params.append('sort_by', orderBy);
    if (list_column_names && search)
      params.append('list_column_names', list_column_names);
    if (fromDate)
      params.append(
        'from_date',
        moment(fromDate).format(DATE_RANGE_FORMAT_API)
      );
    if (toDate)
      params.append(
        'to_date',
        moment(toDate).endOf('day').format(DATE_RANGE_FORMAT_API)
      );
    params.append('active', active);

    try {
      const response = await axiosInstanceAuthAndFilter.get(
        `${APIs.OFFICE_STAFF.GET_OFFICE_STAFFS}?${params.toString()}`
      );

      return { ...response.data, active };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getOfficeStaffById = createAsyncThunk(
  OFFICE_STAFF.GET_OFFICE_STAFF_BY_ID,
  async (uuid, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceAuthAndFilter.get(
        APIs.OFFICE_STAFF.GET_UPDATE_OFFICE_STAFFS.replace('{id}', uuid)
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateOfficeStaff = createAsyncThunk(
  OFFICE_STAFF.UPDATE_OFFICE_STAFF,
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceAuthAndFilter.put(
        APIs.OFFICE_STAFF.GET_UPDATE_OFFICE_STAFFS.replace('{id}', id),
        data
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createOfficeStaff = createAsyncThunk(
  OFFICE_STAFF.CREATE_OFFICE_STAFF,
  async (req, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceAuthAndFilter.post(
        APIs.OFFICE_STAFF.CREATE_OFFICE_STAFFS,
        req
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
