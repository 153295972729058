import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import ForwardToInboxOutlinedIcon from '@mui/icons-material/ForwardToInboxOutlined';
import { Box } from '@mui/material';
import { debounce } from 'lodash';

import { SECONDARY } from '../../constants/Colors';
import {
  CUSTOMER_QUOTATION_STATUS,
  WORK_ORDER_STEPS,
} from '../../constants/Constants';
import { SWIPEABLE_DRAWER_DEFECT_QUOTE_ADD_PRODUCT_WIDTH } from '../../constants/Typography';
import { snackbarToggle } from '../../store/CommonReducer';
import { editCompany } from '../../store/company/api';
import { getProperty } from '../../store/property/api';
import {
  getDefectQuotesDetail,
  getWorkOrderQuotation,
  updateDefectQuotationStatus,
} from '../../store/workOrder/api';
import {
  resetDefectQuotes,
  resetDefectQuotesDetail,
  resetUpdateQuotationStatus,
} from '../../store/workOrder/reducer';
import { formattedDate } from '../../utils';
import { Edit, View } from '../CommonComponents/ActionComponent';
import CustomButton from '../CommonComponents/CustomButton';
import CustomGridTable from '../CommonComponents/CustomGridTable';
import CustomCircularLoader from '../CommonComponents/CustomLoader';
import { ConfirmationModal } from '../CommonComponents/Modal';
import NoRecordFound from '../CommonComponents/NoDataPage/NoRecordFound';
import StatusLabel from '../CommonComponents/StatusLabel';
import SwipeableDrawer from '../CommonComponents/SwipeableDrawer';
import AddProduct from './AddDefectQuotesProduct';
import generatePDF from './DefectQuotePDF';
import PreviewDefectQuoteInvoice from './PreviewDefectQuoteInvoice';

export const formatDefectQuotesStatus = (status = '', t = () => {}) => {
  const lowerCaseStatus =
    typeof status === 'string' ? status?.toLowerCase() : status;

  if (lowerCaseStatus === 'rejected') {
    return <StatusLabel label={t('common.status.rejected')} color="#C54036" />;
  }
  if (lowerCaseStatus === 'approved') {
    return <StatusLabel label={t('common.status.approved')} color="#95C020" />;
  }
  if (lowerCaseStatus === 'draft') {
    return <StatusLabel label={t('common.status.draft')} color="#999999" />;
  }
  if (lowerCaseStatus === 'submit_to_customer') {
    return <StatusLabel label={t('common.status.submitted')} color="#59366B" />;
  }
  if (lowerCaseStatus === 'created') {
    return (
      <StatusLabel
        label={t('common.status.inReview')}
        color="var(--in-review-color)"
      />
    );
  }
};

const DefectQuotes = ({ workOrderId, setNextStepTitle }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('created_at');
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({});
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [quoteId, setQuoteId] = useState('');
  const [hasFormValues, sethasFormValues] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [openPreview, setOpenPreview] = useState(false);
  const [isPropertyLoading, setIsPropertyLoading] = useState(false);
  const [propertyData, setPropertyData] = useState([]);

  const { defectQuotesList } = useSelector(
    (state) => state.workOrder.defectQuotes
  );

  const { data, isLoading } = useSelector(
    (state) => state.workOrder.defectQuotesDetail
  );
  const { isLoading: isLoadingCompany, company } = useSelector(
    (state) => state.company.edit
  );
  const { isLoading: updateQuotiationLoading, data: updateQuotiationData } =
    useSelector((state) => state.workOrder.updateQuotationStatus);

  const user_info = localStorage.getItem('user_info');
  const user = user_info ? JSON.parse(user_info) : null;

  const {
    handleSubmit,
    control,
    watch,
    reset,
    setValue,
    trigger,
    setError,
    clearErrors,
  } = useForm();

  const watchedFields = watch();

  useEffect(() => {
    sethasFormValues(Object.values(watchedFields).some((value) => value));
  }, [watchedFields]);

  const columns = useMemo(() => {
    const baseColumns = [
      {
        headerName: `${t('attributes.work_order.quote_id')}`,
        field: 'quote_id',
        sortable: true,
        flex: 1,
      },
      {
        headerName: `${t('attributes.work_order.status')}`,
        field: 'status',
        sortable: false,
        flex: 1,
        renderCell: ({ row }) => formatDefectQuotesStatus(row.status, t),
      },
      {
        headerName: `${t('attributes.work_order.quote_date')}`,
        field: 'quote_date',
        sortable: false,
        flex: 1,
      },
      {
        headerName: `${t('attributes.work_order.due_date')}`,
        field: 'due_date',
        sortable: true,
        flex: 1,
      },
      {
        headerName: `${t('attributes.amount')}`,
        field: 'amount',
        sortable: false,
        flex: 1,
      },
      {
        field: `${t('attributes.actions')}`,
        headerName: t('attributes.actions'),
        flex: 0.5,
        sortable: false,
        hideable: false,
        renderCell: ({ row }) => (
          <Box sx={{ display: 'flex', gap: '8px' }}>
            <View
              onClick={() => {
                if (user.company_uuid && row.property_uuid) {
                  setIsPropertyLoading(true);
                  dispatch(editCompany(user?.company_uuid));
                  dispatch(getProperty(row?.property_uuid))
                    .then((res) => setPropertyData(res.payload?.data[0]))
                    .finally(() => setIsPropertyLoading(false));
                }
                if (row?.id) {
                  setQuoteId(row?.id);
                  dispatch(
                    getDefectQuotesDetail({
                      work_order_id: workOrderId,
                      quote_id: row?.id,
                    })
                  );
                }
                setOpenPreview(true);
              }}
            />
            <Edit
              onClick={() => {
                dispatch(resetUpdateQuotationStatus());
                if (row?.id) {
                  setQuoteId(row?.id);
                  dispatch(
                    getDefectQuotesDetail({
                      work_order_id: workOrderId,
                      quote_id: row?.id,
                    })
                  );
                  setNextStepTitle({
                    title: WORK_ORDER_STEPS.defect_quotes,
                    step: `${row?.quote_id}`,
                  });
                  setOpen(true);
                }
              }}
            />
          </Box>
        ),
      },
    ];

    return baseColumns;
  }, []);

  useEffect(() => {
    if (updateQuotiationLoading !== null && !updateQuotiationLoading) {
      if (updateQuotiationData?.data?.length > 0) {
        setOpenPreview(false);
        dispatch(
          snackbarToggle({
            isOpen: true,
            isErrorMsg: false,
            msg: t('attributes.work_order.quotationSuccessMessage'),
          })
        );
      }
    }
  }, [updateQuotiationData, updateQuotiationLoading]);

  const getAmountByQtyAndPrice = (data) =>
    data
      ?.reduce(
        (total, item) => total + item?.qty * parseFloat(item?.sell_price || 0),
        0
      )
      .toFixed(2);

  const rows =
    defectQuotesList?.length > 0 &&
    defectQuotesList.map((item) => ({
      id: item.uuid,
      quote_id: `Q-${item.id}`,
      status: item.status,
      quote_date: formattedDate(item.created_at) || '-',
      due_date: formattedDate(item.due_date) || '-',
      amount: getAmountByQtyAndPrice(item.quotation_items),
      property_uuid: item.property_uuid,
    }));

  useEffect(
    () => () => {
      dispatch(resetDefectQuotes());
      dispatch(resetDefectQuotesDetail());
      dispatch(resetUpdateQuotationStatus());
    },
    []
  );

  // Function to fetch data based on search, pagination, and filter
  const getAllWorkOrder = useCallback(() => {
    const visibleFieldsString = columns
      .filter((col) => columnVisibilityModel[col.field] !== false)
      .map((col) => col.field)
      .join(',');

    setIsDataLoading(true);

    dispatch(
      getWorkOrderQuotation({
        id: workOrderId,
        order: order,
        orderBy: orderBy,
        list_column_names: visibleFieldsString,
      })
    ).finally(() => {
      setIsInitialLoading(false);
      setIsDataLoading(false);
    });
  }, [
    dispatch,
    order,
    orderBy,
    columns,
    columnVisibilityModel,
    updateQuotiationData,
  ]);

  // Debounced function to avoid frequent API calls
  const debouncedFetchData = useCallback(debounce(getAllWorkOrder, 500), [
    getAllWorkOrder,
  ]);

  // Call debouncedFetchData whenever search, page, or filter changes
  useEffect(() => {
    debouncedFetchData();

    // Clean up function for debounce to avoid memory leaks
    return () => {
      debouncedFetchData.cancel();
    };
  }, [debouncedFetchData]);

  const sendDefectQuotation = () => {
    dispatch(
      updateDefectQuotationStatus({
        work_order_id: workOrderId,
        quote_id: quoteId,
        status: 'submit_to_customer',
      })
    );
  };

  return (
    <>
      {openConfirmationModal && (
        <ConfirmationModal
          title={t('confirmationModal.title')}
          description={t('confirmationModal.description')}
          open={openConfirmationModal}
          setOpen={setOpenConfirmationModal}
          onConfirm={() => {
            setOpen(false);
            setOpenConfirmationModal(false);
          }}
        />
      )}
      {isInitialLoading ? (
        <Box
          sx={{
            width: '100%',
            mt: 2,
            display: 'flex',
            paddingTop: '200px',
            alignItems: 'center',
          }}
        >
          <CustomCircularLoader />
        </Box>
      ) : (
        !isInitialLoading && (
          <CustomGridTable
            columns={columns}
            rows={rows || []}
            order={order}
            orderBy={orderBy}
            setOrder={setOrder}
            setOrderBy={setOrderBy}
            columnVisibilityModel={columnVisibilityModel}
            setColumnVisibilityModel={setColumnVisibilityModel}
            noData={<NoRecordFound />}
            isLoading={isDataLoading}
            checkboxSelection={false}
            paginationRequired={false}
            sx={{ paddingLeft: '18px' }}
          />
        )
      )}
      {open && (
        <SwipeableDrawer
          open={open}
          title="Add Asset"
          onClose={() => {
            hasFormValues ? setOpenConfirmationModal(true) : setOpen(false);
          }}
          // footerButton={
          //   <CustomButton
          //     text={t('attributes.save')}
          //     startIcon={<SaveOutlinedIcon />}
          //     onClick={handleSubmit(onSubmit)}
          //     disabled={createAssetLoading || updatedAssetLoading}
          //   />
          // }
          width={SWIPEABLE_DRAWER_DEFECT_QUOTE_ADD_PRODUCT_WIDTH}
        >
          <AddProduct
            control={control}
            watch={watch}
            reset={reset}
            setValue={setValue}
            trigger={trigger}
          />
        </SwipeableDrawer>
      )}

      {openPreview && (
        <SwipeableDrawer
          bgColor={SECONDARY}
          open={openPreview}
          title={`Q-${data?.id ? data?.id : '-'}`}
          onClose={() => {
            setOpenPreview(false);
          }}
          footerButton={[
            <CustomButton
              text="Donwload"
              color="inherit"
              disabled={isPropertyLoading || isLoadingCompany || isLoading}
              startIcon={<DownloadOutlinedIcon />}
              onClick={() => {
                generatePDF(data, propertyData, company);
              }}
            />,
            data.status !== CUSTOMER_QUOTATION_STATUS.PENDING &&
              data.status !== CUSTOMER_QUOTATION_STATUS.APPROVED && (
                <CustomButton
                  text="Send Defects Quote"
                  disabled={
                    isPropertyLoading ||
                    isLoadingCompany ||
                    updateQuotiationLoading
                  }
                  startIcon={<ForwardToInboxOutlinedIcon />}
                  onClick={sendDefectQuotation}
                />
              ),
          ]}
          width={653}
        >
          <PreviewDefectQuoteInvoice
            companyData={company}
            propertyData={propertyData}
            isPropertyLoading={isPropertyLoading || isLoading}
          />
        </SwipeableDrawer>
      )}
    </>
  );
};

export default DefectQuotes;
