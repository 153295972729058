import { useMemo } from 'react';

const getDropdownDataHook = ({ data, labelName, valueName }) =>
  useMemo(() => {
    if (!data || data.length === 0) {
      return [];
    }

    return data.map((item) => ({
      label: item[labelName],
      value: item[valueName],
    }));
  }, [data, labelName, valueName]);

export default getDropdownDataHook;
