import { useMemo } from 'react';
import { useSelector } from 'react-redux';

const getCityByStateHook = (state) => {
  const stateCityList = useSelector((state) => state?.common?.stateCityList);

  return useMemo(() => {
    if (!stateCityList || !stateCityList.Australia) {
      return [];
    }

    const cities = stateCityList.Australia[state] || [];

    return cities.map((city) => ({ label: city, value: city }));
  }, [state, stateCityList]);
};

export default getCityByStateHook;
