import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Document,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
  pdf,
} from '@react-pdf/renderer';
import moment from 'moment';

import { DarkLogo } from '../../assets/images';
import { DATE_FORMAT } from '../../constants/Constants';

// Styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: 'white',
    padding: 30,
    fontSize: 11,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginBottom: 20,
  },
  section: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginBottom: 10,
  },
  table: {
    display: 'table',
    width: '100%',
    borderCollapse: 'collapse',
  },
  tableRow: {
    flexDirection: 'row',
  },
  tableHeaderRow: {
    flexDirection: 'row',
    backgroundColor: '#ebebeb20',
  },
  tableCell: {
    padding: 8,
    border: '1px solid #EBEBEB',
    textAlign: 'left',
    lineHeight: 1.5,
  },
  tableCellCenter: {
    textAlign: 'center',
  },
  tableCellRight: {
    textAlign: 'right',
  },
  bold: {
    fontWeight: 'bold',
  },
  logo: {
    height: 40,
    width: 107,
  },
  footer: {
    marginTop: 20,
    lineHeight: 1.5,
  },
  title: {
    marginBottom: 4,
    fontWeight: 'bold',
  },
  address: {
    lineHeight: 1.5,
    color: '#646464',
  },
  termsAndConditions: {
    fontWeight: 'bold',
    textDecoration: 'underline',
  },
});

const QuotePDF = ({ companyData, quoteData, propertyData }) => {
  const { t } = useTranslation();

  const { total } = useMemo(() => {
    const subTotal = quoteData?.quotation_items.reduce(
      (sum, item) => sum + item?.sell_price * item?.qty,
      0
    );

    return { total: subTotal };
  }, [quoteData]);

  return (
    <Document>
      <Page style={styles.page}>
        {/* Header Section */}
        <View style={styles.header}>
          <View>
            <Text style={styles.title}>{companyData?.company_name}</Text>
            <Text style={styles.address}>
              {companyData?.address}
              {'\n'}
              {`${companyData?.state}, ${companyData?.city}, ${companyData?.zip_code}`}
            </Text>
          </View>
          <Image src={DarkLogo} style={styles.logo} />
        </View>

        {/* Bill To Section */}
        <View style={styles.section}>
          <View>
            <Text style={[styles.bold, { marginBottom: 5 }]}>
              {t('attributes.work_order.bill_to')}
            </Text>
            <Text style={styles.address}>{propertyData?.customer}</Text>
            <Text style={styles.address}>{propertyData?.address}</Text>
            <Text
              style={styles.address}
            >{`${propertyData?.state}, ${propertyData?.city}, ${propertyData?.zip_code}`}</Text>
          </View>
          <View>
            <View
              style={{ flexDirection: 'row', justifyContent: 'space-between' }}
            >
              <Text
                style={{
                  width: '200px',
                  textAlign: 'right',
                  paddingRight: 10,
                  lineHeight: 1.5,
                }}
              >
                {t('attributes.work_order.quote')}
              </Text>
              <Text>{quoteData?.id}</Text>
            </View>
            <View
              style={{ flexDirection: 'row', justifyContent: 'space-between' }}
            >
              <Text
                style={{
                  width: '200px',
                  textAlign: 'right',
                  paddingRight: 10,
                  lineHeight: 1.5,
                }}
              >
                {t('attributes.work_order.quote_date')}
              </Text>
              <Text>{quoteData?.quote_date ?? '-'}</Text>
            </View>
            <View
              style={{ flexDirection: 'row', justifyContent: 'space-between' }}
            >
              <Text
                style={{
                  width: '200px',
                  textAlign: 'right',
                  paddingRight: 10,
                  lineHeight: 1.5,
                }}
              >
                {t('attributes.work_order.quote_due')}
              </Text>
              <Text>{quoteData?.due_date ?? '-'}</Text>
            </View>
            <View
              style={{ flexDirection: 'row', justifyContent: 'space-between' }}
            >
              <Text
                style={{
                  width: '200px',
                  textAlign: 'right',
                  paddingRight: 10,
                  lineHeight: 1.5,
                }}
              >
                {t('attributes.work_order.ref')} #
              </Text>
              <Text>-</Text>
            </View>
          </View>
        </View>

        {/* Property Section */}
        <View style={styles.section}>
          <Text style={styles.bold}>
            Property Name:{' '}
            <Text style={styles.address}>{propertyData?.property_name}</Text>
          </Text>
        </View>

        {/* Table Section */}
        <View style={styles.table}>
          <View style={styles.tableHeaderRow}>
            <Text style={[styles.tableCell, { width: '50%' }, styles.bold]}>
              {t('common.desciption')}
            </Text>
            <Text
              style={[
                styles.tableCell,
                { width: '10%' },
                styles.tableCellCenter,
                styles.bold,
              ]}
            >
              {t('common.qty')}
            </Text>
            <Text
              style={[
                styles.tableCell,
                { width: '20%' },
                styles.tableCellRight,
                styles.bold,
              ]}
            >
              {t('common.unit_price')}
            </Text>
            <Text
              style={[
                styles.tableCell,
                { width: '20%' },
                styles.tableCellRight,
                styles.bold,
              ]}
            >
              {t('common.amount')}
            </Text>
          </View>
          {quoteData?.quotation_items.map((item, index) => (
            <View style={styles.tableRow} key={index}>
              <Text style={[styles.tableCell, { width: '50%' }]}>
                <Text style={styles.bold}>
                  {item.product_catalogue.product_name}
                </Text>
              </Text>
              <Text
                style={[
                  styles.tableCell,
                  { width: '10%' },
                  styles.tableCellCenter,
                ]}
              >
                {item.qty}
              </Text>
              <Text
                style={[
                  styles.tableCell,
                  { width: '20%' },
                  styles.tableCellRight,
                ]}
              >
                {item.sell_price?.toFixed(2)}
              </Text>
              <Text
                style={[
                  styles.tableCell,
                  { width: '20%' },
                  styles.tableCellRight,
                ]}
              >
                {(item.qty * item.sell_price)?.toFixed(2)}
              </Text>
            </View>
          ))}
          <View style={styles.tableRow}>
            <Text
              style={[
                styles.tableCell,
                { width: '80%', textAlign: 'right' },
                styles.bold,
              ]}
            >
              {t('common.total')}
            </Text>
            <Text
              style={[
                styles.tableCell,
                { width: '20%' },
                styles.tableCellRight,
                styles.bold,
              ]}
            >
              {total.toFixed(2)}
            </Text>
          </View>
        </View>

        {/* Footer Section */}
        <View style={styles.footer}>
          <Text style={styles.termsAndConditions}>
            {t('common.termsAndCondition')}
          </Text>
          <Text>{t('common.paymentDecription1')}</Text>
          <Text>{`${t('common.paymentDecription2')} ${companyData?.company_name}`}</Text>
        </View>
      </Page>
    </Document>
  );
};

// PDF generation function
const generatePDF = async (data, propertyData, company) => {
  const blob = await pdf(
    <QuotePDF
      companyData={company}
      quoteData={data}
      propertyData={propertyData}
    />
  ).toBlob();
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');

  link.href = url;
  link.download = `${moment().format(DATE_FORMAT)}-Q-${data?.id}`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export default generatePDF;
