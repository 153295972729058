import { useTranslation } from 'react-i18next';

import { Search } from '@mui/icons-material';
import { InputAdornment } from '@mui/material';

// Internal
import CustomTextField from './CustomTextField';

const CustomSearch = ({
  value,
  onChange = () => {},
  width = 260,
  height = '26px',
  ...rest
}) => {
  const { t } = useTranslation();

  return (
    <CustomTextField
      sx={{ width: width }}
      placeholder={t('attributes.search')}
      value={value}
      onChange={onChange}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Search sx={{ height: '20px', width: '20px' }} />
          </InputAdornment>
        ),
        sx: { height: height },
      }}
      {...rest}
    />
  );
};

export default CustomSearch;
