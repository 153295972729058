import { createBrowserRouter, Navigate } from 'react-router-dom';

// Internal
import Billing from './components/Billing/Billing';
import ViewBillingCard from './components/Billing/ViewBillingCard';
import ComingSoon from './components/CommonComponents/ComingSoon';
import { DataManagement } from './components/CommonComponents/Reports/DataManagement';
import { ExportData } from './components/CommonComponents/Reports/ExportData';
import ImportData from './components/CommonComponents/Reports/ImportData';
import { Templates } from './components/CommonComponents/Reports/Templates';
import Companies from './components/Company/Companies';
import CustomerPropertyDashboard from './components/Dashboard/CustomerPropertyDashboard';
import Dashboard from './components/Dashboard/Dashboard';
import DefectDashboard from './components/DefectDashboard/DefectDashboard';
import DefectDashboardLayout from './components/DefectDashboard/DefectDashboardLayout';
import DefectList from './components/DefectDashboard/DefectList';
import EmailTemplate from './components/EmailTemplate/EmailTemplate';
import InvoiceDashboard from './components/InvoiceDashboard/InvoiceDashboard';
import InvoiceDashboardLayout from './components/InvoiceDashboard/InvoiceDashboardLayout';
import InvoiceList from './components/InvoiceDashboard/InvoiceList';
import LandingPage from './components/LandingPage';
import NoFound from './components/NotFound/NotFound';
import ProductCatalogue from './components/ProductCatalogue/ProductCatalogue';
import Properties from './components/Properties/Properties';
import QuotesDashboard from './components/QuotesDashboard/QuotesDashboard';
import QuotesDashboardLayout from './components/QuotesDashboard/QuotesDashboardLayout';
import QuotesList from './components/QuotesDashboard/QuotesList';
import ScheduleBoard from './components/ScheduleBoard/ScheduleBoard';
import Layout from './components/Shared/Layout';
import SuperAdmins from './components/SuperAdmin/SuperAdmin';
import Suppliers from './components/Suppliers/Suppliers';
import Timesheet from './components/Timesheet/Timesheet';
import BillingContractForm from './components/Users/BillingContracts/BillingContractForm';
import BillingContract from './components/Users/BillingContracts/BillingContracts';
import Customer from './components/Users/Customer/Customer';
import OfficeStaff from './components/Users/OfficeStaff/OfficeStaff';
import Technician from './components/Users/Technician/Technician';
import Invoices from './components/WorkOrder/Invoices/Invoices';
import WorkOrder from './components/WorkOrder/WorkOrder';
import WorkOrderDetails from './components/WorkOrder/WorkOrderDetails';
import { CUSTOMER, GLOBAL_ADMIN, SUPER_ADMIN } from './constants/Roles';
import { AUTH_ROUTES, ROUTENAME } from './constants/RoutesConstants';
import AuthLayout from './hoc/AuthLayout';
import AuthRoute from './hoc/AuthRoute';
import UnAuthLayout from './hoc/UnAuthLayout';
import { loggedInUserRole } from './utils';

export const routes = createBrowserRouter([
  // Public route for the landing page
  {
    path: ROUTENAME.DEFAULT_ROUTE, // e.g., '/'
    element: (
      <UnAuthLayout>
        <LandingPage />
      </UnAuthLayout>
    ),
  },
  // Protected routes under AuthLayout
  {
    path: ROUTENAME.DEFAULT_ROUTE,
    element: (
      <AuthLayout>
        <Layout />
      </AuthLayout>
    ),
    children: [
      {
        path: ROUTENAME.DASHBOARD,
        children: [
          {
            index: true,
            element: (
              <AuthRoute type={AUTH_ROUTES.ADMIN_DASHBOARD}>
                {/* Global Admin & Super Admin */}
                <Dashboard />
              </AuthRoute>
            ),
          },
          {
            path: ROUTENAME.PROPERTIES,
            element: (
              <AuthRoute type={AUTH_ROUTES.CUSTOMER_DASHBOARD_PROPERTIES}>
                <CustomerPropertyDashboard />
              </AuthRoute>
            ),
          },
          {
            path: ROUTENAME.QUOTES,
            element: (
              <AuthRoute type={AUTH_ROUTES.CUSTOMER_QUOTES}>
                {/* Customer Quotes Dashboard */}
                <QuotesDashboardLayout />
              </AuthRoute>
            ),
            children: [
              {
                index: true,
                element: (
                  <AuthRoute type={AUTH_ROUTES.CUSTOMER_QUOTES}>
                    <QuotesDashboard />
                  </AuthRoute>
                ),
              },
              {
                path: ROUTENAME.QUOTES_STATUS,
                element: (
                  <AuthRoute type={AUTH_ROUTES.CUSTOMER_QUOTES_STATUS}>
                    <QuotesList />
                  </AuthRoute>
                ),
              },
            ],
          },
          {
            path: ROUTENAME.INVOICES,
            element: (
              <AuthRoute type={AUTH_ROUTES.CUSTOMER_INVOICES}>
                {/* Customer */}
                <InvoiceDashboardLayout />
              </AuthRoute>
            ),
            children: [
              {
                index: true,
                element: (
                  <AuthRoute type={AUTH_ROUTES.CUSTOMER_INVOICES}>
                    <InvoiceDashboard />
                  </AuthRoute>
                ),
              },
              {
                path: ROUTENAME.INVOICES_STATUS,
                element: (
                  <AuthRoute type={AUTH_ROUTES.CUSTOMER_INVOICE_STATUS}>
                    <InvoiceList />
                  </AuthRoute>
                ),
              },
            ],
          },
          {
            path: ROUTENAME.DEFECTS,
            element: (
              <AuthRoute type={AUTH_ROUTES.DEFECTS}>
                {/* Customer */}
                <DefectDashboardLayout />
              </AuthRoute>
            ),
            children: [
              {
                index: true,
                element: (
                  <AuthRoute type={AUTH_ROUTES.DEFECTS}>
                    {/* Customer */}
                    <DefectDashboard />
                  </AuthRoute>
                ),
              },
              {
                path: ROUTENAME.DEFECT_STATUS,
                element: (
                  <AuthRoute type={AUTH_ROUTES.CUSTOMER_QUOTES_DEFECTS_STATUS}>
                    {/* Customer */}
                    <DefectList />
                  </AuthRoute>
                ),
              },
            ],
          },
        ],
      },
      {
        path: ROUTENAME.SCHEDULING,
        children: [
          {
            index: true,
            element: <Navigate to={ROUTENAME.ROUTINE_SERVICES} />,
          },
          {
            path: ROUTENAME.ROUTINE_SERVICES,
            element: (
              <AuthRoute type={AUTH_ROUTES.SUPER_ADMIN_ROUTINE_SERVICES}>
                <ComingSoon />
              </AuthRoute>
            ),
          },
          {
            path: ROUTENAME.SERVICE_REQUESTS,
            element: (
              <AuthRoute type={AUTH_ROUTES.SERVICE_REQUESTS}>
                <ComingSoon />
              </AuthRoute>
            ),
          },
          {
            path: ROUTENAME.SCHEDULE_BOARD,
            element: (
              <AuthRoute type={AUTH_ROUTES.SCHEDULE_BOARD}>
                <ScheduleBoard />
              </AuthRoute>
            ),
          },
          {
            path: ROUTENAME.WORK_ORDERS,
            children: [
              {
                index: true,
                element: (
                  <AuthRoute type={AUTH_ROUTES.WORK_ORDERS}>
                    <WorkOrder />
                  </AuthRoute>
                ),
              },
              {
                path: ROUTENAME.WORK_ORDERS_VIEW,
                element: (
                  <AuthRoute type={AUTH_ROUTES.WORK_ORDERS_VIEW}>
                    <WorkOrderDetails />
                  </AuthRoute>
                ),
              },
            ],
          },
        ],
      },
      {
        path: ROUTENAME.CUSTOMERS,
        children: [
          {
            index: true,
            element: <Navigate to={ROUTENAME.CUSTOMER} />,
          },
          {
            path: ROUTENAME.CUSTOMER,
            element: (
              <AuthRoute type={AUTH_ROUTES.NAME}>
                <Customer />
              </AuthRoute>
            ),
          },
          {
            path: ROUTENAME.PROPERTIES,
            children: [
              {
                index: true,
                element: (
                  <AuthRoute type={AUTH_ROUTES.SUPER_ADMIN_PROPERTIES}>
                    <Properties />
                  </AuthRoute>
                ),
              },
              {
                path: ROUTENAME.PROPERTIES_DETAILS,
                element: (
                  <AuthRoute type={AUTH_ROUTES.PROPERTIES_DETAILS}>
                    <Properties />
                  </AuthRoute>
                ),
              },
            ],
          },
          {
            path: ROUTENAME.BILLING_CONTRACTS,
            children: [
              {
                index: true,
                element: (
                  <AuthRoute type={AUTH_ROUTES.BILLING_CONTRACTS}>
                    <BillingContract />
                  </AuthRoute>
                ),
              },
              {
                path: ROUTENAME.BILLING_CONTRACTS_EDIT,
                element: (
                  <AuthRoute type={AUTH_ROUTES.BILLING_CONTRACTS_EDIT}>
                    <BillingContractForm />
                  </AuthRoute>
                ),
              },
            ],
          },
        ],
      },
      {
        path: ROUTENAME.USERS,
        children: [
          {
            path: ROUTENAME.SUPER_ADMIN,
            element: (
              <AuthRoute type={AUTH_ROUTES.SUPER_ADMIN}>
                <SuperAdmins />
              </AuthRoute>
            ),
          },
          {
            path: ROUTENAME.OFFICE_STAFF,
            element: (
              <AuthRoute type={AUTH_ROUTES.OFFICE_STAFFS}>
                <OfficeStaff />
              </AuthRoute>
            ),
          },
          {
            path: ROUTENAME.STAFF_MANAGEMENT,
            element: (
              <AuthRoute type={AUTH_ROUTES.STAFF_MANAGEMENT}>
                <ComingSoon />
              </AuthRoute>
            ),
          },
          {
            path: ROUTENAME.TECHNICIAN,
            element: (
              <AuthRoute type={AUTH_ROUTES.TECHNICIANS}>
                <Technician />
              </AuthRoute>
            ),
          },
        ],
      },
      {
        path: ROUTENAME.COMMERCIAL,
        children: [
          {
            index: true,
            element: <Navigate to={ROUTENAME.DEFECTS_QUOTES} />,
          },
          // Super Admin
          {
            path: ROUTENAME.DEFECTS_QUOTES,
            element: (
              <AuthRoute type={AUTH_ROUTES.DEFECTS_QUOTES}>
                <ComingSoon />
              </AuthRoute>
            ),
          },
          {
            path: ROUTENAME.QUOTES_SERVICES,
            element: (
              <AuthRoute type={AUTH_ROUTES.QUOTES_SERVICES}>
                <ComingSoon />
              </AuthRoute>
            ),
          },
          {
            path: ROUTENAME.PURCHASE_ORDER,
            element: (
              <AuthRoute
                type={AUTH_ROUTES.SUPER_ADMIN_COMMERCIAL_PURCHASE_ORDER}
              >
                <ComingSoon />
              </AuthRoute>
            ),
          },
          {
            path: ROUTENAME.INVOICES,
            element: (
              <AuthRoute type={AUTH_ROUTES.SUPER_ADMIN_COMMERCIAL_INVOICES}>
                <Invoices isFromSideBar={true} isFromWorkOrder={false} />
              </AuthRoute>
            ),
          },
          {
            path: ROUTENAME.TIMESHEET,
            element: (
              <AuthRoute type={AUTH_ROUTES.SUPER_ADMIN_TIMESHEET}>
                <Timesheet />
              </AuthRoute>
            ),
          },
          {
            path: ROUTENAME.BILLING,
            children: [
              {
                index: true,
                element: (
                  <AuthRoute type={AUTH_ROUTES.SUPER_ADMIN_BILLING_INFO}>
                    <Billing />
                  </AuthRoute>
                ),
              },
              {
                path: ROUTENAME.BILLING_DETAIL,
                element: (
                  <AuthRoute type={AUTH_ROUTES.SUPER_ADMIN_BILLING_VIEW}>
                    <ViewBillingCard />
                  </AuthRoute>
                ),
              },
            ],
          },
        ],
      },
      {
        path: ROUTENAME.WAREHOUSE,
        children: [
          {
            index: true,
            element: <Navigate to={ROUTENAME.PRODUCTS} />,
          },
          {
            path: ROUTENAME.PRODUCTS,
            element: (
              <AuthRoute type={AUTH_ROUTES.PRODUCTS}>
                <ComingSoon />
              </AuthRoute>
            ),
          },
          {
            path: ROUTENAME.ASSETS,
            element: (
              <AuthRoute type={AUTH_ROUTES.SUPER_ADMIN_ASSETS}>
                <ComingSoon />
              </AuthRoute>
            ),
          },
          {
            path: ROUTENAME.INVOICES,
            element: (
              <AuthRoute type={AUTH_ROUTES.SUPER_ADMIN_INVOICES}>
                {/* Super Admin */}
                <ComingSoon />
              </AuthRoute>
            ),
          },
        ],
      },
      {
        path: ROUTENAME.USERS,
        children: [
          {
            path: ROUTENAME.GLOBAL_ADMIN,
            element: (
              <AuthRoute type={AUTH_ROUTES.GLOBAL_ADMINS}>
                <ComingSoon />
              </AuthRoute>
            ),
          },
        ],
      },
      {
        path: ROUTENAME.CONTROL_PANEL,
        element: (
          <AuthRoute
            type={[
              AUTH_ROUTES.SUPER_ADMIN_CONTROL_PANEL,
              AUTH_ROUTES.GLOBAL_ADMIN_CONTROL_PANEL,
            ]}
          >
            {loggedInUserRole() === GLOBAL_ADMIN && <ComingSoon />}
            {loggedInUserRole() === SUPER_ADMIN && <ComingSoon />}
          </AuthRoute>
        ),
      },
      {
        path: ROUTENAME.REPORTS,
        ...(loggedInUserRole() === CUSTOMER && {
          element: (
            <AuthRoute
              type={[
                AUTH_ROUTES.SUPER_ADMIN_REPORTS,
                AUTH_ROUTES.CUSTOMER_REPORTS,
              ]}
            >
              {loggedInUserRole() === CUSTOMER && <ComingSoon />}
            </AuthRoute>
          ),
        }),
        ...(loggedInUserRole() === SUPER_ADMIN && {
          children: [
            {
              path: ROUTENAME.DATA_MANAGEMENT,
              children: [
                {
                  index: true,
                  element: (
                    <AuthRoute type={AUTH_ROUTES.DATA_MANAGEMENT}>
                      <DataManagement />
                    </AuthRoute>
                  ),
                },
                {
                  path: ROUTENAME.IMPORT_DATA,
                  element: (
                    <AuthRoute type={AUTH_ROUTES.IMPORT_DATA}>
                      <ImportData />
                    </AuthRoute>
                  ),
                },
                {
                  path: ROUTENAME.SAMPLE_TEMPLATES,
                  element: (
                    <AuthRoute type={AUTH_ROUTES.SAMPLE_TEMPLATES}>
                      <Templates />
                    </AuthRoute>
                  ),
                },
                {
                  path: ROUTENAME.EXPORT_DATA,
                  element: (
                    <AuthRoute type={AUTH_ROUTES.EXPORT_DATA}>
                      <ExportData />
                    </AuthRoute>
                  ),
                },
              ],
            },
          ],
        }),
      },
      {
        path: ROUTENAME.FIRE_COMPANY,
        children: [
          {
            index: true,
            element: <Navigate to={ROUTENAME.COMPANIES} />,
          },
          {
            path: ROUTENAME.COMPANIES,
            element: (
              <AuthRoute type={AUTH_ROUTES.FIRE_COMPANIES}>
                <Companies />
              </AuthRoute>
            ),
          },
          {
            path: ROUTENAME.SUPER_ADMINS,
            element: (
              <AuthRoute type={AUTH_ROUTES.SUPER_ADMINS}>
                <SuperAdmins />
              </AuthRoute>
            ),
          },
        ],
      },
      {
        path: ROUTENAME.FORMS,
        children: [
          {
            index: true,
            element: <Navigate to={ROUTENAME.ROUTINE_SERVICES} />,
          },
          {
            path: ROUTENAME.ROUTINE_SERVICES,
            element: (
              <AuthRoute type={AUTH_ROUTES.GLOBAL_ADMIN_ROUTINE_SERVICES}>
                {/* Add dependencies in package.json for survey-core */}
                {/* <Forms />  */}
                <ComingSoon />
              </AuthRoute>
            ),
          },
          {
            path: ROUTENAME.ASSETS,
            element: (
              <AuthRoute type={AUTH_ROUTES.GLOBAL_ADMIN_ASSETS}>
                <ComingSoon />
              </AuthRoute>
            ),
          },
        ],
      },
      {
        path: ROUTENAME.TEMPLATES,
        children: [
          {
            index: true,
            element: <Navigate to={ROUTENAME.EMAIL_TEMPLATE} />,
          },
          {
            path: ROUTENAME.EMAIL_TEMPLATE,
            element: (
              <AuthRoute type={AUTH_ROUTES.EMAIL_TEMPLATE}>
                <EmailTemplate />
              </AuthRoute>
            ),
          },
          {
            path: ROUTENAME.REPORTS,
            element: (
              <AuthRoute type={AUTH_ROUTES.GLOBAL_ADMIN_REPORT}>
                {/* Global Admin */}
                <ComingSoon />
              </AuthRoute>
            ),
          },
          {
            path: ROUTENAME.QUOTES,
            element: (
              <AuthRoute type={AUTH_ROUTES.QUOTE}>
                <ComingSoon />
              </AuthRoute>
            ),
          },
          {
            path: ROUTENAME.INVOICE,
            element: (
              <AuthRoute type={AUTH_ROUTES.INVOICES}>
                <ComingSoon />
              </AuthRoute>
            ),
          },
          {
            path: ROUTENAME.PURCHASE_ORDERS,
            element: (
              <AuthRoute type={AUTH_ROUTES.PURCHASE_ORDERS}>
                <ComingSoon />
              </AuthRoute>
            ),
          },
        ],
      },
      {
        path: ROUTENAME.INVOICES,
        element: (
          <AuthRoute type={AUTH_ROUTES.GLOBAL_ADMIN_INVOICES}>
            {/* Global Admin */}
            <ComingSoon />
          </AuthRoute>
        ),
      },
      {
        path: ROUTENAME.MAKE_REQUEST,
        element: (
          <AuthRoute type={AUTH_ROUTES.MAKE_A_REQUEST}>
            <ComingSoon />
          </AuthRoute>
        ),
      },
      {
        path: ROUTENAME.ASSETS,
        element: (
          <AuthRoute type={AUTH_ROUTES.CUSTOMER_ASSETS}>
            <ComingSoon />
          </AuthRoute>
        ),
      },
      {
        path: ROUTENAME.ROUTINE_ACTIVITIES,
        element: (
          <AuthRoute type={AUTH_ROUTES.ROUTINE_ACTIVITIES}>
            <ComingSoon />
          </AuthRoute>
        ),
      },
      {
        path: ROUTENAME.REACTIVE_TASK,
        element: (
          <AuthRoute type={AUTH_ROUTES.REACTIVE_TASK}>
            <ComingSoon />
          </AuthRoute>
        ),
      },
      {
        path: ROUTENAME.DOCUMENTS,
        element: (
          <AuthRoute type={AUTH_ROUTES.DOCUMENTS}>
            <ComingSoon />
          </AuthRoute>
        ),
      },
      {
        path: ROUTENAME.SETTINGS,
        children: [
          {
            index: true,
            element: <Navigate to={ROUTENAME.SUPPLIERS} />,
          },
          {
            path: ROUTENAME.SUPPLIERS,
            element: (
              <AuthRoute type={AUTH_ROUTES.SUPPLIERS}>
                {/* Supplier */}
                <Suppliers />
              </AuthRoute>
            ),
          },
          {
            path: ROUTENAME.PRODUCT_CATALOGUE,
            element: (
              <AuthRoute type={AUTH_ROUTES.PRODUCT_CATALOGUE}>
                {/* Product Catalogue */}
                <ProductCatalogue />
              </AuthRoute>
            ),
          },
        ],
      },
    ],
  },
  {
    path: '*',
    element: <NoFound />,
  },
]);
