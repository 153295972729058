import { Box, Typography } from '@mui/material';
import { BarChart } from '@mui/x-charts/BarChart';

const CustomBarChart = ({ data, xLabels, colors }) => (
  <>
    <Box
      display="flex"
      columnGap={0.5}
      sx={{ position: 'absolute', top: '10px', left: '10px', flexWrap: 'wrap' }}
    >
      {xLabels.map((label, index) => (
        <Box key={label} display="flex" alignItems="center" gap={0.5}>
          <Box width={12} height={12} bgcolor={colors[index]} />
          <Typography variant="body2" sx={{ whiteSpace: 'nowrap' }}>
            {label}
          </Typography>
        </Box>
      ))}
    </Box>

    <BarChart
      xAxis={[
        {
          scaleType: 'band',
          data: xLabels,
          categoryGapRatio: 0.4,
          barGapRatio: -1,
          colorMap: {
            type: 'ordinal',
            colors: colors,
          },
        },
      ]}
      series={[{ data: data }]}
    />
  </>
);

export default CustomBarChart;
