import { createAsyncThunk } from '@reduxjs/toolkit';

import { APIs } from '../../constants/APIConstants';
import { STANDARDS } from '../actionPrefix';
import { axiosPropertyUrl } from '../axios';

// Get Standards Dropdown List
export const getStandardsDropdownList = createAsyncThunk(
  STANDARDS.GET_STANDARDS_DROPDOWN_LIST,
  async (req, { rejectWithValue }) => {
    try {
      const response = await axiosPropertyUrl.get(
        APIs.STANDARDS.GET_STANDARDS_DROPDOWN_LIST
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
