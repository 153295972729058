import { createAsyncThunk } from '@reduxjs/toolkit';

import { APIs } from '../../constants/APIConstants';
import { PRODUCT_CATALOGUE } from '../actionPrefix';
import { axiosInstanceTechnician } from '../axios';

const {
  GET_PRODUCT_CATALOGUE_list,
  GET_PRODUCT_DETAILS,
  CREATE_PRODUCT_CATALOGUE,
  UPDATE_PRODUCT_CATALOGUE,
} = PRODUCT_CATALOGUE;

export const getproductCatalogueList = createAsyncThunk(
  GET_PRODUCT_CATALOGUE_list,
  async (
    {
      page,
      search,
      size,
      limit,
      equipment_type,
      type,
      active,
      supplier_uuid,
      order,
      orderBy,
      list_column_names,
    },
    { rejectWithValue }
  ) => {
    const params = new URLSearchParams();

    // Add params conditionally
    if (page) params.append('page', page);
    if (search) params.append('search', search);
    if (size) params.append('size', size);
    if (active) params.append('status', active);
    if (limit) params.append('limit', limit);
    if (equipment_type) params.append('equipment_type', equipment_type);
    if (type) params.append('type', type);
    if (supplier_uuid) params.append('supplier_uuid', supplier_uuid);
    if (order) params.append('order', order);
    if (orderBy) params.append('order_by', orderBy);
    if (list_column_names)
      params.append('list_column_names', list_column_names);

    try {
      const response = await axiosInstanceTechnician.get(
        `${APIs.PRODUCT_CATALOGUE.GET_PRODUCT_CATALOGUE_LIST}?${params.toString()}`
      );

      return { ...response.data, active };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateProductCatalogue = createAsyncThunk(
  UPDATE_PRODUCT_CATALOGUE,
  async ({ uuid, data }, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceTechnician.patch(
        APIs.PRODUCT_CATALOGUE.UPDATE_PRODUCT_CATALOGUE.replace('{uuid}', uuid),
        data
      );

      return response.data;
    } catch (error) {
      // Forward the custom error object to the rejected promise
      return rejectWithValue(error);
    }
  }
);

export const createProductCatalogue = createAsyncThunk(
  CREATE_PRODUCT_CATALOGUE,
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceTechnician.post(
        APIs.PRODUCT_CATALOGUE.CREATE_PRODUCT_CATALOGUE,
        data
      );

      return response.data;
    } catch (error) {
      // Forward the custom error object to the rejected promise
      return rejectWithValue(error);
    }
  }
);

export const getProductDetailsById = createAsyncThunk(
  GET_PRODUCT_DETAILS,
  async (uuid, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceTechnician.get(
        APIs.PRODUCT_CATALOGUE.GET_PRODUCT_CATALOGUE_DETAILS.replace(
          '{product_catalogue_uuid}',
          uuid
        )
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
