import MarkEmailReadOutlinedIcon from '@mui/icons-material/MarkEmailReadOutlined';
import { Box, styled, Typography } from '@mui/material';

import { PRIMARY, SECONDARY } from '../../../../constants/Colors';

const CustomIcon = styled(Box)(({ theme }) => ({
  display: 'flex',
  background: PRIMARY,
  padding: '20px',
  borderRadius: '100%',
}));

const InviteTheCustomer = ({ title, watch }) => {
  const contactName = watch('contact_name') || 'Test name';
  const contactEmail = watch('contact_email') || 'test@gmail.com';

  return (
    <Box
      sx={{
        background: SECONDARY,
        borderRadius: '4px',
      }}
    >
      <Box
        sx={{
          padding: '50px 90px 50px 90px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          textAlign: 'center',
        }}
      >
        <CustomIcon>
          <MarkEmailReadOutlinedIcon
            sx={{ color: 'white', height: '40px', width: '40px' }}
          />
        </CustomIcon>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
            paddingTop: '16px',
          }}
        >
          <Typography variant="body1">{title}</Typography>
          <Typography variant="body2">
            {`Do you want to send the invite ${contactName}, Invite Email Sent to
            ${contactEmail}`}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default InviteTheCustomer;
