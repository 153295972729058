import { createAsyncThunk } from '@reduxjs/toolkit';

import { APIs } from '../../constants/APIConstants';
import { formatDateAPI } from '../../utils';
import { CUSTOMER } from '../actionPrefix';
import {
  axiosInstanceBilling,
  axiosInstanceReporting,
  axiosInstanceTechnician,
} from '../axios';

const {
  GET_INVOICE_LIST,
  GET_INVOICE_TYPE,
  GET_INVOICE_PROPERTYNAME,
  GET_INVOICE_STATUS,
  CREATE_INVOICE,
  UPDATE_INVOICE,
  CREATE_INVOICE_PRODUCT,
  UPDATE_INVOICE_PRODUCT,
  DELETE_INVOICE_PRODUCT,
} = CUSTOMER;

export const getInvoiceList = createAsyncThunk(
  GET_INVOICE_LIST,
  async (
    {
      page,
      search,
      size,
      type,
      status,
      order,
      orderBy,
      property_name,
      work_order_uuid,
      invoiceFromDate,
      invoiceToDate,
      limit,
      uuid,
    },
    { rejectWithValue }
  ) => {
    const params = new URLSearchParams();

    // Add params conditionally
    if (page) params.append('page', page);
    if (size) params.append('size', size);
    if (limit) params.append('limit', limit);
    if (order) params.append('order', order);
    if (orderBy) params.append('order_by', orderBy);
    if (type) params.append('type', type);
    if (status) params.append('status', status);
    if (property_name) params.append('property_name', property_name);
    if (work_order_uuid) params.append('work_order_uuid', work_order_uuid);
    if (invoiceFromDate)
      params.append(
        'invoice_creation_date__gte',
        formatDateAPI(invoiceFromDate)
      );
    if (invoiceToDate)
      params.append('invoice_creation_date__lte', formatDateAPI(invoiceToDate));
    if (search) params.append('search', search);
    if (orderBy) params.append('order_by', orderBy);
    if (uuid) params.append('uuid', uuid);

    try {
      const response = await axiosInstanceBilling.get(
        `${APIs.CUSTOMER.GET_INVOICE_LIST}?${params.toString()}`
      );

      return { ...response.data };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Get Invoice Type List API
export const getInvoiceTypeDropdownList = createAsyncThunk(
  GET_INVOICE_TYPE,
  async (req, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceReporting.get(
        APIs.CUSTOMER.GET_INVOICE_TYPE
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Get Invoice Property List API
export const getInvoicePropertyDropdownList = createAsyncThunk(
  GET_INVOICE_PROPERTYNAME,
  async (req, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceReporting.get(
        APIs.CUSTOMER.GET_INVOICE_PROPERTYNAME
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Get Invoice Status List API
export const getInvoiceStatusDropdownList = createAsyncThunk(
  GET_INVOICE_STATUS,
  async (req, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceReporting.get(
        APIs.CUSTOMER.GET_INVOICE_STATUS
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createInvoice = createAsyncThunk(
  CREATE_INVOICE,
  async ({ work_order_uuid }, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceTechnician.post(
        APIs.WORK_ORDER.CREATE_INVOICE.replace(
          '{work_order_uuid}',
          work_order_uuid
        )
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateInvoice = createAsyncThunk(
  UPDATE_INVOICE,
  async ({ invoice_uuid, data }, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceBilling.patch(
        APIs.CUSTOMER.UPDATE_INVOICE.replace('{invoice_uuid}', invoice_uuid),
        data
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createInvoiceProduct = createAsyncThunk(
  CREATE_INVOICE_PRODUCT,
  async ({ invoice_uuid, data }, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceBilling.post(
        APIs.CUSTOMER.CREATE_INVOICE_PRODUCT.replace(
          '{invoice_uuid}',
          invoice_uuid
        ),
        data
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateInvoiceProduct = createAsyncThunk(
  UPDATE_INVOICE_PRODUCT,
  async ({ invoice_uuid, invoice_item_uuid, data }, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceBilling.put(
        APIs.CUSTOMER.UPDATE_INVOICE_PRODUCT.replace(
          '{invoice_uuid}',
          invoice_uuid
        ).replace('{item_uuid}', invoice_item_uuid),
        data
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteInvoiceProduct = createAsyncThunk(
  DELETE_INVOICE_PRODUCT,
  async ({ invoice_uuid, invoice_item_uuid }, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceBilling.delete(
        APIs.CUSTOMER.UPDATE_INVOICE_PRODUCT.replace(
          '{invoice_uuid}',
          invoice_uuid
        ).replace('{item_uuid}', invoice_item_uuid)
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
