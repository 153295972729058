import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { Typography } from '@mui/material';

import { getAssetsList } from '../../../store/workOrder/api';
import { formatStatus, formattedDate } from '../../../utils';
import { View } from '../../CommonComponents/ActionComponent';
import CustomGridTable from '../../CommonComponents/CustomGridTable';
import NoRecordFound from '../../CommonComponents/NoDataPage/NoRecordFound';
import SwipeableDrawer from '../../CommonComponents/SwipeableDrawer';
import AssetView from './AssetView';

const Assets = ({ workOrderUUID }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [perPageData, setPerPageData] = useState(10);

  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('base_date');

  const [columnVisibilityModel, setColumnVisibilityModel] = useState({});
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [assets, setAssets] = useState(null);
  const [selectedAsset, setSelectedAsset] = useState(null);

  const columns = useMemo(() => {
    const baseColumns = [
      {
        field: 'name',
        headerName: `${t('attributes.property.asset')} ${t('attributes.name')}`,
        flex: 1,
        sortable: false,
        renderCell: ({ row }) =>
          row?.name ? (
            <Typography
              variant="body1"
              sx={{ textDecoration: 'underline', cursor: 'pointer' }}
            >
              {row.name}
            </Typography>
          ) : (
            ''
          ),
      },
      {
        field: 'location',
        headerName: t('attributes.location'),
        flex: 1,
        sortable: false,
      },
      {
        field: 'status',
        headerName: t('attributes.property.status'),
        flex: 1,
        sortable: false,
        renderCell: ({ row }) => formatStatus(row.status),
      },
      {
        field: 'base_date',
        headerName: `${t('attributes.property.base')} ${t('attributes.date')}`,
        flex: 1,
        sortable: false,
        renderCell: ({ row }) => formattedDate(row?.base_date) || '-',
      },
      // it will use in future
      // {
      //   field: 'perform_date',
      //   headerName: `${t('attributes.property.perform')} ${t('attributes.date')}`,
      //   flex: 1,
      //   sortable: false,
      // },
      {
        field: 'view',
        headerName: t('attributes.actions'),
        flex: 0.5,
        sortable: false,
        hideable: false,
        renderCell: ({ row }) => (
          <View
            onClick={() => {
              setSelectedAsset(row);
              setOpen(true);
            }}
          />
        ),
      },
    ];

    return baseColumns;
  }, []);

  const rows = assets?.data?.map((asset) => ({
    ...asset,
    name: asset?.asset?.label || '-',
    location: asset?.asset?.location || '-',
    status: asset?.asset_status,
    base_date: asset?.asset?.base_date,
    // perform_date: formattedDate(asset?.due_date) || '-',
  }));

  const getAssets = useCallback(() => {
    setIsDataLoading(true);
    const visibleFieldsString = columns
      .filter((col) => columnVisibilityModel[col.field] !== false)
      .map((col) => col.field)
      .join(',');

    dispatch(
      getAssetsList({
        limit: perPageData,
        page: currentPage,
        order: order,
        orderBy: orderBy,
        workorder_uuid: workOrderUUID,
      })
    )
      .then((res) => setAssets(res?.payload))
      .finally(() => setIsDataLoading(false));

    setColumnVisibilityModel(visibleFieldsString);
  }, [
    perPageData,
    currentPage,
    order,
    orderBy,
    columns,
    columnVisibilityModel,
  ]);

  useEffect(() => {
    if (workOrderUUID) {
      getAssets();
    }
  }, [workOrderUUID, perPageData, currentPage]);

  useEffect(() => {
    setCurrentPage(1);
  }, [perPageData]);

  return (
    <>
      <CustomGridTable
        columns={columns}
        rows={rows}
        total={assets?.total || 0}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        perPageData={perPageData}
        setPerPageData={setPerPageData}
        order={order}
        orderBy={orderBy}
        setOrder={setOrder}
        setOrderBy={setOrderBy}
        columnVisibilityModel={columnVisibilityModel}
        setColumnVisibilityModel={setColumnVisibilityModel}
        isLoading={isDataLoading}
        noData={<NoRecordFound />}
        paginationRequired={false}
      />
      {open && selectedAsset && (
        <SwipeableDrawer
          open={open}
          title={selectedAsset?.asset?.label || ''}
          onClose={() => setOpen(false)}
        >
          <AssetView
            asset_id={selectedAsset?.asset?.uuid}
            work_order_id={workOrderUUID}
          />
        </SwipeableDrawer>
      )}
    </>
  );
};

export default Assets;
