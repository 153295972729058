import { createAsyncThunk } from '@reduxjs/toolkit';

import { APIs } from '../../constants/APIConstants';
import { PROPERTIES } from '../actionPrefix';
import { axiosPropertyUrl } from '../axios';

// get equipment list
export const getEquipmentTypesDropdown = createAsyncThunk(
  PROPERTIES.GET_EQUIPEMENT_TYPE,
  async (req, { rejectWithValue }) => {
    try {
      const response = await axiosPropertyUrl.get(
        APIs.PROPERTIES.GET_EQUIPEMENT_TYPE
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
