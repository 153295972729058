import { useEffect, useState } from 'react';

import moment from 'moment';
import { DatePicker, DateRangePicker } from 'rsuite';

import 'rsuite/dist/rsuite.min.css';
import {
  DATE_FORMAT,
  DATE_RANGE_FORMAT,
  DATE_TIME_RANGE_FORMAT,
  DATE_TIME_RANGE_FORMAT_DEFAULT,
} from '../../constants/Constants';

export const CustomDatePicker = ({ ...rest }) => <DatePicker {...rest} />;

const CustomDateRangePicker = ({
  size = 'md',
  placeholder = `${DATE_FORMAT} - ${DATE_FORMAT}`,
  onOkClick = () => {},
  onClear = () => {},
  fromDate,
  toDate,
  placement = 'bottomStart',
  format = DATE_RANGE_FORMAT,
  width = '20%',
  ...rest
}) => {
  const [value, setValue] = useState([]);

  const formatDate = () => {
    if (!value.length || value.some((date) => date === null)) {
      return placeholder;
    }

    return format === DATE_TIME_RANGE_FORMAT
      ? `${moment(value[0]).format(DATE_TIME_RANGE_FORMAT_DEFAULT)} - ${moment(value[1]).format(DATE_TIME_RANGE_FORMAT_DEFAULT)}`
      : `${moment(value[0]).format(DATE_FORMAT)} - ${moment(value[1]).format(DATE_FORMAT)}`;
  };

  useEffect(() => {
    if (!fromDate && !toDate) setValue([]);
    if (fromDate || toDate) {
      setValue([fromDate, toDate]);
    }
  }, [fromDate, toDate]);

  return (
    <DateRangePicker
      size={size}
      placeholder={placeholder}
      showHeader={false}
      character=" - "
      ranges={[]}
      format={format}
      onOk={(dates) => {
        setValue(dates);
        onOkClick(dates);
      }}
      onClean={() => {
        setValue([]);
        onClear();
      }}
      value={value}
      renderValue={formatDate}
      style={{ width: width }}
      placement={placement}
      {...rest}
    />
  );
};

export default CustomDateRangePicker;
