import { CloseOutlined } from '@mui/icons-material';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import { Box, Snackbar, styled, Typography } from '@mui/material';

import { WARNING } from '../../constants/Colors';

const SnackbarStyled = styled(Box)(({ isError, theme }) => ({
  background: isError ? WARNING : theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderRadius: '4px',
  padding: '14px 16px !important',
  width: '356px',
}));

const CustomSnackbar = ({
  open = true,
  vertical = 'top',
  horizontal = 'center',
  msg = '',
  onClose = () => {},
  isError = false,
}) => {
  if (msg) {
    return (
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        message={msg}
        autoHideDuration={3000}
        key={vertical + horizontal}
        onClose={onClose}
      >
        <SnackbarStyled isError={isError} sx={{ minheight: '48px' }}>
          <Box sx={{ display: 'flex' }}>
            {isError ? (
              <ErrorOutlineRoundedIcon />
            ) : (
              <CheckCircleOutlineRoundedIcon
                style={{ height: '22px', width: '22px' }}
              />
            )}
            <Typography variant="body1" padding={'0 0.75rem 0 0.75rem'}>
              <span dangerouslySetInnerHTML={{ __html: msg }} />
            </Typography>
          </Box>
          <CloseOutlined onClick={onClose} />
        </SnackbarStyled>
      </Snackbar>
    );
  }

  return null;
};

export default CustomSnackbar;
