import { LineChart } from '@mui/x-charts/LineChart';

import { PRIMARY } from '../../../constants/Colors';

const CustomLineChart = ({
  xAxisLabels,
  chartData,
  lineStyle = 'linear',
  lineColor = PRIMARY,
  height = 250,
  prefix = '',
}) => (
  <LineChart
    xAxis={[
      {
        data: xAxisLabels, // Use numeric values for data points
        scaleType: 'point',
      },
    ]}
    series={[
      {
        data: chartData,
        curve: lineStyle,
        color: lineColor,
      },
    ]}
    yAxis={[
      {
        valueFormatter: (value) => `${prefix}${[value]}`,
      },
    ]}
    height={height}
  />
);

export default CustomLineChart;
