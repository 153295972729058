import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { WarningAmberOutlined } from '@mui/icons-material';
import BallotOutlinedIcon from '@mui/icons-material/BallotOutlined';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Box, Typography } from '@mui/material';
import { debounce } from 'lodash';
import moment from 'moment';

import { DIVIDER_COLOR, RED } from '../../constants/Colors';
import { dateTimeRange, exportToCSV } from '../../constants/common';
import {
  getStatusLabels,
  SCHEDULE_BOARD_WO_UTC_LOCAL,
  STATUS_LIST,
  WORK_ORDER_TYPE_LIST,
} from '../../constants/Constants';
import {
  COLUMNS_TO_EXPORT,
  COLUMNS_TO_MAP,
  EXPORT_FILE_NAMES,
} from '../../constants/ExportConstant';
import getDropdownListHook from '../../hooks/getDropdownListHook';
import { snackbarToggle } from '../../store/CommonReducer';
import { resetDropdown } from '../../store/company/reducer';
import {
  getPropertyCustomerList,
  getPropertyList,
} from '../../store/property/api';
import { getTechnicianNamesList } from '../../store/technician/api';
import { getWorkOrderList } from '../../store/workOrder/api';
import { resetPagination } from '../../store/workOrder/reducer';
import { formatStatus, formattedDate } from '../../utils';
import { View } from '../CommonComponents/ActionComponent';
import Autocomplete from '../CommonComponents/AutoComplete';
import CustomButton from '../CommonComponents/CustomButton';
import CustomDateRangePicker from '../CommonComponents/CustomDateRangePicker';
import CustomGridTable from '../CommonComponents/CustomGridTable';
import CustomCircularLoader from '../CommonComponents/CustomLoader';
import {
  FilterComponent,
  FilterSection,
} from '../CommonComponents/FilterComponent';
import NewNoDataPage from '../CommonComponents/NoDataPage/NewNoDataPage';
import NoRecordFound from '../CommonComponents/NoDataPage/NoRecordFound';
import StyledMainWrapper from '../CommonComponents/StyledMainWrapper';

const defaultValues = {
  work_order_name: '',
  work_order_type: '',
  property: '',
  asset_count: 0,
  technician: '',
  frequency: '',
  status: '',
  appoitment: '',
  due_date: '',
};

const defaultFilters = {
  customer: null,
  property: null,
  workOrderTypes: null,
  technician: null,
  status: null,
};

const listColumnNames = {
  work_order_name: 'work_order_name',
  work_order_type: 'work_order_type',
  property: 'property',
  asset_count: 'asset_count',
  technician: 'technician',
  frequency: 'frequency',
  status: 'status',
  appoitment: 'appoitment',
  due_date: 'due_date',
  edit: 'edit',
};

const filterHeight = (isFilterOpen) => (isFilterOpen ? 357 : 301);

const WorkOrder = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { workOrderList, total, totalCount } = useSelector(
    (state) => state.workOrder.get
  );

  const [currentPage, setCurrentPage] = useState(1);
  const [perPageData, setPerPageData] = useState(10);
  const [isFilterComponentVisible, setIsFilterComponentVisible] =
    useState(false);

  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('created_at');
  const [searchText, setSearchText] = useState('');
  const [appointmentFromDate, setAppointmentFromDate] = useState(null);
  const [appointmentToDate, setAppointmentToDate] = useState(null);
  const [dueFromDate, setDueFromDate] = useState(null);
  const [dueToDate, setDueToDate] = useState(null);

  const [filters, setFilters] = useState(defaultFilters);

  const [isDataLoading, setIsDataLoading] = useState(true);
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({});
  const [refresh, setRefresh] = useState(false);
  const [selectedRows, setSelectedRows] = useState(null);

  const {
    propertyDropdownLoading: customerDropdownLoading,
    propertyDropdownData: customerDropdownData,
  } = getDropdownListHook({
    reducerName: 'property',
    dropdownListName: 'propertyCustomerList',
    labelName: 'customer_name',
    valueName: 'uuid',
  });

  const { propertyDropdownLoading, propertyDropdownData } = getDropdownListHook(
    {
      reducerName: 'property',
      dropdownListName: 'propertyList',
      labelName: 'property_name',
      valueName: 'uuid',
      loading: 'loading',
    }
  );

  const { technicianDropdownLoading, technicianDropdownData } =
    getDropdownListHook({
      reducerName: 'technician',
      dropdownListName: 'technicianNamesDropdownList',
      labelName: 'display_name',
      valueName: 'uuid',
    });

  // const { statusDropdownLoading, statusDropdownData } = getDropdownListHook({
  //   reducerName: 'status',
  //   dropdownListName: 'statusDropdownList',
  //   labelName: 'display_name',
  //   valueName: 'name',
  // });

  const columns = useMemo(() => {
    const baseColumns = [
      {
        field: 'id',
        headerName: t('attributes.work_order.id'),
        flex: 1,
        sortable: true,
      },
      {
        field: 'work_order_name',
        headerName: t('attributes.work_order.work_order_name'),
        flex: 1,
        sortable: false,
      },
      {
        field: 'work_order_type',
        headerName: t('attributes.work_order.work_order_type'),
        flex: 1,
        sortable: false,
      },
      {
        field: 'property',
        headerName: t('attributes.property.property'),
        flex: 1,
        sortable: false,
        renderCell: ({ row }) => (
          <Box>
            <Typography
              variant="body1"
              sx={{ textDecoration: 'underline', cursor: 'pointer' }}
            >
              {row.property_name}
            </Typography>
            <Box>{`${row.address}`}</Box>
          </Box>
        ),
      },
      {
        field: 'asset_count',
        headerName: t('attributes.work_order.asset_count'),
        flex: 1,
        sortable: false,
        renderCell: ({ row }) => (
          <Box
            sx={{
              p: '10px',
              height: 20,
              width: 20,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              border: '1px solid black',
              borderRadius: '50%',
            }}
          >
            {row.asset_count}
          </Box>
        ),
      },
      {
        field: 'technician',
        headerName: t('attributes.technician.technician'),
        flex: 1,
        sortable: false,
        renderCell: ({ row }) => row.technician_name,
      },
      {
        field: 'frequency',
        headerName: t('attributes.work_order.frequency'),
        flex: 1,
        sortable: false,
        renderCell: ({ row }) => (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
              gap: '4px',
            }}
          >
            {row.frequency && (
              <Box
                sx={{
                  px: '16px',
                  py: '4px',
                  border: '1px solid black',
                  borderRadius: '24px',
                  bgcolor: DIVIDER_COLOR,
                }}
              >
                {row.frequency}
              </Box>
            )}
          </Box>
        ),
      },
      {
        field: 'status',
        headerName: t('attributes.work_order.status'),
        flex: 1,
        sortable: false,
        renderCell: ({ row }) => <Box>{formatStatus(row.status)}</Box>,
      },
      {
        field: 'appointment_date',
        headerName: `${t('attributes.work_order.appointment')} ${t('attributes.work_order.date_nd_time')}`,
        flex: 1,
        sortable: false,
        renderHeader: () => (
          <Typography variant="body1" fontWeight={700}>
            {t('attributes.work_order.appointment')}
            <br />
            {t('attributes.work_order.date_nd_time')}
          </Typography>
        ),
        renderCell: ({ row }) => {
          if (row.schedule_start_date_time === '-') return '-';
          const { startFormattedDate, endFormattedDate, startTime, endTime } =
            dateTimeRange(
              formattedDate(
                row?.scheduled_start_date_time,
                SCHEDULE_BOARD_WO_UTC_LOCAL
              ),
              formattedDate(
                row?.scheduled_end_date_time,
                SCHEDULE_BOARD_WO_UTC_LOCAL
              )
            );

          return startFormattedDate === endFormattedDate ? (
            <Box>
              {startFormattedDate}
              <br />
              {`${startTime} to ${endTime}`}
            </Box>
          ) : (
            <Box>
              <span>
                {startFormattedDate} {startTime}
              </span>
              <br />
              <span>
                to {endFormattedDate} {endTime}
              </span>
            </Box>
          );
        },
      },
      {
        field: 'due_date',
        headerName: t('attributes.work_order.due_date'),
        flex: 1.2,
        renderCell: ({ row }) => {
          if (
            moment(
              formattedDate(row?.due_date, SCHEDULE_BOARD_WO_UTC_LOCAL)
            ).isBefore(moment())
          ) {
            return (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography color={RED}>
                  {formattedDate(row?.due_date)}
                </Typography>
                <WarningAmberOutlined sx={{ color: `${RED}!important` }} />
              </Box>
            );
          }

          return formattedDate(row.due_date);
        },
      },
      {
        field: 'view',
        headerName: t('attributes.work_order.actions'),
        flex: 0.5,
        sortable: false,
        hideable: false,
        renderCell: ({ row }) => (
          <View
            onClick={() => {
              navigate(row.uuid);
            }}
          />
        ),
      },
    ];

    return baseColumns;
  }, []);

  // Function to fetch data based on search, pagination, and filter
  const getAllWorkOrder = useCallback(() => {
    setSelectedRows(null);
    const visibleFieldsString = columns
      .filter((col) => columnVisibilityModel[col.field] !== false)
      .map((col) => col.field)
      .join(',');

    setIsDataLoading(true);

    dispatch(
      getWorkOrderList({
        size: perPageData,
        page: currentPage,
        search: searchText,
        order: order,
        orderBy: orderBy,
        customer: filters.customer?.value,
        property: filters.property?.value,
        workOrderTypes: filters.workOrderTypes?.value,
        technician: filters.technician?.value,
        status: filters.status,
        appointmentFromDate: appointmentFromDate,
        appointmentToDate: appointmentToDate,
        dueFromDate: dueFromDate,
        dueToDate: dueToDate,
        list_column_names: visibleFieldsString,
      })
    ).finally(() => {
      setIsInitialLoading(false);
      setIsDataLoading(false);
    });
  }, [
    dispatch,
    perPageData,
    currentPage,
    order,
    orderBy,
    filters,
    searchText,
    columns,
    columnVisibilityModel,
    refresh,
    appointmentFromDate,
    appointmentToDate,
    dueFromDate,
    dueToDate,
  ]);

  useEffect(
    () => () => {
      dispatch(resetPagination());
      dispatch(resetDropdown());
    },
    []
  );

  // Reset pagination on page size, filters, or searchText change
  useEffect(() => {
    setCurrentPage(1);
  }, [perPageData, filters, searchText]);

  // Debounced function to avoid frequent API calls
  const debouncedFetchData = useCallback(debounce(getAllWorkOrder, 500), [
    getAllWorkOrder,
  ]);

  // Call debouncedFetchData whenever search, page, or filter changes
  useEffect(() => {
    debouncedFetchData();

    // Clean up function for debounce to avoid memory leaks
    return () => {
      debouncedFetchData.cancel();
    };
  }, [debouncedFetchData]);

  // Replace list with related list dropdown once API is ready
  useEffect(() => {
    dispatch(getPropertyCustomerList());
    dispatch(getTechnicianNamesList());
  }, [dispatch]);

  useEffect(() => {
    const req = {
      limit: -1,
      customerUuid: filters.customer?.value,
    };

    if (filters.customer?.value) {
      dispatch(getPropertyList(req));
    }
  }, [dispatch, filters.customer?.value]);

  const rows = workOrderList?.map((item) => ({
    ...item,
    id: `W-${item.id}`,
    work_order_type: item.work_order_type.toUpperCase(),
    work_order_name: item.work_order_name || '',
    asset_count: item.asset_count || 0,
    technician: item.technician_uuid || '-',
    status: getStatusLabels(item),
    property_name:
      item?.property_equipment_routine_service_schedule?.property
        ?.property_name || '-',
    address:
      item?.property_equipment_routine_service_schedule?.property?.address ||
      '-',
    property_address: `${
      item?.property_equipment_routine_service_schedule?.property
        ?.property_name || '-'
    } ${
      item?.property_equipment_routine_service_schedule?.property?.address ||
      '-'
    }`,
    technician_name: item.technician_uuid
      ? technicianDropdownData?.filter(
          (tech) => tech.value == item.technician_uuid
        )?.[0].label
      : '-',
    frequency:
      item?.property_equipment_routine_service_schedule?.frequencies?.code,
    appointment_date: formattedDate(item.scheduled_date),
    due_date: item.due_date || '-',
    created_at: item?.created_at,
    schedule_start_date_time: item?.scheduled_start_date_time || '-',
    scheduled_end_date_time: item?.scheduled_end_date_time || '-',
  }));

  const resetFilter = () => {
    setFilters({
      customer: null,
      property: null,
      workOrderTypes: null,
      status: null,
      technician: null,
      appoitment_date: null,
      due_date: null,
    });
    setSearchText('');
  };

  const handleSelectionChange = (newSelection) => {
    setSelectedRows(rows?.filter((row) => newSelection.includes(row.id)));
  };

  const noData = (
    <NewNoDataPage
      icon={<BallotOutlinedIcon />}
      title={t('attributes.workOrder.noDataNote')}
      onlyTitle={true}
      filterHeight={filterHeight(isFilterComponentVisible)}
    />
  );

  const renderedComponent = (
    <>
      <FilterSection
        onFilterBtnClick={() =>
          setIsFilterComponentVisible(!isFilterComponentVisible)
        }
        searchText={searchText}
        onResetFilter={resetFilter}
        onRefreshFilter={() => setRefresh(!refresh)}
        isRefresh={true}
        // After API impl need to include debounce search
        onSearchChange={(e) => setSearchText(e.target.value)}
        isResetButtonVisible={
          searchText ||
          filters.customer ||
          filters.property ||
          filters.tags ||
          filters.workOrderTypes ||
          filters.status ||
          filters.technician
        }
        isActive={isFilterComponentVisible}
      />
      {isFilterComponentVisible && (
        <FilterComponent>
          <Autocomplete
            placeholder="Customers"
            options={customerDropdownData}
            onChange={(e, newVal) =>
              setFilters((prev) => ({ ...prev, customer: newVal }))
            }
            value={filters?.customer}
            isLoadingData={customerDropdownLoading}
            width="190px"
          />
          <Autocomplete
            placeholder="Properties"
            options={propertyDropdownData}
            onChange={(e, newVal) =>
              setFilters((prev) => ({ ...prev, property: newVal }))
            }
            isLoadingData={propertyDropdownLoading}
            value={filters?.property}
            disabledDropdown={!filters?.customer?.value}
            width="190px"
          />
          <Autocomplete
            placeholder="Work Order Types"
            options={WORK_ORDER_TYPE_LIST}
            onChange={(e, newVal) =>
              setFilters((prev) => ({ ...prev, workOrderTypes: newVal }))
            }
            value={filters?.workOrderTypes}
            isLoadingData={false}
            width="190px"
          />
          <Autocomplete
            placeholder="Technicians"
            options={technicianDropdownData}
            onChange={(e, newVal) =>
              setFilters((prev) => ({ ...prev, technician: newVal }))
            }
            value={filters?.technician}
            isLoadingData={technicianDropdownLoading}
            width="190px"
          />
          <Autocomplete
            placeholder="Status"
            options={STATUS_LIST}
            onChange={(e, newVal) =>
              setFilters((prev) => ({ ...prev, status: newVal }))
            }
            value={filters?.status}
            isLoadingData={false}
            width="190px"
          />

          {/*     Need to see date range picker or normal date picker       */}
          <CustomDateRangePicker
            placeholder="Appointment Date"
            onOkClick={(val) => {
              setAppointmentFromDate(val[0]);
              setAppointmentToDate(val[1]);
            }}
            onClear={() => {
              setAppointmentFromDate(null);
              setAppointmentToDate(null);
            }}
            fromDate={appointmentFromDate}
            toDate={appointmentToDate}
          />
          <CustomDateRangePicker
            placeholder="Due Date"
            onOkClick={(val) => {
              setDueFromDate(val[0]);
              setDueToDate(val[1]);
            }}
            onClear={() => {
              setDueFromDate(null);
              setDueToDate(null);
            }}
            fromDate={dueFromDate}
            toDate={dueToDate}
          />
        </FilterComponent>
      )}

      <CustomGridTable
        columns={columns}
        rows={rows}
        total={total}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        perPageData={perPageData}
        setPerPageData={setPerPageData}
        order={order}
        orderBy={orderBy}
        setOrder={setOrder}
        setOrderBy={setOrderBy}
        columnVisibilityModel={columnVisibilityModel}
        onRowSelectionModelChange={handleSelectionChange}
        setColumnVisibilityModel={setColumnVisibilityModel}
        isLoading={isDataLoading}
        filterHeight={filterHeight(isFilterComponentVisible)}
        noData={<NoRecordFound />}
        // Later just do what has been done in work_order for the same
      />
    </>
  );

  const WorkOrderWrapper = (
    <StyledMainWrapper
      title={t('attributes.work_order.work_orders')}
      btn={
        <>
          <CustomButton
            text={t('attributes.export')}
            color="secondary"
            sx={{ height: '52%' }}
            startIcon={<OpenInNewIcon />}
            onClick={() => {
              if (!selectedRows || selectedRows?.length === 0) {
                dispatch(
                  snackbarToggle({
                    isOpen: true,
                    isErrorMsg: true,
                    msg: t('message.common.noRecordExportMessage'),
                  })
                );
                setSelectedRows(null);
              } else {
                exportToCSV(
                  selectedRows,
                  COLUMNS_TO_EXPORT.WORK_ORDER,
                  EXPORT_FILE_NAMES.WORK_ORDER,
                  COLUMNS_TO_MAP.WORK_ORDER
                );
              }
            }}
          />
        </>
      }
    >
      <Box sx={{ width: '100%', p: '16px 16px 0 16px' }}>
        {!isDataLoading && totalCount === 0 && noData}
        {isInitialLoading ? (
          <Box
            sx={{
              width: '100%',
              mt: 2,
              minHeight: `calc(100vh - ${isFilterComponentVisible ? 357 : 301}px)`,
              maxHeight: `calc(100vh - ${isFilterComponentVisible ? 357 : 301}px)`,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <CustomCircularLoader />
          </Box>
        ) : (
          !isInitialLoading && totalCount > 0 && renderedComponent
        )}
      </Box>
    </StyledMainWrapper>
  );

  return <>{WorkOrderWrapper}</>;
};

export default WorkOrder;
