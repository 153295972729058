import { createAsyncThunk } from '@reduxjs/toolkit';

import { APIs } from '../../constants/APIConstants';
import { CATEGORY } from '../actionPrefix';
import { axiosInstanceTechnician } from '../axios';

// Get Category List API
export const getCategoryDropdownList = createAsyncThunk(
  CATEGORY.GET_CATEGORY_DROPDOWN_LIST,
  async (req, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceTechnician.get(
        APIs.CATEGORY.GET_CATEGORY_DROPDOWN_LIST
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
