import { createSlice } from '@reduxjs/toolkit';

import { getFrequencyList } from './api';

const frequencyList = {
  isLoading: true,
  error: false,
  data: [],
};

const frequencySlice = createSlice({
  name: 'frequency',
  initialState: {
    frequencyList,
  },
  reducers: {
    resetFrequencyList(state) {
      state.standardsDropdownList = { ...frequencyList };
    },
  },
  extraReducers: (builder) => {
    builder
      // Get Frequency List
      .addCase(getFrequencyList.pending, (state) => {
        state.frequencyList.isLoading = true;
      })
      .addCase(getFrequencyList.fulfilled, (state, action) => {
        state.frequencyList.isLoading = false;
        state.frequencyList.data = action.payload;
      })
      .addCase(getFrequencyList.rejected, (state, action) => {
        state.frequencyList.isLoading = false;
        state.frequencyList.error = action.error.message;
      });
  },
});

export const { resetFrequencyList } = frequencySlice.actions;

export default frequencySlice.reducer;
