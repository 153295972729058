import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouterProvider } from 'react-router-dom';

import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { CssBaseline, ThemeProvider } from '@mui/material';

// Internal
import { theme } from './assets/theme';
import CustomSnackbar from './components/CommonComponents/Snackbar';
import { msalConfig } from './hoc/authConfig';
import { routes } from './routes';
import { snackbarToggle } from './store/CommonReducer';

// MSAL instance
export const msalInstance = new PublicClientApplication(msalConfig);

const App = () => {
  const dispatch = useDispatch();
  const { snackbarOpen, snackbarMessage, isErrorMsg } = useSelector(
    (state) => state.common
  );

  useEffect(() => {
    if (snackbarOpen) {
      const timer = setTimeout(() => {
        dispatch(
          snackbarToggle({
            snackbarOpen: false,
            snackbarMessage: '',
            isErrorMsg: false,
          })
        );
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [snackbarOpen]);

  return (
    <MsalProvider instance={msalInstance}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <RouterProvider router={routes} />
        <CustomSnackbar
          open={snackbarOpen}
          onClose={() => dispatch(snackbarToggle)}
          msg={snackbarMessage}
          isError={isErrorMsg}
        />
      </ThemeProvider>
    </MsalProvider>
  );
};

export default App;
