import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import BallotOutlinedIcon from '@mui/icons-material/BallotOutlined';
import EastOutlinedIcon from '@mui/icons-material/EastOutlined';
import UpgradeOutlinedIcon from '@mui/icons-material/UpgradeOutlined';
import {
  Box,
  Card,
  CardContent,
  Grid,
  styled,
  Typography,
} from '@mui/material';

import {
  DATA_AMANAGEMENT_ICON_COLOR,
  DIVIDER_COLOR,
} from '../../../constants/Colors';
import { ROUTENAME } from '../../../constants/RoutesConstants';
import StyledMainWrapper from '../StyledMainWrapper';

const IconBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

const CustomTypography = styled(Typography)(({ theme }) => ({
  fontSize: '11px',
}));

const CustomCardContent = styled(CardContent)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
  gap: '8px',
  paddingBottom: 0,
}));

export const DataManagement = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const Cards = () => (
    <Grid container spacing="25px">
      {/* Card 1 */}
      <Grid item xs={12} sm={6} md={4}>
        <Card
          sx={{ boxShadow: 0, borderRadius: '4px', cursor: 'pointer' }}
          onClick={() => {
            navigate(ROUTENAME.IMPORT_DATA);
          }}
        >
          <CustomCardContent>
            <IconBox>
              <UpgradeOutlinedIcon
                style={{
                  rotate: '180deg',
                  height: '24px',
                  width: '24px',
                  color: DATA_AMANAGEMENT_ICON_COLOR,
                }}
              />
              <EastOutlinedIcon
                style={{
                  height: '24px',
                  width: '24px',
                }}
              />
            </IconBox>
            <CustomTypography variant="common600">
              {t('attributes.import')}
            </CustomTypography>
            <CustomTypography variant="common400">
              {t('attributes.reports.dataManagement.importDataNote')}
            </CustomTypography>
          </CustomCardContent>
        </Card>
      </Grid>

      {/* Card 2 */}
      <Grid item xs={12} sm={6} md={4}>
        <Card
          sx={{ boxShadow: 0, borderRadius: '4px', cursor: 'pointer' }}
          onClick={() => {
            navigate(ROUTENAME.EXPORT_DATA);
          }}
        >
          <CustomCardContent>
            <IconBox>
              <UpgradeOutlinedIcon
                style={{
                  height: '24px',
                  width: '24px',
                  color: DATA_AMANAGEMENT_ICON_COLOR,
                }}
              />
              <EastOutlinedIcon
                style={{
                  height: '24px',
                  width: '24px',
                }}
              />
            </IconBox>
            <CustomTypography variant="common600">
              {t('attributes.export')}
            </CustomTypography>
            <CustomTypography variant="common400">
              {t('attributes.reports.dataManagement.exportDataNote')}
            </CustomTypography>
          </CustomCardContent>
        </Card>
      </Grid>

      {/* Card 3 */}
      <Grid item xs={12} sm={6} md={4}>
        <Card
          sx={{ boxShadow: 0, borderRadius: '4px', cursor: 'pointer' }}
          onClick={() => {
            navigate(ROUTENAME.SAMPLE_TEMPLATES);
          }}
        >
          <CustomCardContent>
            <IconBox>
              <BallotOutlinedIcon
                style={{
                  height: '24px',
                  width: '24px',
                  color: DATA_AMANAGEMENT_ICON_COLOR,
                }}
              />
              <EastOutlinedIcon
                style={{
                  height: '24px',
                  width: '24px',
                }}
              />
            </IconBox>
            <CustomTypography variant="common600">Templates</CustomTypography>
            <CustomTypography variant="common400">
              {t('attributes.reports.dataManagement.templatesNote')}
            </CustomTypography>
          </CustomCardContent>
        </Card>
      </Grid>
    </Grid>
  );

  const DataManagementqWrapper = (
    <StyledMainWrapper
      title={t('attributes.reports.dataManagement.title')}
      style={{ height: '100%', backgroundColor: DIVIDER_COLOR }}
    >
      <Cards />
    </StyledMainWrapper>
  );

  return <>{DataManagementqWrapper}</>;
};
