import { createSlice } from '@reduxjs/toolkit';

import {
  createOfficeStaff,
  getOfficeStaffById,
  getOfficeStaffList,
  updateOfficeStaff,
} from './api';

const initialCreateData = {
  isLoading: false,
  error: false,
  createdOfficeStaff: false,
};

const initialEditData = {
  isLoading: false,
  error: false,
  editOfficeStaff: false,
};

const initialGetData = {
  isLoading: true,
  error: null,
  officeStaffActiveList: [],
  officeStaffInactiveList: [],
  page: 1,
  size: 10,
  total: 0,
  totalCount: 0,
};

const officeStaffSlice = createSlice({
  name: 'officeStaff',
  initialState: {
    get: { ...initialGetData },
    create: {
      ...initialCreateData,
    },
    edit: {
      ...initialEditData,
    },
  },
  reducers: {
    resetCreate(state) {
      state.create = { ...initialCreateData };
    },
    resetEdit(state) {
      state.edit = { ...initialEditData };
    },
    resetPagination(state) {
      state.get = { ...initialGetData };
    },
  },
  extraReducers: (builder) => {
    builder
      // OfficeStaff List
      .addCase(getOfficeStaffList.pending, (state) => {
        state.get.isLoading = true;
      })
      .addCase(getOfficeStaffList.fulfilled, (state, action) => {
        const { pagination, data, active } = action.payload;
        state.get.isLoading = false;

        if (active) {
          state.get.officeStaffActiveList = data;
        } else {
          state.get.officeStaffInactiveList = data;
        }
        state.get.total = pagination.total_items;
        state.get.totalCount = pagination.total_count;
      })
      .addCase(getOfficeStaffList.rejected, (state, action) => {
        state.get.isLoading = false;
        state.get.error = action.payload;
      })
      // Create OfficeStaff
      .addCase(createOfficeStaff.pending, (state) => {
        state.create.isLoading = true;
      })
      .addCase(createOfficeStaff.fulfilled, (state, action) => {
        state.create.error = false;
        state.create.isLoading = false;
        state.create.createdOfficeStaff = action.payload.data[0];
      })
      .addCase(createOfficeStaff.rejected, (state, action) => {
        state.create.isLoading = false;
        state.create.error = action.payload;
      })
      // Update OfficeStaff
      .addCase(updateOfficeStaff.pending, (state) => {
        state.create.isLoading = true;
      })
      .addCase(updateOfficeStaff.fulfilled, (state, action) => {
        state.create.error = false;
        state.create.isLoading = false;
        state.create.createdOfficeStaff = action.payload.data[0];
      })
      .addCase(updateOfficeStaff.rejected, (state, action) => {
        state.create.isLoading = false;
        state.create.error = action.payload;
      })
      // Edit OfficeStaff
      .addCase(getOfficeStaffById.pending, (state) => {
        state.edit.isLoading = true;
      })
      .addCase(getOfficeStaffById.fulfilled, (state, action) => {
        state.edit.isLoading = false;
        state.edit.editOfficeStaff = action.payload.data[0];
      })
      .addCase(getOfficeStaffById.rejected, (state, action) => {
        state.edit.isLoading = false;
        state.edit.error = action.payload;
      });
  },
});

export const { resetCreate, resetEdit, resetPagination } =
  officeStaffSlice.actions;

export default officeStaffSlice.reducer;
