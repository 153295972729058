import React from 'react';
import { useSelector } from 'react-redux';

// MUI
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import {
  AppBar,
  Avatar,
  Badge,
  Box,
  IconButton,
  Toolbar,
  Tooltip,
  styled,
} from '@mui/material';

import { PRIMARY } from '../../constants/Colors';
import {
  DRAWER_WIDTH,
  HEADER_HEIGHT,
  ICON_DRAWER_WIDTH,
} from '../../constants/Typography';
import CustomSearch from '../CommonComponents/Search';

const ToolbarStyled = styled(Toolbar)(() => ({
  '&.MuiToolbar-root': {
    minHeight: HEADER_HEIGHT,
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

const Header = ({ isNavbarOpen, setIsNavbarOpen }) => {
  const { profileDetails } = useSelector((state) => state.common);

  function extractInitials(str) {
    // Split the string by spaces
    let parts = str?.split(' ');

    if (parts?.length > 0) {
      if (parts.length === 1) {
        return parts[0][0];
      } else {
        return parts[0][0] + (parts.length > 1 ? parts[1][0] : '');
      }
    } else {
      return '-';
    }
  }

  return (
    <AppBar
      position="fixed"
      sx={{
        width: {
          sm: `calc(100% - ${isNavbarOpen ? DRAWER_WIDTH : ICON_DRAWER_WIDTH}px)`,
        },
        ml: { sm: `${isNavbarOpen ? DRAWER_WIDTH : ICON_DRAWER_WIDTH}px` },
        background: 'white',
        borderRadius: 0,
        boxShadow: 0,
      }}
    >
      <ToolbarStyled>
        <IconButton
          aria-label="open drawer"
          edge="start"
          onClick={() => setIsNavbarOpen(!isNavbarOpen)}
        >
          <MenuIcon />
        </IconButton>
        <Box
          sx={{
            color: 'black',
            display: 'flex',
            alignItems: 'center',
            gap: '4px',
          }}
        >
          <Box sx={{ pr: '8px' }}>
            <CustomSearch />
          </Box>
          <IconButton>
            <HelpOutlineOutlinedIcon
              sx={{ color: 'black', fontSize: '24px !important' }}
            />
          </IconButton>
          <IconButton>
            <Badge
              badgeContent={11} // Number to display on the badge
              overlap="circular"
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              sx={{
                '& .MuiBadge-badge': {
                  fontSize: '6px !important', // Adjust the font size of the number
                  bgcolor: PRIMARY,
                  color: 'white',
                  height: '16px',
                  minWidth: '13px',
                },
              }}
            >
              <NotificationsOutlinedIcon
                sx={{ color: 'black', fontSize: '24px !important' }}
              />
            </Badge>
          </IconButton>
          <IconButton>
            <SettingsOutlinedIcon
              sx={{ color: 'black', fontSize: '24px !important' }}
            />
          </IconButton>
          <Tooltip title={profileDetails?.role_title || '-'} arrow>
            <Avatar
              sx={{
                width: 36,
                height: 36,
                bgcolor: PRIMARY,
                ml: '8px',
                '&.MuiAvatar-root': { fontWeight: 600, fontSize: '14px' },
              }}
            >
              {extractInitials(
                profileDetails?.name || profileDetails?.customer_name
              )}
            </Avatar>
          </Tooltip>
        </Box>
      </ToolbarStyled>
    </AppBar>
  );
};

export default Header;
