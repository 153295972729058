import { createSlice } from '@reduxjs/toolkit';

import {
  getAssignedWorkOrder,
  postAssignWOtoTech,
  unassignedWOtoTech,
} from './api';

const scheduleBoardEventList = {
  isLoading: true,
  error: false,
  data: false,
};

const assignWOtoTech = {
  isLoading: false,
  error: false,
  data: false,
};

const unassignedWOFromTech = {
  isLoading: false,
  error: false,
  data: false,
};

const scheduleBoardSlice = createSlice({
  name: 'scheduleBoard',
  initialState: {
    scheduleBoardEventList,
    assignWOtoTech,
    unassignedWOFromTech,
  },
  reducers: {
    resetScheduleBoardWOList(state) {
      state.scheduleBoardEventList = { ...scheduleBoardEventList };
    },
    resetAssignWOtoTech(state) {
      state.assignWOtoTech = { ...assignWOtoTech };
    },
    resetUnassignedWOtoTech(state) {
      state.unassignedWOFromTech = { ...unassignedWOFromTech };
    },
  },
  extraReducers: (builder) => {
    builder
      // Get Assigned Work Order
      .addCase(getAssignedWorkOrder.pending, (state) => {
        state.scheduleBoardEventList.isLoading = true;
      })
      .addCase(getAssignedWorkOrder.fulfilled, (state, action) => {
        state.scheduleBoardEventList.isLoading = false;
        state.scheduleBoardEventList.data = action.payload.data;
      })
      .addCase(getAssignedWorkOrder.rejected, (state, action) => {
        state.scheduleBoardEventList.isLoading = false;
        state.scheduleBoardEventList.error = action.error.message;
      })

      // Post Assign Work Order To Technician
      .addCase(postAssignWOtoTech.pending, (state) => {
        state.assignWOtoTech.isLoading = true;
      })
      .addCase(postAssignWOtoTech.fulfilled, (state, action) => {
        state.assignWOtoTech.isLoading = false;
        state.assignWOtoTech.data = action.payload;
      })
      .addCase(postAssignWOtoTech.rejected, (state, action) => {
        state.assignWOtoTech.isLoading = false;
        state.assignWOtoTech.error = action.error.message;
      })

      // Unassigned Work Order To Technician
      .addCase(unassignedWOtoTech.pending, (state) => {
        state.unassignedWOFromTech.isLoading = true;
      })
      .addCase(unassignedWOtoTech.fulfilled, (state, action) => {
        state.unassignedWOFromTech.isLoading = false;
        state.unassignedWOFromTech.data = action.payload;
      })
      .addCase(unassignedWOtoTech.rejected, (state, action) => {
        state.unassignedWOFromTech.isLoading = false;
        state.unassignedWOFromTech.error = action.error.message;
      });
  },
});

export const {
  resetScheduleBoardWOList,
  resetAssignWOtoTech,
  resetUnassignedWOtoTech,
} = scheduleBoardSlice.actions;

export default scheduleBoardSlice.reducer;
