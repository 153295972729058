import { useTranslation } from 'react-i18next';

import { Box, Chip, Divider, Typography, styled } from '@mui/material';
import { toNumber } from 'lodash';

import { SCHEDULE_BOARD_WO_UTC_LOCAL } from '../../constants/Constants';
// eslint-disable-next-line import-helpers/order-imports
import { SQFT_TO_SQM, longDateTimeRange } from '../../constants/common';
import { formattedDate } from '../../utils';
import CustomGoogleMap from '../CommonComponents/GoogleMap';

const StyledHeader = styled(Box)({
  padding: '16px',
  backgroundColor: 'rgba(235, 235, 235, 0.2)',
});

const SideWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '16px',
  padding: '16px',
  width: '100%',
});

const KeyValueComponent = ({ name, value, minWidth = '180px' }) => (
  <Box sx={{ display: 'flex' }}>
    <Typography variant="body1" minWidth={minWidth}>
      {`${name}:`}
    </Typography>
    <Typography variant="body2" sx={{ textTransform: 'capitalize' }}>
      {value}
    </Typography>
  </Box>
);

const WorkOrderData = ({ data, selectedProperty }) => {
  const { t } = useTranslation();
  const workOrderDetailKey =
    data[
      data?.type_name
        ?.split(' ')
        ?.map((d) => d.toLowerCase())
        ?.join('_')
    ];

  const markers = [
    {
      id: 1,
      lat: toNumber(selectedProperty?.latitude) ?? '',
      lng: toNumber(selectedProperty?.longitude) ?? '',
      tooltipText: selectedProperty?.address,
      color: '#395B50',
    },
  ];

  const center = {
    lat: toNumber(selectedProperty?.latitude) ?? '',
    lng: toNumber(selectedProperty?.longitude) ?? '',
  };
  const buildingEra = selectedProperty?.building_attributes.length
    ? selectedProperty?.building_attributes[0]
    : null;
  const accessDetail = selectedProperty?.access_details?.[0];

  const requirements = [
    { key: 'induction_required', value: 'Induction Required' },
    { key: 'log_book_entry_required', value: 'Log Book Entry Required' },
    { key: 'police_check_required', value: 'Police Check Required' },
    {
      key: 'working_with_children_check_required',
      value: ' Working with Children Check Required',
    },
  ];

  const displayRequirements = requirements
    ?.filter((r) => selectedProperty?.site_requirements[0][r.key])
    .map((v) => v?.value)
    .join(',');

  return (
    <Box sx={{ overflow: 'auto', height: 'calc(100vh - 310px)' }}>
      <Box>
        <StyledHeader>
          <Typography variant="body1">
            {t('attributes.work_order.work_order_details')}
          </Typography>
        </StyledHeader>
        <Divider />
        <Box
          sx={{
            display: 'flex',
            width: '100%',
          }}
        >
          <SideWrapper>
            <KeyValueComponent
              name="Work Order ID"
              value={`W-${data?.id}` || '-'}
            />
            <Box sx={{ display: 'flex' }}>
              <Typography variant="body1" minWidth="180px">
                {`${t('attributes.address')}:`}
              </Typography>
              <Box>
                <Typography
                  variant="body2"
                  sx={{ textTransform: 'capitalize' }}
                >
                  {`${selectedProperty?.address ? `${selectedProperty?.address},` : ''} ${selectedProperty?.city} ${selectedProperty?.state} ${selectedProperty?.zip_code}` ||
                    '-'}
                </Typography>
                <Box sx={{ height: '100px', width: '200px', marginTop: '8px' }}>
                  <CustomGoogleMap
                    isDashboard={false}
                    markers={markers}
                    center={center}
                  />
                </Box>
              </Box>
            </Box>
          </SideWrapper>
          <Divider orientation="vertical" flexItem />
          <SideWrapper>
            <KeyValueComponent
              name={t('attributes.work_order.work_order_name')}
              value={`${workOrderDetailKey?.equipment_type?.display_name} - ${data?.work_order_type}`}
            />
            <KeyValueComponent
              name={t('attributes.work_order.tolerance_date_duration')}
              value="-"
            />
            <KeyValueComponent
              name={t('attributes.work_order.estimated_duration')}
              value="-"
            />
            <KeyValueComponent
              name={t('attributes.work_order.assign_to')}
              value={selectedProperty?.contract_assignment_details?.technician}
            />
            <KeyValueComponent name="Type" value={data?.work_order_type} />
          </SideWrapper>
        </Box>
        <Divider />
      </Box>
      <Box>
        <StyledHeader>
          <Typography variant="body1">
            {t('attributes.work_order.contract_details')}
          </Typography>
        </StyledHeader>
        <Divider />
        <Box
          sx={{
            display: 'flex',
            width: '100%',
          }}
        >
          <SideWrapper>
            <KeyValueComponent
              name={`${t('attributes.work_order.appointment')} ${t('attributes.work_order.date_nd_time')}`}
              value={longDateTimeRange(
                formattedDate(
                  data?.scheduled_start_date_time,
                  SCHEDULE_BOARD_WO_UTC_LOCAL
                ),
                formattedDate(
                  data?.scheduled_end_date_time,
                  SCHEDULE_BOARD_WO_UTC_LOCAL
                )
              )}
              minWidth="200px"
            />
            <KeyValueComponent
              name={t('attributes.customer.customer')}
              value={selectedProperty?.customer}
              minWidth="200px"
            />
          </SideWrapper>
          <Divider orientation="vertical" flexItem />
          <SideWrapper>
            <KeyValueComponent
              name={t('attributes.work_order.property_contact')}
              value={selectedProperty?.property_contact_info?.name}
            />
            <KeyValueComponent
              name={t('attributes.company.cell_phone')}
              value={selectedProperty?.property_contact_info?.phone}
            />
            <KeyValueComponent
              name={t('attributes.email')}
              value={selectedProperty?.property_contact_info?.email ?? '-'}
            />
          </SideWrapper>
        </Box>
        <Divider />
      </Box>
      <Box>
        <StyledHeader>
          <Typography variant="body1">
            {t('attributes.property.accessDetails')}
          </Typography>
        </StyledHeader>
        <Divider />
        <Box
          sx={{
            display: 'flex',
            width: '100%',
          }}
        >
          <SideWrapper>
            <KeyValueComponent
              name={t('attributes.property.accessSchedule')}
              value={
                accessDetail?.access_detail_schedule?.map((item) => (
                  <Chip key={item.id} label={item.schedules?.display_name} />
                )) ?? '-'
              }
            />
            <KeyValueComponent
              name={t('attributes.property.accessProcedure')}
              value={
                accessDetail?.access_detail_procedure?.map((item) => (
                  <Chip
                    key={item.id}
                    label={item.procedures?.display_name}
                    sx={{ height: '20px' }}
                  />
                )) ?? '-'
              }
            />
          </SideWrapper>
          <Divider orientation="vertical" flexItem />
          <SideWrapper>
            <KeyValueComponent
              name={t('attributes.property.accessCode')}
              value={accessDetail?.access_code ?? '-'}
            />
            <KeyValueComponent
              name={t('attributes.property.accessNote')}
              value={accessDetail?.access_note ?? '-'}
            />
          </SideWrapper>
        </Box>
        <Divider />
      </Box>
      <Box>
        <StyledHeader>
          <Typography variant="body1">
            {t('attributes.property.siteRequirement')}
          </Typography>
        </StyledHeader>
        <Divider />
        <Box
          sx={{
            display: 'flex',
            width: '100%',
          }}
        >
          <SideWrapper>
            <KeyValueComponent
              name={t('attributes.requirements')}
              value={displayRequirements}
            />
            <KeyValueComponent
              name={t('attributes.property.serviceRequirements')}
              value={
                selectedProperty?.site_requirements[0]?.service_requirements ??
                '-'
              }
            />
            <KeyValueComponent
              name={t('attributes.property.safetyRequirements')}
              value={
                selectedProperty?.site_requirements[0]
                  ?.technician_requirements ?? '-'
              }
            />
          </SideWrapper>
        </Box>
        <Divider />
      </Box>
      <Box>
        <StyledHeader>
          <Typography variant="body1">
            {t('attributes.property.buildingAttributes')}
          </Typography>
        </StyledHeader>
        <Divider />
        <Box
          sx={{
            display: 'flex',
            width: '100%',
          }}
        >
          <SideWrapper>
            <KeyValueComponent
              name={t('attributes.property.annualDueCertificateDate')}
              value={
                formattedDate(buildingEra?.annual_due_certification_date) || '-'
              }
              minWidth="220px"
            />
            <KeyValueComponent
              name={t('attributes.property.buildingEra')}
              value={buildingEra?.property_building_era?.display_name || '-'}
              minWidth="220px"
            />
            <KeyValueComponent
              name={t('attributes.property.buildingClass')}
              value={buildingEra?.property_building_class?.display_name || '-'}
              minWidth="220px"
            />
            <KeyValueComponent
              name={t('attributes.property.buildingType')}
              value={buildingEra?.property_building_type?.display_name || '-'}
              minWidth="220px"
            />
            <KeyValueComponent
              name={t('attributes.property.buildingSizeText')}
              value={buildingEra?.building_size || '-'}
              minWidth="220px"
            />
            <KeyValueComponent
              name={t('attributes.property.buildingSizeSqm')}
              value={SQFT_TO_SQM(buildingEra?.building_size) || '-'}
              minWidth="220px"
            />
            <KeyValueComponent
              name={t('attributes.property.internalNote')}
              value={selectedProperty?.internal_note || '-'}
              minWidth="220px"
            />
          </SideWrapper>
          <Divider orientation="vertical" flexItem />
          <SideWrapper>
            <KeyValueComponent
              name={t('attributes.property.storyAboveGround')}
              value={buildingEra?.stories_above_ground || '-'}
            />
            <KeyValueComponent
              name={t('attributes.property.storyBelowGround')}
              value={buildingEra?.stories_below_ground || '-'}
            />
            <KeyValueComponent
              name={t('attributes.property.constructionClass')}
              value={
                buildingEra?.property_construction_class?.display_name || '-'
              }
            />
            <KeyValueComponent
              name={t('attributes.property.buildingPart')}
              value={buildingEra?.building_part || '-'}
            />
            <KeyValueComponent
              name={t('attributes.property.buildingTenancies')}
              value={buildingEra?.building_tenancies_no || '-'}
            />
            <KeyValueComponent
              name={t('attributes.property.buildingStandards')}
              value={
                buildingEra?.property_building_standard
                  ?.standard_display_name || '-'
              }
            />
          </SideWrapper>
        </Box>
        <Divider />
      </Box>
    </Box>
  );
};

export default WorkOrderData;
