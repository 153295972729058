import GridOffOutlinedIcon from '@mui/icons-material/GridOffOutlined';
import { Box, Typography } from '@mui/material';

import { NODATA_ICON_COLOR } from '../../../constants/Colors';

const NoRecordFound = () => (
  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
    <GridOffOutlinedIcon
      sx={{ fontSize: '40px !important', color: NODATA_ICON_COLOR }}
    />
    <Typography variant="body1" sx={{ fontSize: 14 }}>
      No records found
    </Typography>
  </Box>
);

export default NoRecordFound;
