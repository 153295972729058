import { createSlice } from '@reduxjs/toolkit';

import { getRoutineStandardFrequencyList } from './api';

const initialRoutineStandardFrequencyList = {
  isLoading: true,
  error: false,
  data: false,
};

const routineSlice = createSlice({
  name: 'routine',
  initialState: {
    routineStandardFrequencyList: initialRoutineStandardFrequencyList,
  },
  reducers: {
    resetRoutineStandardFrequencyList(state) {
      state.routineStandardFrequencyList = {
        ...initialRoutineStandardFrequencyList,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      // Get Routine Standard Frequency List
      .addCase(getRoutineStandardFrequencyList.pending, (state) => {
        state.routineStandardFrequencyList.isLoading = true;
      })
      .addCase(getRoutineStandardFrequencyList.fulfilled, (state, action) => {
        state.routineStandardFrequencyList.isLoading = false;
        state.routineStandardFrequencyList.data = action.payload;
      })
      .addCase(getRoutineStandardFrequencyList.rejected, (state, action) => {
        state.routineStandardFrequencyList.isLoading = false;
        state.routineStandardFrequencyList.error = action.error.message;
      });
  },
});

export const { resetRoutineStandardFrequencyList } = routineSlice.actions;

export default routineSlice.reducer;
