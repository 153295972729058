import React from 'react';
import { Controller } from 'react-hook-form';

import { Box, Divider, Typography } from '@mui/material';

import { SECONDARY } from '../../../../constants/Colors';
import { Validation } from '../../../../constants/FieldValidationMsg';
import { EMAIL_REGEX } from '../../../../constants/Regex';
import { clearTextfields } from '../../../../utils';
import CustomTextField from '../../../CommonComponents/CustomTextField';

const Quoting = ({ title, control, Section, setValue, trigger }) => (
  <Box
    sx={{
      background: SECONDARY,
      borderRadius: '4px',
    }}
  >
    <Box>
      <Box sx={{ padding: '16px' }}>
        <Typography variant="h2">{title}</Typography>
      </Box>
      <Divider />
      <Section>
        <Controller
          name="quoting_contact_name"
          control={control}
          rules={{
            maxLength: {
              value: 80,
              message: Validation.general.quotingContactName,
            },
          }}
          render={({
            field: { onChange, value, name, onBlur },
            fieldState: { error },
          }) => (
            <CustomTextField
              label="Quoting Contact Name"
              fullWidth
              value={value}
              onChange={(e) => {
                onChange(e);
                trigger('quoting_contact_name');
              }}
              helperText={error ? error.message : ''}
              error={error}
              onClear={() => clearTextfields(setValue, name)}
              isRequired={false}
              onBlur={() => {
                onChange(value.trim());
                onBlur();
              }}
            />
          )}
        />
        <Controller
          name="quotingEmailTo"
          control={control}
          rules={{
            maxLength: {
              value: 254,
              message: Validation.general.email,
            },
            pattern: {
              value: EMAIL_REGEX,
              message: Validation.general.validEmail,
            },
          }}
          render={({
            field: { onChange, value, name },
            fieldState: { error },
          }) => (
            <CustomTextField
              label="Email To"
              fullWidth
              value={value}
              onChange={(e) => {
                onChange(e);
                trigger('quotingEmailTo');
              }}
              helperText={error ? error.message : ''}
              error={error}
              onClear={() => clearTextfields(setValue, name)}
              isRequired={false}
            />
          )}
        />
      </Section>
    </Box>
  </Box>
);

export default Quoting;
