import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AppBar, Divider, Toolbar } from '@mui/material';
import { styled } from '@mui/system';

//Internal
import Logo from '../../assets/images/dark-logo.png';
import {
  LANDINGPAGE_LOGO_HEIGHT,
  LANDINGPAGE_LOGO_WIDTH,
} from '../../constants/Typography';
import CustomButton from '../CommonComponents/CustomButton';

const CustomAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  position: 'absolute',
  width: '100%',
  height: '100px',
  boxShadow: 'none',
  justifyContent: 'center',
}));

const LoginButton = styled(CustomButton)(({ theme }) => ({
  fontWeight: '600',
  margin: '30px 80px 30px 0px',
  width: '86px',
  height: '40px',
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.secondary.main,
  },
}));

const LandingPageHeader = ({ openModal }) => {
  const { t, i18n } = useTranslation();
  const [changeLanguage, _setChangeLanguage] = useState(
    localStorage.getItem('language') || 'en'
  );

  useEffect(() => {
    localStorage.setItem('language', changeLanguage);
    i18n.changeLanguage(changeLanguage);
  }, [changeLanguage]);

  return (
    <CustomAppBar>
      <Toolbar
        sx={{
          justifyContent: 'space-between',
        }}
      >
        <img
          src={Logo}
          alt="Logo"
          style={{
            height: LANDINGPAGE_LOGO_HEIGHT,
            width: LANDINGPAGE_LOGO_WIDTH,
            margin: '27px 100px',
          }}
        />
        <LoginButton
          text={t('auth.login.login')}
          variant="outlined"
          onClick={openModal}
          fontSize="16px"
        />
        {/* TODO: Design part is pending and it's for developer purpose for now once we've localization ticket will do the chnages whenever is required.  */}
        {/* <Select
            value={changeLanguage}
            label="Age"
            onChange={(event) => setChangeLanguage(event.target.value)}
          >
            <MenuItem value={"en"}>English</MenuItem>
            <MenuItem value={"es"}>Spanish</MenuItem>
          </Select> */}
      </Toolbar>
      <Divider />
    </CustomAppBar>
  );
};

export default LandingPageHeader;
