import { useCallback, useEffect } from 'react';

import {
  ADDRESS_COUNTRY,
  ADDRESS_REGION_CODE,
  GOOGLE_API_KEY,
} from '../constants/Constants';
import { axiosInstanceGoogleMapAPI } from '../store/axios';

const useAddressValidationHook = ({
  address,
  city,
  state,
  postcode,
  addressBlur,
  stateBlur,
  cityBlur,
  postcodeBlur,
  setError,
  clearErrors,
  setCoords = () => {},
  fieldName,
  addressRecheck = true,
}) => {
  const clearAllAddressErrors = () => {
    clearErrors(fieldName.addressKey);
    clearErrors(fieldName.stateKey);
    clearErrors(fieldName.cityKey);
    clearErrors(fieldName.postCodeKey);
  };

  const checkAddress = useCallback(async () => {
    const params = new URLSearchParams();

    params.append('key', GOOGLE_API_KEY);

    try {
      const response = await axiosInstanceGoogleMapAPI.post(
        `?${params.toString()}`,
        {
          address: {
            regionCode: ADDRESS_REGION_CODE,
            addressLines: [
              `${address}, ${city.value} ${state.value} ${postcode}, ${ADDRESS_COUNTRY}`,
            ],
          },
        }
      );

      return response.data;
    } catch (error) {
      console.error('Address validation failed:', error);
      // Handle error as needed
    }
  }, [address, city, state, postcode]);

  useEffect(() => {
    if (
      address &&
      state &&
      city &&
      postcode?.length > 3 &&
      addressBlur &&
      stateBlur &&
      cityBlur &&
      postcodeBlur &&
      addressRecheck
    ) {
      clearAllAddressErrors();
      const validateAddress = async () => {
        const resp = await checkAddress();

        if (resp) {
          let unconfirmedComponentTypes =
            resp.result.address.unconfirmedComponentTypes;

          if (unconfirmedComponentTypes) {
            if (unconfirmedComponentTypes.includes('postal_code')) {
              setError(fieldName.postCodeKey, {
                type: 'server',
                message: `${fieldName.postCodeValue} is not correct.`,
              });
            }

            if (unconfirmedComponentTypes.includes('locality')) {
              setError(fieldName.cityKey, {
                type: 'server',
                message: `Combination of ${fieldName.cityValue} and ${fieldName.postCodeValue} is not valid`,
              });
            }

            if (
              unconfirmedComponentTypes.includes('administrative_area_level_1')
            ) {
              setError(fieldName.stateKey, {
                type: 'server',
                message: `Combination of ${fieldName.stateValue} and ${fieldName.postCodeValue} is not valid`,
              });
            }

            unconfirmedComponentTypes = unconfirmedComponentTypes.filter(
              (val) =>
                val !== 'postal_code' ||
                val !== 'locality' ||
                val !== 'administrative_area_level_1'
            );

            let resultAddress = resp.result.address.addressComponents.filter(
              (val) =>
                unconfirmedComponentTypes.includes(val.componentType) &&
                val.confirmationLevel === 'UNCONFIRMED_AND_SUSPICIOUS'
            );

            const message = `${resultAddress.map((val) => val.componentName.text).join(' ')} address not found.`;

            resultAddress.length > 0 &&
              setError(fieldName.addressKey, {
                type: 'server',
                message,
              });
            if (
              resp?.result.geocode?.location?.latitude &&
              resp?.result?.geocode?.location?.longitude
            ) {
              setCoords({
                latitude: resp.result.geocode.location.latitude,
                longitude: resp.result.geocode.location.longitude,
              });
            }
          } else {
            clearAllAddressErrors();
            setCoords({
              latitude: resp.result.geocode.location.latitude,
              longitude: resp.result.geocode.location.longitude,
            });
          }
        }
      };

      validateAddress();
    }
  }, [
    address,
    city,
    state,
    postcode,
    addressBlur,
    cityBlur,
    stateBlur,
    postcodeBlur,
    clearErrors,
    setError,
    setCoords,
    checkAddress, // Add checkAddress to dependencies
  ]);
};

export default useAddressValidationHook;
