import React, { useEffect } from 'react';
import { Controller, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Card, Divider, styled, Typography } from '@mui/material';

import { TAB_STATUS } from '../../constants/Constants';
import { Validation } from '../../constants/FieldValidationMsg';
import { getProductDetailsById } from '../../store/productCatalogue/api';
import { formatPriceWithDecimalValue } from '../../utils';
import Autocomplete from '../CommonComponents/AutoComplete';
import CommonCheckbox from '../CommonComponents/CustomCheckbox';
import CustomCircularLoader from '../CommonComponents/CustomLoader';
import CustomTextField from '../CommonComponents/CustomTextField';

const FormFieldWrapper = styled(Box)(() => ({
  padding: '16px',
  rowGap: '16px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));

const FormWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '16px',
}));

const CustomCard = styled(Card)(() => ({
  boxShadow: 'none',
}));

const PRODUCT_TYPE = [
  { value: 'material', label: 'Material' },
  { value: 'equipment', label: 'Equipment' },
  { value: 'expense', label: 'Expense' },
  { value: 'labour', label: 'Labour' },
];

const UNIT_OF_MEASURE = [
  { label: 'Each', value: 'each' },
  { label: 'Length', value: 'length' },
  { label: 'Weight', value: 'weight' },
];

export const ProductCatalogueForm = ({
  id,
  control,
  reset,
  setValue,
  trigger,
  supplierList,
  equipmentTypeList,
  watch,
}) => {
  const dispatch = useDispatch();
  const { isLoading, productDetails } = useSelector(
    (state) => state.productCatalogue.edit
  );
  const { t } = useTranslation();
  const clearHandler = (name) => setValue(name, '');
  const labels = (label) => t(`label.superAdmin.${label}`);

  const findLabelByValue = (list, value) => {
    const item = list.find((option) => option.value === value);

    return item ? { label: item.label, value: item.value } : null;
  };

  useEffect(() => {
    if (id) {
      dispatch(getProductDetailsById(id));
    }
  }, []);

  useEffect(() => {
    if (!isLoading && productDetails && id) {
      reset({
        uuid: productDetails?.uuid,
        product_name: productDetails?.product_name,
        type: findLabelByValue(PRODUCT_TYPE, productDetails?.type),
        equipment_type:
          productDetails?.equipment_type?.map((et) =>
            findLabelByValue(equipmentTypeList, et)
          ) || [],
        description: productDetails?.description,
        sell_price: formatPriceWithDecimalValue(productDetails?.sell_price),
        supplier_uuid: findLabelByValue(
          supplierList,
          productDetails?.supplier_uuid
        ),
        um: findLabelByValue(UNIT_OF_MEASURE, productDetails?.um),
        cost: formatPriceWithDecimalValue(productDetails?.cost),
        estimated_time_hours: productDetails?.estimated_time_hours,
        active: productDetails?.status === TAB_STATUS.active,
      });
    }
  }, [isLoading, productDetails]);

  const selectedType = useWatch({
    control,
    name: 'type',
    defaultValue: '',
  });

  const renderFormBasedOnType = () => {
    switch (selectedType?.value) {
      case 'material':
      case 'equipment':
      case 'expense':
        return materialEquipementExpenseForm;
      case 'labour':
        return labourForm;
    }
  };

  const materialEquipementExpenseForm = (
    <>
      <CustomCard>
        <Box sx={{ padding: '16px' }}>
          <Typography variant="body1">{`${t('attributes.productCatalogue.product_details')}`}</Typography>
        </Box>
        <Divider />
        <FormFieldWrapper>
          <Controller
            name="equipment_type"
            control={control}
            rules={{
              required: `${t('attributes.productCatalogue.equipment_type')} ${Validation.general.required}`,
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Autocomplete
                multiple
                options={equipmentTypeList}
                label={t('attributes.productCatalogue.equipment_type')}
                onChange={(e, newValue) => {
                  onChange(newValue);
                  trigger('equipment_type');
                }}
                value={value}
                error={error}
                helperText={error ? error.message : ''}
              />
            )}
          />
          <Controller
            name="description"
            control={control}
            rules={{
              maxLength: {
                value: 300,
                message: Validation.general.description,
              },
            }}
            render={({
              field: { onChange, value, name },
              fieldState: { error },
            }) => (
              <CustomTextField
                isRequired={false}
                label={t('attributes.productCatalogue.description')}
                fullWidth
                value={value}
                onChange={(e) => {
                  onChange(e);
                  trigger('description');
                }}
                multiline
                rows={2}
                helperText={error ? error.message : ''}
                error={error}
                onClear={() => clearHandler(name)}
              />
            )}
          />
          <Typography variant="body2">
            {t('attributes.productCatalogue.product_detail_description_note')}
          </Typography>
          <Controller
            name="sell_price"
            control={control}
            rules={{
              required: `${t('attributes.productCatalogue.default_sell_price')} ${Validation.general.required}`,
            }}
            render={({
              field: { onChange, value, name },
              fieldState: { error },
            }) => (
              <CustomTextField
                label={t('attributes.productCatalogue.default_sell_price')}
                fullWidth
                value={value}
                onChange={(e) => {
                  onChange(e);
                  trigger('sell_price');
                }}
                onBlur={() => {
                  // Format value on blur
                  if (value) {
                    const formattedValue = parseFloat(value).toFixed(2);

                    onChange(formattedValue);
                    trigger('sell_price');
                  }
                }}
                helperText={error ? error.message : ''}
                error={error}
                onClear={() => clearHandler(name)}
              />
            )}
          />
        </FormFieldWrapper>
      </CustomCard>
      <CustomCard>
        <Box sx={{ padding: '16px' }}>
          <Typography variant="body1">{`${t('attributes.productCatalogue.supplier_details')}`}</Typography>
        </Box>
        <Divider />
        <FormFieldWrapper>
          <Controller
            name="supplier_uuid"
            control={control}
            rules={{
              required: `${t('attributes.productCatalogue.supplier')} ${Validation.general.required}`,
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Autocomplete
                options={supplierList}
                label={t('attributes.productCatalogue.supplier')}
                onChange={(e, newValue) => {
                  onChange(newValue);
                }}
                value={value}
                error={error}
                helperText={error ? error.message : ''}
              />
            )}
          />
          <Controller
            name="cost"
            control={control}
            rules={{
              required: `${t('attributes.productCatalogue.cost_price')} ${Validation.general.required}`,
            }}
            render={({
              field: { onChange, value, name },
              fieldState: { error },
            }) => (
              <CustomTextField
                label={t('attributes.productCatalogue.cost_price')}
                fullWidth
                value={value}
                onChange={(e) => {
                  onChange(e);
                  trigger('cost');
                }}
                onBlur={() => {
                  // Format value on blur
                  if (value) {
                    const formattedValue = parseFloat(value).toFixed(2);

                    onChange(formattedValue);
                    trigger('cost');
                  }
                }}
                helperText={error ? error.message : ''}
                error={error}
                onClear={() => clearHandler(name)}
              />
            )}
          />
          <Controller
            name="um"
            control={control}
            rules={{
              required: `${t('attributes.productCatalogue.unit_of_measure')} ${Validation.general.required}`,
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Autocomplete
                options={UNIT_OF_MEASURE}
                label={t('attributes.productCatalogue.unit_of_measure')}
                onChange={(e, newValue) => {
                  onChange(newValue);
                }}
                value={value}
                error={error}
                helperText={error ? error.message : ''}
              />
            )}
          />
        </FormFieldWrapper>
      </CustomCard>
      <CustomCard>
        <Box sx={{ padding: '16px' }}>
          <Typography variant="body1">{`${t('attributes.productCatalogue.services_details')}`}</Typography>
        </Box>
        <Divider />
        <FormFieldWrapper>
          <Controller
            name="estimated_time_hours"
            control={control}
            rules={{
              required: `${t('attributes.productCatalogue.estimate_time')} ${Validation.general.required}`,
            }}
            render={({
              field: { onChange, value, name },
              fieldState: { error },
            }) => (
              <CustomTextField
                label={t('attributes.productCatalogue.estimate_time')}
                fullWidth
                value={value}
                onChange={(e) => {
                  onChange(e);
                  trigger('estimated_time_hours');
                }}
                helperText={error ? error.message : ''}
                error={error}
                onClear={() => clearHandler(name)}
              />
            )}
          />
          <Typography variant="body2">
            {t('attributes.productCatalogue.esitmated_time_note')}
          </Typography>
        </FormFieldWrapper>
      </CustomCard>
    </>
  );

  const labourForm = (
    <CustomCard>
      <Box sx={{ padding: '16px' }}>
        <Typography variant="body1">{`${t('attributes.productCatalogue.labour_details')}`}</Typography>
      </Box>
      <Divider />
      <FormFieldWrapper>
        <Controller
          name="cost"
          control={control}
          rules={{
            required: `${t('attributes.productCatalogue.cost_price')} ${Validation.general.required}`,
          }}
          render={({
            field: { onChange, value, name },
            fieldState: { error },
          }) => (
            <CustomTextField
              label={t('attributes.productCatalogue.cost_price')}
              fullWidth
              value={value}
              onChange={(e) => {
                onChange(e);
                trigger('cost');
              }}
              helperText={error ? error.message : ''}
              error={error}
              onClear={() => clearHandler(name)}
            />
          )}
        />
        <Typography variant="body2">
          {t('attributes.productCatalogue.labour_details_cost_price_note')}
        </Typography>
        <Controller
          name="sell_price"
          control={control}
          rules={{
            required: `${t('attributes.productCatalogue.default_sell_price')} ${Validation.general.required}`,
          }}
          render={({
            field: { onChange, value, name },
            fieldState: { error },
          }) => (
            <CustomTextField
              label={t('attributes.productCatalogue.default_sell_price')}
              fullWidth
              value={value}
              onChange={(e) => {
                onChange(e);
                trigger('sell_price');
              }}
              helperText={error ? error.message : ''}
              error={error}
              onClear={() => clearHandler(name)}
            />
          )}
        />
        <Controller
          name="description"
          control={control}
          rules={{
            maxLength: {
              value: 300,
              message: Validation.general.description,
            },
          }}
          render={({
            field: { onChange, value, name },
            fieldState: { error },
          }) => (
            <CustomTextField
              label={t('attributes.productCatalogue.description')}
              fullWidth
              isRequired={false}
              value={value}
              onChange={(e) => {
                onChange(e);
                trigger('description');
              }}
              multiline
              rows={2}
              helperText={error ? error.message : ''}
              error={error}
              onClear={() => clearHandler(name)}
            />
          )}
        />
      </FormFieldWrapper>
    </CustomCard>
  );

  return isLoading ? (
    <CustomCircularLoader />
  ) : (
    <FormWrapper>
      <CustomCard>
        <Box sx={{ padding: '16px' }}>
          <Typography variant="body1">{`${t('attributes.productCatalogue.product_type')}`}</Typography>
        </Box>
        <Divider />
        <FormFieldWrapper>
          <Controller
            name="product_name"
            control={control}
            rules={{
              required: `${t('attributes.name')} ${Validation.general.required}`,
              maxLength: {
                value: 80,
                message: Validation.general.contactName,
              },
            }}
            render={({
              field: { onChange, value, name },
              fieldState: { error },
            }) => (
              <CustomTextField
                label={t('attributes.name')}
                fullWidth
                value={value}
                onChange={(e) => {
                  onChange(e);
                  trigger('product_name');
                }}
                helperText={error ? error.message : ''}
                error={error}
                onClear={() => clearHandler(name)}
              />
            )}
          />
          <Controller
            name="type"
            control={control}
            rules={{
              required: `${t('attributes.productCatalogue.type')} ${Validation.general.required}`,
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Autocomplete
                options={PRODUCT_TYPE}
                label={t('attributes.productCatalogue.type')}
                onChange={(e, newValue) => {
                  onChange(newValue);
                  if (selectedType?.value === 'labour') {
                    reset({
                      ...watch(),
                      equipment_type: '',
                      description: '',
                      sell_price: '',
                      supplier_uuid: null,
                      um: null,
                      cost: '',
                      estimated_time_hours: '',
                    });
                  } else {
                    reset({
                      ...watch(),
                      sell_price: '',
                      cost: '',
                      description: '',
                    });
                  }
                }}
                value={value}
                error={error}
                helperText={error ? error.message : ''}
              />
            )}
          />
          <Box display="flex" flexDirection="column" gap="8px">
            <Typography variant="body2">
              <b>{t('attributes.productCatalogue.materials')}</b>{' '}
              {t('attributes.productCatalogue.materials_detail_text')}
            </Typography>
            <Typography variant="body2">
              <b>{t('attributes.productCatalogue.equipment')}</b>{' '}
              {t('attributes.productCatalogue.equipment_details_text')}
            </Typography>
            <Typography variant="body2">
              <b>{t('attributes.productCatalogue.expenses')}</b>{' '}
              {t('attributes.productCatalogue.expenses_details_text')}
            </Typography>
            <Typography variant="body2">
              <b>{t('attributes.productCatalogue.labour')}</b>{' '}
              {t('attributes.productCatalogue.labour_details_text')}
            </Typography>
          </Box>
        </FormFieldWrapper>
      </CustomCard>
      {renderFormBasedOnType()}
      {id && (
        <CustomCard>
          <Box sx={{ padding: '0 16px 0 16px' }}>
            <CommonCheckbox
              control={control}
              name="active"
              label={t('common.active')}
              isRequired={false}
              showMessage={true} // Enable message display
              messageOnChecked={labels('productActiveCheckbox')}
              messageOnUnchecked={labels('productInactiveCheckbox')}
            />
          </Box>
        </CustomCard>
      )}
    </FormWrapper>
  );
};
