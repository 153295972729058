import React from 'react';

import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import { Box, styled, Typography } from '@mui/material';

// Internal
import { LandingPageImage } from '../../assets/images';
import { PRIMARY, SECONDARY } from '../../constants/Colors';

// Styled Components
const Container = styled(Box)({
  width: '100%',
  height: '100vh',
  overflow: 'hidden',
  display: 'flex',
  justifyContent: 'space-between',
});

const Centered = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const DescriptionStyled = styled(Typography)(({ theme }) => ({
  '&.MuiTypography-root': {
    fontSize: '18px',
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const LandingPageBody = () => (
  <Container sx={{ backgroundColor: `${SECONDARY} !important` }}>
    <Centered style={{ marginLeft: '124px' }}>
      <Box style={{ display: 'flex', flexDirection: 'column', rowGap: '20px' }}>
        <Typography color="primary" variant="h5">
          Your Fire Safety <br /> Solution in the Cloud
        </Typography>
        <Typography color="secondary.contrastText" variant="h4">
          Streamlined Fire Safety Management
        </Typography>
        <DescriptionStyled>
          Fire <span style={{ color: PRIMARY }}>ORA</span>’s PaaS and SaaS
          solutions streamline all aspects of fire <br /> safety and compliance.
          Our cloud-based platform adapts to your <br /> business, ensuring
          seamless management and scalability.
        </DescriptionStyled>
        <Box sx={{ display: 'flex' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <CheckCircleRoundedIcon
              sx={{
                color: PRIMARY,
                height: '24px',
                width: '24px',
              }}
            />
            <Typography
              sx={{
                fontWeight: '400',
                fontSize: '16px',
                paddingLeft: '16px',
              }}
            >
              Service
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              paddingLeft: '30px',
            }}
          >
            <CheckCircleRoundedIcon
              sx={{
                color: PRIMARY,
                height: '24px',
                width: '24px',
              }}
            />
            <Typography
              sx={{
                fontWeight: '400',
                fontSize: '16px',
                paddingLeft: '16px',
              }}
            >
              Testing
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              paddingLeft: '30px',
            }}
          >
            <CheckCircleRoundedIcon
              sx={{
                color: PRIMARY,
                height: '24px',
                width: '24px',
              }}
            />
            <Typography
              sx={{
                fontWeight: '400',
                fontSize: '16px',
                paddingLeft: '16px',
              }}
            >
              Auditing
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              paddingLeft: '30px',
            }}
          >
            <CheckCircleRoundedIcon
              sx={{
                color: PRIMARY,
                height: '24px',
                width: '24px',
              }}
            />
            <Typography
              sx={{
                fontWeight: '400',
                fontSize: '16px',
                paddingLeft: '16px',
              }}
            >
              Maintenance
            </Typography>
          </Box>
        </Box>
      </Box>
    </Centered>
    <Centered style={{ marginRight: '124px' }}>
      <img
        src={LandingPageImage}
        alt="Landing Page"
        style={{ maxWidth: '100%', height: 'auto' }}
      />
    </Centered>
  </Container>
);

export default LandingPageBody;
