import { Navigate } from 'react-router-dom';

import { CUSTOMER } from '../constants/Roles';
import { ROUTENAME } from '../constants/RoutesConstants';
import { getUserRole } from './Permission';

const UnAuthLayout = ({ children }) => {
  const userInfo = localStorage.getItem('user_info');
  const userRole = getUserRole();
  const redirectPath =
    CUSTOMER === userRole
      ? `${ROUTENAME.DASHBOARD}/${ROUTENAME.PROPERTIES}`
      : ROUTENAME.DASHBOARD;

  return userInfo ? <Navigate to={redirectPath} /> : children;
};

export default UnAuthLayout;
