import { Typography, Box } from '@mui/material';

//Internal
import MainWrapper from '../CommonComponents/MainWrapper';

const EmailTemplate = () => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
    }}
  >
    <MainWrapper>
      <Typography variant="h1">Coming Soon</Typography>
    </MainWrapper>
  </Box>
);

export default EmailTemplate;
