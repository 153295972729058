import { Carousel } from 'react-responsive-carousel';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Box, styled } from '@mui/material';

import 'react-responsive-carousel/lib/styles/carousel.min.css';

const CommonCarouselView = ({
  currentSlide = 0,
  onPreviousClick = () => {},
  onNextClick = () => {},
  widthInPercentage = 25,
  children,
  totalItems,
  arrowSize = '12px',
  arrowWrapperSize = '20px',
  arrowLeft = 0,
  arrowRight = 0,
  ...rest
}) => {
  const CustomStyledArrow = styled(Box)(({ theme, left, right, disabled }) => ({
    height: arrowWrapperSize,
    width: arrowWrapperSize,
    backgroundColor: theme.palette.primary.main,
    borderRadius: '50%',
    display: disabled ? 'none' : 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: '50%',
    left,
    right,
    zIndex: 9999,
  }));

  const CustomPrevArrow = ({ onClick, disabled }) => (
    <CustomStyledArrow left={arrowLeft} onClick={onClick} disabled={disabled}>
      <ArrowBackIosIcon
        style={{
          height: arrowSize,
          width: arrowSize,
          fill: 'white',
        }}
      />
    </CustomStyledArrow>
  );

  const CustomNextArrow = ({ onClick, disabled }) => (
    <CustomStyledArrow right={arrowRight} onClick={onClick} disabled={disabled}>
      <ArrowForwardIosIcon
        style={{
          height: arrowSize,
          width: arrowSize,
          fill: 'white',
        }}
      />
    </CustomStyledArrow>
  );

  return (
    <Carousel
      renderArrowPrev={() => (
        <CustomPrevArrow
          onClick={onPreviousClick}
          disabled={currentSlide === 0}
        />
      )}
      renderArrowNext={() => (
        <CustomNextArrow
          onClick={onNextClick}
          disabled={currentSlide === totalItems - 1}
        />
      )}
      showThumbs={false}
      showStatus={false}
      showIndicators={false}
      centerMode
      centerSlidePercentage={widthInPercentage}
      selectedItem={currentSlide}
      {...rest}
    >
      {children}
    </Carousel>
  );
};
export default CommonCarouselView;
