import { createSlice } from '@reduxjs/toolkit';

import {
  downloadSampleTemplate,
  getDocumentList,
  getDocumentUploadStatus,
  uploadBulkDocument,
} from './api';

const initialGetData = {
  isLoading: true,
  error: null,
  quoteList: [],
  page: 1,
  size: 10,
  total: 0,
  totalCount: 0,
};

const documentSlice = createSlice({
  name: 'documents',
  initialState: {
    get: { ...initialGetData },
    upload: {
      isDocumentLoading: false,
      uploadedSuccessfully: false,
      uploadError: null,
      documentData: null,
    },
    template: {
      isDownloading: false,
      downloadError: null,
      documentData: null,
    },
    getUploadStatus: {
      isDocStatusLoading: false,
      isDocStatusError: null,
      documentUploadStatus: null,
    },
  },
  reducers: {
    setDocumentStatus(state) {
      state.isDocStatusLoading = false;
    },
    resetDocumentList(state) {
      state.get = { ...initialGetData };
    },
    resetDocumentData(state) {
      state.upload.documentData = null;
      state.upload.isDocumentLoading = false;
      state.upload.uploadedSuccessfully = false;
      state.upload.uploadError = null;
    },
    resetTemplateData(state) {
      state.template.documentData = null;
      state.template.isDownloading = false;
      state.template.downloadError = null;
    },
    resetDocumentUploadStatus(state) {
      state.getUploadStatus = {
        isDocStatusLoading: false,
        isDocStatusError: null,
        documentUploadStatus: null,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      // Quote List
      .addCase(getDocumentList.pending, (state) => {
        state.get.isLoading = true;
      })
      .addCase(getDocumentList.fulfilled, (state, action) => {
        const { data, pagination } = action.payload;

        state.get.isLoading = false;
        state.get.documentList = data;
        state.get.total = pagination.total_items;
        state.get.totalCount = pagination.total_count;
      })
      .addCase(getDocumentList.rejected, (state, action) => {
        state.get.isLoading = false;
        state.get.error = action.payload;
      })
      .addCase(uploadBulkDocument.pending, (state) => {
        state.upload.isDocumentLoading = true;
      })
      .addCase(uploadBulkDocument.fulfilled, (state, action) => {
        state.upload.isDocumentLoading = false;
        state.upload.uploadedSuccessfully = true;
        state.upload.documentData = action.payload?.data[0];
      })
      .addCase(uploadBulkDocument.rejected, (state, action) => {
        state.upload.isDocumentLoading = false;
        state.upload.uploadError = action.payload;
      })
      // Download Sample Template
      .addCase(downloadSampleTemplate.pending, (state) => {
        state.template.isDownloading = true;
      })
      .addCase(downloadSampleTemplate.fulfilled, (state, action) => {
        state.template.isDownloading = false;
        state.template.documentData = action.payload;
      })
      .addCase(downloadSampleTemplate.rejected, (state, action) => {
        state.template.isDownloading = false;
        state.template.downloadError = action.payload;
      })
      .addCase(getDocumentUploadStatus.pending, (state) => {
        state.getUploadStatus.isDocStatusLoading = true;
      })
      .addCase(getDocumentUploadStatus.fulfilled, (state, action) => {
        state.getUploadStatus.isDocStatusLoading = false;
        state.getUploadStatus.documentUploadStatus = action.payload;
      })
      .addCase(getDocumentUploadStatus.rejected, (state, action) => {
        state.getUploadStatus.isDocStatusLoading = false;
        state.getUploadStatus.isDocStatusError = action.payload;
      });
  },
});

export const { resetDocumentList, resetDocumentData, resetTemplateData } =
  documentSlice.actions;

export default documentSlice.reducer;
