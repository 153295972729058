import { Box, Typography } from '@mui/material';
import { PieChart as CustomPieChart } from '@mui/x-charts/PieChart';

import { DIVIDER_COLOR } from '../../../constants/Colors';
import { FONT_SIZE } from '../../../constants/Constants';

const PieChart = ({
  data = [],
  isRadius = false,
  isArcLabel = true,
  isLegendBelowToGraph = false,
}) => {
  // Data Config
  let defaultConfig = {
    data: data,
    type: 'pie',
    cx: 180,
    cy: 150,
    outerRadius: 110,
  };
  // Legend Config
  let defaultLegendConfig = {};

  if (isRadius) {
    defaultConfig = {
      ...defaultConfig,
      innerRadius: 30,
      paddingAngle: 5,
      cornerRadius: 5,
      startAngle: 0,
      endAngle: 360,
    };
  }

  const TOTAL = data.map((item) => item.value).reduce((a, b) => a + b, 0);

  const getArcLabel = (params) => {
    const percent = params.value / TOTAL;

    return `${(percent * 100).toFixed(0)}%`;
  };

  if (isArcLabel) {
    defaultConfig = { ...defaultConfig, arcLabel: getArcLabel };
  }

  // Legend
  if (!isLegendBelowToGraph) {
    defaultLegendConfig = {
      position: {
        vertical: 'middle',
        horizontal: 'right',
      },
      labelStyle: {
        fontSize: FONT_SIZE,
        fontWeight: 400,
      },
      itemMarkWidth: 12,
      itemMarkHeight: 12,
      markGap: 5,
      itemGap: 10,
    };
  } else {
    defaultLegendConfig = { hidden: true };
  }

  return isLegendBelowToGraph ? (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ flexGrow: 1 }}>
        <CustomPieChart
          series={[defaultConfig]}
          slotProps={{
            legend: defaultLegendConfig,
          }}
        />
      </Box>

      {data?.map((d) => (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderTop: `1px solid ${DIVIDER_COLOR}`,
            padding: '8px',
          }}
        >
          <Typography variant="body2">{d.label}</Typography>
          <Box
            sx={{
              height: '20px',
              width: '19px',
              borderRadius: '4px',
              background: d.color,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Typography fontSize="10px" fontWeight={600} color="secondary">
              {d?.value}
            </Typography>
          </Box>
        </Box>
      ))}
    </Box>
  ) : (
    <CustomPieChart
      series={[defaultConfig]}
      slotProps={{
        legend: defaultLegendConfig,
      }}
    />
  );
};

export default PieChart;
