import InsertPhotoOutlinedIcon from '@mui/icons-material/InsertPhotoOutlined';
import { Box, styled, Typography } from '@mui/material';

const LogoOuterWrapper = styled(Box)({
  border: '1px solid #0000003B',
  borderRadius: '4px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

const LogoInnerWrapper = styled(Box)({
  width: '210px',
  height: '90px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
});

const UploadLogo = ({ logoUrl = '' }) => (
  <LogoOuterWrapper>
    <LogoInnerWrapper>
      {logoUrl ? (
        <img
          src={logoUrl}
          alt="Logo"
          style={{ objectFit: 'cover', height: 'inherit', width: 'inherit' }}
        />
      ) : (
        <>
          <InsertPhotoOutlinedIcon />
          <Typography variant="caption">Upload Logo</Typography>
        </>
      )}
    </LogoInnerWrapper>
  </LogoOuterWrapper>
);

export default UploadLogo;
