import { useMemo } from 'react';
import { useSelector } from 'react-redux';

const getStatesHook = () => {
  const stateCityList = useSelector((state) => state.common?.stateCityList);

  return useMemo(() => {
    if (!stateCityList || !stateCityList.Australia) {
      return [];
    }

    return Object.keys(stateCityList.Australia).map((key) => ({
      label: key,
      value: key,
    }));
  }, [stateCityList]);
};

export default getStatesHook;
