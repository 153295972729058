import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { Box } from '@mui/material';

import { COLUMNS } from '../../../constants/ImportConstans';
import { snackbarToggle } from '../../../store/CommonReducer';
import CustomGridTable from '../CustomGridTable';
import NoRecordFound from '../NoDataPage/NoRecordFound';

const ValidationPreview = ({
  selectedDocument,
  hasError,
  setHasError,
  setIsFileEmpty,
}) => {
  const [parsedData, setParsedData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { data, fileModule } = selectedDocument;
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const columns = COLUMNS[fileModule];

  const uploadObjLength = (data, module) =>
    Object.keys(data[0]).length !== module.length;

  const columnsPresent = (data, module) =>
    module.some((item) => Object.keys(data[0]).includes(item.field));

  useEffect(() => {
    setHasError(false);
    setIsFileEmpty(false);

    if (
      data.length === 0 ||
      uploadObjLength(data, columns) ||
      !columnsPresent(data, columns)
    ) {
      dispatch(
        snackbarToggle({
          isOpen: true,
          isErrorMsg: true,
          msg: t(
            `message.common.${data.length === 0 ? 'fileEmpty' : 'uploadFileIncorrect'}`
          ),
        })
      );
      data.length === 0 && setIsFileEmpty(true);
      setHasError(true);
      setIsLoading(false);

      return;
    }

    setParsedData(data.map((el, id) => ({ id, ...el })));
    setIsLoading(false);

    return () => {
      setHasError(false);
      setIsFileEmpty(false);
    };
  }, []);

  return (
    <Box
      sx={{
        height: 'calc(100vh - 37vh)',
      }}
    >
      <CustomGridTable
        checkboxSelection={false}
        columns={columns}
        rows={hasError ? [] : parsedData}
        noData={<NoRecordFound />}
        isLoading={isLoading}
        disableColumnMenu
        paginationRequired={false}
      />
    </Box>
  );
};

export default ValidationPreview;
