import { createSlice } from '@reduxjs/toolkit';

import { getAddressDetail, getAutocompleteAddress } from './api';

const autocompleteAddress = {
  isLoading: false,
  error: false,
  data: false,
};

const googleAPIsSlice = createSlice({
  name: 'googleAPIs',
  initialState: {
    autocompleteAddress,
    addressDetail: { ...autocompleteAddress },
  },
  reducers: {
    resetAutocompleteAddress(state) {
      state.autocompleteAddress = { ...autocompleteAddress };
    },
    resetAddressDetail(state) {
      state.addressDetail = { ...autocompleteAddress };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAutocompleteAddress.pending, (state) => {
        state.autocompleteAddress.isLoading = true;
      })
      .addCase(getAutocompleteAddress.fulfilled, (state, action) => {
        state.autocompleteAddress.isLoading = false;
        state.autocompleteAddress.data = action.payload.data[0].predictions.map(
          (val) => ({
            label: val.description,
            value: val.place_id,
          })
        );
      })
      .addCase(getAutocompleteAddress.rejected, (state, action) => {
        state.autocompleteAddress.isLoading = false;
        state.autocompleteAddress.error = action.error.message;
      })
      .addCase(getAddressDetail.pending, (state) => {
        state.addressDetail.isLoading = true;
      })
      .addCase(getAddressDetail.fulfilled, (state, action) => {
        state.addressDetail.isLoading = false;
        state.addressDetail.data = action.payload.data[0];
      })
      .addCase(getAddressDetail.rejected, (state, action) => {
        state.addressDetail.isLoading = false;
        state.addressDetail.error = action.error.message;
      });
  },
});

export default googleAPIsSlice.reducer;

export const { resetAutocompleteAddress, resetAddressDetail } =
  googleAPIsSlice.actions;
