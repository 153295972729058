import React, { useCallback, useMemo } from 'react';
import { Views } from 'react-big-calendar';
import { useTranslation } from 'react-i18next';

import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Box, IconButton, Typography } from '@mui/material';
import moment from 'moment';

import { SECONDARY, TEXT_COLOR } from '../../constants/Colors';
import {
  SCHEDULE_BOARD_CALENDAR_VIEW,
  SCHEDULE_BOARD_DAY,
  SCHEDULE_BOARD_MONTH,
  SCHEDULE_BOARD_WEEK_END,
  SCHEDULE_BOARD_WEEK_START,
} from '../../constants/Constants';
import getDropdownListHook from '../../hooks/getDropdownListHook';
import Autocomplete from '../CommonComponents/AutoComplete';
import CustomButton from '../CommonComponents/CustomButton';
import TechnicianList from './TimesheetTechList';

const TimesheetCalendarControls = ({
  date,
  setDate,
  selectedView,
  setSelectedView,
  zonesDropdown,
  setZonesDropdown,
  disableControls,
  setDisplayCalendarLoader,
  selectedTechnician,
  setSelectedTechnician,
  displayCalendarLoader,
  selectedTab,
  setSelectedTab,
}) => {
  const { t } = useTranslation();

  const { zonesDropdownLoading, zonesDropdownData } = getDropdownListHook({
    reducerName: 'zones',
    dropdownListName: 'zonesDropdownList',
    labelName: 'display_name',
    valueName: 'name',
  });

  const onNextClick = useCallback(() => {
    setDisplayCalendarLoader(true);
    if (selectedView.value === Views.WEEK) {
      setDate(moment(date).add(1, 'week').toDate());
    } else if (selectedView.value === Views.DAY) {
      setDate(moment(date).add(1, 'day').toDate());
    } else if (selectedView.value === Views.MONTH) {
      setDate(moment(date).add(1, 'month').toDate());
    }
  }, [date, selectedView]);

  const onPreviousClick = useCallback(() => {
    setDisplayCalendarLoader(true);
    if (selectedView.value === Views.WEEK) {
      setDate(moment(date).subtract(1, 'week').toDate());
    } else if (selectedView.value === Views.DAY) {
      setDate(moment(date).subtract(1, 'day').toDate());
    } else if (selectedView.value === Views.MONTH) {
      setDate(moment(date).subtract(1, 'month').toDate());
    }
  }, [date, selectedView]);

  const dateText = useMemo(() => {
    if (Views.WEEK === selectedView.value) {
      const startDate = moment(date)
        .startOf('week')
        .format(SCHEDULE_BOARD_WEEK_START);
      const endDate = moment(date)
        .endOf('week')
        .format(SCHEDULE_BOARD_WEEK_END);
      let dateRange = `${startDate}-${endDate}`;

      return dateRange;
    } else if (Views.DAY === selectedView.value) {
      return moment(date).format(SCHEDULE_BOARD_DAY);
    } else if (Views.MONTH === selectedView.value) {
      return moment(date).format(SCHEDULE_BOARD_MONTH);
    }
  }, [date, selectedView]);

  return (
    <>
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
        height={43}
        padding={'0 13px 0 13px'}
      >
        {/* FlexStart Section */}
        <Box display="flex" alignItems="center" gap={2}>
          <Box
            sx={{
              maxHeight: '26px !important',
              maxWidth: '26px !important',
              borderRadius: '4px',
              border: `1px solid ${TEXT_COLOR}`,
              padding: '4px',
              cursor: disableControls ? 'not-allowed !important' : 'pointer',
              pointerEvents: 'auto',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            disabled={disableControls}
            onClick={onPreviousClick}
          >
            <IconButton disabled={disableControls}>
              <KeyboardArrowLeftIcon />
            </IconButton>
          </Box>
          <Typography variant="body1" color={TEXT_COLOR}>
            {dateText}
          </Typography>
          <Box
            sx={{
              maxHeight: '26px !important',
              maxWidth: '26px !important',
              borderRadius: '4px',
              border: `1px solid ${TEXT_COLOR}`,
              padding: '4px',
              cursor: disableControls ? 'not-allowed !important' : 'pointer',
              pointerEvents: 'auto',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            disabled={disableControls}
            onClick={onNextClick}
          >
            <IconButton disabled={disableControls}>
              <KeyboardArrowRightIcon />
            </IconButton>
          </Box>
          <CustomButton
            sx={{
              bgcolor: SECONDARY,
              height: '26px',
            }}
            text="Today"
            variant="outlined"
            onClick={() => {
              setDisplayCalendarLoader(true);
              setDate(moment().toDate());
            }}
            disabled={disableControls}
          />
        </Box>
        <Box display="flex" alignItems="center" gap={2}>
          <Autocomplete
            placeholder={t('attributes.property.zone')}
            options={zonesDropdownData}
            value={zonesDropdown}
            isLoadingData={zonesDropdownLoading}
            onChange={(e, newValue) => {
              setZonesDropdown(newValue);
              setDisplayCalendarLoader(true);
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                height: '26px',
                width: '150px',
              },
            }}
          />
          <Autocomplete
            options={[
              { label: SCHEDULE_BOARD_CALENDAR_VIEW.DAY, value: Views.DAY },
              { label: SCHEDULE_BOARD_CALENDAR_VIEW.WEEK, value: Views.WEEK },
              { label: SCHEDULE_BOARD_CALENDAR_VIEW.MONTH, value: Views.MONTH },
            ]}
            value={selectedView}
            onChange={(e, newVal) => {
              if (newVal) {
                setDate(moment().toDate());
                setDisplayCalendarLoader(true);
                setSelectedView(newVal);
              }
            }}
            disableClearable={true}
            disabledDropdown={disableControls}
            calendarIcon={true}
            sx={{
              '& .MuiOutlinedInput-root': {
                height: '26px',
                width: '125px',
              },
            }}
          />
        </Box>
      </Box>
      {!displayCalendarLoader && (
        <TechnicianList
          selectedTechnician={selectedTechnician}
          setSelectedTechnician={setSelectedTechnician}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
        />
      )}
    </>
  );
};

export default TimesheetCalendarControls;
