/**
 * NOTA: Por favor, mantén el orden alfabético para facilitar la búsqueda y navegación dentro del archivo.
 * Añade los valores según las siguientes etiquetas:
 *
 * 1. attributes - Esta sección incluye valores de atributos a nivel del sitio, por ejemplo, Email, Nombre, Contraseña, etc.
 * 2. auth - Esta sección incluye todas las etiquetas relacionadas con la autenticación, con objetos anidados para cada tipo de autenticación (por ejemplo, login, signUp, passwordReset, etc.).
 * 3. common - Esta sección incluye traducciones comunes utilizadas en toda la aplicación.
 * 4. label - Esta sección incluye etiquetas para botones, por ejemplo, Iniciar sesión, Registrarse, etc.
 * 5. message - Esta sección incluye mensajes comunes que se mostrarán en la aplicación, por ejemplo, Correo verificado, etc.
 * 6. modal - Esta sección incluye todas las etiquetas, descripciones y subtítulos para los modales, por ejemplo, Modal de Registro, Modal de Configuración, etc.
 *
 **/

const translation = {
  attributes: {
    email: 'Correo electrónico',
    name: 'Nombre',
  },

  auth: {
    login: {
      continueLoginWith: 'Continuar Iniciar sesión con',
      login: 'Iniciar sesión',
    },
  },

  common: {
    next: 'Siguiente',
  },

  label: {
    login: 'Iniciar sesión',
  },

  message: {
    errorPage: {
      error: 'Página no encontrada',
      errorMessage: 'La página que buscas no existe.',
      status: '404',
    },
    forbiddenPage: {
      forbidden: 'Prohibido',
      forbiddenMessage: 'No tienes permiso para ver esta página.',
      status: '403',
    },
  },

  modal: {
    delete: {
      message: '¿Estás seguro de que quieres eliminar?',
    },
  },
};

export default { translation };
